import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Prestation, PrestationType } from 'src/app/shared/model/prestation.model';
import { PrestationService } from 'src/app/shared/services/prestation.service';
import { PrestationDialogComponent } from '../prestation-dialog/prestation-dialog.component';
import { RejectPrestationDialogComponent } from '../reject-prestation-dialog/reject-prestation-dialog.component';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss']
})
export class PrestationsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['prestataire', 'type', 'periode', 'periodetype', 'status', 'actions'];
  public data: Prestation[];
  public sortedData: Prestation[];
  public organisation: Organisation;
  public hasGottenData = false;
  private dialog$: Subscription;
  private rejectDialog$: Subscription;

  constructor(private prestationService: PrestationService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.getPrestations();
  }

  /**
   * Sort table prestations
   */
  public sortData(sort: Sort): void {
    const data = this.sortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'mandant':
          return this.compare(a.organisationOwner.name, b.organisationOwner.name, isAsc);
        case 'prestataire':
          return this.compare(a.organisationRecipient.name, b.organisationRecipient.name, isAsc);
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        case 'periode':
          return this.compare(a.dateFrom, b.dateFrom, isAsc);
        case 'status':
          return this.compare(a.statusLabel, b.statusLabel, isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Get prestation type label
   * @param type Type to get the label of
   */
  public getTypeLabel(type: PrestationType): string {
    if (type === PrestationType.DATAPROVIDER) {
      return 'Fourniture de données privées';
    } else if (type === PrestationType.DATAPROVIDERINVENTORY) {
      return "Fourniture de données d'inventaire";
    } else if (type === PrestationType.HELP) {
      return 'Accompagnement';
    } else {
      return type;
    }
  }

  /**
   * Create prestation
   */
  public addPrestation(): void {
    this.openPrestation(null);
  }

  /**
   * View prestation
   * @param prestation - Prestation to view
   */
  public viewPrestation(prestation: Prestation): void {
    this.openPrestation(prestation);
  }

  /**
   * Renew prestation
   * @param prestation - Prestation to eenew
   */
  public renewPrestation(prestation: Prestation): void {
    this.openPrestation(prestation, ['prestataire', 'type', 'comment', 'dateFrom', 'dateTo'], 'renew');
  }

  /**
   * Confirm prestation
   * @param prestation - Prestation to confirm
   */
  public confirmPrestation(prestation: Prestation): void {
    this.openPrestation(prestation, ['dateTo'], 'confirm');
  }

  /**
   * Edit prestation
   * @param prestation - Prestation to edit
   */
  public editPrestation(prestation: Prestation): void {
    this.openPrestation(prestation, ['prestataire', 'type', 'comment', 'dateFrom', 'dateTo']);
  }

  /**
   * Open prestation dialog
   * @param prestation - Prestation to open, if any
   * @param canEdit - List of editable fields
   */
  public openPrestation(prestation?: Prestation, canEdit?: Array<string>, type?: string): void {
    const dialogRef = this.dialog.open(PrestationDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['prestation-dialog'],
      data: {
        prestation: prestation ? prestation : null,
        canEdit: canEdit ? canEdit : [],
        type: type ? type : null
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldReload => {
      if (shouldReload) {
        this.getPrestations();
      }
    });
  }

  /**
   * Reject prestation
   * @param prestation - Prestation to reject
   */
  public rejectPrestation(prestation: Prestation): void {
    const dialogRef = this.dialog.open(RejectPrestationDialogComponent, {
      disableClose: true,
      panelClass: ['prestation-dialog'],
      data: prestation
    });

    this.rejectDialog$ = dialogRef.afterClosed().subscribe(shouldReject => {
      if (shouldReject) {
        const editedPrestation = {
          status: 'reject'
        };

        this.prestationService.editPrestation(prestation.id, editedPrestation).subscribe(() => {
          this.getPrestations();
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }

    if (this.rejectDialog$) {
      this.rejectDialog$.unsubscribe();
    }
  }

  /**
   * Get list of prestations
   */
  private getPrestations(): void {
    let query = null;
    if (this.organisation.type === OrganisationType.COMMUNE) {
      query = `?organisationOwner=${this.organisation.id}`;
    } else if (this.organisation.type === OrganisationType.SERVICEPROVIDER) {
      this.displayedColumns = ['mandant', 'type', 'periode', 'status'];
      query = `?organisationRecipient=${this.organisation.id}`;
    }

    this.prestationService.getPrestationsByOrganisation(query).subscribe(prestations => {
      this.handlePrestations(prestations);
    });
  }

  /**
   * Handle list of prestations
   * @param prestations - Prestations list
   */
  private handlePrestations(prestations: Prestation[]): void {
    const now = new Date();
    prestations.forEach(prestation => {
      const prestationStart = new Date(prestation.dateFrom);
      const prestationEnd = new Date(prestation.dateTo);
      if (prestationEnd.getTime() < now.getTime()) {
        prestation.periodType = 'Passé';
      } else if (prestationStart.getTime() > now.getTime()) {
        prestation.periodType = 'Futur';
      } else if (prestationStart.getTime() <= now.getTime() && prestationEnd.getTime() >= now.getTime()) {
        prestation.periodType = 'Actuel';
      } else {
        prestation.periodType = '-';
      }
    });
    this.hasGottenData = true;
    this.data = prestations;
    this.sortedData = prestations;
    this.sortData({ active: 'status', direction: 'asc' });
  }

  /**
   * Compare to sort
   */
  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
