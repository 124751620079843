<div *ngIf="organisation" class="dashboard">
  <div class="row">
    <div class="col-9">
      <div class="col-12 d-flex dashboard__header">
        <h1 class="mt-0">Tableau de bord</h1>
        <h3 *ngIf="organisation" class="ml-4 mt-0">{{ organisation.name }}</h3>
      </div>
      <div class="row dashboard__content">
        <div *ngIf="organisation.type !== 'service_provider'" class="col-12 pr-0">
          <div class="dashboard__section dashboard__section--with-separation">
            <div class="dashboard__subsection">
              <app-graph-waste-type-rate></app-graph-waste-type-rate>
            </div>
            <div class="dashboard__subsection">
              <app-graph-waste-evolution></app-graph-waste-evolution>
            </div>
          </div>
        </div>
        <div *ngIf="organisation.type !== 'service_provider'" class="col-6 mb-0 d-none d-xl-block">
          <div class="dashboard__section">
            <app-graph-waste-per-resident></app-graph-waste-per-resident>
          </div>
        </div>
        <div *ngIf="organisation.type !== 'service_provider'" class="col-6 mb-0 d-none d-xl-block px-0">
          <div class="dashboard__section dashboard__section--secondary">
            <app-top-elements [isRecyclable]="true"></app-top-elements>
          </div>
        </div>
        <div *ngIf="organisation.type === 'service_provider'" class="col-6">
          <div class="dashboard__section">
            <app-graph-performance-rate></app-graph-performance-rate>
          </div>
        </div>
        <div *ngIf="organisation.type === 'service_provider'" class="col-6 pl-0">
          <div class="dashboard__section dashboard__section--primary">
            <app-top-elements [isRecyclable]="false"></app-top-elements>
          </div>
        </div>
        <div *ngIf="organisation.type === 'service_provider'" class="col-12 mb-0 d-none d-xl-block">
          <div class="dashboard__section dashboard__section--table">
            <div class="d-flex justify-content-between">
              <h4>Dernières déclarations</h4>
              <a [routerLink]="['/gestion/declarations-detail']" class="btn btn-small">Détails</a>
            </div>
            <app-last-import-events></app-last-import-events>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mb-3 mb-xl-0 declarations-status__container">
      <div class="dashboard__section dashboard__section--white">
        <app-declarations-status></app-declarations-status>
      </div>
    </div>
    <div *ngIf="organisation.type !== 'service_provider'" class="col-6 pb-4 d-xl-none">
      <div class="dashboard__section">
        <app-graph-waste-per-resident></app-graph-waste-per-resident>
      </div>
    </div>
    <div *ngIf="organisation.type !== 'service_provider'" class="col-6 pb-4 d-xl-none pl-0">
      <div class="dashboard__section dashboard__section--secondary">
        <app-top-elements [isRecyclable]="true"></app-top-elements>
      </div>
    </div>
    <div *ngIf="organisation.type === 'service_provider'" class="col-12 mb-0 d-xl-none">
      <div class="dashboard__section dashboard__section--table">
        <div class="d-flex justify-content-between">
          <h4>Dernières déclarations</h4>
          <a [routerLink]="['/gestion/declarations-detail']" class="btn btn-small">Détails</a>
        </div>
        <app-last-import-events></app-last-import-events>
      </div>
    </div>
  </div>
</div>
