import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/guards/component-can-deactivate.guard';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Code } from 'src/app/shared/model/code.model';
import { WasteflowViews } from 'src/app/shared/model/wasteflow.model';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { WasteflowsTableComponent } from 'src/app/shared/components/wasteflows-table/wasteflows-table.component';
import { SiteService } from 'src/app/shared/services/site.service';
import { DataElement } from 'src/app/shared/model/dashboard.model';
import { map } from 'lodash';
import { multiselectConfiguration } from 'src/app/shared/data';
import { delay } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { getSelectedYear$ } from '../../../core/store/selectors/app.selectors';
import { AppState } from '../../../core/store/states/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sites-flow-detail',
  templateUrl: './sites-flow-detail.component.html',
  styleUrls: ['./sites-flow-detail.component.scss']
})
export class SitesFlowDetailComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
  @ViewChild(WasteflowsTableComponent) wasteflowTable: WasteflowsTableComponent;
  public V = WasteflowViews;
  public currentView: WasteflowViews = this.V.MAIN;
  public disableEditFields = true;
  public organisation: Organisation;
  public dataChart: DataElement[];
  public dynamicChartHeight = 'auto';
  public colorScheme = {
    domain: []
  };
  public codeLmodList: Code[] = [];
  public selectedCodeLmod: Code[];
  public previousSelectedCodeLmod: Code[];
  public submitting = false;
  public selectedSiteId: number;
  public multiselectConfiguration = multiselectConfiguration;
  public xAxisTickFormatting = this.xAxisTickFormattingFn.bind(this);
  private dialog$: Subscription;
  private subscriptions$: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private siteService: SiteService,
    private cdRef: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private store$: Store<AppState>
  ) {}

  /**
   * Controls that the user is not trying to leave the page without any unsaved changes
   */
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.submitting && this.disableEditFields;
  }

  /**
   * Format x axis tick label
   * @param value - Value to format
   */
  public xAxisTickFormattingFn(value: string | number): string {
    return this.decimalPipe.transform(value, '', 'fr');
  }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    // Check in route if there is a parameter. If so, it means we need to get details from a specific declaration ID
    this.selectedSiteId = parseInt(this.route.snapshot.params.id, 10);
    this.subscriptions$.push(
      this.store$.select(getSelectedYear$).subscribe(() => {
        this.getFilters().then(() => {
          this.filterWasteflows();
        });
      })
    );
  }

  public getChartData(): void {
    let selectedCodeLmod = map(this.codeLmodList, 'id');
    if (this.selectedCodeLmod?.length) {
      selectedCodeLmod = map(this.selectedCodeLmod, 'id');
    }
    if (this.selectedSiteId) {
      this.siteService.getSiteFlowDetails(this.selectedSiteId, selectedCodeLmod).subscribe(data => {
        this.dataChart = data.data;
        // Add colors to color scheme of the chart.
        this.colorScheme.domain = data.colors;
      });
    }
  }

  /**
   * Filter wasteflows list
   * Called when a filter has been selected/deselecter
   */
  public filterWasteflows(askForConfirmation = true): void {
    if (askForConfirmation) {
      this.dialog$ = this.askForConfirmation().subscribe(result => {
        if (result) {
          this.saveFiltersPreviousValues();
          this.getWasteflows();
          this.getChartData();
        } else {
          this.selectedCodeLmod = this.previousSelectedCodeLmod;
        }
      });
    } else {
      this.saveFiltersPreviousValues();
      this.getWasteflows();
      this.getChartData();
    }
    this.setDynamicChartHeight();
    this.subscriptions$.push(this.dialog$);
  }

  /**
   * Reinitialize filters to "all"
   */
  public setFiltersBack(): void {
    this.selectedCodeLmod = undefined;
    this.saveFiltersPreviousValues();
  }

  /**
   * Reinitialize filters to "all" and filter wasteflows again
   */
  public deleteFilters(): void {
    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        this.setFiltersBack();
        this.filterWasteflows(false);
      }
    });
    this.subscriptions$.push(this.dialog$);
  }

  /**
   * Cancel all changes, reload content
   */
  public cancelChanges(): void {
    this.getWasteflows();
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  isSaveDisabled(): boolean {
    if (this.wasteflowTable) {
      return !this.wasteflowTable.canSave || this.disableEditFields;
    }
    return true;
  }

  /**
   * Calculate the height of the graph by its number of items.
   */
  private setDynamicChartHeight(): void {
    const countLmod = this.selectedCodeLmod?.length ? this.selectedCodeLmod.length : this.codeLmodList?.length;
    this.dynamicChartHeight = countLmod ? countLmod * 30 + 'px' : 'auto';
  }

  /**
   * Get lists to fill in the 3 filters for the page on main view
   */
  private getFilters(): Promise<boolean> {
    return new Promise(resolve => {
      this.siteService
        .getLmodCodes(this.selectedSiteId)
        .pipe(delay(0))
        .subscribe(list => {
          list.sort((a, b) => {
            return this.compare(a.label, b.label, true);
          });
          this.codeLmodList = list;
          resolve(true);
        });
    });
  }

  /**
   * If user has unsaved changes, ask to confirm the action. Otherwise changes won't be saved
   */
  private askForConfirmation(): Observable<boolean> {
    if (!this.submitting && this.disableEditFields) {
      return of(true);
    } else {
      return this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Voulez-vous vraiment quitter la page?',
            text: 'Vous avez des modifications non sauvegardées sur cette page.'
          }
        })
        .afterClosed();
    }
  }

  /**
   * Save filters previous values when changing them
   */
  private saveFiltersPreviousValues(): void {
    this.previousSelectedCodeLmod = this.selectedCodeLmod;
  }

  /**
   * Compare to sort filters
   */
  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private getWasteflows(): void {
    setTimeout(() => {
      if (this.wasteflowTable) {
        this.wasteflowTable.getWasteflows();
      }
    }, 0);
  }
}
