import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RejectPrestationDialogComponent } from 'src/app/modules/prestations/reject-prestation-dialog/reject-prestation-dialog.component';
import { Code, Unit } from 'src/app/shared/model/code.model';
import { Wasteflow } from 'src/app/shared/model/wasteflow.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { UnitService } from '../../../shared/services/unit.service';

interface WasteflowVentilationDialogData {
  units: Unit[];
  lmodCodes: Code[];
  treatments: Code[];
  wasteflow: Wasteflow;
}

@Component({
  selector: 'app-wasteflow-ventilation-dialog',
  templateUrl: './wasteflow-ventilation-dialog.component.html',
  styleUrls: ['./wasteflow-ventilation-dialog.component.scss']
})
export class WasteflowVentilationDialogComponent implements OnInit {
  wasteflows: Wasteflow[] = [];
  displayMessage = false;
  errorMessage: string;
  displayedColumns: string[] = [
    'codeLmod',
    'libelleLmod',
    'origine',
    'collecte',
    'destination',
    'traitement',
    'poids',
    'quantite',
    'repartition'
  ];
  private totalWeight: number;

  constructor(
    public dialogRef: MatDialogRef<RejectPrestationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WasteflowVentilationDialogData,
    private wasteflowService: WasteflowService,
    private unitService: UnitService
  ) {}

  ngOnInit(): void {
    this.totalWeight = this.data.wasteflow.convertedQty;
    if (!this.data.wasteflow.provenanceCode) {
      this.data.wasteflow.provenanceCode = {
        '@id': null,
        id: null,
        label: null,
        code: null
      };
    }

    // Déchets encombrants
    if (this.data.wasteflow.codeLmod.code === '20 03 07') {
      this.addLinesAtStart(6);
      this.setUpLinesForDechetsEncombrants();
    } else {
      this.addLinesAtStart(2);
    }

    // 20 03 01 - Déchets urbains
    if (this.data.wasteflow.codeLmod.id === 947) {
      this.displayMessage = true;
      const entrepriseGETreatment = this.data.treatments.find(code => code.id === 1935);
      this.wasteflows[1].provenanceCode = entrepriseGETreatment;
    }

    this.wasteflows.forEach((wasteflow, index) => {
      if (index !== 0) {
        wasteflow.repartition = 0;
        wasteflow.originQty = 0;
        wasteflow.convertedQty = 0;
      } else {
        wasteflow.repartition = 100;
      }
      this.filterUnits(wasteflow);
    });
  }

  public onChangeOriginQty(element: Wasteflow, i: number): void {
    if (element.originQty < 0) {
      element.originQty = 0;
    }
    const totalQty = this.totalWeight / Number(element.originUnit.multiplicator);
    element.repartition = (element.originQty / totalQty) * 100;
    this.updateQuantitiesAndRepartitions(i);
  }

  public onChangeOriginUnit(element: Wasteflow): void {
    element.originUnit = { ...this.data.units.find(unit => unit.id === element.originUnit.id) };
    element.originQty = element.convertedQty / Number(element.originUnit.multiplicator);
  }

  public submit(): void {
    this.errorMessage = null;
    if (this.isFormValid()) {
      const newWasteFlows: { [k: string]: any }[] = this.wasteflows.map((wasteflow: Wasteflow) => {
        return {
          originQty: wasteflow.originQty,
          originUnit: wasteflow.originUnit.id,
          codeLmod: wasteflow.codeLmod.id,
          treatment: wasteflow.treatment.id,
          provenanceCode: wasteflow.provenanceCode.id
        };
      });

      this.wasteflowService.postWasteFlowDataBreakdown(this.data.wasteflow.id, newWasteFlows).subscribe(
        response => {
          if (response) {
            this.dialogRef.close(true);
          }
        },
        error => {
          if (error.error.errorMessage) {
            this.errorMessage = error.error.errorMessage;
          }
        }
      );
    } else {
      this.errorMessage = 'Veuillez remplir tous les champs de chaque ligne.';
    }
  }

  public isFormValid(): boolean {
    return (
      this.wasteflows.filter(
        d => !d.codeLmod || !d.codeLmod.id || !d.provenanceCode || !d.provenanceCode.id || d.originQty < 0
      ).length === 0
    );
  }

  public addDataLine(): void {
    this.wasteflows.push(JSON.parse(JSON.stringify(this.wasteflows[0])));
    const i = this.wasteflows.length - 1;
    this.wasteflows[i].repartition = 0;
    this.wasteflows[i].originQty = 0;
    this.wasteflows[i].convertedQty = 0;
    this.wasteflows = [...this.wasteflows];
  }

  /**
   * If element is negative, then it's invalid
   * @param el - Value for origin quantity
   * @param isPercent If value is percentage (not more than 100)
   */
  public isInvalid(el: number, isPercent = false): boolean {
    return el < 0 || (isPercent && el > 100);
  }

  /**
   * Called when lmod field is updated
   * @param value - New value for Lmod code
   * @param wasteflow - Wasteflow element to update
   */
  public onUpdateLmodField(value: Code, wasteflow: Wasteflow): void {
    wasteflow.codeLmod = value;
    this.filterUnits(wasteflow);
  }

  /**
   * Filter units from specified lmod code.
   */
  public filterUnits(wasteflow: Wasteflow): void {
    wasteflow.filteredUnits = this.unitService.filterUnits(wasteflow.codeLmod?.['@id'], this.data?.units);
  }

  private addLinesAtStart(amount: number): void {
    for (let i = 0; i < amount; i++) {
      this.wasteflows.push(JSON.parse(JSON.stringify(this.data.wasteflow)));
    }
  }

  private setUpLinesForDechetsEncombrants(): void {
    this.wasteflows.forEach((wasteflow, index) => {
      if (index === 0) {
        wasteflow.treatment = this.data.treatments.find(code => code.code === 'R101');
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '20 03 07');
      } else {
        wasteflow.treatment = this.data.treatments.find(code => code.code === 'R153');
      }

      if (index === 1) {
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '20 01 37');
      } else if (index === 2) {
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '20 01 98');
      } else if (index === 3) {
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '16 02 13');
      } else if (index === 4) {
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '20 01 01');
      } else if (index === 5) {
        wasteflow.codeLmod = this.data.lmodCodes.find(code => code.code === '19 12 95');
      }
    });
  }

  private updateQuantitiesAndRepartitions(i: number): void {
    if (this.wasteflows[i].repartition > 100) {
      this.wasteflows[i].repartition = 100;
    } else if (this.wasteflows[i].repartition < 0) {
      this.wasteflows[i].repartition = 0;
    }

    let totalRepartition = 0;
    for (const el of this.wasteflows) {
      totalRepartition += el.repartition;
    }

    const difference = 100 - totalRepartition;
    if (i === 0) {
      // If first line repartition is changed
      this.wasteflows[this.wasteflows.length - 1].repartition += difference;
    } else {
      // If any other line repartition is changed
      this.wasteflows[0].repartition += difference;
    }
    this.wasteflows.forEach(wasteflow => {
      wasteflow.convertedQty = this.totalWeight * (wasteflow.repartition / 100);
      wasteflow.originQty =
        (this.totalWeight / Number(wasteflow.originUnit.multiplicator)) * (wasteflow.repartition / 100);
    });
  }
}
