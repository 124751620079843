<h1 *ngIf="data" mat-dialog-title>Edition de l'utilisateur</h1>
<h1 *ngIf="!data" mat-dialog-title>Ajout d'un utilisateur</h1>
<mat-dialog-content>
  <div class="form-dialog__content form-dialog__content--full">
    <form [formGroup]="userForm" class="form-dialog__form">
      <mat-radio-group *ngIf="!isItself && userOrganisation.type === 'gesdec'" formControlName="enabled">
        <mat-radio-button [value]="true" class="mr-4 mt-4 mb-3">Actif</mat-radio-button>
        <mat-radio-button [value]="false">Inactif</mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Nom</mat-label>
        <input formControlName="lastname" matInput type="text" />
        <mat-error *ngIf="f.lastname.errors && f.lastname.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Prénom</mat-label>
        <input formControlName="firstname" matInput type="text" />
        <mat-error *ngIf="f.firstname.errors && f.firstname.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput type="email" />
        <mat-error *ngIf="f.email.errors && f.email.errors.required">Ce champ est obligatoire.</mat-error>
        <mat-error *ngIf="f.email.errors && f.email.errors.pattern"
        >Le format de l'adresse mail n'est pas correct.
        </mat-error>
      </mat-form-field>
      <div *ngIf="emailDuplicateError" class="text-orange text-sm mb-4">Cet email est déjà utilisé.</div>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Téléphone fixe</mat-label>
        <input formControlName="phone" matInput type="text" />
        <mat-error *ngIf="f.phone.errors && f.phone.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Mobile</mat-label>
        <input formControlName="mobile" matInput type="text" />
        <mat-error *ngIf="f.mobile.errors && f.mobile.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <div *ngIf="!(isItself && !isAdminUser)" class="mt-2">
        <mat-checkbox class="mr-3" color="primary" formControlName="roleAdmin">Admin</mat-checkbox>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
