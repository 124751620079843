import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import {
  Code,
  CodeCreation,
  CodeMappingDownload,
  CodesByCodeType,
  CodeType,
  CodeTypeFull,
  CodeUpdate,
  CodeUpdateRepercution,
  UpdatedCode
} from '../model/code.model';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  private codeFilter = '/codes?codeType.label=';

  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of code types
   */
  getCodeTypes(): Observable<CodeType[]> {
    return this.http.get<CodeType[]>(`${environment.apiUrl}/get_codetype`);
  }

  getCodeType(id: number): Observable<CodeTypeFull> {
    return this.http.get<CodeTypeFull>(`${environment.apiUrl}/code_types/${id}`);
  }

  /**
   * Get list of codes by code types
   */
  getCodesByCodeType(
    id: number,
    page: number,
    search?: string,
    sort?: string,
    sortDir?: string
  ): Observable<CodesByCodeType> {
    let url = `/get_codes_by_codetype?filterCodeTypeId=${id}&page=${page}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sort && sortDir) {
      url += `&order[${sort}]=${sortDir.toUpperCase()}`;
    }
    return this.http.get<any>(`${environment.apiUrl}${url}`).pipe(
      map(response => {
        response.currentPage = parseInt(response.currentPage, 10);
        return response;
      })
    );
  }

  /**
   * Get list of code label options for edit mode on code type details
   */
  getLabelOptions(id: number): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}/codes?codeType.id=${id}&groups[]=app.code.lite.get`)
      .pipe(map(options => options['hydra:member']));
  }

  /**
   * Get download link for code types mapping
   */
  getDownloadLink(id: number): Observable<string> {
    return this.http
      .post<CodeMappingDownload>(`${environment.apiUrl}/post_code_export`, { filterCodeTypeId: id })
      .pipe(map(d => d.file_name));
  }

  /**
   * Update code details
   */
  updateCode(codeId: number, changes: CodeUpdate): Observable<UpdatedCode> {
    return this.http.patch<any>(`${environment.apiUrl}/codes/${codeId}?groups[]=app.code.lite.post`, changes, {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    });
  }

  /**
   * Create code
   */
  createCode(code: CodeCreation): Observable<UpdatedCode> {
    return this.http.post<any>(`${environment.apiUrl}/codes?groups[]=app.code.lite.post`, code);
  }

  /**
   * Update code details with repercutions on existing data with that code
   */
  updateCodeWithRepercution(changes: CodeUpdateRepercution): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/update_code_by_internal_code`, changes);
  }

  /**
   * Get site codes
   */
  getSiteCodes(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}Site_type`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get treatment types
   */
  getTreatmentTypes(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}Treatment_type`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get flow types
   */
  getFlowTypes(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}Flow_type`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get Oled codes
   */
  getOled(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}OLED&groups[]=app.code.lite.get`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get noga codes
   */
  getNogaCodes(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}NOGA_title`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get LMod codes
   */
  getLmod(): Observable<Code[]> {
    return this.http
      .get<HyDraMemberResponse<Code[]>>(`${environment.apiUrl}${this.codeFilter}LMod&groups[]=app.code.lite.get`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }
}
