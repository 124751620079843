import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(public dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ? true : this.confirmLeaving();
  }

  /**
   * This function will show a dialog asking the user to confirm leaving the page, in case there are any unsaved changes
   */
  confirmLeaving(): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Voulez-vous vraiment quitter la page?',
          text: 'Vous avez des modifications non sauvegardées sur cette page.'
        }
      })
      .afterClosed();
  }
}
