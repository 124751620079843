<section class="inventory-preview">
  <h1 class="mt-0">Prévisualisation des données d’inventaire</h1>
  <div class="inventory-preview__top">
    <div *ngIf="inventories?.length" class="inventory-preview__top__left">
      <h4 class="mt-5 pt-3 mb-0">Progression générale de la préclôture</h4>
      <app-pre-closure-progress-bar
        *ngIf="inventoriesProgress"
        [animated]="firstInitGlobalProgressBar"
        [data]="inventoriesProgress"
        [showPercent]="true"
      >
      </app-pre-closure-progress-bar>
    </div>
    <div>
      <button
        *ngIf="inventories && inventories.length"
        (click)="openClosureDialog(true)"
        [disabled]="communeClosureExists"
        class="btn btn-small mb-2"
      >
        {{ communeClosureBtnLabel }}
      </button>
      <button
        *ngIf="inventories && inventories.length"
        (click)="openClosureDialog(false)"
        [disabled]="entrepriseClosureExists || !communeClosureExists"
        class="btn btn-small mb-2"
      >
        {{ entrepriseClosureBtnLabel }}
      </button>
      <button (click)="downloadInventory(true)" class="btn btn-small mb-2">
        Télécharger l'inventaire des communes
      </button>
      <button (click)="downloadInventory(false)" class="btn btn-small">Télécharger l'inventaire des entreprises</button>
    </div>
  </div>
  <div class="content-tertiary">
    <div class="decla-detail__success" *ngIf="messagePostClosure">
      <mat-icon>done</mat-icon>
      <div>{{ messagePostClosure }}</div>
    </div>

    <div [formGroup]="inventoryFilters" class="inventory-preview__filters">
      <div>
        <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
          <mat-label>Provenance</mat-label>
          <input
            (keyup)="filterInputChange($event.target?.value)"
            [matAutocomplete]="provenance"
            formControlName="provenanceSite"
            matInput
            type="text"
          />
          <mat-autocomplete
            #provenance="matAutocomplete"
            (optionSelected)="getData()"
            [displayWith]="wasteflowService.displayLabelFn"
            autoActiveFirstOption
          >
            <mat-option *ngFor="let provenanceSite of filteredProvenanceSiteList$ | async" [value]="provenanceSite">
              {{ provenanceSite.label }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
          <mat-label>Type de déchet</mat-label>
          <input
            (keyup)="filterInputChange($event.target?.value)"
            [matAutocomplete]="wasteType"
            formControlName="wasteType"
            matInput
            type="text"
          />
          <mat-autocomplete
            #wasteType="matAutocomplete"
            (optionSelected)="getData()"
            [displayWith]="wasteflowService.displayLabelFn"
            autoActiveFirstOption
          >
            <mat-option *ngFor="let wasteType of filteredWasteTypeSiteList$ | async" [value]="wasteType">
              {{ wasteType.label }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
          <mat-label>Traitement</mat-label>
          <input
            (keyup)="filterInputChange($event.target?.value)"
            [matAutocomplete]="treatment"
            formControlName="treatment"
            matInput
            type="text"
          />
          <mat-autocomplete
            #treatment="matAutocomplete"
            (optionSelected)="getData()"
            [displayWith]="wasteflowService.displayLabelFn"
            autoActiveFirstOption
          >
            <mat-option *ngFor="let treatment of filteredTreatmentSiteList$ | async" [value]="treatment">
              {{ treatment.label }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button
          (click)="resetFilters()"
          class="btn-icon btn-small mr-2"
          matTooltipPosition="above"
          matTooltip="Réinitialiser les filtres"
          tabindex="-1"
        >
          <span class="material-icons-round">replay</span>
        </button>
      </div>
    </div>

    <div *ngIf="inventories?.length; else noData" class="table-container inventory-preview__table-container">
      <mat-table
        (matSortChange)="sortData($event)"
        [dataSource]="inventories"
        [matSortActive]="sorting.displaySort"
        [matSortDirection]="sorting.dir"
        class="inventory-preview__table"
        matSort
        multiTemplateDataRows
      >
        <ng-container matColumnDef="provenance">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="provenance">
            Provenance
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span matTooltipPosition="above" [matTooltip]="element.provenance">
              {{ element.provenance }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="waste_type">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="waste_type">
            Type de déchet
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <span matTooltipPosition="above" [matTooltip]="element.waste_type">
              {{ element.waste_type }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="treatment">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="treatment">
            Traitement
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <span matTooltipPosition="above" [matTooltip]="element.treatment">
              {{ element.treatment }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="weight">
            Poids (t)
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="d-flex flex-column">
              <span matTooltipPosition="above" [matTooltip]="element.weight">{{
                element.weight | numberValue | number: '':'fr'
              }}</span>
              <span
                [class]="element.weight_percent <= -20 || element.weight_percent >= 20 ? 'text-orange' : 'text-green'"
                matTooltipPosition="above"
                [matTooltip]="element.weight"
                class="indicator"
              >
                {{
                  element.weight_percent >= 0
                    ? '+ ' + element.weight_percent
                    : '- ' + element.weight_percent.toString().substring(1)
                }}%
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight_per_hab">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="weight_per_hab">
            kg/hab
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="d-flex flex-column">
              <ng-container
                *ngIf="
                  element.provenance_type !== organisationType.COMMUNE && element.weight_per_hab === 0;
                  else weightPerHabValue
                "
              >
                <span>-</span>
              </ng-container>

              <ng-template #weightPerHabValue>
                <span matTooltipPosition="above" [matTooltip]="element.weight_per_hab">{{
                  element.weight_per_hab
                }}</span>
                <span
                  [class]="
                    element.weight_per_hab_percent <= -20 || element.weight_per_hab_percent >= 20
                      ? 'text-orange'
                      : 'text-green'
                  "
                  matTooltipPosition="above"
                  [matTooltip]="element.weight_per_hab"
                  class="indicator"
                >
                  {{
                    element.weight_per_hab_percent >= 0
                      ? '+ ' + element.weight_per_hab_percent
                      : '- ' + element.weight_per_hab_percent.toString().substring(1)
                  }}%
                </span>
              </ng-template>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="progress_bar">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="progress_bar">
            Avancement pré-clôture
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <app-pre-closure-progress-bar [animated]="firstInitProgressBar" [data]="element.progress_bar">
            </app-pre-closure-progress-bar>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a class="btn-link d-flex align-items-center">
              Voir données
              <span class="action__badge">{{ element.wasteflows_data.length }}</span>
              <mat-icon [class.expanded]="element === expandedElement">expand_more</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="wasteflows_data">
          <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="expandable__item" mat-cell>
            <div [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <app-inventory-details-row
                (preclotureInventory)="preclotureInventory($event)"
                [organisation]="organisation"
                [wasteflows]="element.wasteflows_data"
              ></app-inventory-details-row>
            </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          (click)="expandedElement = expandedElement === row ? null : row"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
        <tr
          *matRowDef="let row; columns: ['wasteflows_data']"
          [class.b-none]="row !== expandedElement"
          class="inventory-preview__detail-row"
          mat-row
        ></tr>
      </mat-table>
    </div>
    <app-table-pagination
      (updatePage)="updatePage($event)"
      [currentPage]="currentPage - 1"
      [itemsPerPage]="20"
      [pageLength]="pageLength"
    ></app-table-pagination>
  </div>
</section>

<ng-template #noData>
  <p>Aucune donnée disponible</p>
</ng-template>
