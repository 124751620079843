import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WasteflowHistory } from 'src/app/shared/model/wasteflow.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent implements OnInit {
  values: WasteflowHistory;
  comment: string;
  displayCommentLine = false;
  hasAddedComment = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private dialogRef: MatDialogRef<HistoryDialogComponent>,
    private wasteflowService: WasteflowService
  ) {}

  ngOnInit(): void {
    this.getHistory();
  }

  showCommentLine(): void {
    this.displayCommentLine = true;
  }

  saveComment(): void {
    this.wasteflowService
      .updateWasteflow(this.data, {
        comment: this.comment,
        commentTime: Math.round(new Date().getTime() / 1000)
      })
      .subscribe(() => {
        this.displayCommentLine = false;
        this.hasAddedComment = true;
        this.comment = null;
        this.getHistory();
      });
  }

  close(): void {
    // Reload table data if a new comment has been added
    this.dialogRef.close(this.hasAddedComment);
  }

  /**
   * Get wasteflow history
   */
  private getHistory(): void {
    this.wasteflowService.getWasteflowHistory(this.data).subscribe(history => {
      this.values = history;
      this.values.history.reverse();
    });
  }
}
