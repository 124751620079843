import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { Code } from '../model/code.model';
import { GenericData } from '../model/dashboard.model';
import { FusionResponse, Site, SiteFlow } from '../model/site.model';
import { BaseService } from './base.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { AuthorizationSite } from '../model/authorization.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BaseService {
  constructor(private readonly http: HttpClient, store$: Store<AppState>) {
    super(store$);
  }

  /**
   * Get sites summary for sites flow
   */
  getSitesSummary(organisationId?: number): Observable<SiteFlow[]> {
    let url = `/sites_summary${this.getYearParam()}`;
    if (organisationId) {
      url += `&organisation=${organisationId}`;
    }

    return this.http.get<SiteFlow[]>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get list of sites
   */
  getSites(
    organisationId?: number,
    organisationType?: string,
    status?: string,
    customParams?: any[]
  ): Observable<Site[]> {
    let url = '/sites?exists[siteAlias]=false';

    if (organisationId) {
      url += `&organisation=${organisationId}`;
    }

    if (organisationType) {
      url += `&organisation.type=${organisationType}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (customParams) {
      customParams.forEach(param => {
        url += `&${param.name}=${param.value}`;
      });
    }

    return this.http
      .get<HyDraMemberResponse<Site[]>>(`${environment.apiUrl}${url}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get list of site flow details
   */
  getSiteFlowDetails(siteId: number, codeLmods: number[]): Observable<GenericData> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/post_site_detail`,
        { siteId, filtersCodeLmod: codeLmods, year: this.year },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .pipe(
        map(response => ({
          colors: response.colors,
          data: response.dataChart
        }))
      );
  }

  /**
   * Fusion/Merge sites
   */
  mergeSites(sourceSiteId: number, destinationSiteId: number): Observable<FusionResponse> {
    return this.http.post<FusionResponse>(`${environment.apiUrl}/post_merge_site`, {
      sourceSiteId,
      destinationSiteId
    });
  }

  /**
   * Get list of lmod codes used on site
   */
  getLmodCodes(siteId: number): Observable<Code[]> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/post_site_detail_filter_code_lmod`,
        { siteId, year: this.year },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(map(result => result.listCodeLmod));
  }

  /**
   * Update site
   */
  updateSite(url: string, site: any): Observable<Site> {
    return this.http.put<Site>(`${environment.apiUrl}${url.replace('/api', '')}`, site, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Create Site
   */
  createSite(site: any): Observable<Site> {
    return this.http.post<Site>(`${environment.apiUrl}/sites`, site, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
