import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrestationsComponent } from './prestations/prestations.component';
import { SharedModule } from '../../shared/shared.module';
import { PrestationDialogComponent } from './prestation-dialog/prestation-dialog.component';
import { PrestataireDialogComponent } from './prestation-dialog/prestataire-dialog/prestataire-dialog.component';
import { RejectPrestationDialogComponent } from './reject-prestation-dialog/reject-prestation-dialog.component';

@NgModule({
  declarations: [
    PrestationsComponent,
    PrestationDialogComponent,
    PrestataireDialogComponent,
    RejectPrestationDialogComponent
  ],
  imports: [CommonModule, SharedModule]
})
export class PrestationsModule {}
