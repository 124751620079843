import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  ClosureResponse,
  InventoryExistsResponse,
  InventoryFilters,
  InventoryPreviewResponse
} from '../model/inventory.model';
import { ExportFile, PreClosureProgressBarResponse, WasteflowSorting } from '../model/wasteflow.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends BaseService {
  constructor(private readonly http: HttpClient, store$: Store<AppState>) {
    super(store$);
  }

  getInventoryPreview(
    sort?: WasteflowSorting,
    provenanceSite?: string,
    wasteType?: string,
    treatment?: string,
    page = 1
  ): Observable<InventoryPreviewResponse> {
    const params: any = { page };
    let url = `get_inventory_preview${this.getYearParam()}`;
    if (sort && sort.sort && sort.dir) {
      url += `&order[${sort.sort}]=${sort.dir.toUpperCase()}`;
    }

    if (provenanceSite) {
      url += `&filters[provenance]=${provenanceSite}`;
    }

    if (wasteType) {
      url += `&filters[waste_type]=${wasteType}`;
    }

    if (treatment) {
      url += `&filters[treatment]=${treatment}`;
    }
    return this.http.get<InventoryPreviewResponse>(`${environment.apiUrl}/${url}`, {
      params
    });
  }

  generateRapport(year: number, is_commune: boolean): Observable<ExportFile> {
    return this.http.post<ExportFile>(`${environment.apiUrl}/generate_rapport`, { year, is_commune });
  }

  getInventoryPreviewProgress(
    provenanceSite?: string,
    wasteType?: string,
    treatment?: string
  ): Observable<PreClosureProgressBarResponse> {
    let url = `/get_inventory_preview_progress_bar${this.getYearParam()}`;
    if (provenanceSite) {
      url += `&filters[provenance]=${provenanceSite}`;
    }

    if (wasteType) {
      url += `&filters[waste_type]=${wasteType}`;
    }

    if (treatment) {
      url += `&filters[treatment]=${treatment}`;
    }
    return this.http.get<PreClosureProgressBarResponse>(`${environment.apiUrl}${url}`);
  }

  getInventoryPreviewFilters(selectedYear: number): Observable<InventoryFilters> {
    return this.http.get<InventoryFilters>(`${environment.apiUrl}/get_inventory_preview_filter?year=${selectedYear}`);
  }

  getExistsClosure(year: number, is_commune: boolean): Observable<InventoryExistsResponse> {
    return this.http.post<InventoryExistsResponse>(`${environment.apiUrl}/exist_closure`, { year, is_commune });
  }

  startClosure(is_pre_check: boolean, year: number, is_commune: boolean): Observable<ClosureResponse> {
    return this.http.post<ClosureResponse>(`${environment.apiUrl}/closure`, {
      year,
      is_commune,
      is_pre_check
    });
  }
}
