import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { round } from 'lodash';
import { PreClosureProgressBar } from '../../model/wasteflow.model';
import { MatProgressBar } from '@angular/material/progress-bar';
import { of } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-pre-closure-progress-bar',
  templateUrl: './pre-closure-progress-bar.component.html',
  styleUrls: ['./pre-closure-progress-bar.component.scss']
})
export class PreClosureProgressBarComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {
  @Input() animated = true;
  @Input() data: PreClosureProgressBar;
  @Input() showPercent: boolean;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;

  uploadPercentage$ = of(1);
  percent = 1;
  tooltipContent: string;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setData();
  }

  ngAfterViewInit(): void {
    if (this.animated) {
      this.initBar();
    } else {
      this.uploadPercentage$ = of(this.percent);
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnChanges(): void {
    this.setData();
  }

  private initBar() {
    this.uploadPercentage$ = this.progressBar.animationEnd.pipe(
      startWith(0),
      delay(0),
      map(() => this.percent)
    );
  }

  public getColorClass(): string {
    if (this.percent) {
      if (this.percent === 100) {
        return 'green';
      } else if (this.data.nb_valid > 0) {
        return 'orange';
      }
    }
    return 'red';
  }

  private setData(): void {
    if (this.data) {
      const percent = round((this.data.nb_pre_closure / this.data.nb_total) * 100);
      this.percent = percent === 0 ? 1 : percent;
      if (Number.isNaN(this.percent)) {
        this.percent = 100;
      }

      this.tooltipContent = [
        'Pré-clôturées: ' + this.data.nb_pre_closure,
        'À traiter: ' + this.data.nb_valid,
        'En attente: ' + this.data.nb_tofix_or_tovalidated
      ].join('\n');
    } else {
      this.percent = this.tooltipContent = undefined;
    }

    if (this.progressBar) {
      this.initBar();
    }
  }
}
