<div class="organisation__header">
  <h4>Les sites</h4>
  <button (click)="openSiteDialog()" class="btn btn-small">Ajouter site</button>
</div>
<div class="mt-4 organisation__search">
  <div class="common-search">
    <h4>Recherche:</h4>
    <mat-form-field appearance="outline" class="mat-form-field-small">
      <input matInput (keyup)="filterByName($event)" type="text" />
    </mat-form-field>
  </div>
</div>
<div class="mt-4 organisation-sites table-container">
  <mat-table
    [dataSource]="dataSource"
    class="organisation-sites__table"
    [class.organisation-sites__table--gesdec]="organisation.type === 'gesdec'"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>Nom</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div matTooltipPosition="above" [matTooltip]="element.name">
          {{ element.name }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="siteLabel">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div matTooltipPosition="above" [matTooltip]="element.siteLabel">
          {{ element.siteLabel }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="street1">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>Adresse</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div matTooltipPosition="above" [matTooltip]="getFullStreet(element)">
          {{ getFullStreet(element) }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="town">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>Ville</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.town }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="authorisation">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>N° Autorisation</mat-header-cell>
      <mat-cell *matCellDef="let element">
          <div class="items">
            <div>
              {{ element.authorizationMessage }}
            </div>
            <div *ngFor="let item of element.activeAuthorizations">
              <a [routerLink]="'/gestion/autorisations/' + element.id">{{item.authorizationNumber}}</a>
            </div>
          </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="public">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header>Visibilité</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.status | siteType }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button (click)="openSiteDialog(element)" class="btn-link">Editer</button>
        <button
          [disabled]="!canMerge"
          *ngIf="organisation.type === 'gesdec'"
          (click)="fusion(element)"
          class="btn-link"
        >
          Fusionner
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<div *ngIf="showPagination()" class="table-pagination">
  <button (click)="goToPreviousPage()" [disabled]="getCurrentIndex() === 0">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <ng-container *ngFor="let el of getVisiblePageButtons(); let i = index">
    <button (click)="goToPageManually(el)" [class.active]="getCurrentIndex() === el">
      {{ el !== null ? el + 1 : '...' }}
    </button>
    <span *ngIf="shouldAddDots(el, i)">...</span>
  </ng-container>

  <button (click)="goToNextPage()" [disabled]="getCurrentIndex() === getLastPage()">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <mat-paginator [pageSize]="pageSize" hidePageSize></mat-paginator>
</div>
