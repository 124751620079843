import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryClosureStateEnum } from 'src/app/shared/model/inventory.model';
import { InventoryService } from 'src/app/shared/services/inventory.service';

interface dialogData {
  year: number;
  isCommune: boolean;
}

@Component({
  selector: 'app-inventory-closure-dialog',
  templateUrl: './inventory-closure-dialog.component.html',
  styleUrls: ['./inventory-closure-dialog.component.scss']
})
export class InventoryClosureDialogComponent implements OnInit {
  stateEnum = InventoryClosureStateEnum;
  state = InventoryClosureStateEnum.PRECHECK_LOADING;
  errors: Array<string>;

  constructor(
    public dialogRef: MatDialogRef<InventoryClosureDialogComponent>,
    public dialog: MatDialog,
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) public data: dialogData
  ) {}

  ngOnInit(): void {
    this.startClosure(true);
  }

  private startClosure(isPrecheck: boolean): void {
    // Start loading
    this.state = isPrecheck ? InventoryClosureStateEnum.PRECHECK_LOADING : InventoryClosureStateEnum.CLOSURE_LOADING;
    this.inventoryService.startClosure(isPrecheck, this.data.year, this.data.isCommune).subscribe(
      () => {
        // Success
        this.state = isPrecheck
          ? InventoryClosureStateEnum.PRECHECK_SUCCESS
          : InventoryClosureStateEnum.CLOSURE_SUCCESS;
      },
      error => {
        // Failed
        this.state = isPrecheck ? InventoryClosureStateEnum.PRECHECK_FAILED : InventoryClosureStateEnum.CLOSURE_FAILED;

        // Show errors if closure has failed
        if (
          this.state === InventoryClosureStateEnum.PRECHECK_FAILED ||
          this.state === InventoryClosureStateEnum.CLOSURE_FAILED
        ) {
          this.errors = error.error?.errors;
        }
      }
    );
  }

  close(): void {
    this.dialogRef.close(this.state);
  }
}
