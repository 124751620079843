import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-confirm-impersonation-dialog',
  templateUrl: './confirm-impersonation-dialog.component.html',
  styleUrls: ['./confirm-impersonation-dialog.component.scss']
})
export class ConfirmImpersonationDialogComponent {
  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmImpersonationDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; backToProfile: boolean }
  ) {}
}
