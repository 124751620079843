import { Code, Unit } from './code.model';
import { ImportEventSimple, StatusInventory, StatusValidation } from './import-event.model';
import { OrganisationType } from './organisation.model';
import { Site, SiteSimple } from './site.model';
import { SortDirection } from '@angular/material/sort/sort-direction';
import { Observable } from 'rxjs';

export class Wasteflow {
  flowType: Code;
  codeLmod: Code;
  flowPeriodFrom: string;
  flowPeriodTo: string;
  codeOled: Code;
  convertedQty: number;
  convertedUnit: Unit;
  destinationSite: Site;
  flowPeriod: string;
  id: number;
  importEvent: ImportEventSimple;
  originQty: number;
  originUnit: Unit;
  provenanceSite: Site;
  wasteFlowStatus: WasteFlowStatus;
  statusWrtInventory: StatusInventory;
  treatment: Code;
  provenanceCode?: Code;
  newProvenanceCode?: Code;
  filteredProvenanceCodes?: Observable<Code[]>;
  newOriginQty?: number;
  newOriginUnitId?: string;
  filteredUnits?: Unit[];
  newTreatment?: Code;
  filteredTreatments?: Observable<Code[]>;
  newCodeLmod?: Code;
  newStatus?: WasteFlowStatus;
  shouldSetToToValidate?: boolean;
  shouldSetToToFix?: boolean;
  shouldSetToValid?: boolean;
  validator: WasteflowValidator;
  aberrantJsonInfoFront: string;
  duplicateGroupId: string;
  error?: string;
  showCommentLine?: boolean;
  expanded?: boolean;
  comment?: string;
  isFirstOfGroup?: boolean;
  isLastOfGroup?: boolean;
  newStatusWrtInventory?: StatusInventory;
  isComplementary?: boolean;
  repartition: number;
}

export interface ExportFile {
  file_name: string;
}

export interface WasteflowValidator {
  '@id': string;
  '@type': string;
  id: number;
  name: string;
  type: OrganisationType;
}

export interface WasteFlowStatus {
  '@id': string;
  '@type': string;
  code: StatusValidation;
  label: string;
  orderCommune: number;
  orderEco21: number;
  orderEnterprise: number;
  orderGesdec: number;
  orderServiceProvider: number;
}

export interface WasteflowHistory {
  currentData: WasteflowHistoryData;
  originData: WasteflowHistoryData;
  history: WasteflowHistoryData[];
  noHistory: boolean;
}

export interface WasteflowHistoryData {
  codeLmod: string;
  codeTreatment: string;
  date: string;
  labelLmod: string;
  labelTreatment: string;
  originQty: number;
  originUnit: string;
  userFirstName: string;
  userLastName: string;
  userOrganisationName: string;
  wasteFlowStatus: StatusValidation;
}

export enum WasteflowViews {
  MAIN,
  DOUBLON,
  ENTERPRISE
}

export interface WasteflowSorting {
  dir: SortDirection;
  sort: string;
  displaySort: string;
}

export interface PreClosureProgressBarResponse {
  data: PreClosureProgressBar;
}

export interface PreClosureProgressBar {
  nb_pre_closure: number;
  nb_tofix_or_tovalidated: number;
  nb_total: number;
  nb_valid: number;
}
