import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserDialogComponent } from './components/user-dialog/user-dialog.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { NumberValuePipe } from './pipe/number-value.pipe';
import { UserTypePipe } from './pipe/user-type.pipe';
import { OrganisationTypePipe } from './pipe/organisation-type.pipe';
import { StatusLabelPipe } from './pipe/status-label.pipe';
import { ImportEventTypePipe } from './pipe/import-event-type.pipe';
import { WasteflowsTableComponent } from './components/wasteflows-table/wasteflows-table.component';
import { LmodFieldComponent } from './components/wasteflows-table/lmod-field/lmod-field.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { ChartsDataPipe } from './pipe/charts-data.pipe';
import { PreClosureProgressBarComponent } from './components/pre-closure-progress-bar/pre-closure-progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StatusColorPipe } from './pipe/status-color.pipe';
import { WasteflowToggleStatePipe } from './components/wasteflows-table/wasteflow-toggle-state.pipe';
import { WasteflowToggleLabelPipe } from './components/wasteflows-table/wasteflow-toggle-label.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SiteTypePipe } from './pipe/site-type.pipe';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    UserDialogComponent,
    ImportEventTypePipe,
    AddressFormComponent,
    NumberValuePipe,
    ChartsDataPipe,
    UserTypePipe,
    OrganisationTypePipe,
    StatusLabelPipe,
    LmodFieldComponent,
    WasteflowsTableComponent,
    PreClosureProgressBarComponent,
    TablePaginationComponent,
    StatusColorPipe,
    WasteflowToggleStatePipe,
    WasteflowToggleLabelPipe,
    SpinnerComponent,
    SiteTypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMenuModule,
    MatIconModule,
    BrowserModule,
    MatTableModule,
    MatNativeDateModule,
    HttpClientModule,
    NgxChartsModule,
    MatSortModule,
    MatDialogModule,
    AppRoutingModule,
    MatPaginatorModule,
    RouterModule,
    MatProgressBarModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    CommonModule,
    MatSelectModule,
    MatMenuModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTableModule,
    NgxChartsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSortModule,
    BrowserModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    ImportEventTypePipe,
    MatNativeDateModule,
    MatPaginatorModule,
    AppRoutingModule,
    RouterModule,
    AddressFormComponent,
    NumberValuePipe,
    UserTypePipe,
    StatusColorPipe,
    OrganisationTypePipe,
    LmodFieldComponent,
    StatusLabelPipe,
    WasteflowsTableComponent,
    PreClosureProgressBarComponent,
    SpinnerComponent,
    TablePaginationComponent,
    ChartsDataPipe,
    SiteTypePipe
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    NumberValuePipe,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    }
  ]
})
export class SharedModule {}
