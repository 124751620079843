<div *ngIf="pageLength > 1" [class.d-none]="shouldHide" class="table-pagination">
  <button (click)="goToPreviousPage()" [disabled]="currentPage === 0">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <ng-container *ngFor="let el of getVisiblePageButtons(); let i = index">
    <button (click)="goToPageManually(el)" [class.active]="currentPage === el">
      {{ el !== null ? el + 1 : '...' }}
    </button>
    <span *ngIf="shouldAddDots(el, i)">...</span>
  </ng-container>

  <button (click)="goToNextPage()" [disabled]="currentPage === pageLength - 1">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
