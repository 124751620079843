import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ImportEvent } from 'src/app/shared/model/import-event.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { ImportEventDialogComponent } from './import-event-dialog/import-event-dialog.component';
import { DownloadEventDialogComponent } from './download-event-dialog/download-event-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { getSelectedYear$ } from '../../core/store/selectors/app.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-events',
  templateUrl: './import-events.component.html',
  styleUrls: ['./import-events.component.scss']
})
export class ImportEventsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['id', 'periode', 'site', 'prestataire', 'type', 'statut', 'actions'];
  public data: ImportEvent[];
  public organisation: Organisation;
  public hasGottenData = false;
  public needsBigActionColumn = true;
  private dialog$: Subscription;
  private selectedYear$: Subscription;

  constructor(
    private importEventService: ImportEventService,
    private router: Router,
    private dialog: MatDialog,
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(() => {
      this.getImportEvents();
    });
  }

  public isEcoOrGesDecOrg(): boolean {
    return (
      this.organisation.type === (OrganisationType.GESDEC || OrganisationType.ECO21)
    );
  }

  /**
   * Delete import event element
   * @param element - Element to delete
   */
  public deleteImportEvent(element: ImportEvent): void {
    this.dialog$ = this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Êtes-vous sûr-e de vouloir supprimer cette déclaration?',
          text: 'Le fichier et toutes les données associées seront définitivement supprimées.'
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.importEventService.deleteImportEvent(element.id).subscribe(response => {
            this.getImportEvents();
          });
        }
      });
  }

  /**
   * Open dialog to import a import event file
   */
  public openImportEventDialog(): void {
    const dialogRef = this.dialog.open(ImportEventDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: '60vw',
      panelClass: ['declaration-dialog'],
      data: this.organisation
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((newPrestation: any) => {
      // If a new prestation has been created, redirect user to its page details
      if (newPrestation && newPrestation.importEvent && newPrestation.importEvent.id) {
        this.router.navigate(['/gestion/declarations-detail'], {
          queryParams: { id: newPrestation.importEvent.id }
        });
      }
    });
  }

  /**
   * Open dialog to download event file
   */
  public openDownloadEventDialog(): void {
    const dialogRef = this.dialog.open(DownloadEventDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: '40vw',
      panelClass: ['declaration-dialog'],
      data: this.organisation
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((newPrestation: any) => {
      // If a new prestation has been created, redirect user to its page details
      if (newPrestation && newPrestation.importEvent && newPrestation.importEvent.id) {
        this.router.navigate(['/gestion/declarations-detail'], {
          queryParams: { id: newPrestation.importEvent.id }
        });
      }
    });
  }

  /**
   * Download import event .xlsx template
   */
  downloadImportEventTemplate(): void {
    this.importEventService
      .generateTemplateBySite()
      .subscribe(
        template => (location.href = `${environment.apiUrl.split('/api')[0]}/download?file=${template.file_name}`)
      );
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get list of import events
   */
  private getImportEvents(): void {
    this.importEventService
      .getImportEventsByOrganisation(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
      .subscribe(importEvents => {
        this.hasGottenData = true;
        this.data = importEvents;
        this.needsBigActionColumn = this.data.findIndex(data => data.file !== '') > -1;
      });
  }
}
