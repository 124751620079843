import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationRoles } from '../model/organisation.model';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {
  transform(role: OrganisationRoles): string {
    if (role === OrganisationRoles.USER) {
      return 'User';
    } else if (role === OrganisationRoles.ADMIN) {
      return 'Admin';
    } else {
      return role;
    }
  }
}
