<div *ngIf="hasGottenData && data.length" class="declaration-last">
  <mat-table
    (matSortChange)="sortData($event)"
    [dataSource]="data"
    class="declaration__list-table declaration__list-table--last"
    matSort
    matSortActive="status"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="periode">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="periode">Période</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.period }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="site">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="site">Site</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div matTooltipPosition="above" [matTooltip]="element.site">
          {{ element.site }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="prestataire">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="prestataire"
        >Prestataire
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.organisation }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statut">
      <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="status">Statut</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [ngClass]="element.statusContext | statusColor: organisation.type" class="status"></span>
        {{ element.statusContext | statusLabel }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          <a [queryParams]="{ id: element.id }" [routerLink]="['/gestion/declarations-detail']">
            {{ element.status === 'valid' ? 'Voir' : 'Modifier' }}
          </a>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
