<div [formGroup]="addressForm">
  <mat-radio-group class="mb-3" formControlName="isGeneva">
    <div style="margin-bottom: 5px">
      <mat-radio-button [value]="0" class="mr-4">Adresse sur Genève</mat-radio-button>
    </div>
    <div style="margin-bottom: 5px">
      <mat-radio-button [value]="1">Adresse en Suisse</mat-radio-button>
    </div>
    <div style="margin-bottom: 5px">
      <mat-radio-button [value]="2">Adresse à l'étranger</mat-radio-button>
    </div>
  </mat-radio-group>

  <div class="mt-2 row">
    <div [class.col-12]="f.isGeneva && f.isGeneva.value >= 1" class="col-9">
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Rue</mat-label>
        <input [matAutocomplete]="auto" formControlName="street1" matInput type="text" />
        <div *ngIf="isSearching" class="loader-sm" matSuffix></div>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of options" [value]="option.adresse.adresse">
            <span>{{ option.adresse.adresse }}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.street1.errors && f.street1.errors.required">Ce champ est obligatoire.</mat-error>
        <mat-error *ngIf="f.street1.errors && f.street1.errors['incorrect']"
        >L'adresse n'a pas l'air de se trouver dans le canton de Genève. Êtes-vous sûr.e d'avoir bien sélectionné une
          adresse proposée?
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="f.isGeneva && f.isGeneva.value >= 1" class="col-3">
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>N°</mat-label>
        <input formControlName="street2" matInput type="text" />
        <mat-error *ngIf="f.street2.errors && f.street2.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="f.isGeneva && f.isGeneva.value >= 1">
    <div class="row">
      <div class="col-4">
        <mat-form-field appearance="outline" class="form-dialog__input">
          <mat-label>Code postal</mat-label>
          <input formControlName="postalCode" matInput type="text" />
          <mat-error *ngIf="f.postalCode.errors && f.postalCode.errors.required">Ce champ est obligatoire.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline" class="form-dialog__input">
          <mat-label>Ville</mat-label>
          <input formControlName="town" matInput type="text" />
          <mat-error *ngIf="f.town.errors && f.town.errors.required">Ce champ est obligatoire.</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field *ngIf="(f.isGeneva && f.isGeneva.value === 2) || !f.isGeneva" appearance="outline" class="form-dialog__input">
      <mat-label>Pays</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countries" [value]="country.code">
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.country.errors && f.country.errors.required">Ce champ est obligatoire.</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="(f.isGeneva && f.isGeneva.value === 1) || !f.isGeneva" appearance="outline" class="form-dialog__input">
      <mat-label>Canton</mat-label>
      <mat-select formControlName="canton">
        <mat-option *ngFor="let canton of cantons" [value]="canton.code">
          {{ canton.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.canton.errors && f.canton.errors.required">Ce champ est obligatoire.</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="this.isSpecialCategorySiteValue && f.chIdNumber && f.isGeneva && (f.isGeneva.value === 0 || f.isGeneva.value === 1)">
    <mat-form-field *ngIf="(f.isGeneva && (f.isGeneva.value === 0 || f.isGeneva.value === 1)) || !f.isGeneva" appearance="outline" class="form-dialog__input">
      <mat-label>Numéro d'identification CH</mat-label>
      <input formControlName="chIdNumber" [value]="chIdNumber" matInput type="text" />
    </mat-form-field>
  </ng-container>
</div>
