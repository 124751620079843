<ng-container *ngIf="!showAddPrestataire">
  <h1 *ngIf="!data.prestation && !showSuccess && !showWaiting" mat-dialog-title>Nouvelle prestation</h1>
  <h1 *ngIf="data.prestation && !showSuccess && !showWaiting" mat-dialog-title>Prestation</h1>
  <h1 *ngIf="showWaiting" mat-dialog-title>
    <ng-container *ngIf="!data.type || data.type !== 'renew'">
      {{ data.prestation ? 'Modification' : 'Ajout' }} en cours
    </ng-container>
    <ng-container *ngIf="data.type && data.type === 'renew'"> Renouvellement en cours</ng-container>
  </h1>
  <h1 *ngIf="showSuccess" mat-dialog-title>
    <ng-container *ngIf="!data.type || data.type !== 'renew'">
      La prestation a été {{ data.prestation ? 'modifiée' : 'ajoutée' }} avec succès
    </ng-container>
    <ng-container *ngIf="data.type && data.type === 'renew'">
      La prestation a été renouvellée avec succès
    </ng-container>
  </h1>
  <mat-dialog-content>
    <div *ngIf="showWaiting" class="loader"></div>
    <div *ngIf="!showSuccess && !showWaiting" class="prestation-dialog__content">
      <div class="prestation-dialog__content-add">
        <form [formGroup]="prestationForm" class="prestation-dialog__form">
          <mat-form-field appearance="outline" class="prestation-dialog__select">
            <mat-label>Nom du prestataire</mat-label>
            <input [matAutocomplete]="prestataire" formControlName="prestataire" matInput type="text" />
            <mat-autocomplete #prestataire="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
              <mat-option *ngFor="let prestataire of filteredPrestataires$ | async" [value]="prestataire">
                {{ prestataire.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.prestataire.errors && f.prestataire.errors.required"
              >Ce champ est obligatoire.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="prestation-dialog__select">
            <mat-label>Type de prestation</mat-label>
            <mat-select formControlName="type">
              <mat-option value="data_provider">Fourniture de données privées</mat-option>
              <mat-option value="data_provider_inventory">Fourniture de données d'inventaire</mat-option>
              <mat-option value="help">Accompagnement</mat-option>
            </mat-select>
            <mat-error *ngIf="f.type.errors && f.type.errors.required">Ce champ est obligatoire.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Période de la prestation</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input formControlName="dateFrom" matStartDate placeholder="JJ/MM/AAAA" />
              <input formControlName="dateTo" matEndDate placeholder="JJ/MM/AAAA" />
            </mat-date-range-input>
            <mat-datepicker-toggle [disabled]="isDatepickerDisabled" [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="f.dateFrom.hasError('matStartDateInvalid')">Date de début invalide</mat-error>
            <mat-error *ngIf="f.dateTo.hasError('matEndDateInvalid')">Date de fin invalide</mat-error>
            <mat-error
              *ngIf="(f.dateFrom.errors && f.dateFrom.errors.required) || (f.dateTo.errors && f.dateTo.errors.required)"
              >Ce champ est obligatoire.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mat-form-field-small-radius">
            <mat-label>Commentaire</mat-label>
            <textarea formControlName="comment" matInput></textarea>
            <mat-error *ngIf="f.comment.errors && f.comment.errors.required"
              >Il y a une erreur dans ce champ.
            </mat-error>
          </mat-form-field>
          <div *ngIf="errorMessage" class="text-orange mb-1 text-sm">{{ errorMessage }}</div>
        </form>
        <div *ngIf="f.prestataire.enabled" class="prestation-dialog__add-prestataire">
          Vous ne trouvez pas votre prestataire?
          <button (click)="showAddPrestataire = true" class="btn-link btn-link--underlined">
            Ajouter un prestataire
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="showSuccess && !showWaiting" class="d-flex align-items-center">
      <img alt="" class="mr-3" src="../../../assets/img/check-circle.svg" />
      <span>Tout est en ordre, la prestation a été {{ data.prestation ? 'modifiée' : 'ajoutée' }} correctement.</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      *ngIf="!showSuccess && (!data.prestation || data.canEdit.length > 0)"
      [disabled]="showWaiting"
      class="btn-link"
      mat-button
      mat-dialog-close
    >
      Annuler
    </button>
    <button
      *ngIf="showSuccess || (data.prestation && data.canEdit.length === 0)"
      [disabled]="showWaiting"
      [mat-dialog-close]="true"
      class="btn"
      mat-button
    >
      Fermer
    </button>
    <button (click)="submit()" *ngIf="!data.prestation && !showSuccess" [disabled]="showWaiting" class="btn" mat-button>
      Ajouter
    </button>
    <button
      (click)="submit()"
      *ngIf="data.canEdit.length > 0 && data.prestation && !showSuccess"
      [disabled]="showWaiting"
      class="btn"
      mat-button
    >
      {{ saveButtonText }}
    </button>
  </mat-dialog-actions>
</ng-container>
<app-prestataire-dialog
  (closePrestataire)="closeAddPrestataire($event)"
  *ngIf="showAddPrestataire"
></app-prestataire-dialog>
