<h1 mat-dialog-title>Mon organisation</h1>
<mat-dialog-content>
  <div class="form-dialog__content form-dialog__content--full">
    <form [formGroup]="organisationForm" class="form-dialog__form">
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of types" [value]="type.name">
            {{ type.displayName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.type.errors && f.type.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Nom</mat-label>
        <input formControlName="name" matInput type="text" />
        <mat-error *ngIf="f.name.errors && f.name.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Code Noga</mat-label>
        <input [matAutocomplete]="auto" aria-label="Number" formControlName="codeNoga" matInput type="text" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredCodeNogas | async" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.codeNoga.errors && f.codeNoga.errors['incorrect']"
          >Le code Noga n'est pas valide.
        </mat-error>
      </mat-form-field>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__input">
            <mat-label>Prénom du contact</mat-label>
            <input formControlName="contactFirstName" matInput type="text" />
            <mat-error *ngIf="f.contactFirstName.errors && f.contactFirstName.errors.required"
              >Ce champ est obligatoire.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__input">
            <mat-label>Nom du contact</mat-label>
            <input formControlName="contactLastName" matInput type="text" />
            <mat-error *ngIf="f.contactLastName.errors && f.contactLastName.errors.required"
              >Ce champ est obligatoire.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Email</mat-label>
        <input formControlName="contactEmail" matInput type="email" />
        <mat-error *ngIf="f.contactEmail.errors && f.contactEmail.errors.pattern"
          >Le format ne correspond pas à un email.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Téléphone</mat-label>
        <input formControlName="contactPhone" matInput type="text" />
        <mat-error *ngIf="f.contactPhone.errors && f.contactPhone.errors.required">Ce champ est obligatoire.</mat-error>
        <mat-error *ngIf="f.contactPhone.errors && f.contactPhone.errors.pattern"
          >Le numéro de téléphone doit être au format suivant: +xx xx xxx xx xx (avec les espaces)
        </mat-error>
      </mat-form-field>
      <app-address-form
        (fullAddressChange)="onFullAddressChange($event)"
        [addressForm]="organisationForm"
        [idpadr]="data ? data.idpadr : null"
      ></app-address-form>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
