import { Pipe, PipeTransform } from '@angular/core';
import { ImportEventType } from '../model/import-event.model';

@Pipe({
  name: 'importEventType'
})
export class ImportEventTypePipe implements PipeTransform {
  transform(value: ImportEventType): string {
    switch (value) {
      case ImportEventType.FILE_PRIVATE:
        return 'Fichier privé';
      case ImportEventType.FILE_INVENTORY:
        return `Fichier d'inventaire`;
      case ImportEventType.MANUAL_PRIVATE:
        return 'Manuelle privée';
      case ImportEventType.MANUAL_INVENTORY:
        return `Manuelle d'inventaire`;
      case ImportEventType.INVENTORY_CONSOLIDATION:
        return 'Consolidation';
      case ImportEventType.DATA_BREAKDOWN:
        return 'Ventilation';
      default:
        return null;
    }
  }
}
