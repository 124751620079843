import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeTypeListComponent } from './code-type-list/code-type-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CodeTypeDetailComponent } from './code-type-detail/code-type-detail.component';
import { CodeTypeEditConfirmDialogComponent } from './code-type-detail/code-type-edit-confirm-dialog/code-type-edit-confirm-dialog.component';
import { BaseCodeFieldComponent } from './code-type-detail/base-code-field/base-code-field.component';

@NgModule({
  declarations: [CodeTypeListComponent, CodeTypeDetailComponent, CodeTypeEditConfirmDialogComponent, BaseCodeFieldComponent],
  imports: [CommonModule, SharedModule]
})
export class CodeModule {}
