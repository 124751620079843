<h1 mat-dialog-title>Clôture de l'inventaire des {{ data.isCommune ? 'communes' : 'entreprises' }}</h1>
<mat-dialog-content>
  <div class="text-center" *ngIf="state === stateEnum.PRECHECK_LOADING">Vérification des données d'inventaire ...</div>
  <div class="text-center" *ngIf="state === stateEnum.CLOSURE_LOADING">Clôture de l'inventaire en cours ...</div>
  <div *ngIf="state === stateEnum.PRECHECK_LOADING || state === stateEnum.CLOSURE_LOADING" class="loader"></div>
  <div *ngIf="state === stateEnum.PRECHECK_SUCCESS">
    Toutes les données de l'exercice sélectionné vont être clôturées, plus aucune modification ne sera possible.<br />
    Souhaitez-vous vraiment continuer?
  </div>
  <div *ngIf="state === stateEnum.PRECHECK_FAILED">
    <div *ngIf="errors">
      <div *ngFor="let error of errors">
        {{ error }}
      </div>
    </div>
  </div>
  <div *ngIf="state === stateEnum.CLOSURE_FAILED">
    Une erreur s'est produite lors de la clôture.
    <div *ngIf="errors">
      <div *ngFor="let error of errors">
        {{ error }}
      </div>
    </div>
  </div>
  <div *ngIf="state === stateEnum.CLOSURE_SUCCESS">Les données ont été clôturées.</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="state !== stateEnum.CLOSURE_SUCCESS"
    [disabled]="state === stateEnum.CLOSURE_LOADING"
    class="btn-link"
    mat-button
    mat-dialog-close
  >
    Annuler
  </button>
  <button *ngIf="state === stateEnum.PRECHECK_SUCCESS" class="btn" (click)="startClosure(false)" mat-button>
    Clôturer
  </button>
  <button *ngIf="state === stateEnum.CLOSURE_SUCCESS" class="btn" (click)="close()" mat-button>
    Retour aux données
  </button>
  <button *ngIf="state === stateEnum.PRECHECK_FAILED" class="btn" (click)="close()" mat-button>
    Voir les données à traiter
  </button>
</mat-dialog-actions>
