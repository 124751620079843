<div class="organisation__header">
  <h4>Détails de l'organisation</h4>
  <button (click)="openDialog()" class="btn btn-small">Modifier</button>
</div>
<div *ngIf="organisation" class="organisation-details">
  <div class="organisation-details__element">
    <div class="organisation-details__label">Type</div>
    <div class="organisation-details__data">{{ organisation.type | organisationType }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Nom</div>
    <div class="organisation-details__data">{{ organisation.name }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Code Noga</div>
    <div class="organisation-details__data">{{ organisation.codeNoga ? organisation.codeNoga.label : '' }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Adresse</div>
    <div class="organisation-details__data">
      {{ organisation.street1 }} {{ organisation.street2 }}<br />{{ organisation.postalCode }} {{ organisation.town
      }}<br />{{ organisation.country }}
    </div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Nom contact</div>
    <div class="organisation-details__data">{{ organisation.contactLastname }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Prénom contact</div>
    <div class="organisation-details__data">{{ organisation.contactFirstname }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Mail contact</div>
    <div class="organisation-details__data">{{ organisation.contactEmail }}</div>
  </div>
  <div class="organisation-details__element">
    <div class="organisation-details__label">Contact tél</div>
    <div class="organisation-details__data">{{ organisation.contactPhone }}</div>
  </div>
</div>
