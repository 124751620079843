<div class="organisation__header">
  <h4>Les utilisateurs</h4>
  <button
    (click)="editUser(null)"
    *ngIf="connectedOrganisation && connectedOrganisation.type === 'gesdec'"
    class="btn btn-small"
  >
    Ajouter utilisateur
  </button>
</div>
<div *ngIf="users && users.length" class="mt-5 organisation-users table-container">
  <mat-table
    [class.organisation-users__table--gesdec]="connectedOrganisation && connectedOrganisation.type === 'gesdec'"
    [dataSource]="users"
    class="organisation-users__table"
  >
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef>Rôle</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.userOrganisations[0]?.role">
          {{ element.userOrganisations[0].role | userType }}
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="prenom">
      <mat-header-cell *matHeaderCellDef>Prénom</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.firstname }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="nom">
      <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.lastname }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div matTooltipPosition="above" [matTooltip]="element.email">
          {{ element.email }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="fixe">
      <mat-header-cell *matHeaderCellDef>N° fixe</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.phone }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="portable">
      <mat-header-cell *matHeaderCellDef>N° portable</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.mobile }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Activé</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.enabled ? 'Activé' : 'Désactivé' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button (click)="editUser(element)" class="btn-link">Editer</button>
        <button
          (click)="reinitPassword(element)"
          *ngIf="connectedOrganisation && connectedOrganisation.type === 'gesdec'"
          class="btn-link"
        >
          Réinitialiser le mot de passe
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
