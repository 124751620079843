<div class="navigation">
  <div>
    <a class="navigation__header" routerLink="/gestion/dashboard">
      <img alt="" src="./assets/img/logo.svg" />
      <h4 class="title-logo mt-0">
        <span>Outil Cantonal de</span>
        <span>Statistiques des déchets</span>
      </h4>
    </a>

    <ul class="navigation__list">
      <li class="navigation__item">
        <a
          [class.navigation__link--active]="isActive('/gestion/dashboard')"
          [routerLink]="'/gestion/dashboard'"
          class="navigation__link"
        >
          <img
            alt=""
            src="{{
              isActive('/gestion/dashboard') ? './assets/img/dashboard-active.svg' : './assets/img/dashboard.svg'
            }}"
          />
          <span>Tableau de bord</span>
        </a>
      </li>
      <li *ngIf="organisation.type !== 'gesdec'" class="navigation__item">
        <a
          [class.navigation__link--active]="isActive('/gestion/declarations-detail')"
          [routerLink]="'/gestion/declarations-detail'"
          class="navigation__link"
        >
          <img
            alt=""
            src="{{
              isActive('/gestion/declarations-detail')
                ? './assets/img/consult-data-active.svg'
                : './assets/img/consult-data.svg'
            }}"
          />
          <span>Consulter les données</span>
        </a>
      </li>
      <li *ngIf="organisation.type === 'gesdec'" class="navigation__item">
        <span
          (click)="toggleWasteflow()"
          [class.navigation__link--parent--opened]="showWasteflow"
          class="navigation__link navigation__link--parent"
        >
          <span>
            <img alt="" src="./assets/img/consult-data.svg" />
            <span>Données</span>
          </span>
          <mat-icon>expand_more</mat-icon>
        </span>
        <div *ngIf="showWasteflow" class="navigation__subitem">
          <a
            [class.navigation__link--active]="isActive('/gestion/declarations-detail')"
            [routerLink]="'/gestion/declarations-detail'"
            class="navigation__link navigation__link--sub"
          >
            Consulter les données
          </a>
          <a
            [class.navigation__link--active]="isActive('/gestion/sites-flow')"
            [routerLink]="'/gestion/sites-flow'"
            class="navigation__link navigation__link--sub"
          >
            Bilan de flux par site
          </a>
        </div>
      </li>
      <li *ngIf="organisation.type === 'gesdec'" class="navigation__item">
        <a
          [class.navigation__link--active]="isActive('/gestion/inventory-preview')"
          [routerLink]="'/gestion/inventory-preview'"
          class="navigation__link"
        >
          <img
            alt=""
            src="{{
              isActive('/gestion/inventory-preview')
                ? './assets/img/box-taped-active.svg'
                : './assets/img/box-taped.svg'
            }}"
          />
          <span>Inventaire</span>
        </a>
      </li>
      <li class="navigation__item">
        <a
          [class.navigation__link--active]="isActive('/gestion/declarations')"
          [routerLink]="'/gestion/declarations'"
          class="navigation__link"
        >
          <img
            alt=""
            src="{{
              isActive('/gestion/declarations')
                ? './assets/img/declarations-active.svg'
                : './assets/img/declarations.svg'
            }}"
          />
          <span>Déclarations</span>
        </a>
      </li>
      <li class="navigation__item">
        <span
          (click)="toggleAdministration()"
          [class.navigation__link--parent--opened]="showAdministration"
          class="navigation__link navigation__link--parent"
        >
          <span>
            <img alt="" src="./assets/img/administration.svg" />
            <span>Administration</span>
          </span>
          <mat-icon>expand_more</mat-icon>
        </span>
        <div *ngIf="showAdministration" class="navigation__subitem">
          <a
            [class.navigation__link--active]="isActive('/gestion/autorisations')"
            [routerLink]="'/gestion/autorisations'"
            class="navigation__link navigation__link--sub"
          >
            Autorisations
          </a>
          <a
            [class.navigation__link--active]="isActive('/gestion/prestations')"
            [routerLink]="'/gestion/prestations'"
            class="navigation__link navigation__link--sub"
          >
            Mes prestataires
          </a>
          <a
            *ngIf="organisation.type === 'gesdec' || organisation.type === 'eco21'"
            [class.navigation__link--active]="isActive('/gestion/organisations')"
            [routerLink]="'/gestion/organisations'"
            class="navigation__link navigation__link--sub"
          >
            Liste des organisations
          </a>
          <a
            *ngIf="organisation.type === 'gesdec' || organisation.type === 'service_provider'"
            [class.navigation__link--active]="isActive('/gestion/code-type')"
            [routerLink]="'/gestion/code-type'"
            class="navigation__link navigation__link--sub"
          >
            Gestion des codes
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="navigation__bottom">
    <mat-form-field appearance="outline" class="mat-form-field-small w-full">
      <mat-label>Année de l'exercice</mat-label>
      <mat-select (selectionChange)="updateSelectedYear()" [(value)]="year">
        <mat-option *ngFor="let p of periods" [value]="p">
          {{ p }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="currentUser">
      <mat-divider></mat-divider>
      <span *ngIf="isImpersonated()" class="navigation__bottom__connected-as">Connecté en tant que :</span>
      <button [matMenuTriggerFor]="menu" class="navigation__bottom__nav" mat-button>
        <span [class.navigation__initials--alt]="isImpersonated()" class="navigation__initials">{{ initials }}</span>
        <span>
          <h5>{{ currentUser.firstname }} {{ currentUser.lastname }}</h5>
          <span
            *ngIf="currentUser.userOrganisations[0] && currentUser.userOrganisations[0].organisation"
            class="navigation__subtitle"
          >
            {{ currentUser.userOrganisations[0].organisation.name }}
          </span>
        </span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngIf="
            currentUser.userOrganisations[0] &&
            currentUser.userOrganisations[0].organisation &&
            currentUser.userOrganisations[0].organisation.type === 'gesdec'
          "
          [routerLink]="'/gestion/impersonation'"
          mat-menu-item
        >
          Se connecter en tant que
        </button>

        <button (click)="editProfile()" mat-menu-item>Editer mon profil</button>
        <button (click)="goToMyOrganisation()" mat-menu-item>Mon organisation</button>
        <button (click)="logout()" mat-menu-item>Déconnexion</button>
      </mat-menu>
      <button (click)="backToProfile()" *ngIf="isImpersonated()" class="btn btn-small navigation__profile-btn">
        <mat-icon>arrow_back</mat-icon>
        Retour à mon profil
      </button>
    </ng-container>
  </div>
</div>
