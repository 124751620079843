import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganisationFull } from 'src/app/shared/model/organisation.model';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { OrganisationDialogComponent } from './organisation-dialog/organisation-dialog.component';

@Component({
  selector: 'app-organisation-details',
  templateUrl: './organisation-details.component.html',
  styleUrls: ['./organisation-details.component.scss']
})
export class OrganisationDetailsComponent implements OnInit, OnDestroy {
  public organisation: OrganisationFull;
  private organisationId: number;
  private dialog$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private organisationService: OrganisationService
  ) {}

  ngOnInit(): void {
    this.organisationId = parseInt(this.route.snapshot.params.id, 10);
    this.getOrganisation();
  }

  /**
   * Open organisation dialog
   */
  public openDialog(): void {
    const dialogRef = this.dialog.open(OrganisationDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data: this.organisation
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getOrganisation();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  /**
   * Get organisation details
   */
  private getOrganisation(): void {
    this.organisationService.getOrganisationDetails(this.organisationId).subscribe(response => {
      this.organisation = response;
    });
  }
}
