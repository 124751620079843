import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationType } from '../model/organisation.model';

@Pipe({
  name: 'organisationType'
})
export class OrganisationTypePipe implements PipeTransform {
  transform(type: OrganisationType): string {
    switch (type) {
      case OrganisationType.GESDEC:
        return 'GESDEC';
      case OrganisationType.COMMUNE:
        return 'Commune';
      case OrganisationType.SERVICEPROVIDER:
        return 'Prestataire';
      case OrganisationType.ENTERPRISE:
        return 'Entreprise';
      case OrganisationType.ECO21:
        return 'Eco21';
      default:
        return '-';
    }
  }
}
