import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Property, PropertyType } from 'src/app/shared/model/property.model';
import { PropertyService } from 'src/app/shared/services/property.service';
import { AssociatedDataDialogComponent } from './associated-data-dialog/associated-data-dialog.component';

@Component({
  selector: 'app-associated-datas',
  templateUrl: './associated-datas.component.html',
  styleUrls: ['./associated-datas.component.scss']
})
export class AssociatedDatasComponent implements OnInit, OnDestroy {
  public properties: Array<Property>;
  public propertyType = PropertyType;
  public displayedColumns: string[] = ['type', 'annee', 'donnee'];
  private connectedOrganisation: Organisation;
  private organisationId: number;
  private dialog$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.organisationId = parseInt(this.route.snapshot.params.id, 10);
    this.connectedOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.getProperties();

    // Give GESDEC access to Edit action
    if (this.connectedOrganisation.type === OrganisationType.GESDEC) {
      this.displayedColumns = ['type', 'annee', 'donnee', 'actions'];
    }
  }

  /**
   * Open data dialog, whether on create or edit mode
   * @param property - Property to edit, if any
   */
  public editData(property?: Property): void {
    const dialogRef = this.dialog.open(AssociatedDataDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data: {
        property: property ? property : null,
        organisationId: this.organisationId
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getProperties();
      }
    });
  }

  public isNumber(value: any): boolean {
    return !isNaN(value);
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  /**
   * Get list of properties/datas
   */
  private getProperties(): void {
    this.propertyService.getPropertiesByOrganisation(this.organisationId).subscribe(response => {
      this.properties = response;
      // Sort properties by type, then by year within type
      this.properties.sort((a, b) => {
        if (a.type > b.type) {
          return 1;
        }
        if (a.type < b.type) {
          return -1;
        }
        if (this.datePipe.transform(a.date, 'yyyy') > this.datePipe.transform(b.date, 'yyyy')) {
          return -1;
        }
        if (this.datePipe.transform(a.date, 'yyyy') < this.datePipe.transform(b.date, 'yyyy')) {
          return 1;
        }
        return 0;
      });
    });
  }
}
