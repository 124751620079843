export interface Property {
  comment: string;
  date: string;
  organisation: string;
  type: string;
  value: string;
}

export enum PropertyType {
  OFS = 'Nb d\'employés des PME levées au forfait',
  Population = 'Population'
}
