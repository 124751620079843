<mat-form-field class="mat-form-field-small mat-form-field-small--large">
  <input [formControl]="baseCodeControl" [matAutocomplete]="auto" matInput tabindex="-1" type="text" />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    [panelWidth]="350"
    autoActiveFirstOption
    class="mat-autocomplete-panel--large"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.code }} {{ option.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
