<div *ngIf="data" class="declarations-status">
  <div>
    <h4>Statut des déclarations</h4>
    <div>
      <div
        *ngFor="let element of dataSorted"
        [ngClass]="'declarations-status__element--' + element.code_action"
        class="declarations-status__element d-flex"
      >
        <div class="declarations-status__label">
          <div class="declarations-status__circle"></div>
          {{ element.label }}
        </div>
        <div class="declarations-status__value ml-2 text-bold">{{ element.value }}</div>
      </div>
    </div>
  </div>
  <a [routerLink]="['/gestion/declarations-detail']" class="btn btn-small">Détails</a>
</div>
