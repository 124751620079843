import { Injectable } from '@angular/core';
import { AppState } from '../../core/store/states/app.state';
import { Store } from '@ngrx/store';
import { getSelectedYear$ } from '../../core/store/selectors/app.selectors';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected year: string;

  constructor(private readonly store$: Store<AppState>) {
    this.getSelectedYear();
  }

  /**
   *
   * Get the global year period parameter.
   *
   * @param firstParam
   *   Add the correct symbol starting the parameter.
   * @protected
   */
  protected getYearPeriodParam(firstParam = true): string {
    return `${firstParam ? '?' : '&'}flowPeriodFrom[after]=${this.year}-01-01&flowPeriodTo[before]=${
      this.year + 1
    }-01-01`;
  }

  protected getYearPeriodParamFrom(): string {
    return `${this.year}-01-01`;
  }

  protected getYearPeriodParamTo(): string {
    return `${this.year + 1}-01-01`;
  }

  /**
   *
   * Get the global year parameter.
   *
   * @param firstParam
   *   Add the correct symbol starting the parameter.
   * @protected
   */
  protected getYearParam(firstParam = true): string {
    return `${firstParam ? '?' : '&'}year=${this.year}`;
  }

  private getSelectedYear(): void {
    this.store$
      .select(getSelectedYear$)
      .pipe(
        map(selectedYear => {
          this.year = selectedYear;
        })
      )
      .subscribe();
  }
}
