import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { SpinnerService } from './shared/services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showSpinner = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.authenticationService.isLoggedIn()) {
        this.userService.getCurrent().subscribe(user => {
          localStorage.setItem('token', user.newRefreshToken);
        });
      }
    });

    this.spinnerService.showSpinner$.subscribe(showSpinner => (this.showSpinner = showSpinner));
  }
}
