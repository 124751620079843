import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopElementsComponent } from './components/top-elements/top-elements.component';
import { GraphWasteTypeRateComponent } from './components/graph-waste-type-rate/graph-waste-type-rate.component';
import { SharedModule } from '../../shared/shared.module';
import { GraphWasteEvolutionComponent } from './components/graph-waste-evolution/graph-waste-evolution.component';
import { DeclarationsStatusComponent } from './components/declarations-status/declarations-status.component';
import { GraphPerformanceRateComponent } from './components/graph-performance-rate/graph-performance-rate.component';
import { PerformanceRateElementComponent } from './components/graph-performance-rate/performance-rate-element/performance-rate-element.component';
import { LastImportEventsComponent } from './components/last-import-events/last-import-events.component';
import { GraphWastePerResidentComponent } from './components/graph-waste-per-resident/graph-waste-per-resident.component';

@NgModule({
  declarations: [
    DashboardComponent,
    TopElementsComponent,
    GraphWasteTypeRateComponent,
    GraphWasteEvolutionComponent,
    DeclarationsStatusComponent,
    GraphPerformanceRateComponent,
    PerformanceRateElementComponent,
    LastImportEventsComponent,
    GraphWastePerResidentComponent
  ],
  imports: [CommonModule, SharedModule]
})
export class DashboardModule {}
