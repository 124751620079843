<div class="dashboard__element">
  <h4>Evolution des déchets</h4>
  <p class="my-0">Quantité en tonnes</p>
  <div *ngIf="data.length" class="dashboard__chart waste-evolution">
    <ngx-charts-bar-vertical-2d
      [barPadding]="2"
      [groupPadding]="8"
      [legendPosition]="legendPosition.Below"
      [legendTitle]="null"
      [legend]="true"
      [results]="data | chartsData"
      [scheme]="colorScheme"
      [showGridLines]="false"
      [xAxis]="true"
      [yAxisTickFormatting]="yAxisTickFormattingFn"
      [yAxis]="true"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class="tooltip-label">{{ model.series }} · {{ model.name }}</div>
        <div class="tooltip-val">{{ model.value | number: '':'fr' }}</div>
      </ng-template>
    </ngx-charts-bar-vertical-2d>
  </div>
</div>
