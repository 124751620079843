<div class="dashboard__element">
  <h4 *ngIf="isRecyclable">Top 3 recyclables</h4>
  <h4 *ngIf="!isRecyclable">Top 3 des déchets <span class="light">en entrée</span></h4>
  <div class="top-elements__container">
    <div class="dashboard__chart top-elements">
      <div *ngFor="let element of data" class="top-elements__item">
        <img alt="" src="../../../../assets/img/clmod1.png" />
        <span class="top-elements__value">{{ element.value | number: '':'fr' }}t</span>
        <span matTooltipPosition="above" [matTooltip]="element.aggregateLabel" class="top-elements__label">
          {{ element.aggregateLabel }}
        </span>
      </div>
    </div>
  </div>
</div>
