import { Component, OnDestroy, OnInit } from '@angular/core';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { SiteFlow } from 'src/app/shared/model/site.model';
import { SiteService } from 'src/app/shared/services/site.service';
import { getSelectedYear$ } from '../../core/store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sites-flow',
  templateUrl: './sites-flow.component.html',
  styleUrls: ['./sites-flow.component.scss']
})
export class SitesFlowComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'data_in_amount',
    'tonnes_in',
    'data_out_amount',
    'tonnes_out',
    'progress_bar',
    'actions'
  ];
  public data: SiteFlow[];
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private readonly store$: Store<AppState>, private siteService: SiteService) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(() => {
      this.getSiteSummaries();
    });
  }

  /**
   * Unsubscribe to avoid memory leak.
   */
  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get list of sites flows
   */
  private getSiteSummaries(): void {
    this.siteService
      .getSitesSummary(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
      .subscribe(sites => {
        this.data = sites;
      });
  }
}
