<h1 *ngIf="!data" mat-dialog-title>Ajout d'autorisations</h1>
<h1 *ngIf="data && (currentOrganisation.type === 'gesdec')" mat-dialog-title>Édition d'autorisations</h1>
<h1 *ngIf="data && (currentOrganisation.type !== 'gesdec')" mat-dialog-title>Vue des autorisations</h1>
<mat-dialog-content>
  <div class="form-dialog__content form-dialog__content--full">
    <form [formGroup]="authorisationsForm" class="form-dialog__form">

      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Site</mat-label>
        <input [matAutocomplete]="auto" aria-label="Number" formControlName="site" matInput type="text" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySiteFn" (optionSelected)="onOptionSelected($event)" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredSite$ | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.site.errors && f.site.errors['incorrect']"
          >Ce site n'est pas valide.
        </mat-error>
      </mat-form-field>
      <div *ngIf="hasAuthorisations && (currentOrganisation.type === 'gesdec')">
        <span class="red">Ce site fait déjà l'objet d'une autorisation.</span>
        <ul class="ul">
          <li class="list" *ngFor="let item of operatingList">
            <span>Autorisation: {{ item.authorizationNumber }}/{{item.identificationNumber}}<br/>Période: {{item.period}}</span>
            <div>
              <button class="add" mat-button (click)="modifyAuthorisation(item.id)">Modifier</button>
              <button class="add" mat-button (click)="renewAuthorisation(item.id)">Renouveler</button>
            </div>
          </li>
        </ul>
      </div>

      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Numéro d'identification GE</mat-label>
        <input formControlName="idGe" matInput type="text" maxlength="5" minlength="5"/>
        <mat-error *ngIf="f.idGe.errors && f.idGe.errors.required">Ce champ est obligatoire.</mat-error>
        <mat-error *ngIf="f.idGe.errors && f.idGe.errors.min && f.idGe.errors.min">
          Nombre entre 10000 et 99999.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Numéro d'identification CH</mat-label>
        <input formControlName="idCh" matInput type="text" maxlength="9" minlength="9"/>
        <mat-error *ngIf="f.idCh.errors && f.idCh.errors.min && f.idCh.errors.min">
          Nombre entre 100000000 et 999999999.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Période de validité</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input formControlName="periodFrom" matStartDate placeholder="JJ/MM/AAAA" />
          <input formControlName="periodTo" matEndDate placeholder="JJ/MM/AAAA" />
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="f.periodFrom.errors && f.periodFrom.errors['incorrect']">
          Les données d'inventaire doivent être soumises sur une base annuelle.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Statut</mat-label>
        <mat-select formControlName="statut">
          <mat-option *ngFor="let statut of editStatut" [value]="statut.code">
            {{ statut.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Commentaire sur cette modification</mat-label>
        <textarea formControlName="comment" matInput></textarea>
      </mat-form-field>

      <h5>Liste des déchets & traitements autorisés</h5>
      <div formArrayName="items">
        <table class="table">
          <tr class="header-row">
            <th class="td-big">Code LMod</th>
            <th class="td-big">Code traitement</th>
            <th class="td-volume">Poids attendu [t]</th>
            <th class="td-remove">Action</th>
          </tr>
          <tr *ngIf="!itemsFormArray.length" class="repeater">
            <td>Aucun élément</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr class="repeater" *ngFor="let item of itemsFormArray.controls; let i = index" [formGroupName]="i">

            <td class="td-big">
              <mat-form-field appearance="none" class="form-dialog">

              <input
                (change)="lmodInputChange()"
                [matAutocomplete]="lmod"
                formControlName="codeLmod"
                placeholder="Code LMod"
                class="truncate"
                matInput
                type="text"
              />
              <mat-autocomplete
                #lmod="matAutocomplete"
                (optionSelected)="filterUnits()"
                [displayWith]="wasteflowService.displayCodeFn"
                autoActiveFirstOption
              >
                <mat-option *ngFor="let option of filteredLmodCodes$ | async" [value]="option">
                  {{ option.code }} {{ option.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>

          <td class="td-big">
            <mat-form-field appearance="none" class="form-dialog">

              <input
                [matAutocomplete]="treatments"
                formControlName="codeTreatment"
                placeholder="Code traitement"
                class="truncate"
                matInput
                type="text"
              />
              <mat-autocomplete
                #treatments="matAutocomplete"
                [displayWith]="wasteflowService.displayCodeFn"
                autoActiveFirstOption
              >
                <mat-option *ngFor="let treatment of filteredTreatments$ | async" [value]="treatment">
                  {{ treatment.code }} - {{ treatment.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>

          <td class="td-volume">
            <mat-form-field appearance="none" class="form-dialog">

              <input formControlName="volume" matInput type="text" placeholder="Poids attendu [t]" />
              <mat-error *ngIf="item.get('volume').hasError('required')">
                Ce champ est obligatoire.
              </mat-error>
            </mat-form-field>
          </td>

          <td class="td-remove">
              <button *ngIf="currentOrganisation.type === 'gesdec'" class="remove" mat-button (click)="removeItem(i)">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </td>
        </tr>
      </table>
      <div class="arrayError" *ngIf="submitted && itemsFormArray.errors?.atLeastOneItemRequired">
        Une paire de codes est requies.
      </div>
      <button *ngIf="currentOrganisation.type === 'gesdec'" class="add" mat-button (click)="addItem()">Ajouter</button>
    </div>
  </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button *ngIf="currentOrganisation.type === 'gesdec'" (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
