import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Code, CodeByCodeType, CodesByCodeType, CodeStateEnum } from 'src/app/shared/model/code.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { CodeTypeEditConfirmDialogComponent } from './code-type-edit-confirm-dialog/code-type-edit-confirm-dialog.component';

@Component({
  selector: 'app-code-type-detail',
  templateUrl: './code-type-detail.component.html',
  styleUrls: ['./code-type-detail.component.scss']
})
export class CodeTypeDetailComponent implements OnInit {
  data: CodeByCodeType[];
  private codeTypeId: number;
  dataPagination: CodesByCodeType;
  hasOneInEditMode = false;
  searchValue = '';
  STATE = CodeStateEnum;
  sorting = {
    dir: 'asc',
    sort: 'base_code'
  };
  codeBaseCodeOptions: Code[];
  displayedColumns = ['codeBase', 'labelBase', 'codeInterne', 'labelInterne', 'commentaireCodeInterne', 'actions'];

  constructor(private route: ActivatedRoute, private codeService: CodeService, private dialog: MatDialog) {}

  /**
   * Controls that the user is not trying to leave the page without any unsaved changes
   */
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasOneInEditMode;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.codeTypeId = parseInt(params.id, 10);
    });

    this.getCodes();
    this.getCodeBaseCodeOptions();
  }

  /**
   * Sort table
   * @param sort - Column to sort it to and direction
   */
  sortData(sort: Sort): void {
    this.askForConfirmation().subscribe(result => {
      if (result) {
        if (!sort.active || sort.direction === '') {
          this.sorting = {
            dir: null,
            sort: null
          };
        } else {
          this.sorting.dir = sort.direction;
          this.sorting.sort = sort.active;
        }

        this.getCodes();
      }
    });
  }

  getCodes(page = 1): void {
    this.codeService
      .getCodesByCodeType(this.codeTypeId, page, this.searchValue, this.sorting.sort, this.sorting.dir)
      .subscribe(response => {
        this.dataPagination = response;
        this.data = response.codes.map(c => {
          c.state = CodeStateEnum.DEFAULT;
          return c;
        });
      });
  }

  updatePage(page: number): void {
    this.askForConfirmation().subscribe(result => {
      if (result) {
        this.getCodes(page);
      } else {
      }
    });
  }

  /**
   * If user has unsaved changes, ask to confirm the action. Otherwise changes won't be saved
   */
  private askForConfirmation(): Observable<boolean> {
    if (!this.hasOneInEditMode) {
      return of(true);
    } else {
      return this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Voulez-vous vraiment quitter la page?',
            text: 'Vous avez des modifications non sauvegardées sur cette page.'
          }
        })
        .afterClosed();
    }
  }

  private getCodeBaseCodeOptions() {
    this.codeService
      .getCodeType(this.codeTypeId)
      .pipe(
        switchMap(codeType => {
          let n;
          if (typeof codeType.nature === 'string') {
            n = codeType.nature.split('/');
          } else {
            n = codeType.nature.nature.split('/');
          }
          const natureId = parseInt(n[n.length - 1], 10);
          return this.codeService.getLabelOptions(natureId);
        })
      )
      .subscribe(options => {
        this.codeBaseCodeOptions = options;
      });
  }

  setEditMode(element: CodeByCodeType): void {
    element.state = CodeStateEnum.EDITMODE;
    this.hasOneInEditMode = true;
  }

  addCodeLine(): void {
    const newLine = {
      base_code: null,
      comment_base_code: null,
      comment_internal_code: null,
      id_base_code: null,
      internal_code: null,
      id_codetype_nature: null,
      id_internal_code: null,
      label_base_code: null,
      label_internal_code: null,
      organisation: null,
      all_groupscode_base_code_id: null,
      state: CodeStateEnum.NEW
    };

    this.data = [...this.data, newLine];
    this.hasOneInEditMode = true;
  }

  onUpdateBaseCode(value: Code, element: CodeByCodeType): void {
    if (!element.previous_id_base_code) {
      element.previous_id_base_code = element.id_base_code;
    }
    element.id_base_code = value.id;
    element.base_code = value.code;
    element.label_base_code = value.label;
  }

  validateChanges(element: CodeByCodeType): void {
    if (element.state === CodeStateEnum.NEW) {
      // CREATE CODE - Add new line
      this.codeService
        .createCode({
          code: element.internal_code,
          label: element.label_internal_code,
          codeType: `/api/code_types/${this.codeTypeId}`,
          comment: element.comment_internal_code,
          groupCodes: [`/api/codes/${element.id_base_code}`]
        })
        .subscribe(el => {
          element.id_base_code = this.transformUrlToId(el.groupCodes[0]['@id']);
          element.id_internal_code = this.transformUrlToId(el['@id']);
          element.state = CodeStateEnum.DEFAULT;
          element.all_groupscode_base_code_id = [element.id_base_code];
        });
    } else {
      this.dialog
        .open(CodeTypeEditConfirmDialogComponent, {
          panelClass: ['confirm-dialog']
        })
        .afterClosed()
        .subscribe(result => {
          if (result === 0) {
            // UPDATE CODE WITH NO REPERCUTION ON EXISTING DATA
            const groupCodes = this.handleGroupCodes(element);
            this.codeService
              .updateCode(element.id_internal_code, {
                label: element.label_internal_code,
                comment: element.comment_internal_code,
                groupCodes: groupCodes
              })
              .subscribe(el => {
                element.comment_internal_code = el.comment;
                element.label_internal_code = el.label;
                element.state = CodeStateEnum.DEFAULT;
                element.previous_id_base_code = null;
                this.hasOneInEditMode = this.data.findIndex(code => code.state !== CodeStateEnum.DEFAULT) > 0;
              });
          } else if (result === 1) {
            // UPDATE CODE WITH REPERCUTION ON EXISTING DATA
            this.codeService
              .updateCodeWithRepercution({
                id_internal_code: element.id_internal_code,
                new_id_base_code: element.id_base_code,
                old_id_base_code: element.previous_id_base_code ? element.previous_id_base_code : element.id_base_code,
                label_internal_code: element.label_internal_code,
                comment_internal_code: element.comment_internal_code
              })
              .subscribe(() => {
                element.previous_id_base_code = null;
                element.state = CodeStateEnum.DEFAULT;
                this.hasOneInEditMode = this.data.findIndex(code => code.state !== CodeStateEnum.DEFAULT) > 0;
              });
          }
        });
    }
  }

  private transformUrlToId(url): number {
    const idUrl = url.split('/');
    return parseInt(idUrl[idUrl.length - 1], 10);
  }

  private handleGroupCodes(element: CodeByCodeType) {
    let groupCodes;
    if (element.previous_id_base_code && element.previous_id_base_code !== element.id_base_code) {
      groupCodes = element.all_groupscode_base_code_id.map(el => {
        if (el === element.previous_id_base_code) {
          el = element.id_base_code;
        }
        return `/api/codes/${el}`;
      });
      // Make sure we don't send duplicates
      groupCodes = [...new Set(groupCodes)];
    } else {
      groupCodes = element.all_groupscode_base_code_id.map(el => `/api/codes/${el}`);
    }

    return groupCodes;
  }
}
