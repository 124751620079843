import { Component, OnInit } from '@angular/core';
import { Organisation } from 'src/app/shared/model/organisation.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public organisation: Organisation;

  constructor() {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
  }
}
