<div class="organisation-list">
  <div class="organisation-list__top">
    <div>
      <h1 class="mt-0">Liste des organisations</h1>
      <div class="common-search">
        <h4>Recherche:</h4>
        <mat-form-field appearance="outline" class="mat-form-field-small">
          <input (input)="searchOrganisation($event)" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex">
      <button (click)="addOrganisation()" class="btn btn-small mr-2">Créer une organisation</button>
      <button (click)="downloadOrganisations()" class="btn btn-small btn-secondary">
        Télécharger les organisations
      </button>
    </div>
  </div>

  <div *ngIf="data" class="content-tertiary">
    <div class="table-container">
      <mat-table [dataSource]="data" class="organisation-list__table">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.type | organisationType }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amountUsers">
          <mat-header-cell *matHeaderCellDef>Nb utilisateurs</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.countUsers }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amountSites">
          <mat-header-cell *matHeaderCellDef> Nb sites</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.countSites }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Statut</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ getStatusLabel(element.status) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef>Activé</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.active ? 'Activé' : 'Désactivé' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contactEmail">
          <mat-header-cell *matHeaderCellDef>Contact e-mail</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.contactEmail }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a [routerLink]="['/gestion/organisation/' + element.id]" class="btn-link mr-2">Voir les détails</a>
            <button
              (click)="deactivate(element)"
              *ngIf="element.active && element.type !== 'gesdec' && element.type !== 'eco21'"
              class="btn-link"
            >
              Désactiver
            </button>
            <button
              (click)="activate(element)"
              *ngIf="!element.active && element.type !== 'gesdec' && element.type !== 'eco21'"
              class="btn-link"
            >
              Activer
            </button>
            <button (click)="fusion(element)" class="btn-link">Fusionner</button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <app-table-pagination
      (updatePage)="updatePage($event)"
      [currentPage]="currentPage"
      [itemsPerPage]="itemsPerPage"
      [pageLength]="pageLength"
    ></app-table-pagination>
  </div>
</div>
