<section class="inventory-details">
  <div *ngIf="wasteflows && wasteflows.length">
    <div class="table-container inventory-details__table-container">
      <mat-table [dataSource]="wasteflows" class="inventory-details__table" multiTemplateDataRows>
        <ng-container *ngFor="let column of displayedColumns; let i = index" [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>
            {{ labels[i] }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [class.mat-cell-ellipsis]="['treatment', 'lmod', 'origin', 'destination'].includes(column)"
          >
            <div matTooltipPosition="above" [matTooltip]="element[column]">
              <ng-container *ngIf="!['status'].includes(column); else customColumn">
                <ng-container *ngIf="['converted_qty'].includes(column)">
                  {{ element[column] | numberValue | number: '':'fr' }}
                </ng-container>
                <ng-container *ngIf="['origin_qty'].includes(column)">
                  {{ element[column] | numberValue | number: '':'fr' }} {{ element.origin_qty_unit }}
                </ng-container>
                <ng-container *ngIf="!['converted_qty', 'origin_qty'].includes(column)">
                  {{ element[column] }}
                </ng-container>
              </ng-container>

              <ng-template #customColumn>
                <span
                  *ngIf="column === 'status'"
                  [ngClass]="element.status_code | statusColor: organisation.type"
                  class="status"
                ></span>
              </ng-template>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{'light': row.wrt_status === 'complementary'}"
          >
        </mat-row>
      </mat-table>
    </div>
    <div class="inventory-details__actions">
      <a
        [queryParams]="{ wid: getWasteflowIds() }"
        [routerLink]="['/gestion/declarations-detail']"
        class="btn-secondary"
        tabindex="1"
      >
        Modifier les données
      </a>
      <button (click)="preclotureWasteflows()" class="btn" type="button" [disabled]="disableWasteflowClosure()">
        <img alt="" class="icon" src="../../../assets/img/check-double-white.svg" />
        Pré-clôturer
      </button>
    </div>
  </div>
</section>
