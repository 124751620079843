import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-elements',
  templateUrl: './top-elements.component.html',
  styleUrls: ['./top-elements.component.scss']
})
export class TopElementsComponent implements OnInit, OnDestroy {
  @Input() isRecyclable: boolean;
  public data: Array<any>;
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(() => {
      this.getData();
    });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get top 3 elements data
   */
  private getData(): void {
    this.dashboardService
      .getWasteFlowGeneric(
        'GESDEC',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id,
        this.isRecyclable
      )
      .subscribe(data => {
        this.data = [];
        if (data.data) {
          this.data = data.data.splice(0, 3);
        }
      });
  }
}
