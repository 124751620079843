<div class="d-flex align-items-center justify-content-between">
  <h1 class="mt-0 mb-5">Gestion des codes</h1>
</div>

<div *ngIf="data" class="content-tertiary">
  <div class="table-container">
    <mat-table [dataSource]="data" class="code-type-list__table">
      <ng-container matColumnDef="organisation">
        <mat-header-cell *matHeaderCellDef>Organisation</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.organisation }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.label }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="codeParent">
        <mat-header-cell *matHeaderCellDef>Code parent</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.parent_code }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <mat-header-cell *matHeaderCellDef>Commentaire</mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div matTooltipPosition="above" [matTooltip]="element.comment">{{ element.comment }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a [routerLink]="['/gestion/code-type/' + element.code_type_id]" class="btn-link mr-2">Voir le détail</a>
          <button (click)="download(element.code_type_id)" class="btn-link">Télécharger</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <!-- Download link to fake click on link from component -->
  <div class="d-none">
    <a #link href="{{ downloadLink }}" download></a>
  </div>
</div>
