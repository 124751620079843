import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataElement } from 'src/app/shared/model/dashboard.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-graph-waste-per-resident',
  templateUrl: './graph-waste-per-resident.component.html',
  styleUrls: ['./graph-waste-per-resident.component.scss']
})
export class GraphWastePerResidentComponent implements OnInit, OnDestroy {
  public data: Array<DataElement>;
  public view: any[] = [170, 170];
  public colorScheme = {
    domain: []
  };
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$)
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get waste rate per resident data
   */
  private getData(): void {
    this.dashboardService
      .getWasteRatePerResident(
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id
      )
      .subscribe(data => {
        this.data = data.data;
        this.colorScheme.domain = data.colors;
      });
  }
}
