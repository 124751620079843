<h1 mat-dialog-title>
  {{ data.title }}
</h1>
<mat-dialog-content>
  {{ data.text }}
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button [mat-dialog-close]="true" class="btn" mat-button>{{ data.customBtn ? data.customBtn : 'Confirmer' }}</button>
</mat-dialog-actions>
