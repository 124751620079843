<h1 mat-dialog-title>
  Conditions Générales d’Utilisation
</h1>
<mat-dialog-content class='mb-1'>
  <h2 class='h4'>1. Conditions d’utilisation</h2>
  <p>
    En naviguant sur ce site Internet ou en ayant recours aux prestations de SIG, l’utilisateur se déclare d’accord avec la collecte de ses données et avec les conditions d’utilisation de ce site Internet.
  </p>
  <p>
    <span class='text-bold'>Responsabilité</span>
    <br>
    L’utilisateur consulte et utilise ce site Internet à ses propres risques. Les pages du site Internet peuvent contenir des imprécisions ou des erreurs. SIG se réserve le droit d’en améliorer le contenu en tout temps.
    <br>
    <br>
    SIG ne peut être tenue pour responsable de tout dommage causé par l’affichage du site Internet et de ses contenus ou par l’impossibilité d’y accéder, notamment de dommages résultant de l’apparition d’un virus ou d’un autre logiciel malveillant lors du téléchargement de contenus figurant sur ces pages.
  </p>
  <p>
    <span class='text-bold'>Contenu du site</span>
    <br>
    SIG ne garantit pas l'exactitude, la précision, l'actualité, la fiabilité, ni l'exhaustivité du contenu des informations postées sur ce site Internet.
    <br>
    <br>
    Toute prétention en responsabilité contre SIG pour cause de dommages matériels ou immatériels résultant de l'accès à des informations publiées ou de leur utilisation ou non-utilisation, de l'usage abusif de la connexion ou de perturbations techniques, est exclue.
  </p>

  <h2 class='h4 mt-4'>2. Droits d’auteur</h2>
  <p>
    Le contenu de ce site est protégé par des droits d’auteur. Il ne peut être utilisé à des fins commerciales qu’avec l’accord de SIG.
    <br>
    <br>
    Les droits d'auteur et tous les autres droits relatifs aux contenus, images, photos ou autres fichiers figurant sur le site Internet – hormis les photos, images ou autres fichiers en lien avec les objets proposés - sont la propriété exclusive de SIG ou des titulaires des droits spécialement cités.
    <br>
    <br>
    La reproduction de tout élément est soumise à l'accord préalable écrit des titulaires des droits d'auteur.
  </p>

  <h2 class='h4 mt-4'>3. Utilisation de données personnelles</h2>
  <p>
    Conformément à l'article 13 de la Constitution fédérale suisse et aux dispositions de la Loi genevoise sur l’information du public, l’accès aux documents et la protection des données personnelles (LIPAD), toute personne a droit à la protection de sa sphère intime, ainsi qu'à la protection contre l'usage abusif de ses données personnelles. SIG respecte ces dispositions.
    <br>
    <br>
    Les données personnelles sont traitées de manière strictement confidentielle et ne sont ni vendues, ni transmises à des tiers sauf en application d’une obligation légale.
    <br>
    <br>
    Lors de l'accès aux pages web, les données suivantes sont enregistrées dans des fichiers journaux : l’adresse IP, la date, l’heure, la demande du navigateur et les informations transmises de manière générale sur le système d'exploitation, respectivement sur le navigateur.
    <br>
    <br>
    Ces données d'utilisation sont utilisées pour effectuer des évaluations statistiques anonymes, à partir desquelles il est possible d’observer des tendances permettant à SIG d’améliorer ses offres en conséquence.
  </p>

  <h2 class='h4 mt-4'>4. Accès aux données commerciales des utilisateurs</h2>
  <p>
    SIG éco21 n'a accès aux données collectées par l'outil - dans le cadre de l'obligation légale de fourniture de données au GESDEC - que dans le cadre de ses tâches d'utilité publique définies par la convention d'objectifs État-SIG, comme pour les autres activités de monopole de SIG (cloisonnement juridique).
    <br>
    <br>
    Une attention toute particulière est portée au secret d'entreprise de chacun des utilisateurs et à la non-diffusion de données non anonymisées conformément aux législations spécifiques en matière de protection et sécurité des données.
  </p>


  <h2 class='h4 mt-4'>5. Cookies</h2>
  <p>
    Ce site Internet utilise des cookies. Les cookies sont de petits fichiers textes qui sont enregistrés sur l’ordinateur utilisé lors de la visite du site, de façon temporaire ou durable. Le but des cookies est notamment d’analyser l’utilisation du site, en vue d’une exploitation statistique, ainsi que d’améliorations continues.
    <br>
    <br>
    Les cookies peuvent être désactivés à tout moment dans les paramètres du navigateur web, partiellement ou en totalité. En désactivant les cookies, certaines fonctionnalités de ce site peuvent éventuellement ne plus être disponibles.
  </p>

  <p class='mt-5'>
    SIG
    <br>
    Case postale 2777
    <br>
    Chemin Château-Bloch 2
    <br>
    1219 Le Lignon
    <br>
    <br>
    SIG peut être contacté à l’adresse électronique suivante : contacts@statdechets-ge.ch
    <br>
    <br>
    © SIG. Tous droits réservés.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex justify-content-center">
    <button [mat-dialog-close]="1" class="btn">Fermer</button>
  </div>
</mat-dialog-actions>
