import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public success = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    public router: Router,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.resetPasswordForm.controls;
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  /**
   * Submit forgotten password form
   */
  public submit(): void {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.authenticationService.resetPassword(this.f.email.value).subscribe(
        response => {
          this.success = response;
        },
        error => {
          return error;
        }
      );
    }
  }
}
