import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, zip } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FusionStateEnum } from 'src/app/shared/model/fusion.model';
import { OrganisationFull, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { SiteService } from 'src/app/shared/services/site.service';

class SiteFusionDialogData {
  site: Site;
  organisationSites: Site[];
}

@Component({
  selector: 'app-site-fusion-dialog',
  templateUrl: './site-fusion-dialog.component.html',
  styleUrls: ['./site-fusion-dialog.component.scss']
})
export class SiteFusionDialogComponent implements OnInit {
  state: FusionStateEnum = FusionStateEnum.SELECTION;
  stateEnum = FusionStateEnum;
  sites: Site[];
  filteredSites: Observable<Site[]>;
  siteControl = new FormControl();
  showConfirmation = false;
  selectedSite: Site;
  errors: Array<string>;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SiteFusionDialogData,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<SiteFusionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.selectedSite = this.data.site;
    if (this.selectedSite.organisation?.type === OrganisationType.GESDEC) {
      zip(this.siteService.getSites(null, null, 'public'), this.siteService.getSites(null, null, 'inventory'))
        .pipe(map(x => x[0].concat(x[1])))
        .subscribe(data => this.handleListOfSites(data));
    } else {
      this.handleListOfSites(JSON.parse(JSON.stringify(this.data.organisationSites)));
    }
  }

  handleListOfSites(sites: Site[]) {
    this.sites = sites;
    const i = this.sites.findIndex(o => o['@id'] === this.selectedSite['@id']);
    this.sites.splice(i, 1);
    this.filteredSites = this.siteControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  submit() {
    this.errors = [];
    if (this.state === FusionStateEnum.SELECTION) {
      this.state = FusionStateEnum.CONFIRMATION;
    } else if (this.state === FusionStateEnum.CONFIRMATION) {
      this.state = FusionStateEnum.LOADING;
      this.siteService.mergeSites(this.selectedSite.id, this.siteControl.value?.id).subscribe(
        result => {
          if (result.error.length) {
            this.errors = result.error;
            this.state = FusionStateEnum.ERROR;
          } else {
            this.state = FusionStateEnum.SUCCESS;
          }
        },
        () => {
          this.errors = ['Il y a eu une erreur avec la fusion.'];
          this.state = FusionStateEnum.ERROR;
        }
      );
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  displayFn(value: OrganisationFull): string {
    return value && value.name ? value.name : '';
  }

  private _filter(value: any): Site[] {
    const filterValue = value && value.name ? value.name : value;
    return this.sites.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
  }
}
