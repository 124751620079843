import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): Date | null {
    const vals = value.split('/');
    if (vals.length !== 3 || parseInt(vals[2], 10) < 1900) {
      return null;
    }
    return new Date(parseInt(vals[2], 10), parseInt(vals[1], 10) - 1, parseInt(vals[0], 10));
  }
}
