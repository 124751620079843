import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Organisation, OrganisationSimple, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Prestation } from 'src/app/shared/model/prestation.model';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { PrestationService } from 'src/app/shared/services/prestation.service';

interface PrestationDialog {
  prestation: Prestation;
  canEdit: Array<string>;
  type: string;
}

@Component({
  selector: 'app-prestation-dialog',
  templateUrl: './prestation-dialog.component.html',
  styleUrls: ['./prestation-dialog.component.scss']
})
export class PrestationDialogComponent implements OnInit {
  errorMessage: string;

  newPrestataire = null;
  showSuccess = false;
  showWaiting = false;
  showAddPrestataire = false;
  isDatepickerDisabled = false;
  prestataires: Array<OrganisationSimple> = [];
  filteredPrestataires$: Observable<OrganisationSimple[]>;

  prestationForm: FormGroup;
  organisation: Organisation;
  saveButtonText = 'Sauvegarder';

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<PrestationDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: PrestationDialog,
    private prestationService: PrestationService,
    private organisationService: OrganisationService
  ) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.prestationForm = new FormGroup({
      prestataire: new FormControl({ value: '', disabled: false }, Validators.required),
      type: new FormControl({ value: 'data_provider', disabled: false }, Validators.required),
      dateFrom: new FormControl({ value: '', disabled: false }, Validators.required),
      dateTo: new FormControl({ value: '', disabled: false }, Validators.required),
      comment: new FormControl({ value: '', disabled: false })
    });

    this.getPrestataires();

    if (this.data.prestation) {
      this.f.prestataire.disable();
      this.f.type.disable();
      this.f.comment.disable();
      this.f.dateFrom.disable();
      this.f.dateTo.disable();
      this.isDatepickerDisabled = true;

      this.data.canEdit.forEach(el => {
        this.prestationForm.controls[el].enable();

        if (el === 'dateFrom') {
          this.isDatepickerDisabled = false;
        }
      });

      this.f.type.setValue(this.data.prestation.type);
      this.f.dateFrom.setValue(this.data.prestation.dateFrom);
      this.f.dateTo.setValue(this.data.prestation.dateTo);
      this.f.comment.setValue(this.data.prestation.comment);

      if (this.data.type) {
        if (this.data.type === 'confirm') {
          this.saveButtonText = 'Confirmer';
        } else if (this.data.type === 'renew') {
          this.saveButtonText = 'Renouveler';
          this.renewDates();
        }
      }
    }
  }

  displayFn(prestataire: any): string {
    return prestataire ? prestataire.name : '';
  }

  /**
   * Get form control
   */
  get f(): { [key: string]: AbstractControl } {
    return this.prestationForm.controls;
  }

  /**
   * Renew dates of prestation
   * Same period as previous period but starting after the end of it
   */
  private renewDates(): void {
    const start = new Date(this.data.prestation.dateFrom);
    const end = new Date(this.data.prestation.dateTo);
    const diff = end.getTime() - start.getTime();
    this.f.dateFrom.setValue(new Date(end.setDate(end.getDate() + 1)));
    this.f.dateTo.setValue(new Date(end.setTime(end.getTime() + diff)));
  }

  /**
   * Get list of prestatataires
   */
  private getPrestataires(): void {
    this.organisationService
      .getPrestataires(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
      .subscribe(response => {
        this.prestataires = response;

        if (this.data.prestation) {
          if (
            this.prestataires.findIndex(
              prestataire => prestataire['@id'] === this.data.prestation.organisationRecipient['@id']
            ) === -1
          ) {
            this.prestataires.push(this.data.prestation.organisationRecipient);
          }
          this.f.prestataire.setValue(this.data.prestation.organisationRecipient);
        }

        this.filteredPrestataires$ = this.prestationForm.controls.prestataire.valueChanges.pipe(
          startWith(''),
          map(value => {
            const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
            return this.prestataires?.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
          })
        );
      });
  }

  /**
   * Submit form
   */
  submit(): void {
    this.errorMessage = null;
    this.prestationForm.markAsDirty();
    this.prestationForm.markAsTouched();
    this.prestationForm.markAllAsTouched();
    if (this.prestationForm.invalid) {
      return;
    }

    this.showWaiting = true;

    // If custom prestataire, it means we've created one
    if (this.f.prestataire.value['@id'] === 'CUSTOM') {
      this.organisationService.addPrestataire(this.newPrestataire).subscribe(
        response => {
          this.showWaiting = false;
          this.f.prestataire.setValue(response);
          this.submitPrestation();
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      this.submitPrestation();
    }
  }

  /**
   * Submit prestation
   */
  submitPrestation(): void {
    this.errorMessage = null;
    // Hack to make it set it to the correct date
    const dateFrom = new Date(this.f.dateFrom.value);
    const dateTo = new Date(this.f.dateTo.value);
    dateFrom.setHours(dateFrom.getHours() + 2);
    dateTo.setHours(dateTo.getHours() + 2);

    const prestation: { [k: string]: any } = {
      organisationRecipient: this.f.prestataire.value['@id'],
      type: this.f.type.value,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      comment: this.f.comment.value
    };

    if (this.data.prestation && (!this.data.type || this.data.type !== 'renew')) {
      if (this.data.type) {
        if (this.data.type === 'confirm') {
          prestation.status = 'confirm';
        }
      }
      this.prestationService.editPrestation(this.data.prestation.id, prestation).subscribe(
        () => {
          this.showSuccess = true;
          this.showWaiting = false;
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      prestation.organisationOwner = this.organisation['@id'];
      prestation.status = 'new';

      this.prestationService.addPrestation(prestation).subscribe(
        () => {
          this.showSuccess = true;
          this.showWaiting = false;
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  /**
   * Handle prestation import error
   * @param error - Error to handle
   */
  private handleError(error: HttpErrorResponse): void {
    this.errorMessage = error.error['hydra:description'];
    this.showWaiting = false;
  }

  /**
   * Close add prestataire subdialog
   * @param event - Whether we should close add prestataire
   */
  closeAddPrestataire(event: boolean | null): void {
    this.showAddPrestataire = false;
    if (event) {
      this.newPrestataire = event;
      this.prestataires.push({
        name: this.newPrestataire && this.newPrestataire.name ? this.newPrestataire.name : '',
        '@id': 'CUSTOM'
      });

      this.f.prestataire.setValue(this.prestataires.find(presta => presta['@id'] === 'CUSTOM'));
    }
  }
}
