<div class="dashboard__element">
  <h4>Tonnage des déchets</h4>

  <div class="dashboard__chart">
    <div *ngIf="data" class="performance-rate">
      <div class="performance-rate__graph">
        <app-performance-rate-element
          *ngFor="let element of data"
          [color]="element.aggregateColor"
          [height]="element.valueHeight + '%'"
          [label]="element.aggregateLabel"
          [value]="element.value"
        ></app-performance-rate-element>
      </div>
    </div>
  </div>
</div>
