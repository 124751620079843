import { Organisation } from './organisation.model';

export interface Prestation {
  dateFrom: string;
  dateTo: string;
  id: number;
  organisationOwner: Organisation;
  organisationRecipient: Organisation;
  type: PrestationType;
  periodType: string;
  status: string;
  statusLabel: string;
  wasteFlows: Array<any>;
  comment?: string;
}

export enum PrestationType {
  DATAPROVIDER = 'data_provider',
  DATAPROVIDERINVENTORY = 'data_provider_inventory',
  HELP = 'help'
}

export enum PrestationStatus {
  NEW = 'new',
  TOCONFIRM = 'to_confirm',
  CONFIRM = 'confirm',
  REJECT = 'reject'
}
