<div *ngIf="value" class="history-line row">
  <div class="col-2">
    <div class="history-line__title">
      <ng-container *ngIf="isOrigin">Donnée d'origine</ng-container>
      <ng-container *ngIf="isCurrent">Donnée actuelle</ng-container>
      <ng-container *ngIf="!isCurrent && !isOrigin">{{ value.date }}</ng-container>
    </div>
    <div class="history-line__subtitle">
      <ng-container *ngIf="isCurrent || isOrigin">
        {{ value.date }}
      </ng-container>
    </div>
    <div class="history-line__user">
      {{ value.userFirstName }} {{ value.userLastName }}<br />{{ value.userOrganisationName }}
    </div>
  </div>
  <div class="col-10">
    <div class="small-table-content row">
      <div
        class="col-4 ellipsis-three-lines"
        matTooltipPosition="above"
        [matTooltip]="value.codeLmod + ' ' + value.labelLmod"
      >
        {{ value.codeLmod }} {{ value.labelLmod }}
      </div>
      <div
        class="col-4 ellipsis-three-lines"
        matTooltipPosition="above"
        [matTooltip]="value.codeTreatment + ' ' + value.labelTreatment"
      >
        {{ value.codeTreatment }} {{ value.labelTreatment }}
      </div>
      <div class="col-2">{{ value.originQty }} {{ value.originUnit }}</div>
      <div class="col-2">{{ value.wasteFlowStatus | statusLabel }}</div>
    </div>
    <div *ngIf="value.comment" class="history-line__comment">"{{ value.comment }}"</div>
  </div>
</div>
