<div class="dashboard__element">
  <h4>Production de déchets par habitant</h4>
  <p class="my-0">kg/hab</p>
  <div class="dashboard__chart waste-per-resident">
    <ngx-charts-bar-vertical-stacked
      [barPadding]="40"
      [legend]="false"
      [results]="data | chartsData"
      [scheme]="colorScheme"
      [showDataLabel]="true"
      [showGridLines]="false"
      [xAxis]="true"
      [yAxis]="true"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
