<h1 mat-dialog-title>Fusion de site</h1>
<mat-dialog-content>
  <div class="fusion-dialog__content" *ngIf="state === stateEnum.SELECTION">
    <div>
      <h2 class="fusion-dialog__subtitle">Site à fusionner</h2>
      <div class="mt-4 fusion-dialog__element">
        <div class="fusion-dialog__element-label">Site</div>
        <div class="fusion-dialog__element-value">
          {{ selectedSite.organisation && selectedSite.organisation.name ? selectedSite.organisation.name + ' - ' : ''
          }}{{ selectedSite.name }}
        </div>
      </div>
      <div class="fusion-dialog__element">
        <div class="fusion-dialog__element-label">Type de site</div>
        <div class="fusion-dialog__element-value">{{ selectedSite.codeSite ? selectedSite.codeSite.label : '' }}</div>
      </div>
      <div class="fusion-dialog__element">
        <div class="fusion-dialog__element-label">Visibilité</div>
        <div class="fusion-dialog__element-value">{{ selectedSite.status | siteType }}</div>
      </div>
      <div class="fusion-dialog__element">
        <div class="fusion-dialog__element-label">Code Noga</div>
        <div class="fusion-dialog__element-value">{{ selectedSite.codeNoga ? selectedSite.codeNoga.label : '' }}</div>
      </div>
      <div class="fusion-dialog__element">
        <div class="fusion-dialog__element-label">Adresse</div>
        <div class="fusion-dialog__element-value">
          {{ selectedSite.street1 }} {{ selectedSite.street2 }}<br />{{ selectedSite.postalCode }} {{ selectedSite.town
          }}<br />{{ selectedSite.country }}
        </div>
      </div>
    </div>
    <div>
      <h2 class="fusion-dialog__subtitle">Site dans lequel il sera fusionné</h2>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Nom du site</mat-label>
        <input [formControl]="siteControl" [matAutocomplete]="auto" aria-label="Sites" matInput type="text" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredSites | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <ng-container *ngIf="siteControl.value && siteControl.value.name">
        <div class="mt-0 fusion-dialog__element">
          <div class="fusion-dialog__element-label">Site</div>
          <div class="fusion-dialog__element-value">
            {{
              siteControl.value.organisation && siteControl.value.organisation.name
                ? siteControl.value.organisation.name + ' - '
                : ''
            }}{{ siteControl.value.name }}
          </div>
        </div>
        <div class="fusion-dialog__element">
          <div class="fusion-dialog__element-label">Type de site</div>
          <div class="fusion-dialog__element-value">
            {{ siteControl.value.codeSite ? siteControl.value.codeSite.label : '' }}
          </div>
        </div>
        <div class="fusion-dialog__element">
          <div class="fusion-dialog__element-label">Visibilité</div>
          <div class="fusion-dialog__element-value">{{ siteControl.value.status | siteType }}</div>
        </div>
        <div class="fusion-dialog__element">
          <div class="fusion-dialog__element-label">Code Noga</div>
          <div class="fusion-dialog__element-value">
            {{ siteControl.value.codeNoga ? siteControl.value.codeNoga.label : '' }}
          </div>
        </div>
        <div class="fusion-dialog__element">
          <div class="fusion-dialog__element-label">Adresse</div>
          <div class="fusion-dialog__element-value">
            {{ siteControl.value.street1 }} {{ siteControl.value.street2 }}<br />{{ siteControl.value.postalCode }}
            {{ siteControl.value.town }}<br />{{ siteControl.value.country }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="fusion-dialog__content" *ngIf="state === stateEnum.CONFIRMATION">
    Êtes-vous sûr-e? L'élément de gauche sera supprimé et les données assignées à l'élément de droite.
  </div>
  <div class="text-center" *ngIf="state === stateEnum.LOADING">
    <div>Fusion en cours ...</div>
    <div class="loader"></div>
  </div>
  <div class="fusion-dialog__content" *ngIf="state === stateEnum.SUCCESS">
    Le site {{ selectedSite.name }} a été fusionné avec le site {{ siteControl.value.name }} avec succès.
  </div>
  <div class="fusion-dialog__content" *ngIf="state === stateEnum.ERROR">
    <ng-container *ngFor="let error of errors">{{ error }}<br /></ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="state !== stateEnum.SUCCESS && state !== stateEnum.LOADING"
    class="btn-link"
    mat-button
    mat-dialog-close
  >
    Annuler
  </button>
  <button
    *ngIf="state === stateEnum.SELECTION || state === stateEnum.CONFIRMATION"
    (click)="submit()"
    class="btn"
    mat-button
    [disabled]="!siteControl.value || !siteControl.value.name"
  >
    Confirmer
  </button>
  <button *ngIf="state === stateEnum.SUCCESS" (click)="close()" class="btn" mat-button>Fermer</button>
</mat-dialog-actions>
