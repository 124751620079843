<div class="dashboard__element">
  <h4>Répartition des déchets</h4>
  <div *ngIf="data.length" class="dashboard__chart waste-type-rate">
    <div>
      <ngx-charts-pie-chart
        [arcWidth]="0.35"
        [doughnut]="true"
        [labels]="false"
        [legend]="false"
        [margins]="[0, 0, 0, 0]"
        [results]="data | chartsData"
        [scheme]="colorScheme"
        [view]="view"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip-label">{{ model.name }}</div>
          <div class="tooltip-val">{{ formatValue(model.value) }}</div>
        </ng-template>
      </ngx-charts-pie-chart>
    </div>
    <div class="waste-type-rate__legend">
      <div class="waste-type-rate__legend__header">
        <div>Types de déchets</div>
        <div>Valeur en %</div>
      </div>
      <div *ngFor="let el of data" class="waste-type-rate__legend__row">
        <div>
          <span [style.backgroundColor]="el.color" class="waste-type-rate__legend__circle"></span>
          {{ el.name }}
        </div>
        <div class="waste-type-rate__legend__value">{{ el.percent }}</div>
      </div>
    </div>
  </div>
</div>
