import { Site } from './site.model';

export interface ImportEvent {
  front_id: string;
  id: number;
  file: string;
  period: string;
  organisation: string;
  site: string;
  status: StatusValidation;
  statusContext: StatusValidation;
  statusContextLabel: string;
  reporterId: number;
  type: ImportEventType;
}

export enum ImportEventType {
  MANUAL_PRIVATE = 'manualPrivate',
  MANUAL_INVENTORY = 'manualInventory',
  INVENTORY_CONSOLIDATION = 'inventoryConsolidation',
  FILE_PRIVATE = 'filePrivate',
  FILE_INVENTORY = 'fileInventory',
  DATA_BREAKDOWN = 'dataBreakdown'
}

export enum StatusValidation {
  NEW = 'new',
  VIEW = 'view',
  TOFIX = 'to_fix',
  TOVALIDATE = 'to_validate',
  OUTSERVICE = 'out_service',
  VALID = 'valid',
  PRECLOSURE = 'pre_closure',
  PREARCHIVE = 'pre_archive',
  CLOSURE = 'closure',
  ARCHIVE = 'archive'
}

export enum StatusInventory {
  INVENTORY = 'inventory',
  PRIVATE = 'private',
  CONSOLIDATEFORINVENTORY = 'consolidate_for_inventory',
  COMPLEMENTARY = 'complementary',
  INTEGRATED = 'integrated_to_inventory',
  CLOSURE = 'closure'
}

export interface ImportEventSimple {
  '@id': string;
  '@type': string;
  id: number;
  filename: string;
  reporter: Reporter;
  reporterUser: string;
  site: Site;
}

export interface Reporter {
  '@id': string;
  '@type': string;
  id: number;
  name: string;
  type: string;
  contactFirstname: string;
  contactLastname: string;
}
