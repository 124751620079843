import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ImportEvent } from 'src/app/shared/model/import-event.model';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-last-import-events',
  templateUrl: './last-import-events.component.html',
  styleUrls: ['./last-import-events.component.scss']
})
export class LastImportEventsComponent implements OnInit, OnDestroy {
  public data: ImportEvent[];
  public displayedColumns: string[] = ['periode', 'site', 'prestataire', 'statut', 'actions'];
  public hasGottenData = false;
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private importEventService: ImportEventService, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(() => {
      this.getImportEvents();
    });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Function to sort table
   * @param sort - Element to sort and direction
   */
  public sortData(sort: Sort): void {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'periode':
          return this.compare(a.period, b.period, isAsc);
        case 'site':
          return this.compare(a.site, b.site, isAsc);
        case 'prestataire':
          return this.compare(a.organisation, b.organisation, isAsc);
        case 'status':
          return this.compare(a.statusContext, b.statusContext, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Get list of import events
   */
  private getImportEvents(): void {
    this.importEventService.getImportEventsByOrganisation(this.organisation.id).subscribe(importEvents => {
      this.hasGottenData = true;
      // Only keep the last 4 importEvents
      this.data = importEvents.splice(0, 4);
      // Sort by default
      this.sortData({ active: 'status', direction: 'desc' });
    });
  }
}
