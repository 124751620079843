<div class="declaration decla-detail">
  <h1 class="mt-0 mb-5">Détail du bilan de flux site</h1>
  <div
    class="declaration__content content-tertiary"
    [class.decla-detail__btm-padding]="!disableEditFields || !isSaveDisabled()"
  >
    <div class="decla-detail__filters">
      <div>
        <div class="multiselect__wrapper">
          <angular2-multiselect
            (onDeSelect)="filterWasteflows()"
            (onDeSelectAll)="filterWasteflows()"
            (onSelect)="filterWasteflows()"
            (onSelectAll)="filterWasteflows()"
            (onGroupSelect)="filterWasteflows()"
            (onGroupDeSelect)="filterWasteflows()"
            (onFilterSelectAll)="filterWasteflows()"
            (onFilterDeSelectAll)="filterWasteflows()"
            [(ngModel)]="selectedCodeLmod"
            [data]="codeLmodList"
            [settings]="multiselectConfiguration"
            id="codeLmod"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
    <div *ngIf="dataChart?.length" [style]="{ height: dynamicChartHeight }" class="site-flow-chart">
      <ngx-charts-bar-horizontal-2d
        [barPadding]="2"
        [groupPadding]="8"
        [legend]="true"
        [maxYAxisTickLength]="30"
        [results]="dataChart | chartsData"
        [scheme]="colorScheme"
        [showGridLines]="false"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [xAxis]="true"
        [yAxis]="true"
        legendPosition="bottom"
        legendTitle="Légende"
      >
      </ngx-charts-bar-horizontal-2d>
    </div>
    <app-wasteflows-table
      #wasteflowTable
      (updateDisableEditFields)="disableEditFields = $event"
      *ngIf="selectedCodeLmod?.length || codeLmodList?.length; else noData"
      [currentView]="currentView"
      [disableEditFields]="disableEditFields"
      [selectedCodeLmod]="selectedCodeLmod?.length > 0 ? selectedCodeLmod : codeLmodList"
      [selectedSiteId]="selectedSiteId"
      [submitting]="submitting"
    >
    </app-wasteflows-table>
  </div>
</div>

<div class="decla-detail__sticky" *ngIf="!disableEditFields || !isSaveDisabled()">
  <button
    (click)="cancelChanges()"
    [disabled]="disableEditFields"
    class="btn-small btn-secondary mr-2"
    tabindex="1"
    type="button"
  >
    Annuler
  </button>
  <button (click)="wasteflowTable.saveChanges()" [disabled]="isSaveDisabled()" class="btn btn-small" type="button">
    Sauvegarder
  </button>
</div>

<ng-template #noData>
  <p>Aucune donnée disponible</p>
</ng-template>
