<h1 mat-dialog-title>Modifier ces données en masse</h1>
<mat-dialog-content>
  <div class="form-dialog__content form-dialog__content--full">
    <form [formGroup]="wasteflowForm" class="form-dialog__form">

      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Statut</mat-label>
            <mat-select formControlName="statut">
              <mat-option *ngFor="let statut of editStatut" [value]="statut.code">
                {{ statut.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Inclusion</mat-label>
            <mat-select formControlName="inclusion">
              <mat-option *ngFor="let inclusion of editInclusion" [value]="inclusion.code">
                {{ inclusion.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--<mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Site de l'organisation</mat-label>
        <mat-select formControlName="site">
          <mat-option *ngFor="let site of sites" [value]="site">
            {{ site.organisation.name }} - {{ site.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
      <mat-radio-group class="d-block mb-2" formControlName="inventory">
        <mat-radio-button *ngIf="initialWrtInventoryValue === 'inventory'" [value]="'inventory'" class="mr-4">
          Donnée d'inventaire
        </mat-radio-button>
        <mat-radio-button *ngIf="initialWrtInventoryValue === 'complementary'" [value]="'complementary'" class="mr-4">
          Donnée complémentaire
        </mat-radio-button>
        <mat-radio-button
          *ngIf="initialWrtInventoryValue === 'integrated_to_inventory'"
          [value]="'integrated_to_inventory'"
          class="mr-4"
        >
          Donnée intégrée à l'inventaire
        </mat-radio-button>
        <mat-radio-button [value]="'private'">Donnée privée</mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="outline">
        <mat-label>Période de la déclaration</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input formControlName="periodFrom" matStartDate placeholder="JJ/MM/AAAA" />
          <input formControlName="periodTo" matEndDate placeholder="JJ/MM/AAAA" />
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="f.periodFrom.hasError('matStartDateInvalid')">Date de début invalide</mat-error>
        <mat-error *ngIf="f.periodTo.hasError('matEndDateInvalid')">Date de fin invalide</mat-error>
        <mat-error *ngIf="f.periodFrom.errors && f.periodFrom.errors['incorrect']">
          Les données d'inventaire doivent être soumises sur une base annuelle.
        </mat-error>
      </mat-form-field>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Collecte / Traitement à l’origine</mat-label>
            <input [matAutocomplete]="provenance" formControlName="provenanceCode" matInput type="text" />
            <mat-autocomplete
              #provenance="matAutocomplete"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let code of filteredProvenanceCodes | async" [value]="code">
                {{ code.code }} - {{ code.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Traitement à la destination</mat-label>
            <input [matAutocomplete]="treatments" formControlName="treatment" matInput type="text" />
            <mat-autocomplete
              #treatments="matAutocomplete"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let treatment of filteredTreatments | async" [value]="treatment">
                {{ treatment.code }} - {{ treatment.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Code LMod</mat-label>
            <input
              (change)="lmodInputChange()"
              [matAutocomplete]="lmod"
              formControlName="codeLmod"
              matInput
              type="text"
            />
            <mat-autocomplete
              #lmod="matAutocomplete"
              (optionSelected)="filterUnits()"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let option of filteredLmodCodes | async" [value]="option">
                {{ option.code }} {{ option.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__input">
            <mat-label>Valeur de la donnée</mat-label>
            <input formControlName="originQty" matInput type="number" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Unité</mat-label>
            <mat-select formControlName="originUnit">
              <ng-container *ngFor="let unit of filteredUnits">
                <mat-option [value]="unit">
                  {{ unit.name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Organisation validatrice</mat-label>
        <input [matAutocomplete]="validator" formControlName="validator" matInput type="text" />
        <mat-autocomplete
          #validator="matAutocomplete"
          [displayWith]="wasteflowService.displayNameFn"
          autoActiveFirstOption
        >
          <mat-option *ngFor="let validator of filteredValidators | async" [value]="validator">
            {{ validator.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Commentaire sur cette modification</mat-label>
        <textarea formControlName="comment" matInput></textarea>
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>
<mat-card *ngIf="!displayConfirmButton" class="card">
  <mat-card-content>Vous avez filtré <strong>{{ data }}</strong> données à modifier. Pour des raisons de sécurité, il n'est pas possible de modifier en masse plus de 100 données. Merci de sélectionner un sous-ensemble plus restreint.</mat-card-content>
</mat-card>
<mat-dialog-actions>

  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button *ngIf="displayConfirmButton" (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
