import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Organisation, OrganisationFull } from 'src/app/shared/model/organisation.model';
import { AuthorisationsDialogComponent } from './authorisations-dialog/authorisations-dialog.component';
import { OrganisationFusionDialogComponent } from '../organisation-fusion-dialog/organisation-fusion-dialog.component';
import { AuthorisationsService } from 'src/app/shared/services/authorisations.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Authorization } from 'src/app/shared/model/authorization.model';

@Component({
  selector: 'app-authorisations-list',
  templateUrl: './authorisations-list.component.html',
  styleUrls: ['./authorisations-list.component.scss']
})
export class AuthorisationsListComponent implements OnInit, OnDestroy {
  dataSource: any;
  site: any;
  data: Array<Authorization>;
  currentPage = 0;
  currentSearchTerm: string;
  pageLength = 1;
  displayedColumns: string[] = [
    'site',
    'period',
    'number',
    'numberch',
    'statut',
    'codes',
    'volumes',
    'actions'
  ];
  currentOrganisation: Organisation;
  private dialog$: Subscription;
  private itemsPerPage = 20;
  public organisation: Organisation;
  private siteId: any;
  public showSuccessMessage = false;
  public currentUserType = false;

  constructor(
    private authorisationsService: AuthorisationsService,
    private dialog: MatDialog,
    public route: ActivatedRoute
  ) {
    this.authorisationsService.data$.subscribe(data => {
      this.showSuccessMessage = true;
    });
  }

  ngOnInit(): void {
    this.currentOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.route.params.subscribe(routeParams => {
      setTimeout(() => {
        this.siteId = parseInt(routeParams.id, 10);
        this.getAllAuthorizations();
      }, 0);
    });
  }

  /**
   * Create new Authorization
   */
  addAuthorisation(): void {
    const dialogRef = this.dialog.open(AuthorisationsDialogComponent, {
      disableClose: true,
      panelClass: ['form-dialog'],
      data: null,
      autoFocus: false
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(() => {
        this.getAllAuthorizations();
    });
  }

  openAuthorisationDialog(element: Authorization, authId: number, type: string): void {
    const dialogRef = this.dialog.open(AuthorisationsDialogComponent, {
      disableClose: true,
      panelClass: ['form-dialog'],
      data: { element, authId, type },
      autoFocus: false
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(() => {
      this.getAllAuthorizations();
    });
  }

  /**
   * Search organisation on input in search field
   */
  searchAuthorisation(event: any): void {
    this.currentSearchTerm = event.target.value;
    this.currentPage = 0;
    this.getAllAuthorizations();
  }

  /**
   * Fusion organisation with another one
   */
  renew(organisation: OrganisationFull): void {
    const dialogRef = this.dialog.open(OrganisationFusionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['fusion-dialog'],
      data: organisation
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getAllAuthorizations();
      }
    });
  }

  updatePage(page: number): void {
    this.currentPage = page;
    this.getAllAuthorizations();
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  private getAllAuthorizations(): void {
    const currentOrgId = this.currentOrganisation.id;
    const isGesdec = this.currentOrganisation.type;

    let gesdec: boolean = false;

    if(isGesdec === "gesdec") {
      gesdec = true;
      this.currentUserType = true;
    } else {
      gesdec = false
      this.currentUserType = false;
    }

    this.authorisationsService.getAuthorisations(gesdec ? '' : currentOrgId, '', this.currentSearchTerm, this.siteId).subscribe(response => {
      this.data = response['hydra:member'];
      this.pageLength = Math.ceil(response['hydra:totalItems'] / this.itemsPerPage);

      if (Array.isArray(this.data)) {
        const handledSites = this.data.map((item: Authorization) => {
          const operatingAuthorizationCodes = Array.isArray(item.operatingAuthorizationCodes) ? item.operatingAuthorizationCodes : []
            const volumeSum = operatingAuthorizationCodes.reduce((sum, code) => sum + Number(code.volume || 0), 0);
            const endDateCompare = new Date(item.dateTo).getTime();

            const startDate = new Date(item.dateFrom).getTime();
            const endDate = new Date(item.dateTo).getTime();

            // Check if the status is "validated" and if the current date is within the specified range
            const isStatusValidated = item.status === 'validated';
            const isWithinDateRange = Date.now() >= startDate && Date.now() <= endDate;

            // Set the statusLabel based on the conditions
            let statusLabel = '';
            if (isStatusValidated) {
              if (isWithinDateRange) {
                statusLabel = 'Validée';
              } else if (Date.now() < startDate) {
                statusLabel = 'A venir';
              } else {
                statusLabel = 'Echue';
              }
            } else if(item.status === 'draft') {
              statusLabel = 'Brouillon';
            } else if(item.status === 'rejected') {
              statusLabel = 'Rejetée';
            }

            return {
              ...item,
              operatingAuthorizationCount: operatingAuthorizationCodes.length,
              siteName: item.site.name,
              organisationName: item.site.organisation.name,
              volumeSum: volumeSum,
              period: item.period,
              endDate: endDateCompare,
              statusLabel: statusLabel,
            };
        });

        handledSites.sort((a, b) => {
          // Compare siteName first
          const siteNameComparison = a.siteName.localeCompare(b.siteName);

          // If siteName is the same, compare periods by end date
          if (siteNameComparison === 0) {
            return b.endDate - a.endDate;
          }

          return siteNameComparison;
        });

        this.data = handledSites;
        this.dataSource = new MatTableDataSource(this.data);
      }

    })
  }
}
