<div class="d-flex align-items-center justify-content-between">
  <h1 class="mt-0 mb-4">Gestion des codes</h1>
</div>
<div class="common-search">
  <h4>Recherche:</h4>
  <mat-form-field appearance="outline" class="mat-form-field-small">
    <input [disabled]="hasOneInEditMode" [(ngModel)]="searchValue" (input)="getCodes()" matInput type="text" />
  </mat-form-field>
</div>

<div *ngIf="data" class="content-tertiary">
  <div class="table-container">
    <mat-table
      (matSortChange)="sortData($event)"
      [matSortActive]="sorting.sort"
      [matSortDirection]="sorting.dir"
      [dataSource]="data"
      matSort
      class="code-type-detail__table"
    >
      <ng-container matColumnDef="codeBase">
        <mat-header-cell
          *matHeaderCellDef
          arrowPosition="before"
          mat-sort-header="base_code"
          [disabled]="hasOneInEditMode"
          >Code de base</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div *ngIf="element.state === STATE.DEFAULT" matTooltipPosition="above" [matTooltip]="element.base_code">
            {{ element.base_code }}
          </div>
          <app-base-code-field
            (updateField)="onUpdateBaseCode($event, element)"
            *ngIf="element.state !== STATE.DEFAULT"
            [element]="element"
            [options]="codeBaseCodeOptions"
          ></app-base-code-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="labelBase">
        <mat-header-cell
          *matHeaderCellDef
          arrowPosition="before"
          mat-sort-header="label_base_code"
          [disabled]="hasOneInEditMode"
          >Label de base</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div matTooltipPosition="above" [matTooltip]="element.label_base_code">{{ element.label_base_code }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="codeInterne">
        <mat-header-cell
          *matHeaderCellDef
          arrowPosition="before"
          mat-sort-header="internal_code"
          [disabled]="hasOneInEditMode"
          >Code interne</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div *ngIf="element.state !== STATE.NEW" matTooltipPosition="above" [matTooltip]="element.internal_code">
            {{ element.internal_code }}
          </div>
          <mat-form-field *ngIf="element.state === STATE.NEW" class="mat-form-field-small w-full">
            <input [(ngModel)]="element.internal_code" matInput />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="labelInterne">
        <mat-header-cell
          *matHeaderCellDef
          arrowPosition="before"
          mat-sort-header="label_internal_code"
          [disabled]="hasOneInEditMode"
          >Label interne</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div
            *ngIf="element.state === STATE.DEFAULT"
            [matTooltip]="element.label_internal_code"
            matTooltipPosition="above"
          >
            {{ element.label_internal_code }}
          </div>
          <mat-form-field *ngIf="element.state !== STATE.DEFAULT" class="mat-form-field-small w-full">
            <input [(ngModel)]="element.label_internal_code" matInput />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="commentaireCodeInterne">
        <mat-header-cell *matHeaderCellDef>Commentaire code interne</mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
          <div
            *ngIf="element.state === STATE.DEFAULT"
            [matTooltip]="element.comment_internal_code"
            matTooltipPosition="above"
          >
            {{ element.comment_internal_code }}
          </div>
          <mat-form-field *ngIf="element.state !== STATE.DEFAULT" class="mat-form-field-small w-full">
            <input [(ngModel)]="element.comment_internal_code" matInput />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            *ngIf="element.state === STATE.DEFAULT && element.organisation !== 'Code officiel' && element.organisation !== 'GESDEC'  && element.organisation !== 'gesdec'"
            (click)="setEditMode(element)"
            class="btn-link"
          >
            <mat-icon>edit</mat-icon>
            <span>Editer</span>
          </button>
          <button
            [disabled]="
              element.state === STATE.NEW &&
              (!element.id_base_code || !element.internal_code || !element.label_internal_code)
            "
            *ngIf="element.state !== STATE.DEFAULT"
            (click)="validateChanges(element)"
            class="btn-link"
          >
            <mat-icon>check</mat-icon>
            <span>Valider</span>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div>
      <button (click)="addCodeLine()" class="btn-fab mt-1" mat-button>
        <mat-icon>add</mat-icon>
        <span>Ajouter un code</span>
      </button>
      <app-table-pagination
        (updatePage)="updatePage($event + 1)"
        [autoUpdatePagination]="false"
        [currentPage]="dataPagination.currentPage - 1"
        [itemsPerPage]="dataPagination.itemsPerPage"
        [pageLength]="dataPagination.totalPage"
      ></app-table-pagination>
    </div>
  </div>
</div>
