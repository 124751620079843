import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-registration-legend',
  templateUrl: './registration-legend.component.html',
  styleUrls: ['./registration-legend.component.scss'],
})
export class RegistrationLegendComponent {
  constructor(private dialog: MatDialog) {}
  openPrivacyDialog(): void {
    this.dialog.open(PrivacyDialogComponent);
  }
}

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
})
export class PrivacyDialogComponent {}
