import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Wasteflow } from 'src/app/shared/model/wasteflow.model';
import { Prestation } from 'src/app/shared/model/prestation.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';

@Component({
  selector: 'app-reject-prestation-dialog',
  templateUrl: './reject-prestation-dialog.component.html',
  styleUrls: ['./reject-prestation-dialog.component.scss']
})
export class RejectPrestationDialogComponent implements OnInit {
  public wasteflows: Wasteflow[];
  public displayedColumns: string[] = [
    'periode',
    'codeLmod',
    'libelleLmod',
    'origine',
    'destination',
    'traitement',
    'quantite'
  ];

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<RejectPrestationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Prestation,
    private wasteflowService: WasteflowService
  ) {}

  ngOnInit(): void {
    this.wasteflowService.getWasteflows(this.data.organisationRecipient.id).subscribe(wasteflows => {
      this.wasteflows = wasteflows['hydra:member'];
    });
  }
}
