<div class="organisation__header">
  <h4>Les données associées</h4>
  <button (click)="editData()" class="btn btn-small">Ajouter donnée</button>
</div>
<div *ngIf="properties && properties.length" class="mt-5 organisation-datas">
  <mat-table [dataSource]="properties" class="organisation-datas__table">
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ propertyType?.[element.type] ?? '-' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="annee">
      <mat-header-cell *matHeaderCellDef>Année</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.date | date: 'yyyy' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="donnee">
      <mat-header-cell *matHeaderCellDef>Valeur</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.value && isNumber(element.value)">
          {{ element.value | number: '':'fr' }}
        </ng-container>
        <ng-container *ngIf="element.value && !isNumber(element.value)">
          {{ element.value }}
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="organisation-datas__table__actions-cell"></mat-header-cell>
      <mat-cell *matCellDef="let element" class="organisation-datas__table__actions-cell">
        <button (click)="editData(element)" class="btn-link">Editer</button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
