<div class="declaration decla-detail sticky">
  <div class="decla-detail__top">
    <h1 class="mt-0 mb-5">Consulter les données</h1>

    <a [routerLink]="'/gestion/declarations'" tabindex="-1">
      <img alt="" src="../../../assets/img/arrow-left.svg" />
      Liste des déclarations
    </a>
    <div class="decla-detail__flex-container mt-3 mb-3">
      <div class="decla-detail__main-filter">
        <h4 class="mr-2 mb-0 mt-0">Filtrer par déclaration:</h4>
        <mat-form-field *ngIf="importEvents.length" appearance="outline" class="mat-form-field-small">
          <mat-label>ID déclaration</mat-label>
          <input
              [matAutocomplete]="importE"
              [formControl]="importEventsControl"
              matInput
              type="text"
            />
            <mat-autocomplete
              #importE="matAutocomplete"
              (optionSelected)="updateImportEvent(false, $event.option?.value)"
              [displayWith]="displayFrontIdFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let importEvent of filteredImportEvents | async" [value]="importEvent">
                {{ importEvent.front_id }}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <button
          (click)="updateImportEvent(true)"
          [disabled]="currentView === V.ENTERPRISE"
          class="ml-2 btn-link"
          tabindex="-1"
        >
          Tout voir
        </button>
      </div>
      <div class="action__buttons">
        <button (click)="wasteflowTable.openWasteflowMassEditDialog()" class="action__mass btn-secondary btn-small mb-2">
          Modifier ces données en masse
        </button>
        <button (click)="wasteflowTable.openWasteflowDialog()" class="btn-secondary btn-small mb-2">
          Ajouter une donnée
        </button>
      </div>
    </div>
  </div>
  <div
    class="declaration__content content-tertiary"
    [class.decla-detail__btm-padding]="!disableEditFields || wasteflowTable.canStillSaveOnEnterprise"
  >
    <div class="decla-detail__filters decla-detail__filters--main" [formGroup]="wasteflowFilters">
      <div>
        <div class="multiselect__wrapper">
          <angular2-multiselect
            #multiselect
            (onDeSelect)="filterWasteflows(true, true)"
            (onDeSelectAll)="filterWasteflows(true, true)"
            (onSelect)="filterWasteflows(true, true)"
            (onSelectAll)="filterWasteflows(true, true)"
            (onGroupSelect)="filterWasteflows(true, true)"
            (onGroupDeSelect)="filterWasteflows(true, true)"
            (onFilterSelectAll)="filterWasteflows(true, true)"
            (onFilterDeSelectAll)="filterWasteflows(true, true)"
            formControlName="codeLmod"
            [data]="codeLmodList"
            [settings]="multiselectConfiguration"
            id="codeLmodSelect"
          >
          </angular2-multiselect>
        </div>
      </div>
      <div class="decla-detail__filters--row">
        <div>
          <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
            <mat-label>Origine</mat-label>
            <input
              (keyup)="filterInputChange($event.target?.value)"
              [matAutocomplete]="provenance"
              formControlName="provenanceSite"
              matInput
              type="text"
            />
            <mat-autocomplete
              #provenance="matAutocomplete"
              (optionSelected)="filterWasteflows()"
              [displayWith]="wasteflowService.displayNameFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let provenanceSite of filteredProvenanceSiteList | async" [value]="provenanceSite">
                {{ provenanceSite.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
            <mat-label>Destination</mat-label>
            <input
              (keyup)="filterInputChange($event.target?.value)"
              [matAutocomplete]="destination"
              formControlName="destinationSite"
              matInput
              type="text"
            />
            <mat-autocomplete
              #destination="matAutocomplete"
              (optionSelected)="filterWasteflows()"
              [displayWith]="wasteflowService.displayNameFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let destinationSite of filteredDestinationSiteList | async" [value]="destinationSite">
                {{ destinationSite.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" class="mat-form-field-small mr-2">
            <mat-label>Statut</mat-label>
            <mat-select (selectionChange)="filterWasteflows()" formControlName="status">
              <mat-option></mat-option>
              <mat-option *ngFor="let status of wasteflowStatus" [value]="status.code">
                {{ status.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            (click)="deleteFilters()"
            class="btn-icon btn-small mr-2"
            matTooltipPosition="above"
            matTooltip="Réinitialiser les filtres"
          >
            <span class="material-icons-round">replay</span>
          </button>
          <app-export-wasteflow
            [selectedCodeLmod]="wasteflowFilters.controls.codeLmod.value"
            [selectedDestinationSite]="wasteflowFilters.controls.destinationSite.value?.id"
            [selectedImportEventId]="selectedImportEventId"
            [selectedProvenanceSite]="wasteflowFilters.controls.provenanceSite.value?.id"
          ></app-export-wasteflow>
        </div>
        <div>
          <div *ngIf="organisation.type === 'gesdec' || organisation.type === 'commune'" class="decla-detail__toggle">
            <button
              (click)="goToView(0)"
              [class.active]="currentView === V.MAIN"
              class="btn-secondary btn-small btn-icon"
              matTooltipPosition="above"
              matTooltip="Toutes les données"
            >
              <img alt="" src="../../../assets/img/liste.svg" />
            </button>
            <button
              (click)="goToView(1)"
              [class.active]="currentView === V.DOUBLON"
              class="btn-secondary btn-small btn-icon"
              matTooltipPosition="above"
              matTooltip="Marquer les données complémentaires"
            >
              <img alt="" src="../../../assets/img/doublon.svg" />
            </button>
            <button
              (click)="goToView(2)"
              *ngIf="organisation.type === 'gesdec'"
              [class.active]="currentView === V.ENTERPRISE"
              class="btn-secondary btn-small btn-icon"
              matTooltipPosition="above"
              matTooltip="Données d'entreprise"
            >
              <img alt="" src="../../../assets/img/entreprise.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-wasteflows-table
      #wasteflowTable
      (changeView)="goToView($event)"
      (updateDisableEditFields)="disableEditFields = $event"
      [currentView]="currentView"
      [disableEditFields]="disableEditFields"
      [selectedCodeLmod]="wasteflowFilters.controls.codeLmod.value"
      [selectedDestinationSite]="wasteflowFilters.controls.destinationSite.value?.id"
      [selectedWasteflowStatus]="wasteflowFilters.controls.status.value"
      [selectedImportEventId]="selectedImportEventId"
      [selectedProvenanceSite]="wasteflowFilters.controls.provenanceSite.value?.id"
      [submitting]="submitting"
      [wasteflowIds]="wasteflowIds"
    >
    </app-wasteflows-table>
  </div>
</div>

<div class="decla-detail__sticky" *ngIf="!disableEditFields || wasteflowTable.canStillSaveOnEnterprise">
  <button
    (click)="cancelChanges()"
    [disabled]="disableEditFields"
    class="btn-small btn-secondary mr-2"
    tabindex="1"
    type="button"
  >
    Annuler
  </button>
  <button
    (click)="wasteflowTable.saveChanges()"
    [disabled]="
      wasteflowTable && (!wasteflowTable.canSave || (disableEditFields && !wasteflowTable.canStillSaveOnEnterprise))
    "
    class="btn btn-small"
    type="button"
  >
    Sauvegarder
  </button>
</div>
