<h1 *ngIf="!showErrors && !showSuccess && !showWaiting" mat-dialog-title>Importer une déclaration</h1>
<h1 *ngIf="showWaiting" mat-dialog-title>Importation en cours</h1>
<h1 *ngIf="showErrors && !showWaiting" mat-dialog-title>Erreur lors de l'import</h1>
<h1 *ngIf="showSuccess && !showWaiting" mat-dialog-title>Votre déclaration a été importée avec succès</h1>
<mat-dialog-content>
  <div *ngIf="showWaiting" class="loader"></div>
  <div *ngIf="!showSuccess && !showWaiting" class="declaration-dialog__content">
    <form *ngIf="!showErrors" [formGroup]="importForm" class="declaration-dialog__form">
      <div class="declaration-dialog__prestataire">
        <span>{{ data && data.type === 'gesdec' ? 'Organisation connectée' : 'Nom du prestataire' }}</span>
        <span *ngIf="data" class="text-bold">{{ data.name }}</span>
      </div>

      <mat-form-field *ngIf="data.type !== 'gesdec'" appearance="outline" class="declaration-dialog__select">
        <mat-label>Nom du site</mat-label>
        <mat-select formControlName="site">
          <mat-option *ngFor="let site of sites" [value]="site.id">
            {{ site.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.site.errors && f.site.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="data.type === 'gesdec'" appearance="outline" class="declaration-dialog__select">
        <mat-label>Nom du site</mat-label>
        <input [matAutocomplete]="auto" aria-label="Nom du site" formControlName="site" matInput type="text" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredSites | async" [value]="option">
            {{ option.organisation ? option.organisation.name + ' - ' : '' }}{{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.site.errors && f.site.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="declaration-dialog__select">
        <mat-label>Privacité</mat-label>
        <mat-select formControlName="privacy">
          <mat-option value="private" disabled>Données privées</mat-option>
          <mat-option value="inventory">Données d'inventaire</mat-option>
        </mat-select>
        <mat-error *ngIf="f.privacy.errors && f.privacy.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Période</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input formControlName="periodFrom" matStartDate placeholder="JJ/MM/AAAA" />
          <input formControlName="periodTo" matEndDate placeholder="JJ/MM/AAAA" />
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="f.periodFrom.hasError('matStartDateInvalid')">Date de début invalide</mat-error>
        <mat-error *ngIf="f.periodTo.hasError('matEndDateInvalid')">Date de fin invalide</mat-error>
        <mat-error *ngIf="f.periodFrom.errors && f.periodFrom.errors['incorrect']"
          >Les données d'inventaire doivent être soumises sur une base annuelle.
        </mat-error>
      </mat-form-field>
      <div *ngIf="formularyErrors.length">
        <div *ngFor="let error of formularyErrors" class="text-orange mb-1 text-sm">
          {{ error }}
        </div>
      </div>
    </form>
    <div *ngIf="showErrors" class="declaration-dialog__errors">
      <div class="declaration-dialog__error-icon">
        <img alt="" src="../../../assets/img/exclamation-circle.svg" />
      </div>
      <div class="declaration-dialog__error-text">
        <p>
          Votre déclaration n'a pas été importée car des erreurs ont été détectées. Modifiez le fichier puis importez-le
          de nouveau.
        </p>
        <p class="text-sm">
          {{ fileErrors.length }}
          {{ fileErrors.length > 1 ? 'erreurs ont été trouvées' : 'erreur a été trouvée' }}
          lors de la validation du fichier :
        </p>
        <ul class="text-sm">
          <li *ngFor="let error of fileErrors" [innerHTML]="error"></li>
        </ul>
      </div>
    </div>
    <div
      [class.declaration-dialog__file--error]="(submitted && !fileData) || showErrors"
      class="declaration-dialog__file"
    >
      <input
        #fileInput
        (change)="addFile($event)"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="declaration-dialog__input"
        type="file"
      />
      <label for="declaration-dialog__input">
        <img alt="" class="mb-3" src="../../../assets/img/upload.svg" />
        <span *ngIf="filename" class="text-dark mb-2">{{ filename }}</span>
        <span class="declaration-dialog__file__select">
          {{ showErrors ? 'Réimporter le fichier' : 'Sélectionner un fichier' }}
        </span>
        <span class="text-sm">Fichier excel (.xls) uniquement</span>
        <span class="text-xxs mt-1 text-primary">
          Si vous recevez une erreur "Le fichier est en cours d'utilisation.", déplacez votre fichier sur votre bureau
          avant de l'ouvrir.
        </span>
      </label>
      <span *ngIf="submitted && !fileData" class="text-orange text-sm d-block mt-2">
        Vous devez importer un fichier.
      </span>
    </div>
  </div>
  <div *ngIf="showSuccess && !showWaiting" class="d-flex align-items-center">
    <img alt="" class="mr-3" src="../../../assets/img/check-circle.svg" />
    <span>Tout est en ordre, le rapport d'erreur a détecté aucune erreur dans le fichier importé.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="!showSuccess && !precheckError" [disabled]="showWaiting" class="btn-link" mat-button mat-dialog-close>
    Annuler
  </button>
  <button
    (click)="resetForm()"
    *ngIf="!showSuccess && precheckError"
    [disabled]="showWaiting"
    class="btn-link"
    mat-button
  >
    Annuler
  </button>
  <button
    *ngIf="!showSuccess && precheckError && importEventId"
    [disabled]="showWaiting"
    [queryParams]="{ id: importEventId }"
    [routerLink]="['/gestion/declarations-detail']"
    class="btn-link"
    mat-button
    mat-dialog-close
  >
    Voir les données
  </button>
  <button
    (click)="continueUpload()"
    *ngIf="!showSuccess && precheckError"
    [disabled]="showWaiting"
    class="btn"
    mat-button
  >
    Continuer
  </button>
  <button *ngIf="showSuccess" [disabled]="showWaiting" [mat-dialog-close]="newImportEvent" class="btn" mat-button>
    Fermer
  </button>
  <button (click)="submit()" *ngIf="!showSuccess && !precheckError" [disabled]="showWaiting" class="btn" mat-button>
    Importer
  </button>
</mat-dialog-actions>
