<div
  *ngIf="element.wasteFlowStatus.code !== 'to_fix' || (currentView && currentView === V.DOUBLON)"
  matTooltipPosition="above"
  [matTooltip]="element.newCodeLmod.displayLabel"
  class="ellipsis"
>
  {{ element.newCodeLmod.displayLabel }}
</div>
<ng-container *ngIf="element.wasteFlowStatus.code === 'to_fix' && lmodCodes && currentView !== V.DOUBLON">
  <mat-form-field class="mat-form-field-small mat-form-field-small--large">
    <input [formControl]="lmodControl" [matAutocomplete]="auto" matInput tabindex="-1" type="text" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      [panelWidth]="350"
      autoActiveFirstOption
      class="mat-autocomplete-panel--large"
    >
      <mat-option *ngFor="let option of filteredLmodCodes | async" [value]="option">
        {{ option.code }} {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
