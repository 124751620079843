import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code } from 'src/app/shared/model/code.model';
import {
  AtlasElement,
  Organisation,
  OrganisationFull,
  OrganisationType
} from 'src/app/shared/model/organisation.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';

@Component({
  selector: 'app-organisation-dialog',
  templateUrl: './organisation-dialog.component.html',
  styleUrls: ['./organisation-dialog.component.scss']
})
export class OrganisationDialogComponent implements OnInit, OnDestroy {
  private fullAddress: AtlasElement;
  public organisationForm: FormGroup;
  public phoneRegex = /^\+[0-9][0-9][0-9]?\ [0-9][0-9]\ [0-9][0-9][0-9]\ [0-9][0-9]\ [0-9][0-9]$/;
  public codeNogas: Code[] = [];
  public filteredCodeNogas: Observable<Code[]>;
  private currentOrganisation: Organisation;
  private isGenevaChanges$: Subscription;

  public types = [
    {
      displayName: 'GESDEC',
      name: OrganisationType.GESDEC
    },
    {
      displayName: 'Commune',
      name: OrganisationType.COMMUNE
    },
    {
      displayName: 'Prestataire',
      name: OrganisationType.SERVICEPROVIDER
    },
    {
      displayName: 'Entreprise',
      name: OrganisationType.ENTERPRISE
    },
    {
      displayName: 'Eco21',
      name: OrganisationType.ECO21
    }
  ];

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrganisationFull,
    private dialogRef: MatDialogRef<OrganisationDialogComponent>,
    private organisationService: OrganisationService,
    private codeService: CodeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentOrganisation = JSON.parse(localStorage.getItem('organisation'));

    // Create organisation form
    this.organisationForm = new FormGroup({
      type: new FormControl({ value: '', disabled: false }, Validators.required),
      name: new FormControl({ value: '', disabled: false }, Validators.required),
      codeNoga: new FormControl({ value: null, disabled: false }),
      contactFirstName: new FormControl({ value: '', disabled: false }),
      contactLastName: new FormControl({ value: '', disabled: false }),
      contactEmail: new FormControl(
        { value: '', disabled: false },
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
        )
      ),
      contactPhone: new FormControl({ value: '', disabled: false }, Validators.pattern(this.phoneRegex)),
      isGeneva: new FormControl({ value: 0, disabled: false }),
      street1: new FormControl({ value: '', disabled: false }, Validators.required),
      street2: new FormControl({ value: null, disabled: false }, Validators.required),
      postalCode: new FormControl({ value: null, disabled: false }, Validators.required),
      town: new FormControl({ value: null, disabled: false }, Validators.required),
      country: new FormControl({ value: null, disabled: false }, Validators.required),
      canton: new FormControl({ value: null, disabled: false }, Validators.required)
    });

    this.codeService.getNogaCodes().subscribe(codes => {
      this.codeNogas = codes;

      if (this.data && this.data.codeNoga) {
        this.f.codeNoga.setValue(this.data.codeNoga);
      }

      this.filteredCodeNogas = this.f.codeNoga.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });

    this.isGenevaChanges$ = this.organisationForm.get('isGeneva').valueChanges.subscribe(value => {
      this.f.street1.setErrors({ incorrect: false });
      this.f.street1.updateValueAndValidity();
    });

    if (this.data) {
      this.f.type.setValue(this.data.type);
      this.f.name.setValue(this.data.name);
      this.f.contactFirstName.setValue(this.data.contactFirstname);
      this.f.contactLastName.setValue(this.data.contactLastname);
      this.f.contactEmail.setValue(this.data.contactEmail);
      this.f.contactPhone.setValue(this.data.contactPhone);
      this.f.isGeneva.setValue((this.data.idpadr) ? 0 : (this.data.country !== "Suisse") ? 2 : 1);
      this.f.street1.setValue(this.data.street1);
      this.f.street2.setValue(this.data.street2);
      this.f.postalCode.setValue(this.data.postalCode);
      this.f.town.setValue(this.data.town);
      this.f.country.setValue(this.data.country);
      this.f.canton.setValue(this.data.canton);

      if (
        this.currentOrganisation.type !== OrganisationType.ECO21 &&
        this.currentOrganisation.type !== OrganisationType.GESDEC
      ) {
        this.f.type.disable();
        this.f.name.disable();
        this.f.codeNoga.disable();
      }
    }
  }

  public displayFn(value: Code): string {
    return value && value.label ? value.label : '';
  }

  private _filter(value: any): Code[] {
    const filterValue = value && value.label ? value.label : value;
    return this.codeNogas.filter(option => option.label.toLowerCase().includes(filterValue.toLowerCase()));
  }

  /**
   * Get form controls
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.organisationForm.controls;
  }

  /**
   * Update full address when it changes on child component
   * @param address - New address
   */
  onFullAddressChange(address: AtlasElement): void {
    this.fullAddress = address;
  }

  /**
   * Submit prestataire form
   * Does not actually make a call to the backend
   */
  public submit(): void {
    this.organisationForm.markAsDirty();
    this.organisationForm.markAsTouched();
    this.organisationForm.markAllAsTouched();

    if(this.organisationForm.controls.country.value !== "Suisse") {
      this.organisationForm.controls.canton.disable();
    }

    if (this.organisationForm.invalid) {
      return;
    }

    if (this.organisationForm.controls.isGeneva.value === 0 && !this.fullAddress) {
      this.organisationForm.controls.street1.setErrors({ incorrect: true });
      return;
    }

    if (!this.data && this.f.codeNoga.value && this.f.codeNoga.value['@id'] === undefined) {
      this.organisationForm.controls.codeNoga.setErrors({ incorrect: true });
      return;
    }

    let country = 'Suisse';
    if (this.f.isGeneva.value === 1) {
      country = 'Suisse';
    } else if (this.f.isGeneva.value === 2) {
      country = this.f.country.value;
    }
    let canton = 'GE';
    if (this.f.isGeneva.value === 1) {
      canton = this.f.canton.value;
    } else if (this.f.isGeneva.value === 2) {
      canton = '';
    }
    const organisation: { [k: string]: any } = {
      name: this.f.name.value,
      type: this.f.type.value,
      codeNoga: this.f.codeNoga.value ? this.f.codeNoga.value['@id'] : null,
      contactFirstname: this.f.contactFirstName.value,
      contactLastname: this.f.contactLastName.value,
      contactEmail: this.f.contactEmail.value,
      contactPhone: this.f.contactPhone.value,
      active: true,
      status: 'to_confirm',
      street1: this.f.street1.value,
      idpadr: this.f.isGeneva.value === 0 ? this.fullAddress.idpadr.toString() : null,
      street2: this.f.isGeneva.value ? null : this.f.street2.value,
      postalCode: this.f.isGeneva.value === 0 ? this.fullAddress.adresse.npa.toString() : this.f.postalCode.value,
      town: this.f.isGeneva.value === 0 ? this.fullAddress.adresse.lieu : this.f.town.value,
      country,
      canton
    };

    if (this.data) {
      this.organisationService.updateOrganisation(this.data['@id'], organisation).subscribe(response => {
        if (this.currentOrganisation['@id'] === response['@id']) {
          this.currentOrganisation.type = response.type;
          this.currentOrganisation.name = response.name;
          localStorage.setItem('organisation', JSON.stringify(this.currentOrganisation));
          window.location.reload();
        } else {
          this.dialogRef.close(true);
        }
      });
    } else {
      organisation.enabled = true;

      this.organisationService.createOrganisation(organisation).subscribe(response => {
        this.router.navigate([`/gestion/organisation/${response['@id'].replace('/api/organisations/', '')}`]);
        this.dialogRef.close();
      });
    }
  }
  ngOnDestroy(): void {
    if (this.isGenevaChanges$) {
      this.isGenevaChanges$.unsubscribe();
    }
  }
}
