import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Organisation, OrganisationRoles } from '../../model/organisation.model';
import { User } from '../../model/user.model';
import { UserService } from '../../services/user.service';

interface UserDialogData {
  user: User;
  organisationId?: number;
}

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  public userOrganisation: Organisation;
  public organisationId: number;
  public userForm: FormGroup;
  public isItself = false;
  public isAdminUser = true;
  public emailDuplicateError = false;
  private currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    private userService: UserService
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.userOrganisation = JSON.parse(localStorage.getItem('organisation'));

    this.userForm = new FormGroup({
      enabled: new FormControl({ value: true, disabled: false }, Validators.required),
      firstname: new FormControl({ value: '', disabled: false }, Validators.required),
      lastname: new FormControl({ value: '', disabled: false }, Validators.required),
      email: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
        )
      ]),
      phone: new FormControl({ value: '', disabled: false }),
      mobile: new FormControl({ value: '', disabled: false }),
      roleAdmin: new FormControl({ value: true, disabled: false })
    });

    if (this.data.user) {
      this.isItself = this.currentUser.id === this.data.user.id;
      this.isAdminUser = this.currentUser.userOrganisations[0].role === OrganisationRoles.ADMIN;
      this.f.email.disable();
      this.f.enabled.setValue(this.data.user.enabled);
      this.f.firstname.setValue(this.data.user.firstname);
      this.f.lastname.setValue(this.data.user.lastname);
      this.f.email.setValue(this.data.user.email);
      this.f.phone.setValue(this.data.user.phone);
      this.f.mobile.setValue(this.data.user.mobile);
      this.f.roleAdmin.setValue(
        this.data.user.userOrganisations &&
          this.data.user.userOrganisations[0] &&
          this.data.user.userOrganisations[0].role === OrganisationRoles.ADMIN
          ? true
          : false
      );
    }
  }

  /**
   * Submit user form
   */
  public submit(): void {
    this.emailDuplicateError = false;
    this.userForm.markAsDirty();
    this.userForm.markAsTouched();
    this.userForm.markAllAsTouched();

    if (this.userForm.invalid) {
      return;
    }

    const user: { [k: string]: any } = {
      enabled: this.f.enabled.value,
      firstname: this.f.firstname.value,
      lastname: this.f.lastname.value,
      email: this.f.email.value,
      phone: this.f.phone.value,
      mobile: this.f.mobile.value,
      userOrganisations: [
        {
          organisation: this.data.user
            ? this.data.user.userOrganisations[0].organisation['@id']
            : '/api/organisations/' + this.data.organisationId,
          role: this.f.roleAdmin.value === true ? OrganisationRoles.ADMIN : OrganisationRoles.USER
        }
      ]
    };

    if (this.data.user) {
      this.userService.updateUser(this.data.user.id, user).subscribe(response => {
        if (this.isItself) {
          localStorage.setItem('user', JSON.stringify(response));
          window.location.reload();
        } else {
          this.dialogRef.close(true);
        }
      });
    } else {
      user.password = this.generatePassword();
      this.userService.createUser(user).subscribe(
        () => {
          this.dialogRef.close(true);
        },
        error => {
          if (error.status === 422) {
            this.emailDuplicateError = true;
          }
        }
      );
    }
  }

  /**
   * Generate password for new user
   * @param length - Lenght of the password
   * @returns Generated password
   */
  private generatePassword(length = 20): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
