import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code } from 'src/app/shared/model/code.model';
import { Wasteflow, WasteflowViews } from 'src/app/shared/model/wasteflow.model';

@Component({
  selector: 'app-lmod-field',
  templateUrl: './lmod-field.component.html',
  styleUrls: ['./lmod-field.component.scss']
})
export class LmodFieldComponent implements OnInit, OnDestroy {
  @Input() element: Wasteflow;
  @Input() lmodCodes: Code[];
  @Input() currentView: WasteflowViews;
  @Output() updateField = new EventEmitter<Code>();

  V = WasteflowViews;

  public filteredLmodCodes: Observable<Code[]>;
  public lmodControl = new FormControl();
  private lmodControl$: Subscription;

  ngOnInit(): void {
    this.element.newCodeLmod.displayLabel = `${this.element.newCodeLmod.code} ${this.element.newCodeLmod.label}`;
    this.lmodControl.setValue(this.element.newCodeLmod);
    this.filteredLmodCodes = this.lmodControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.lmodControl$ = this.lmodControl.valueChanges.subscribe(value => {
      if (value && value.code) {
        this.updateField.emit(value);
      }
    });
  }

  /**
   * Display value of the field
   * @param value - Value of the field
   */
  public displayFn(value: Code): string {
    return value ? value.displayLabel : '';
  }

  /**
   * Filter autocomplete responses based on what the user has typed
   * @param value - Value typed
   */
  private _filter(value: any): Code[] {
    const filterValue = value && value.code ? value.code.toLowerCase() : value.toLowerCase();
    return this.lmodCodes.filter(option => option.displayLabel.toLowerCase().includes(filterValue.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.lmodControl$.unsubscribe();
  }
}
