import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitesFlowComponent } from './sites-flow.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { SitesFlowDetailComponent } from './sites-flow-detail/sites-flow-detail.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
  declarations: [SitesFlowComponent, SitesFlowDetailComponent],
  imports: [CommonModule, SharedModule, BrowserModule, AngularMultiSelectModule],
  exports: [CommonModule, SharedModule, BrowserModule]
})
export class SitesFlowModule {}
