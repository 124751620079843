<h1 mat-dialog-title>Modifications & Commentaires</h1>
<mat-dialog-content>
  <div class="container-fluid">
    <div class="row small-table-header">
      <div class="offset-2 col-10">
        <div class="row">
          <div class="col-4">Code LMod</div>
          <div class="col-4">Traitement</div>
          <div class="col-2">Quantité d'origine</div>
          <div class="col-2">Statuts</div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="values"
    [class.history-dialog__top--has-history]="!values.noHistory"
    class="history-dialog__top container-fluid"
  >
    <app-history-line [isOrigin]="true" [value]="values.originData"></app-history-line>
    <app-history-line *ngIf="!values.noHistory" [isCurrent]="true" [value]="values.currentData"></app-history-line>
  </div>
  <div
    *ngIf="values && !values.noHistory && values.history.length"
    class="history-dialog__bottom container-fluid"
    [class.history-dialog__bottom--small]="displayCommentLine"
  >
    <h4>Historique</h4>
    <app-history-line *ngFor="let value of values.history" [value]="value"></app-history-line>
  </div>
  <div class="history-dialog__comment" *ngIf="displayCommentLine">
    <mat-form-field>
      <mat-label>Veuillez saisir votre commentaire</mat-label>
      <input [(ngModel)]="comment" matInput />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="values">
  <button
    [class.btn-link]="values.currentData.wasteFlowStatus !== 'closure'"
    [class.btn]="values.currentData.wasteFlowStatus === 'closure'"
    mat-button
    (click)="close()"
  >
    Fermer
  </button>
  <button
    mat-button
    class="btn"
    *ngIf="values.currentData.wasteFlowStatus !== 'closure' && !displayCommentLine"
    (click)="showCommentLine()"
  >
    Ajouter un commentaire
  </button>
  <button
    mat-button
    class="btn"
    *ngIf="values.currentData.wasteFlowStatus !== 'closure' && displayCommentLine"
    (click)="saveComment()"
  >
    Sauvegarder le commentaire
  </button>
</mat-dialog-actions>
