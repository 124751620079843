import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { Prestation } from '../model/prestation.model';

@Injectable({
  providedIn: 'root'
})
export class PrestationService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of prestations
   */
  public getPrestationsByOrganisation(query?: string): Observable<Prestation[]> {
    return this.http
      .get<HyDraMemberResponse<Prestation[]>>(`${environment.apiUrl}/prestations${query ? query : ''}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Add prestation
   */
  public editPrestation(prestationId: number, changes: any): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/prestations/${prestationId}`, changes, {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    });
  }

  /**
   * Add prestation
   */
  public addPrestation(prestation: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/prestations`, prestation, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
