import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { HyDraMemberResponse } from '../model/api.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get current user
   */
  public getCurrent(): Observable<User> {
    return this.http.get<any>(`${environment.apiUrl}/user/current`);
  }

  /**
   * Get users of organisation
   */
  public getUsers(organisationId?: number | string): Observable<User[]> {
    const params: any = { pagination: false };

    if (organisationId) {
      params['userOrganisations.organisation'] = organisationId;
    }
    return this.http
      .get<HyDraMemberResponse<User[]>>(`${environment.apiUrl}/users`, { params })
      .pipe(map((data: HyDraMemberResponse<User[]>) => data['hydra:member']));
  }

  /**
   * Create new user
   */
  public createUser(user): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/users`, user);
  }

  /**
   * Update user
   */
  public updateUser(userId: number, user): Observable<User> {
    return this.http.put<User>(`${environment.apiUrl}/users/${userId}`, user);
  }
}
