<div *ngIf="data" class="form-dialog__header form-dialog__header--w-btn">
  <h1 *ngIf="data" mat-dialog-title>Editer la donnée</h1>
  <button (click)="openHistoryDialog()" class="btn-link">
    Voir l'historique des modifications
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
<h1 *ngIf="!data" mat-dialog-title>Ajouter une donnée</h1>
<mat-dialog-content>
  <div class="form-dialog__content form-dialog__content--full">
    <form [formGroup]="wasteflowForm" class="form-dialog__form">
      <div class="row mb-4">
        <span class="col-6">Fournisseur de la donnée</span>
        <span class="col-6 text-bold">{{ data ? data.importEvent?.reporter?.name : userOrganisation.name }}</span>
      </div>
      <mat-form-field *ngIf="!data" appearance="outline" class="form-dialog__select">
        <mat-label>Site de l'organisation</mat-label>
        <mat-select formControlName="site">
          <mat-option *ngFor="let site of sites" [value]="site">
            {{ site.organisation.name }} - {{ site.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.site.errors && f.site.errors.required">Ce champ est obligatoire.</mat-error>
        <mat-error *ngIf="f.site.errors && f.site.errors['incorrect']">
          Le site ne peut pas être identique au site de destination ou au site d'origine.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Type de flux</mat-label>
        <mat-select formControlName="flowType">
          <mat-option *ngFor="let flowType of flowTypes" [value]="flowType">
            {{ flowType.label }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.flowType.errors && f.flowType.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-radio-group class="d-block mb-2" formControlName="inventory">
        <mat-radio-button *ngIf="initialWrtInventoryValue === 'inventory'" [value]="'inventory'" class="mr-4">
          Donnée d'inventaire
        </mat-radio-button>
        <mat-radio-button *ngIf="initialWrtInventoryValue === 'complementary'" [value]="'complementary'" class="mr-4">
          Donnée complémentaire
        </mat-radio-button>
        <mat-radio-button
          *ngIf="initialWrtInventoryValue === 'integrated_to_inventory'"
          [value]="'integrated_to_inventory'"
          class="mr-4"
        >
          Donnée intégrée à l'inventaire
        </mat-radio-button>
        <mat-radio-button [value]="'private'" disabled>Donnée privée</mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="outline">
        <mat-label>Période de la déclaration</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input formControlName="periodFrom" matStartDate placeholder="JJ/MM/AAAA" />
          <input formControlName="periodTo" matEndDate placeholder="JJ/MM/AAAA" />
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="f.periodFrom.hasError('matStartDateInvalid')">Date de début invalide</mat-error>
        <mat-error *ngIf="f.periodTo.hasError('matEndDateInvalid')">Date de fin invalide</mat-error>
        <mat-error *ngIf="f.periodFrom.errors && f.periodFrom.errors['incorrect']">
          Les données d'inventaire doivent être soumises sur une base annuelle.
        </mat-error>
      </mat-form-field>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label> Origine </mat-label>
            <input [matAutocomplete]="provenanceSite" formControlName="provenanceSite" matInput type="text" />
            <mat-autocomplete
              #provenanceSite="matAutocomplete"
              [displayWith]="wasteflowService.displayNameFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let provenanceSite of filteredProvenanceSites | async" [value]="provenanceSite">
                {{ provenanceSite.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.provenanceSite.errors && f.provenanceSite.errors.required">
              Ce champ est obligatoire.
            </mat-error>
            <mat-error *ngIf="f.provenanceSite.errors && f.provenanceSite.errors['incorrect']">
              Le site d'origine ne peut pas être identique au site de destination.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label> Destination </mat-label>
            <input [matAutocomplete]="destinationSite" formControlName="destinationSite" matInput type="text" />
            <mat-autocomplete
              #destinationSite="matAutocomplete"
              [displayWith]="wasteflowService.displayNameFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let destinationSite of filteredDestinationSites | async" [value]="destinationSite">
                {{ destinationSite.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.destinationSite.errors && f.destinationSite.errors.required">
              Ce champ est obligatoire.
            </mat-error>
            <mat-error *ngIf="f.destinationSite.errors && f.destinationSite.errors['incorrect']">
              Le site de destination ne peut pas être identique au site d'origine.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Collecte / Traitement à l’origine</mat-label>
            <input [matAutocomplete]="provenance" formControlName="provenanceCode" matInput type="text" />
            <mat-autocomplete
              #provenance="matAutocomplete"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let code of filteredProvenanceCodes | async" [value]="code">
                {{ code.code }} - {{ code.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.provenanceCode.errors && f.provenanceCode.errors.required">
              Ce champ est obligatoire.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Traitement à la destination</mat-label>
            <input [matAutocomplete]="treatments" formControlName="treatment" matInput type="text" />
            <mat-autocomplete
              #treatments="matAutocomplete"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let treatment of filteredTreatments | async" [value]="treatment">
                {{ treatment.code }} - {{ treatment.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.treatment.errors && f.treatment.errors.required">Ce champ est obligatoire.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Code LMod</mat-label>
            <input
              (change)="lmodInputChange()"
              [matAutocomplete]="lmod"
              formControlName="codeLmod"
              matInput
              type="text"
            />
            <mat-autocomplete
              #lmod="matAutocomplete"
              (optionSelected)="filterUnits()"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let option of filteredLmodCodes | async" [value]="option">
                {{ option.code }} {{ option.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.codeLmod.errors && f.codeLmod.errors.required">Ce champ est obligatoire.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__input">
            <mat-label>Valeur de la donnée</mat-label>
            <input formControlName="originQty" matInput type="number" />
            <mat-error *ngIf="f.originQty.errors && f.originQty.errors.required">Ce champ est obligatoire.</mat-error>
            <mat-error *ngIf="f.originQty.errors && f.originQty.errors.min">
              La valeur ne peut pas être négative.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="form-dialog__select">
            <mat-label>Unité</mat-label>
            <mat-select formControlName="originUnit">
              <ng-container *ngFor="let unit of filteredUnits">
                <mat-option [value]="unit">
                  {{ unit.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="f.originUnit.errors && f.originUnit.errors.required">Ce champ est obligatoire.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Organisation validatrice</mat-label>
        <input [matAutocomplete]="validator" formControlName="validator" matInput type="text" />
        <mat-autocomplete
          #validator="matAutocomplete"
          [displayWith]="wasteflowService.displayNameFn"
          autoActiveFirstOption
        >
          <mat-option *ngFor="let validator of filteredValidators | async" [value]="validator">
            {{ validator.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.validator.errors && f.validator.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Commentaire sur cette modification</mat-label>
        <textarea formControlName="comment" matInput></textarea>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
