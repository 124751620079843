import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ConfirmImpersonationDialogComponent } from 'src/app/modules/impersonation/impersonation/confirm-impersonation-dialog/confirm-impersonation-dialog.component';
import { UserDialogComponent } from 'src/app/shared/components/user-dialog/user-dialog.component';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { User } from 'src/app/shared/model/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { AppActions } from '../../core/store/actions/app.actions';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public organisation: Organisation;
  public initials = '';
  public showAdministration = false;
  public showWasteflow = false;
  public periods: Array<number> = [];
  public year: number;
  private dialog$: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private readonly store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.initials = this.currentUser.firstname.slice(0, 1) + this.currentUser.lastname.slice(0, 1);
    this.setUpPeriods();
    if (
      this.isActive('/gestion/prestations') ||
      this.isActive('/gestion/organisation') ||
      this.isActive('/gestion/organisations')
    ) {
      this.showAdministration = true;
    }

    if (this.isActive('/gestion/declarations-detail') || this.isActive('/gestion/sites-flow')) {
      this.showWasteflow = true;
    }
  }

  /**
   * Return if a path is the active one or if its child is active
   * @param path Path to check
   */
  isActive(path: string): boolean {
    return this.router.isActive(path, false);
  }

  /**
   * Return if user is impersonated
   */
  isImpersonated(): boolean {
    return localStorage.getItem('originToken') !== null;
  }

  goToMyOrganisation(): void {
    this.router.navigateByUrl(`/gestion/organisation/${this.organisation.id}`);
  }

  /**
   * Toggle administration menu visibility
   */
  toggleAdministration(): void {
    this.showAdministration = !this.showAdministration;
  }

  /**
   * Toggle wasteflow menu visibility
   */
  toggleWasteflow(): void {
    this.showWasteflow = !this.showWasteflow;
  }

  /**
   * Edit user profile
   */
  editProfile(): void {
    this.dialog.open(UserDialogComponent, {
      disableClose: true,
      panelClass: ['form-dialog'],
      data: { user: this.currentUser }
    });
  }

  /**
   * Back to own profile if user is impersonated
   */
  backToProfile(): void {
    const dialogRef = this.dialog.open(ConfirmImpersonationDialogComponent, {
      disableClose: true,
      data: {
        user: null,
        backToProfile: true
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldImpersonate => {
      if (shouldImpersonate) {
        localStorage.setItem('token', localStorage.getItem('originToken'));
        localStorage.setItem('user', localStorage.getItem('originUser'));
        localStorage.setItem('organisation', localStorage.getItem('originOrganisation'));
        localStorage.removeItem('originToken');
        localStorage.removeItem('originUser');
        localStorage.removeItem('originOrganisation');
        window.location.reload();
      }
    });
  }

  /**
   * Logout user
   */
  logout(): void {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  /**
   * Save the selected year to the local storage.
   */
  updateSelectedYear(): void {
    this.store$.dispatch(new AppActions.SetSelectedYear({ selected_year: this.year }));
  }

  /**
   * Set up list of 5 previous years + this year
   */
  private setUpPeriods(): void {
    const year = new Date();
    this.year = year.getFullYear() - 1;
    this.updateSelectedYear();
    let startYear = year.getFullYear() - 5;
    while (startYear <= year.getFullYear()) {
      this.periods.push(startYear++);
    }
  }
}
