import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of users to impersonate
   */
  public getUsers(): Observable<User[]> {
    return this.http
      .get<HyDraMemberResponse<User[]>>(
        `${environment.apiUrl}/users?userOrganisations.organisation.type=commune&userOrganisations.organisation.active=1&enabled=1&pagination=false`
      )
      .pipe(map((data: HyDraMemberResponse<User[]>) => data['hydra:member']));
  }

  /**
   * Impersonate user
   */
  public impersonate(destinationUserId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/user/impersonation`, { destinationUserId });
  }
}
