import { Component, OnDestroy, OnInit } from '@angular/core';
import { WasteFlowGeneric, WasteFlowGenericData } from 'src/app/shared/model/dashboard.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-graph-performance-rate',
  templateUrl: './graph-performance-rate.component.html',
  styleUrls: ['./graph-performance-rate.component.scss']
})
export class GraphPerformanceRateComponent implements OnInit, OnDestroy {
  public data: WasteFlowGenericData[];
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$)
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get performance rate data
   */
  private getData(): void {
    this.dashboardService
      .getWasteFlowGeneric(
        'Flow_type',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id
      )
      .subscribe(data => {
        this.handleWasteTonnes(data);
      });
  }

  /**
   * Handle waste data, check which is the highest data to set up the heights of all elements
   * @param data - Waste data
   */
  private handleWasteTonnes(data: WasteFlowGeneric): void {
    this.data = [];
    if (data.data) {
      const highestElement = data.data.reduce((prev, current) => {
        return prev.value > current.value ? prev : current;
      });
      data.data.forEach(element => {
        element.valueHeight = (100 / highestElement.value) * element.value;
      });
      this.data = data.data;
    }
  }
}
