import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Organisation, OrganisationFull, OrganisationSimple } from 'src/app/shared/model/organisation.model';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { environment } from 'src/environments/environment';
import { OrganisationDialogComponent } from '../organisation-details/organisation-dialog/organisation-dialog.component';
import { OrganisationFusionDialogComponent } from '../organisation-fusion-dialog/organisation-fusion-dialog.component';

@Component({
  selector: 'app-organisations-list',
  templateUrl: './organisations-list.component.html',
  styleUrls: ['./organisations-list.component.scss']
})
export class OrganisationsListComponent implements OnInit, OnDestroy {
  data: OrganisationSimple[];
  currentPage = 0;
  currentSearchTerm: string;
  pageLength = 1;
  displayedColumns: string[] = [
    'name',
    'type',
    'amountUsers',
    'amountSites',
    'status',
    'contactEmail',
    'active',
    'actions'
  ];
  currentOrganisation: Organisation;
  private dialog$: Subscription;
  private itemsPerPage = 20;

  constructor(private organisationService: OrganisationService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.currentOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.getAllOrganisations();
  }

  getStatusLabel(status: string): string {
    switch (status) {
      case 'to_confirm':
        return 'À confirmer';
      case 'reject':
        return 'Rejeté';
      case 'confirm':
        return 'Confirmé';
      default:
        return '-';
    }
  }

  /**
   * Create new organisation
   */
  addOrganisation(): void {
    const dialogRef = this.dialog.open(OrganisationDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data: null
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((addedOrganisation: boolean) => {
      if (addedOrganisation) {
        this.getAllOrganisations();
      }
    });
  }

  /**
   * Téléchargement de toutes les organisations
   */
  downloadOrganisations(): void {
    this.organisationService.downloadOrganisations().subscribe(data => {
      location.href = `${environment.apiUrl.split('/api')[0]}/download?file=${data.file_name}`;
    });
  }

  /**
   * Activate organisation
   */
  activate(organisation: OrganisationSimple): void {
    this.organisationService
      .updateOrganisation(organisation['@id'], {
        active: true
      })
      .subscribe(() => {
        this.getAllOrganisations();
      });
  }

  /**
   * Search organisation on input in search field
   */
  searchOrganisation(event: any): void {
    this.currentSearchTerm = event.target.value;
    this.currentPage = 0;
    this.getAllOrganisations();
  }

  /**
   * Fusion organisation with another one
   */
  fusion(organisation: OrganisationFull): void {
    const dialogRef = this.dialog.open(OrganisationFusionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['fusion-dialog'],
      data: organisation
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getAllOrganisations();
      }
    });
  }

  /**
   * Deactivate organisation
   */
  deactivate(organisation: OrganisationSimple): void {
    this.askForConfirmation().subscribe(result => {
      if (result) {
        this.organisationService
          .updateOrganisation(organisation['@id'], {
            active: false
          })
          .subscribe(() => {
            this.getAllOrganisations();
          });
      }
    });
  }

  updatePage(page: number): void {
    this.currentPage = page;
    this.getAllOrganisations();
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  /**
   * Get list of all the organisations
   */
  private getAllOrganisations(): void {
    this.organisationService.getOrganisationsPaginated(this.currentPage, this.currentSearchTerm).subscribe(response => {
      this.data = response['hydra:member'];
      this.data.forEach(d => {
        d.id = d['@id'].replace('/api/organisations/', '');
      });
      this.pageLength = Math.ceil(response['hydra:totalItems'] / this.itemsPerPage);
    });
  }

  /**
   * If user has unsaved changes, ask to confirm the action. Otherwise changes won't be saved
   */
  private askForConfirmation(): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        panelClass: ['confirm-dialog'],
        data: {
          title: 'Voulez-vous vraiment désactiver cette organisation?',
          text: 'Tous les utilisateurs de cette organisation ne pourront plus se connecter.'
        }
      })
      .afterClosed();
  }
}
