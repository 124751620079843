import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/guards/component-can-deactivate.guard';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Code } from 'src/app/shared/model/code.model';
import { ImportEvent, StatusValidation } from 'src/app/shared/model/import-event.model';
import { WasteFlowStatus, WasteflowViews } from 'src/app/shared/model/wasteflow.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { SiteSimple } from 'src/app/shared/model/site.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { WasteflowsTableComponent } from 'src/app/shared/components/wasteflows-table/wasteflows-table.component';
import { FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { multiselectConfiguration } from 'src/app/shared/data';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/states/app.state';
import { getSelectedYear$ } from '../../core/store/selectors/app.selectors';
import { MasseModificationService } from 'src/app/shared/services/mass-modification.service';

@Component({
  selector: 'app-wasteflows',
  templateUrl: './wasteflows.component.html',
  styleUrls: ['./wasteflows.component.scss']
})
export class WasteflowsComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
  @ViewChild(WasteflowsTableComponent) wasteflowTable: WasteflowsTableComponent;
  @ViewChild('multiselect') elementView: ElementRef;
  public V = WasteflowViews;
  public currentView: WasteflowViews = this.V.MAIN;
  public disableEditFields = true;
  public selectedImportEventId: number;
  public previousSelectedImportEventId: number;
  public organisation: Organisation;
  public importEvents: ImportEvent[] = [];
  public filteredImportEvents: Observable<ImportEvent[]>;
  public importEventsControl = new FormControl('');
  public provenanceSiteList: SiteSimple[] = [];
  public wasteflowStatus: WasteFlowStatus[] = [];
  public filteredProvenanceSiteList: Observable<SiteSimple[]>;
  public destinationSiteList: SiteSimple[] = [];
  public filteredDestinationSiteList: Observable<SiteSimple[]>;
  public codeLmodList: Code[] = [];

  public multiselectConfiguration = multiselectConfiguration;

  public previousSelectedProvenanceSite: number;
  public previousSelectedDestinationSite: number;
  public previousSelectedCodeLmod: Code[];
  public previousWasteflowStatus: string;
  public submitting = false;
  public wasteflowIds: string[];
  public wasteflowFilters: FormGroup = new FormGroup({
    codeLmod: new FormControl(),
    provenanceSite: new FormControl(),
    destinationSite: new FormControl(),
    status: new FormControl()
  });
  private route$: Subscription;
  private dialog$: Subscription;
  private subscriptions$: Subscription[] = [];
  private massSubscription: Subscription;

  constructor(
    private router: Router,
    public wasteflowService: WasteflowService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private importEventService: ImportEventService,
    private readonly store$: Store<AppState>,
    private masseModificationService: MasseModificationService,
  ) {
    this.massSubscription = this.masseModificationService.data$.subscribe(data => {
      this.getWasteflows();
    });
  }

  /**
   * Controls that the user is not trying to leave the page without any unsaved changes
   */
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.submitting && this.disableEditFields;
  }

  ngOnInit(): void {
    this.subscriptions$.push(
      this.store$.select(getSelectedYear$).subscribe(() => {
        if (this.selectedImportEventId) {
          this.router.navigate(['/gestion/declarations-detail']);
        } else {
          this.getImportEvents();
        }
      })
    );
    multiselectConfiguration.labelKey = 'displayLabel';
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    // Check in route if there is a parameter. If so, it means we need to get details from a specific declaration ID
    this.route$ = this.route.queryParams.subscribe((params: { [k: string]: any }) => {
      this.wasteflowIds = params.wid ?? [];
      this.selectedImportEventId = parseInt(params.id, 10);
      this.previousSelectedImportEventId = this.selectedImportEventId;
      if (this.wasteflowTable) {
        this.wasteflowTable.updateSorting([
          {
            dir: 'asc',
            sort: 'sourceRef',
            displaySort: 'sourceRef'
          }
        ]);
      }
      this.getImportEvents();
      this.getFilters();
    });

    this.subscriptions$.push(this.route$);
  }

  /**
   * Check if user does not have any unsaved changes before update wasteflows
   * @param isAll - Whether user wants to see all (= "Tout voir")
   * @value Newly selected import event
   */
  public updateImportEvent(isAll: boolean, value?: ImportEvent): void {
    if (value) {
      this.selectedImportEventId = value.id;
    }

    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        this.previousSelectedImportEventId = this.selectedImportEventId;
        if (isAll) {
          this.importEventsControl.setValue('');
          this.router.navigate(['/gestion/declarations-detail']);
        } else {
          this.importEventsControl.setValue(value);
          this.router.navigate(['/gestion/declarations-detail'], {
            queryParams: { id: this.selectedImportEventId }
          });
        }
      } else {
        this.selectedImportEventId = this.previousSelectedImportEventId;
      }
    });
    this.subscriptions$.push(this.dialog$);
  }


  public displayFrontIdFn(entity: any): string {
    return entity ? entity?.front_id : '';
  }

  /**
   * Filter wasteflows list
   * Called when a filter has been selected/deselecter
   */
  public filterWasteflows(askForConfirmation = true, isLMod = false): void {
    if (askForConfirmation) {
      this.dialog$ = this.askForConfirmation().subscribe(result => {
        if (result) {
          this.saveFiltersPreviousValues();
          this.getWasteflows();
          if (isLMod) {
            this.handleHeightOfSticky();
          }
        } else {
          this.wasteflowFilters.controls.provenanceSite.setValue(this.previousSelectedProvenanceSite);
          this.wasteflowFilters.controls.codeLmod.setValue(this.previousSelectedCodeLmod);
          this.wasteflowFilters.controls.destinationSite.setValue(this.previousSelectedDestinationSite);
          this.wasteflowFilters.controls.status.setValue(this.previousWasteflowStatus);
          // hack to make sure we cannot change the code lmod filter while editing
          this.previousSelectedCodeLmod = JSON.parse(JSON.stringify(this.previousSelectedCodeLmod));
        }
      });
    } else {
      this.saveFiltersPreviousValues();
      this.getWasteflows();
      if (isLMod) {
        this.handleHeightOfSticky();
      }
    }
    this.subscriptions$.push(this.dialog$);
  }

  private handleHeightOfSticky() {
    setTimeout(() => {
      const h = document.getElementById('codeLmodSelect').offsetHeight;
      document.getElementById('wasteflowTableHeader').style.top = `${80 + h}px`;
    }, 0);
  }

  /**
   * Reinitialize filters to "all"
   */
  public setFiltersBack(): void {
    this.wasteflowFilters.controls.provenanceSite.setValue('');
    this.wasteflowFilters.controls.codeLmod.setValue(null);
    this.wasteflowFilters.controls.destinationSite.setValue('');
    this.wasteflowFilters.controls.status.setValue('');
    this.wasteflowIds = [];
    this.saveFiltersPreviousValues();
  }

  /**
   * Reinitialize filters to "all" and filter wasteflows again
   */
  public deleteFilters(): void {
    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        this.setFiltersBack();
        this.filterWasteflows(false, true);
      }
    });
    this.subscriptions$.push(this.dialog$);
  }

  /**
   * Cancel all changes, reload content
   */
  public cancelChanges(): void {
    this.getWasteflows();
  }

  /**
   * Change view (main/doublons)
   * @param isMainView - Should go to main view or not
   */
  public goToView(currentView: WasteflowViews): void {
    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        this.submitting = false;
        this.disableEditFields = true;
        this.wasteflowTable.currentPage = 0;
        if (currentView !== this.currentView) {
          this.currentView = currentView;

          if (this.currentView === this.V.MAIN) {
            this.wasteflowTable.updateSorting([
              {
                dir: 'asc',
                sort: 'sourceRef',
                displaySort: 'sourceRef'
              }
            ]);
            this.getWasteflows();
          } else if (this.currentView === this.V.DOUBLON) {
            this.wasteflowTable.updateSorting([
              {
                dir: 'asc',
                sort: 'duplicateGroupId',
                displaySort: null
              },
              {
                dir: 'asc',
                sort: 'flowType.code',
                displaySort: null
              }
            ]);

            this.getWasteflows();
          } else if (this.currentView === this.V.ENTERPRISE) {
            this.updateImportEvent(true);
            this.wasteflowTable.updateSorting([
              {
                dir: 'desc',
                sort: 'statusWrtInventory',
                displaySort: 'statusWrtInventory'
              }
            ]);
            this.getWasteflows();
          }
        }
      }
    });
    this.subscriptions$.push(this.dialog$);
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.massSubscription.unsubscribe();
  }

  /**
   * Check if the filter input is empty and reload filters.
   */
  filterInputChange(val: string | undefined): void {
    if (val !== undefined && val === '') {
      this.filterWasteflows();
    }
  }

  /**
   * Get lists to fill in the 3 filters for the page on main view
   */
  private getFilters(): void {
    const statusOrder = [
      StatusValidation.NEW,
      StatusValidation.TOVALIDATE,
      StatusValidation.TOFIX,
      StatusValidation.VALID,
      StatusValidation.VIEW,
      StatusValidation.PREARCHIVE,
      StatusValidation.PRECLOSURE,
      StatusValidation.ARCHIVE,
      StatusValidation.CLOSURE,
      StatusValidation.OUTSERVICE
    ];
    this.wasteflowService.getWasteflowStatus().subscribe(status => {
      status.sort((a, b) => {
        return statusOrder.indexOf(a.code) - statusOrder.indexOf(b.code);
      });
      this.wasteflowStatus = status;
    });

    this.wasteflowService
      .getWasteflowsFilter(
        'list_site_provenance',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id,
        this.selectedImportEventId
      )
      .subscribe(list => {
        list.sort((a, b) => {
          return this.wasteflowService.compare(a.name, b.name, true);
        });
        this.provenanceSiteList = list;
        this.filteredProvenanceSiteList = this.wasteflowFilters.controls.provenanceSite.valueChanges.pipe(
          startWith(''),
          map(val => {
            return this.wasteflowService._entityFilter(this.provenanceSiteList, val);
          })
        );
      });

    this.wasteflowService
      .getWasteflowsFilter(
        'list_site_destination',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id,
        this.selectedImportEventId
      )
      .subscribe(list => {
        list.sort((a, b) => {
          return this.wasteflowService.compare(a.name, b.name, true);
        });
        this.destinationSiteList = list;
        this.filteredDestinationSiteList = this.wasteflowFilters.controls.destinationSite.valueChanges.pipe(
          startWith(''),
          map(val => this.wasteflowService._entityFilter(this.destinationSiteList, val))
        );
      });

    this.wasteflowService
      .getWasteflowsFilter(
        'list_code_lmod',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id,
        this.selectedImportEventId
      )
      .subscribe(list => {
        list.sort((a, b) => {
          return this.wasteflowService.compare(a.displayLabel, b.displayLabel, true);
        });
        list.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
        this.codeLmodList = list;
      });
  }

  /**
   * Get list of wasteflows
   */
  private getImportEvents(): void {
    if (this.organisation) {
      this.importEventService
        .getImportEventsByOrganisation(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
        .subscribe(importEvents => {
          this.importEvents = importEvents.sort((a, b) => a.front_id.localeCompare(b.front_id));
          this.filteredImportEvents = this.importEventsControl.valueChanges.pipe(
            startWith(''),
            map(val => this.wasteflowService._entityFilter(this.importEvents, val, 'front_id'))
          );
          if (this.selectedImportEventId) {
            this.importEventsControl.setValue(this.importEvents.find(i => i.id === this.selectedImportEventId));
          }
          this.getWasteflows();
        });
    }
  }

  /**
   * If user has unsaved changes, ask to confirm the action. Otherwise changes won't be saved
   */
  private askForConfirmation(): Observable<boolean> {
    if (!this.submitting && this.disableEditFields) {
      return of(true);
    } else {
      return this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Voulez-vous vraiment quitter la page?',
            text: 'Vous avez des modifications non sauvegardées sur cette page.'
          }
        })
        .afterClosed();
    }
  }

  /**
   * Save filters previous values when changing them
   */
  private saveFiltersPreviousValues(): void {
    this.previousSelectedCodeLmod = JSON.parse(JSON.stringify(this.wasteflowFilters.controls.codeLmod.value));
    this.previousSelectedProvenanceSite = this.wasteflowFilters.controls.provenanceSite.value;
    this.previousSelectedDestinationSite = this.wasteflowFilters.controls.destinationSite.value;
    this.previousWasteflowStatus = this.wasteflowFilters.controls.status.value;
  }

  public getWasteflows(): void {
    setTimeout(() => {
      this.wasteflowTable.subscribeSelectedYear();
    }, 0);
  }
}
