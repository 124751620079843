import { Pipe, PipeTransform } from '@angular/core';
import { StatusInventory } from '../../model/import-event.model';

@Pipe({
  name: 'wasteflowToggleLabel'
})
export class WasteflowToggleLabelPipe implements PipeTransform {
  transform(status: StatusInventory, newStatus: StatusInventory): string {
    if ((!newStatus && status === 'integrated_to_inventory') || newStatus === 'integrated_to_inventory') {
      return "Donnée marquée pour inclusion dans l'inventaire";
    } else if ((!newStatus && status === 'complementary') || newStatus === 'complementary') {
      return "Donnée complémentaire - non incluse dans l'inventaire";
    } else {
      return "Donnée non traitée - incluse par défaut dans l'inventaire";
    }
  }
}
