import { Code } from './code.model';

export interface UserOrganisation {
  '@id': string;
  '@type': string;
  organisation: Organisation;
  role: OrganisationRoles;
}

export interface Organisation {
  '@id': string;
  '@type': string;
  id: number;
  name: string;
  type: OrganisationType;
}

export enum OrganisationRoles {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN'
}

export interface OrganisationSimple {
  name: string;
  '@id': string;
  id?: string | number;
}

export enum OrganisationType {
  SERVICEPROVIDER = 'service_provider',
  COMMUNE = 'commune',
  GESDEC = 'gesdec',
  ENTERPRISE = 'enterprise',
  ECO21 = 'eco21'
}

export interface AtlasElement {
  idpadr: number;
  adresse: AtlasAddress;
}

export interface AtlasAddress {
  adresse: string;
  code_voie: number;
  lieu: string;
  no_adresse: string;
  npa: number;
  rue: string;
}

export interface PrestataireCountry {
  code: string;
  name: string;
}

export interface PrestataireCanton {
  code: string;
  name: string;
}

export interface OrganisationFull {
  '@id': string;
  id: string;
  active: boolean;
  codeNoga: Code;
  contactEmail: string;
  contactFirstname: string;
  contactLastname: string;
  contactPhone: string;
  country: string;
  canton: string;
  idpadr: string | number;
  name: string;
  postalCode: string;
  status: string;
  street1: string;
  street2: string;
  town: string;
  type: OrganisationType;
}
