import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Property, PropertyType } from 'src/app/shared/model/property.model';
import { PropertyService } from 'src/app/shared/services/property.service';

interface AssociatedDataDialog {
  property: Property;
  organisationId: string;
}

@Component({
  selector: 'app-associated-data-dialog',
  templateUrl: './associated-data-dialog.component.html',
  styleUrls: ['./associated-data-dialog.component.scss']
})
export class AssociatedDataDialogComponent implements OnInit {
  public dataForm: FormGroup;
  public years = [];
  public errorMessage: string;
  public propertyType = PropertyType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AssociatedDataDialog,
    private dialogRef: MatDialogRef<AssociatedDataDialogComponent>,
    private datePipe: DatePipe,
    private propertyService: PropertyService
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.dataForm.controls;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // Create data form
    this.dataForm = new FormGroup({
      type: new FormControl({ value: '', disabled: false }, Validators.required),
      date: new FormControl({ value: '', disabled: false }, Validators.required),
      value: new FormControl({ value: '', disabled: false }, Validators.required)
    });

    this.setUpYears();

    // Assign property value if on edit mode
    if (this.data.property) {
      this.f.type.setValue(this.data.property.type);
      this.f.date.setValue(parseInt(this.datePipe.transform(this.data.property.date, 'yyyy'), 10));
      this.f.value.setValue(this.data.property.value);
    }
  }

  /**
   * Submit form
   */
  public submit(): void {
    this.errorMessage = null;
    this.dataForm.markAsDirty();
    this.dataForm.markAsTouched();
    this.dataForm.markAllAsTouched();

    if (this.dataForm.invalid) {
      return;
    }

    const newDate = new Date();
    newDate.setUTCHours(0, 0, 0, 0);
    newDate.setUTCDate(1);
    newDate.setUTCMonth(0);
    newDate.setUTCFullYear(this.dataForm.controls.date.value);

    const data: { [k: string]: any } = {
      type: this.dataForm.controls.type.value,
      date: newDate.toISOString(),
      value: this.dataForm.controls.value.value
    };

    if (this.data.property) {
      // Update property/data
      this.propertyService.updateProperty(this.data.property['@id'], data).subscribe(
        () => {
          this.dialogRef.close(true);
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      // Create new property/data
      data.organisation = `/api/organisations/${this.data.organisationId}`;
      this.propertyService.createProperty(data).subscribe(
        () => {
          this.dialogRef.close(true);
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  /**
   * Set up list of years from 2010 until next year
   */
  private setUpYears(): void {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    this.years = [];
    let startYear = 2010;
    while (startYear <= nextYear.getFullYear()) {
      this.years.push(startYear++);
    }
    this.years.reverse();
  }

  /**
   * Handle backend error
   */
  private handleError(error: HttpErrorResponse): void {
    // If status is 422, it means a data/property with the same date exists
    if (error.status === 422) {
      this.errorMessage = `Il existe déjà une valeur de ${this.dataForm.controls.type.value} pour ${this.dataForm.controls.date.value}. Si vous avez repéré une donnée erronée, merci de le signaler par mail à [mail d'info].`;
    }
  }
}
