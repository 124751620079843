import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/registration/login/login.component';
import { ForgottenPasswordComponent } from './modules/registration/forgotten-password/forgotten-password.component';
import { CoreModule } from './core/core.module';
import { HalfPageLogoComponent } from './modules/registration/components/half-page-logo/half-page-logo.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { ChangePasswordComponent } from './modules/registration/change-password/change-password.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ImpersonationModule } from './modules/impersonation/impersonation.module';
import { PrestationsModule } from './modules/prestations/prestations.module';
import { OrganisationModule } from './modules/organisation/organisation.module';
import localeFr from '@angular/common/locales/fr';
import { ImportEventsModule } from './modules/import-events/import-events.module';
import { SitesFlowModule } from './modules/sites-flow/sites-flow.module';
import { WasteflowsModule } from './modules/wasteflows/wasteflows.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InventoryDetailsRowComponent } from './modules/inventory-preview/inventory-details-row/inventory-details-row.component';
import { RegistrationModule } from './modules/registration/registration.module';
import { CodeModule } from './modules/code/code.module';
import { InventoryPreviewComponent } from './modules/inventory-preview/inventory-preview.component';
import { InventoryClosureDialogComponent } from './modules/inventory-preview/inventory-closure-dialog/inventory-closure-dialog.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    HalfPageLogoComponent,
    ChangePasswordComponent,
    InventoryPreviewComponent,
    InventoryClosureDialogComponent,
    InventoryDetailsRowComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ImpersonationModule,
    OrganisationModule,
    DashboardModule,
    PrestationsModule,
    CoreModule,
    SharedModule,
    ImportEventsModule,
    SitesFlowModule,
    WasteflowsModule,
    BrowserAnimationsModule,
    MainModule,
    AngularMultiSelectModule,
    MatProgressBarModule,
    RegistrationModule,
    CodeModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
