import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ImportEvent } from 'src/app/shared/model/import-event.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-import-event-dialog',
  templateUrl: './import-event-dialog.component.html',
  styleUrls: ['./import-event-dialog.component.scss']
})
export class ImportEventDialogComponent implements OnInit {
  public submitted = false;
  public filename: string;
  public sites: Site[];
  public filteredSites: Observable<Site[]>;

  public fileData: FormData;
  public showErrors = false;
  public showSuccess = false;
  public showWaiting = false;
  public precheckError = false;
  public importEventId: number;
  public formularyErrors: Array<string> = [];
  public fileErrors: Array<string> = [];
  public importForm: FormGroup;
  public newImportEvent: ImportEvent;
  public organisation: Organisation;

  private defaultStartDate = new Date('2021-01-01T22:00:00.000Z');
  private defaultEndDate = new Date('2021-12-31T22:00:00.000Z');

  constructor(
    public dialogRef: MatDialogRef<ImportEventDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: Organisation,
    private siteService: SiteService,
    private datePipe: DatePipe,
    private importEventService: ImportEventService,
    private formBuilder: FormBuilder
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.importForm.controls;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const today = new Date();
    this.defaultStartDate.setFullYear(today.getFullYear() - 1);
    this.defaultEndDate.setFullYear(today.getFullYear() - 1);
    this.importForm = this.formBuilder.group({
      site: ['', Validators.required],
      privacy: ['inventory', Validators.required],
      periodFrom: [this.defaultStartDate, Validators.required],
      periodTo: [this.defaultEndDate, Validators.required]
    });

    this.siteService.getSites(this.data.type === OrganisationType.GESDEC ? null : this.data.id).subscribe(value => {
      this.sites = value;
      this.sites.forEach(site => {
        if (site.organisation) {
          site.autocompleteDisplayValue = `${site.organisation.name} - ${site.name}`;
        } else {
          site.autocompleteDisplayValue = site.name;
        }
      });

      this.filteredSites = this.f.site.valueChanges.pipe(
        startWith(''),
        map(val => this._filter(val))
      );

      if (this.data.type !== OrganisationType.GESDEC && this.sites && this.sites.length) {
        this.f.site.setValue(this.sites[0].id);
      }
    });

    this.f.periodTo.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.periodFrom.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.privacy.valueChanges.subscribe(() => {
      if (this.f.periodFrom.value && this.f.periodTo.value) {
        this.verifyValidityOfPeriod();
      }
    });
  }

  public displayFn(value: Site): string {
    return value ? value.autocompleteDisplayValue : '';
  }

  /**
   * Upload file
   * @param event - File item
   */
  public addFile(event: any): void {
    if (event.target.files.length > 0) {
      this.filename = event.target.files[0].name;
      const file: File = event.target.files[0];
      this.fileData = new FormData();
      this.fileData.append('file', file, file.name);
    }
  }

  /**
   * Submit form
   */
  public submit(): void {
    this.importForm.markAsDirty();
    this.importForm.markAsTouched();
    this.importForm.markAllAsTouched();
    this.submitted = true;

    if (this.importForm.invalid || !this.fileData) {
      return;
    }

    // Create the file data form to send
    if (this.data.type === OrganisationType.GESDEC) {
      this.fileData.append('siteId', this.f.site.value.id);
    } else {
      this.fileData.append('siteId', this.f.site.value);
    }
    this.fileData.append('organisationId', this.data.id.toString());
    this.fileData.append('privacy', this.f.privacy.value);
    this.fileData.append('periodFrom', this.datePipe.transform(this.f.periodFrom.value, 'yyyy-MM-dd'));
    this.fileData.append('periodTo', this.datePipe.transform(this.f.periodTo.value, 'yyyy-MM-dd'));

    // Show loader in case it takes some time
    this.showWaiting = true;
    this.preCheckImportEventFile();
  }

  public continueUpload(): void {
    this.showWaiting = true;
    this.precheckError = false;
    this.importEventId = null;
    this.uploadImportEventFile();
  }

  public resetForm(): void {
    this.precheckError = false;
    this.showErrors = false;
    this.importEventId = null;
    this.fileErrors = null;
  }

  private preCheckImportEventFile(): void {
    this.importEventService.checkImportEventFile(this.fileData).subscribe(
      response => {
        this.uploadImportEventFile();
      },
      error => {
        this.showWaiting = false;
        if (error?.error?.infos?.length) {
          this.showErrors = true;
          this.precheckError = true;
          if (error?.error?.importEventId) {
            this.importEventId = error.error.importEventId;
          }
          this.fileErrors = error.error.infos;
        }
      }
    );
  }

  private uploadImportEventFile(): void {
    this.showWaiting = true;
    this.precheckError = false;
    this.fileErrors = [];
    this.importEventService.uploadImportEventFile(this.fileData).subscribe(
      response => {
        this.showSuccess = true;
        this.showWaiting = false;
        this.showErrors = false;
        this.newImportEvent = response;
      },
      error => {
        this.showWaiting = false;
        if (error.error) {
          // Show errors on the form
          if (error.error.errors && error.error.errors.formulary && error.error.errors.formulary.length) {
            this.formularyErrors = error.error.errors.formulary;
            return;
          }

          // Show errors on the file
          if (error.error.errors && error.error.errors.file && error.error.errors.file.length) {
            this.showErrors = true;
            this.fileErrors = error.error.errors.file;
          }

          if (error.error.errorMessage) {
            this.showErrors = true;
            this.fileErrors.push(error.error.errorMessage);
          }
        }
      }
    );
  }

  /**
   * Add validators to period in case inventory and not yearly period
   */
  private verifyValidityOfPeriod(): void {
    const periodFrom = new Date(this.f.periodFrom.value);
    const periodTo = new Date(this.f.periodTo.value);

    // If inventory, check that dates correspond to a yearly period (01.01.20xx - 31.12.20xx)
    if (
      this.f.privacy.value !== 'private' &&
      (periodFrom.getMonth() !== 0 ||
        periodFrom.getDate() !== 1 ||
        periodTo.getDate() !== 31 ||
        periodTo.getMonth() !== 11 ||
        periodTo.getFullYear() !== periodFrom.getFullYear())
    ) {
      this.f.periodFrom.setErrors({ incorrect: true });
      return;
    } else {
      setTimeout(() => {
        this.f.periodFrom.clearValidators();
        this.f.periodFrom.updateValueAndValidity();
      }, 0);
    }
  }

  /**
   * Filter site field
   * @param value - Value typed in
   */
  private _filter(value: any): Site[] {
    const filterValue = value && value.name ? value.name : value;
    return this.sites.filter(option =>
      option.autocompleteDisplayValue.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
}
