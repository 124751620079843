import { Pipe, PipeTransform } from '@angular/core';
import { DataElement } from '../model/dashboard.model';
import { NumberValuePipe } from './number-value.pipe';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'chartsData'
})
/**
 * Map charts data to format numbers to the project defined format.
 */
export class ChartsDataPipe implements PipeTransform {
  constructor(private numberValue: NumberValuePipe, private decimal: DecimalPipe) { }

  transform(data: DataElement[]): DataElement[] {
    return data?.map(chart => {
      chart?.series?.map((serie) => serie.value = Number(this.numberValue.transform(serie.value)));
      return chart;
    });
  }
}
