import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FusionStateEnum } from 'src/app/shared/model/fusion.model';
import { OrganisationFull } from 'src/app/shared/model/organisation.model';
import { OrganisationService } from 'src/app/shared/services/organisation.service';

@Component({
  selector: 'app-organisation-fusion-dialog',
  templateUrl: './organisation-fusion-dialog.component.html',
  styleUrls: ['./organisation-fusion-dialog.component.scss']
})
export class OrganisationFusionDialogComponent implements OnInit {
  state: FusionStateEnum = FusionStateEnum.SELECTION;
  stateEnum = FusionStateEnum;
  organisation: OrganisationFull;
  organisations: OrganisationFull[];
  filteredOrganisations: Observable<OrganisationFull[]>;
  organisationControl = new FormControl();
  showConfirmation = false;
  errors: Array<string>;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrganisationFull,
    private organisationService: OrganisationService,
    private dialogRef: MatDialogRef<OrganisationFusionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.organisationService.getOrganisations().subscribe(organisations => {
      this.organisations = organisations['hydra:member'];

      // Remove current organisation as you can't merge it into itself
      const i = this.organisations.findIndex(o => o['@id'] === this.data['@id']);
      this.organisations.splice(i, 1);

      this.filteredOrganisations = this.organisationControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
  }

  submit() {
    if (this.state === FusionStateEnum.SELECTION) {
      this.state = FusionStateEnum.CONFIRMATION;
    } else if (this.state === FusionStateEnum.CONFIRMATION) {
      this.state = FusionStateEnum.LOADING;
      this.organisationService
        .mergeOrganisations(parseInt(this.data.id, 10), this.organisationControl.value?.id)
        .subscribe(
          result => {
            if (result.error.length) {
              this.errors = result.error;
              this.state = FusionStateEnum.ERROR;
            } else {
              this.state = FusionStateEnum.SUCCESS;
            }
          },
          () => {
            this.errors = ['Il y a eu une erreur avec la fusion.'];
            this.state = FusionStateEnum.ERROR;
          }
        );
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  displayFn(value: OrganisationFull): string {
    return value && value.name ? value.name : '';
  }

  private _filter(value: any): OrganisationFull[] {
    const filterValue = value && value.name ? value.name : value;
    return this.organisations.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
  }
}
