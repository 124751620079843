<div class="wasteflow-ventilation-dialog">
  <div class="form-dialog__header form-dialog__header--w-btn wasteflow-ventilation-dialog__header">
    <h1 mat-dialog-title>Ventilation de la donnée</h1>
  </div>
  <mat-dialog-content>
    <p class="text-dark" *ngIf="displayMessage">
      Veuillez sélectionner l'unité en fonction du mode de refacturation et renseigner le nombre correspondant sur la
      première ligne.
    </p>

    <div class="mt-4 table-container">
      <mat-table [dataSource]="wasteflows" class="wasteflow-ventilation-dialog__table">
        <ng-container matColumnDef="codeLmod">
          <mat-header-cell *matHeaderCellDef>Code LMod</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <app-ventilation-lmod-field
              (updateField)="onUpdateLmodField($event, element)"
              *ngIf="data.lmodCodes"
              [lmodCodes]="data.lmodCodes"
              [selectedCode]="element.codeLmod"
            ></app-ventilation-lmod-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="libelleLmod">
          <mat-header-cell *matHeaderCellDef>Libellé LMod</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.codeLmod.label">
              {{ element.codeLmod.label }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="origine">
          <mat-header-cell *matHeaderCellDef>Origine</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.provenanceSite.name">
              {{ element.provenanceSite.name }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="collecte">
          <mat-header-cell *matHeaderCellDef>Collecte</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <mat-form-field
              [class.mat-form-field-invalid]="errorMessage && element.provenanceCode.id === null"
              class="mat-form-field-small mat-form-field-small--large"
            >
              <mat-select [(value)]="element.provenanceCode.id" tabindex="-1">
                <mat-option *ngFor="let code of data.treatments" [value]="code.id">
                  {{ code.code }} - {{ code.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef>Destination</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.destinationSite.name">
              {{ element.destinationSite.name }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="traitement">
          <mat-header-cell *matHeaderCellDef>Traitement</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <mat-form-field class="mat-form-field-small mat-form-field-small--large">
              <mat-select [(value)]="element.treatment.id">
                <mat-option *ngFor="let treatment of data.treatments" [value]="treatment.id">
                  {{ treatment.code }} - {{ treatment.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="poids">
          <mat-header-cell *matHeaderCellDef>Poids (t)</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span matTooltipPosition="above" [matTooltip]="element.convertedQty | number: '':'fr'">
              {{ element.convertedQty | numberValue | number: '':'fr' }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantite">
          <mat-header-cell *matHeaderCellDef>Quantité d'origine</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-form-field
              [class.mat-form-field-invalid]="isInvalid(element.originQty)"
              class="mat-form-field-small mr-3"
            >
              <input
                (change)="onChangeOriginQty(element, i)"
                [(ngModel)]="element.originQty"
                matInput
                min="0"
                tabindex="2"
                type="number"
              />
            </mat-form-field>
            <mat-form-field class="mat-form-field-small">
              <mat-select
                (selectionChange)="onChangeOriginUnit(element)"
                [(ngModel)]="element.originUnit.id"
                [(value)]="element.originUnit.id"
                tabindex="-1"
              >
                <mat-option *ngFor="let unit of element.filteredUnits" [value]="unit.id">
                  {{ unit.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="repartition">
          <mat-header-cell *matHeaderCellDef>Répartition (%)</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-form-field
              [class.mat-form-field-invalid]="isInvalid(element.repartition, true)"
              class="mat-form-field-small mr-3"
            >
              <input
                (change)="updateQuantitiesAndRepartitions(i)"
                [(ngModel)]="element.repartition"
                matInput
                max="100"
                min="0"
                tabindex="2"
                type="number"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <button (click)="addDataLine()" class="btn-fab mt-4" mat-button>
      <mat-icon>add</mat-icon>
      <span>Ajouter une ligne de donnée</span>
    </button>
    <div *ngIf="errorMessage" class="text-orange mb-1 mt-3 text-sm">{{ errorMessage }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
    <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
  </mat-dialog-actions>
</div>
