<div class="prestation">
  <div class="prestation__top">
    <div>
      <h1 class="mt-0 mb-5">Mes prestataires</h1>
    </div>
    <button
      (click)="addPrestation()"
      *ngIf="hasGottenData && data.length && organisation && organisation.type !== 'service_provider'"
      class="btn"
    >
      Ajouter une prestation
    </button>
  </div>
  <div *ngIf="hasGottenData && data.length" class="prestation__content content-tertiary">
    <div class="table-container">
      <mat-table
        (matSortChange)="sortData($event)"
        [dataSource]="sortedData"
        class="prestation__list-table"
        matSort
        matSortActive="status"
        matSortDirection="asc"
      >
        <ng-container matColumnDef="mandant">
          <mat-header-cell *matHeaderCellDef mat-sort-header="mandant">Mandant</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.organisationOwner ? element.organisationOwner.name : '-'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="prestataire">
          <mat-header-cell *matHeaderCellDef mat-sort-header="prestataire">Prestataire</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.organisationRecipient ? element.organisationRecipient.name : '-'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type de prestation</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ getTypeLabel(element.type) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="periode">
          <mat-header-cell *matHeaderCellDef mat-sort-header="periode">Période</mat-header-cell>
          <mat-cell *matCellDef="let element">
            Du {{ element.dateFrom | date: 'dd.MM.yy' }} au
            {{ element.dateTo | date: 'dd.MM.yy' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="periodetype">
          <mat-header-cell *matHeaderCellDef class="prestation__cell--type"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="prestation__cell--type">{{ element.periodType }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef class="prestation__cell--status" mat-sort-header="status"
            >Statut
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="prestation__cell--status">{{
            element.statusLabel ? element.statusLabel : '-'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="prestation__cell--action">Actions</mat-header-cell>
          <mat-cell *matCellDef="let element" class="prestation__cell--action">
            <button
              (click)="confirmPrestation(element)"
              *ngIf="element.status === 'to_confirm' || element.status === 'reject'"
              class="btn-link"
            >
              Confirmer
            </button>
            <button (click)="viewPrestation(element)" *ngIf="element.status === 'confirm'" class="btn-link">
              Voir le détail
            </button>
            <button
              (click)="renewPrestation(element)"
              *ngIf="element.status === 'new' || element.status === 'confirm'"
              class="btn-link"
            >
              Renouveler
            </button>
            <button (click)="editPrestation(element)" *ngIf="element.status === 'new'" class="btn-link">Editer</button>
            <button (click)="rejectPrestation(element)" *ngIf="element.status === 'to_confirm'" class="btn-link">
              Rejeter
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
  <div *ngIf="hasGottenData && data.length === 0" class="prestation__content prestation__content--empty">
    <img alt="" src="../../../assets/img/empty-declarations.svg" />
    <p *ngIf="organisation && organisation.type === 'service_provider'" class="mt-5">
      Vous n'avez aucune prestation.<br />
    </p>
    <p *ngIf="organisation && organisation.type !== 'service_provider'" class="mt-5">
      Vous n'avez pas encore ajouté de prestation.<br />Faites-le maintenant !
    </p>
    <button (click)="addPrestation()" *ngIf="organisation && organisation.type !== 'service_provider'" class="btn mt-5">
      Ajouter une prestation
    </button>
  </div>
</div>
