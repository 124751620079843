<div class="sites-flow">
  <h1 class="mt-0 mb-5">Bilan de flux par site</h1>
  <div *ngIf="data" class="content-tertiary">
    <div class="table-container sites-flow__table-container">
      <mat-table [dataSource]="data" class="sites-flow__table">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span
              *ngIf="element.alert"
              class="material-icons sites-flow__error-icon"
              matTooltipPosition="above"
              matTooltip="L'écart entre entrée et sortie est important (+/- 20%)"
              >warning_amber</span
            >
            {{ element.site_name }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="data_in_amount">
          <mat-header-cell *matHeaderCellDef>Données en entrée</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.wasteflow_count_destination }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tonnes_in">
          <mat-header-cell *matHeaderCellDef>Tonnage en entrée</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span matTooltipPosition="above" [matTooltip]="element.wasteflow_qty_destination | number: '':'fr'">
              {{ element.wasteflow_qty_destination | numberValue | number: '':'fr' }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="data_out_amount">
          <mat-header-cell *matHeaderCellDef>Données en sortie</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.wasteflow_count_provenance }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tonnes_out">
          <mat-header-cell *matHeaderCellDef>Tonnage en sortie</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span matTooltipPosition="above" [matTooltip]="element.wasteflow_qty_provenance | number: '':'fr'">
              {{ element.wasteflow_qty_provenance | numberValue | number: '':'fr' }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="progress_bar">
          <mat-header-cell *matHeaderCellDef>Statut pré-clôture</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <app-pre-closure-progress-bar [data]="element.progressBar"></app-pre-closure-progress-bar>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a [routerLink]="'/gestion/sites-flow/' + element.site_id" class="btn-link">Voir les détails</a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [class.sites-flow__row--error]="row.alert"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
