import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit {
  public organisationId: number;

  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.organisationId = null;
      setTimeout(() => {
        this.organisationId = parseInt(routeParams.id, 10);
      }, 0);
    });
  }
}
