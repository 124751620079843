import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberValue'
})
export class NumberValuePipe implements PipeTransform {
  transform(value: number): number | string {
    if (value >= 10) {
      return value.toFixed(0);
    } else {
      return parseFloat(value.toFixed(3));
    }
  }
}
