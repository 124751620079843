export interface Code {
  '@id': string;
  id: number;
  code: string;
  label: string;
  displayLabel?: string;
  isSpecialCategorySite?: boolean;
}

export interface Unit {
  '@id': string;
  '@type': string;
  id: number;
  name: string;
  symbol: string;
  multiplicator: string;
  codeLmod?: string;
}

export interface CodeType {
  code_type_id: number;
  organisation: string;
  parent_code: string;
  label: string;
  comment: string;
}

export interface CodeTypeFull {
  id: number;
  nature: string | NatureCode;
  parent: CodeTypeFull;
  comment: string;
  createdBy: string;
  label: string;
  codes: string[];
}

interface NatureCode {
  nature: string;
}

export interface UpdatedCode {
  '@id': string;
  code: string;
  comment: string;
  label: string;
  groupCodes: Code[];
}

export interface CodeMappingDownload {
  error: Array<any>;
  file_name: string;
  status: string;
}

export interface CodesByCodeType {
  codes: CodeByCodeType[];
  currentPage: number;
  nbPerPage: number;
  totalPage: number;
}

export interface CodeByCodeType {
  base_code: string;
  comment_base_code: string;
  comment_internal_code: string;
  id_base_code: number;
  id_codetype_nature: number;
  id_internal_code: number;
  internal_code: string;
  label_base_code: string;
  label_internal_code: string;
  organisation: string;
  all_groupscode_base_code_id: number[];
  state?: CodeStateEnum;
  isEditMode?: boolean;
  isNew?: boolean;
  previous_id_base_code?: number;
}

export enum CodeStateEnum {
  DEFAULT,
  EDITMODE,
  NEW
}

export interface CodeUpdate {
  label: string;
  comment: string;
  groupCodes: string[];
}

export interface CodeUpdateRepercution {
  id_internal_code: number;
  new_id_base_code: number;
  old_id_base_code: number;
  label_internal_code: string;
  comment_internal_code: string;
}

export interface CodeCreation {
  code: string;
  label: string;
  codeType: string;
  comment: string;
  groupCodes: [string];
}
