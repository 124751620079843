import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public submitted = false;
  public showPassword = false;
  public errorMessage = null;
  public isFromChangePassword = false;
  private route$: Subscription;

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.route$ = this.route.queryParams.subscribe((params: { [k: string]: any }) => {
      this.isFromChangePassword = params.isFromChangePassword;
    });
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * Log in
   */
  public submit(): void {
    this.submitted = true;
    this.errorMessage = null;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authenticationService.login(this.f.email.value, this.f.password.value).subscribe(
        success => {
          if (success) {
            this.userService.getCurrent().subscribe(user => {
              localStorage.setItem('user', JSON.stringify(user));
              if (user.userOrganisations.length && user.userOrganisations[0].organisation) {
                localStorage.setItem('organisation', JSON.stringify(user.userOrganisations[0].organisation));
              }
              this.router.navigate(['/']);
            });
          }
        },
        error => {
          if (error && error.error) {
            if (error.error.message === 'Invalid credentials.') {
              this.errorMessage = `Nom d'utilisateur ou mot de passe incorrect.`;
            } else {
              this.errorMessage = error.error.message;
            }
          }
          return error;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.route$.unsubscribe();
  }
}
