<div class="declaration">
  <div class="declaration__top">
    <div>
      <h1 class="mt-0">Déclarations</h1>
      <h4 class="mt-5 pt-3 mb-0">Liste des déclarations</h4>
    </div>
    <div class="d-flex">
      <button
        (click)="openImportEventDialog()"
        *ngIf="
          hasGottenData &&
          data.length &&
          organisation &&
          (organisation.type === 'service_provider' || organisation.type === 'gesdec')
        "
        class="btn btn-small mr-2"
      >
        Importer déclaration
      </button>

      <button
        (click)="openDownloadEventDialog()"
        *ngIf="
          hasGottenData && (organisation.type === 'service_provider' || organisation.type === 'gesdec' || organisation.type === 'eco21')
        "
        class="btn btn-small btn-secondary"
      >
        Télécharger modèle
      </button>
      <button
        (click)="downloadImportEventTemplate()"
        *ngIf="
          hasGottenData && organisation.type !== 'service_provider' && organisation.type !== 'gesdec' && organisation.type !== 'eco21'
        "
        class="btn btn-small btn-secondary"
      >
        Télécharger modèle
      </button>
    </div>
  </div>
  <div *ngIf="hasGottenData && data.length" class="declaration__content content-tertiary">
    <div class="table-container">
      <mat-table
        [class.declaration__list-table--large-last-col]="needsBigActionColumn"
        [dataSource]="data"
        class="declaration__list-table"
      >
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.front_id }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="periode">
          <mat-header-cell *matHeaderCellDef>Période</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.period }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="site">
          <mat-header-cell *matHeaderCellDef>Site</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.site">
              {{ element.site }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="prestataire">
          <mat-header-cell *matHeaderCellDef>Fourni par</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.organisation }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.type | importEventType">
              {{ element.type | importEventType }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statut">
          <mat-header-cell *matHeaderCellDef>Statut</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [ngClass]="element.statusContext | statusColor: organisation.type" class="status"></span>
            {{ element.statusContext | statusLabel }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>
              <ng-container *ngIf="element.file">
                <a href="{{ element.file }}">Télécharger</a>
                <span class="text-xxs mr-3"> - fichier source</span>
              </ng-container>
              <a [queryParams]="{ id: element.id }" [routerLink]="['/gestion/declarations-detail']">Voir</a>
              <button (click)="deleteImportEvent(element)" *ngIf="element.canBeDeleted" class="btn btn-link">
                Supprimer
              </button>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <a [routerLink]="['/gestion/declarations-detail']" class="btn declaration__see-all-btn mt-3">
      Voir toutes les données
    </a>
  </div>
  <div *ngIf="hasGottenData && data.length === 0" class="declaration__content declaration__content--empty">
    <img alt="" src="../../../assets/img/empty-declarations.svg" />
    <p *ngIf="organisation && organisation.type !== 'service_provider'" class="mt-5">
      Vous n'avez aucune déclaration.<br />
    </p>
    <p *ngIf="organisation && organisation.type === 'service_provider'" class="mt-5">
      Vous n'avez pas encore importé de déclaration.<br />Faites-le maintenant !
    </p>
    <button
      (click)="openImportEventDialog()"
      *ngIf="organisation && (organisation.type === 'service_provider' || organisation.type === 'gesdec')"
      class="btn mt-5"
    >
      Importer déclaration
    </button>
  </div>
</div>
