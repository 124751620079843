import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationComponent } from './organisation.component';
import { OrganisationDetailsComponent } from './organisation-details/organisation-details.component';
import { AssociatedDatasComponent } from './associated-datas/associated-datas.component';
import { UsersComponent } from './users/users.component';
import { SitesComponent } from './sites/sites.component';
import { SharedModule } from '../../shared/shared.module';
import { AssociatedDataDialogComponent } from './associated-datas/associated-data-dialog/associated-data-dialog.component';
import { SiteDialogComponent } from './sites/site-dialog/site-dialog.component';
import { OrganisationDialogComponent } from './organisation-details/organisation-dialog/organisation-dialog.component';
import { AuthorisationsDialogComponent } from './authorisations-list/authorisations-dialog/authorisations-dialog.component';
import { OrganisationsListComponent } from './organisations-list/organisations-list.component';
import { OrganisationFusionDialogComponent } from './organisation-fusion-dialog/organisation-fusion-dialog.component';
import { SiteFusionDialogComponent } from './sites/site-fusion-dialog/site-fusion-dialog.component';
import { AuthorisationsListComponent } from './authorisations-list/authorisations-list.component';
import { FormatInputIdGeDirective } from 'src/app/shared/directives/format-idge';

@NgModule({
  declarations: [
    OrganisationComponent,
    OrganisationDetailsComponent,
    AssociatedDatasComponent,
    UsersComponent,
    SitesComponent,
    AssociatedDataDialogComponent,
    SiteDialogComponent,
    OrganisationDialogComponent,
    AuthorisationsDialogComponent,
    OrganisationsListComponent,
    AuthorisationsListComponent,
    OrganisationFusionDialogComponent,
    SiteFusionDialogComponent,
    FormatInputIdGeDirective
  ],
  imports: [CommonModule, SharedModule]
})
export class OrganisationModule {}
