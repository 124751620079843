<div class="registration">
  <app-half-page-logo></app-half-page-logo>
  <div class="registration__content">
    <h1>Vous avez oublié votre mot de passe?</h1>

    <p class="mb-4">
      Pour réinitier votre mot de passe, saisissez l’adresse e-mail que vous utilisez pour vous connecter à votre compte
      Outil Cantonal de Statistiques des Déchets.
    </p>
    <form (ngSubmit)="submit()" [formGroup]="resetPasswordForm" class="registration__form">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input formControlName="email" id="email" matInput placeholder="email@email.com" />
        <mat-error *ngIf="submitted && f.email.errors && f.email.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <div *ngIf="success" class="text-green text-sm mb-3">
        La demande de réinitialisation de mot de passe a été prise en compte. Vous devriez recevoir un email.
        <p>
          La réception peut durer quelques minutes, merci de vérifier vos courriers indésirables avant de nous contacter
        </p>
      </div>
      <button class="btn" type="submit">Envoyer</button>
      <a [routerLink]="'/login'" class="pt-3">Je me connecte</a>
    </form>
    <app-registration-legend></app-registration-legend>
  </div>
</div>
