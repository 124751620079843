import { Pipe, PipeTransform } from '@angular/core';
import { StatusValidation } from '../model/import-event.model';

@Pipe({
  name: 'statusLabel'
})
export class StatusLabelPipe implements PipeTransform {
  transform(status: StatusValidation): string {
    switch (status) {
      case StatusValidation.NEW:
        return 'Nouveau';
      case StatusValidation.TOVALIDATE:
        return 'À valider';
      case StatusValidation.VIEW:
        return 'Vue';
      case StatusValidation.OUTSERVICE:
        return 'Hors prestation';
      case StatusValidation.OUTSERVICE:
        return 'Hors prestation';
      case StatusValidation.PREARCHIVE:
        return 'Pré-archivée';
      case StatusValidation.CLOSURE:
        return 'Cloturée';
      case StatusValidation.ARCHIVE:
        return 'Archivée';
      case StatusValidation.TOFIX:
        return 'À corriger';
      case StatusValidation.PRECLOSURE:
        return 'Pré-clôturée';
      case StatusValidation.VALID:
        return 'Validé';
      default:
        return 'Label non enregistré';
    }
  }
}
