import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportEventsComponent } from './import-events.component';
import { SharedModule } from '../../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { ImportEventDialogComponent } from './import-event-dialog/import-event-dialog.component';
import { DownloadEventDialogComponent } from './download-event-dialog/download-event-dialog.component';
import { HistoryDialogComponent } from '../wasteflows/history-dialog/history-dialog.component';
import { HistoryLineComponent } from '../wasteflows/history-dialog/history-line/history-line.component';

@NgModule({
  declarations: [ImportEventsComponent, DownloadEventDialogComponent, ImportEventDialogComponent, HistoryDialogComponent, HistoryLineComponent],
  imports: [CommonModule, SharedModule, BrowserModule],
  exports: [CommonModule, SharedModule, BrowserModule]
})
export class ImportEventsModule {}
