<div class="form-dialog__title">
  <h1 *ngIf="data.property" mat-dialog-title>Edition d'une donnée</h1>
  <h1 *ngIf="!data.property" mat-dialog-title>Ajout d'une donnée</h1>
</div>
<mat-dialog-content>
  <div class="form-dialog__content">
    <form [formGroup]="dataForm" class="form-dialog__form">
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option value="OFS">{{ propertyType.OFS }}</mat-option>
          <mat-option value="Population">{{ propertyType.Population }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.type.errors && f.type.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Date</mat-label>
        <mat-select formControlName="date">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.date.errors && f.date.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Valeur</mat-label>
        <input formControlName="value" matInput type="number" />
        <mat-error *ngIf="f.value.errors && f.value.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <div *ngIf="errorMessage" class="text-orange mb-1 mt-3 text-sm">{{ errorMessage }}</div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
