import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.getToken()) {
      request = this.addToken(request, this.authenticationService.getToken());
    }

    return next.handle(request).pipe(
      catchError(error => {
        // If 401 error, redirect to log in page because it means there is no token or it's not valid anymore.
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authenticationService.logout();
          // Close all open dialog boxes to avoid seeing them on the login page.
          this.dialog.closeAll();
        } else if (error.error) {
          if (error.error['hydra:description']) {
            if (
              error.error['hydra:description'] ===
              'The type of the "originQty" attribute must be "float", "NULL" given.'
            ) {
              this.showError(
                'Le champ "quantité" ne peut pas être vide. Les lignes modifiées non concernées par ce message ont été sauvegardées normalement.'
              );
            } else {
              this.showError(error.error['hydra:description']);
            }
          } else if (error.error.error && Array.isArray(error.error.error)) {
            this.showError(error.error.error.join(', '));
          } else if (error.error.errorMessage) {
            this.showError(error.error.errorMessage);
          }
        } else if (error.errorMessage) {
          this.showError(error.errorMessage);
        }
        return throwError(error);
      })
    );
  }

  public addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private showError(message: string): void {
    this.snackBar.open(message, 'close', {
      horizontalPosition: 'right'
    });
  }
}
