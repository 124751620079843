import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericData } from 'src/app/shared/model/dashboard.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-graph-waste-evolution',
  templateUrl: './graph-waste-evolution.component.html',
  styleUrls: ['./graph-waste-evolution.component.scss']
})
export class GraphWasteEvolutionComponent implements OnInit, OnDestroy {
  legendPosition = LegendPosition;

  public colorScheme = {
    domain: null
  };
  public data: Array<any> = [];
  public yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, public decimalPipe: DecimalPipe, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$)
      .subscribe(() => {
        // this.data = [];
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Format x axis tick label
   * @param value - Value to format
   */
  public yAxisTickFormatting(value: string | number): string {
    return this.decimalPipe.transform(value, '', 'fr');
  }

  /**
   * Get waste evolution data
   */
  private getData(): void {
    this.dashboardService
      .getWasteEvolution(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
      .subscribe(data => {
        this.handleData(data);
      });
  }

  /**
   * Handle waste evolution data
   * @param data - Waste evolution data
   */
  private handleData(data: GenericData): void {
    data.data.forEach(d => (d.name = d.name.toString()));
    this.data = data.data;

    // Add colors to color scheme of the graph
    this.colorScheme.domain = data.colors;
  }
}
