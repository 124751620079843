<h1 *ngIf="!showErrors && !showSuccess && !showWaiting" mat-dialog-title>Télécharger un modèle de déclaration</h1>
<h1 *ngIf="showWaiting" mat-dialog-title>Chargement des sites</h1>
<mat-dialog-content>
  <div *ngIf="showWaiting" class="loader"></div>
  <div *ngIf="!showSuccess && !showWaiting" class="declaration-dialog__content">
    <form *ngIf="!showErrors" [formGroup]="downloadForm" class="declaration-dialog__form">

      <mat-form-field *ngIf="data.type !== 'gesdec'" appearance="outline" class="declaration-dialog__select">
        <mat-label>Nom du site</mat-label>
        <mat-select formControlName="site">
          <mat-option *ngFor="let site of sites" [value]="site.id">
            {{ site.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.site.errors && f.site.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="data.type === 'gesdec'" appearance="outline" class="declaration-dialog__select">
        <mat-label>Nom du site</mat-label>
        <input [matAutocomplete]="auto" aria-label="Nom du site" formControlName="site" matInput type="text" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredSites | async" [value]="option">
            {{ option.organisation ? option.organisation.name + ' - ' : '' }}{{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.site.errors && f.site.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>

      <p>Le fichier téléchargé comprendra les codes autorisés pour l'installation sélectionnée (ou les codes utilisés sur les deux dernières années en l'absence d'autorisation en cours de validité).</p>

    </form>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="!showSuccess" [disabled]="showWaiting" class="btn-link" mat-button mat-dialog-close>
    Annuler
  </button>
  <ng-container>
    <button (click)="submit()" *ngIf="!showSuccess" [disabled]="showWaiting" class="btn" mat-button>
      Télécharger
    </button>
  </ng-container>
</mat-dialog-actions>
