import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code } from 'src/app/shared/model/code.model';

@Component({
  selector: 'app-ventilation-lmod-field',
  templateUrl: './ventilation-lmod-field.component.html',
  styleUrls: ['./ventilation-lmod-field.component.scss']
})
export class VentilationLmodFieldComponent implements OnInit, OnDestroy {
  @Input() selectedCode: Code;
  @Input() lmodCodes: Code[];
  @Output() updateField = new EventEmitter<Code>();

  public filteredLmodCodes: Observable<Code[]>;
  public lmodControl = new FormControl();
  private lmodControl$: Subscription;
  public isInvalid = false;

  ngOnInit(): void {
    this.selectedCode.displayLabel = `${this.selectedCode.code} ${this.selectedCode.label}`;
    this.lmodControl.setValue(this.selectedCode);
    this.filteredLmodCodes = this.lmodControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.lmodControl$ = this.lmodControl.valueChanges.subscribe(value => {
      this.isInvalid = !value || !value.code;
      this.updateField.emit(value);
    });
  }

  /**
   * Display value of the field
   * @param value - Value of the field
   */
  public displayFn(value: Code): string {
    return value ? value.displayLabel : '';
  }

  /**
   * Filter autocomplete responses based on what the user has typed
   * @param value - Value typed
   */
  private _filter(value: any): Code[] {
    const filterValue = value && value.code ? value.code.toLowerCase() : value.toLowerCase();
    return this.lmodCodes.filter(option => option.displayLabel.toLowerCase().includes(filterValue.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.lmodControl$.unsubscribe();
  }
}
