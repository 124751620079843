import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code } from 'src/app/shared/model/code.model';
import {
  AtlasElement,
  Organisation,
  OrganisationSimple,
  OrganisationType
} from 'src/app/shared/model/organisation.model';
import { Site, SiteStatus } from 'src/app/shared/model/site.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { SiteService } from 'src/app/shared/services/site.service';

interface SiteDataDialog {
  site: Site;
  organisationId: string;
}

@Component({
  selector: 'app-site-dialog',
  templateUrl: './site-dialog.component.html',
  styleUrls: ['./site-dialog.component.scss']
})
export class SiteDialogComponent implements OnInit, OnDestroy {
  siteForm: FormGroup;
  siteCodes: Code[] = [];
  nogaCodes: Code[] = [];
  addressOptions = [];
  fullAddress: AtlasElement;
  isSearching = false;
  private streetChanges$: Subscription;
  private isGenevaChanges$: Subscription;
  organisations: OrganisationSimple[];
  public filteredOrganisations: Observable<OrganisationSimple[]>;
  isGesdecUser = false;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SiteDataDialog,
    private dialogRef: MatDialogRef<SiteDialogComponent>,
    private siteService: SiteService,
    private codeService: CodeService,
    private organisationService: OrganisationService
  ) {}

  ngOnInit(): void {
    this.isGesdecUser = JSON.parse(localStorage.getItem('organisation')).type === OrganisationType.GESDEC;

    if (this.isGesdecUser) {
      this.organisationService.getOrganisations().subscribe(organisations => {
        this.organisations = organisations['hydra:member'];

        this.filteredOrganisations = this.f.organisation.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      });
    }

    // Create site form
    this.siteForm = new FormGroup({
      organisation: new FormControl(
        { value: { '@id': '/api/organisations/' + this.data.organisationId }, disabled: false },
        Validators.required
      ),
      name: new FormControl({ value: '', disabled: false }, Validators.required),
      codeSite: new FormControl({ value: '', disabled: false }, Validators.required),
      codeNoga: new FormControl({ value: '', disabled: false }),
      status: new FormControl({ value: SiteStatus.PRIVATE, disabled: false }, Validators.required),
      isGeneva: new FormControl({ value: 0, disabled: false }),
      street1: new FormControl({ value: '', disabled: false }, Validators.required),
      street2: new FormControl({ value: null, disabled: false }),
      postalCode: new FormControl({ value: null, disabled: false }),
      town: new FormControl({ value: null, disabled: false }),
      country: new FormControl({ value: null, disabled: false }),
      canton: new FormControl({ value: null, disabled: false }),
      chIdNumber: new FormControl({ value: null, disabled: false }),
      isSpecialCategorySite: new FormControl({ value: null, disabled: false })
    });

    // If in edit mode, add site data
    if (this.data.site) {
      this.f.organisation.setValue(this.data.site.organisation);
      this.f.name.setValue(this.data.site.name);
      this.f.status.setValue(this.data.site.status);
      if (this.data.site.codeSite) {
        this.f.codeSite.setValue(this.data.site.codeSite['@id']);
      }
      let isGeneva = 0;
      if (!this.data.site.idpadr && this.data.site.country === 'Suisse') {
        isGeneva = 1;
      } else if (this.data.site.country !== 'Suisse') {
        isGeneva = 2;
      }
      this.f.isGeneva.setValue(isGeneva);
      this.f.street1.setValue(this.data.site.street1);
      this.f.street2.setValue(this.data.site.street2);
      this.f.postalCode.setValue(this.data.site.postalCode);
      this.f.town.setValue(this.data.site.town);
      this.f.country.setValue(this.data.site.country);
      this.f.canton.setValue(this.data.site.canton);
      this.f.chIdNumber.setValue(this.data.site.chIdNumber);
      this.f.isSpecialCategorySite.setValue(this.data.site.isSpecialCategorySite);

      if (this.data.site.codeNoga) {
        this.f.codeNoga.setValue(this.data.site.codeNoga['@id']);
      }
    }

    this.codeService.getNogaCodes().subscribe(response => {
      this.nogaCodes = response;
    });

    this.codeService.getSiteCodes().subscribe(response => {
      this.siteCodes = response;
    });

    this.isGenevaChanges$ = this.siteForm.get('isGeneva').valueChanges.subscribe(value => {
      this.f.street1.setErrors({ incorrect: false });
      this.f.street1.updateValueAndValidity();
    });

    this.streetChanges$ = this.siteForm.get('street1').valueChanges.subscribe(value => {
      this.isSearching = true;
      this.organisationService.getGenevaAddresses(value, 5).subscribe(
        response => {
          this.isSearching = false;
          this.addressOptions = response;
        },
        () => {
          this.isSearching = false;
        }
      );

      this.fullAddress = this.addressOptions.find(option => option.adresse.adresse === value);
    });
  }

  private _filter(value: any): OrganisationSimple[] {
    const filterValue = value && value.name ? value.name : value;
    return this.organisations.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  displayFn(value: OrganisationSimple): string {
    return value && value.name ? value.name : '';
  }

  /**
   * Get form controls
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.siteForm.controls;
  }

  /**
   * Update full address when it changes on child component
   * @param address - New address
   */
  onFullAddressChange(address: AtlasElement): void {
    this.fullAddress = address;
  }

  /**
   * Submit form
   */
  public submit(): void {
    this.siteForm.markAsDirty();
    this.siteForm.markAsTouched();
    this.siteForm.markAllAsTouched();

    if (this.f.isGeneva.value === 0 && !this.fullAddress) {
      this.f.street1.setErrors({ incorrect: true });
      return;
    }

    if (this.siteForm.invalid) {
      return;
    }

    // Create site object
    let country = 'Suisse';
    if (this.f.isGeneva.value === 1) {
      country = 'Suisse';
    } else if (this.f.isGeneva.value === 2) {
      country = this.f.country.value;
    }
    let canton = 'GE';
    if (this.f.isGeneva.value === 1) {
      canton = this.f.canton.value;
    } else if (this.f.isGeneva.value === 2) {
      canton = '';
    }
    let chIdNumber = this.f.chIdNumber.value;
    if (this.f.isGeneva.value === 1) {
      chIdNumber = this.f.chIdNumber.value;
    } else if (this.f.isGeneva.value === 2) {
      chIdNumber = '';
    }
    const site: { [k: string]: any } = {
      name: this.f.name.value,
      street1: this.f.street1.value,
      idpadr: this.f.isGeneva.value === 0 ? this.fullAddress.idpadr.toString() : null,
      street2: this.f.isGeneva.value === 0 ? null : this.f.street2.value,
      postal_code: this.f.isGeneva.value === 0 ? this.fullAddress.adresse.npa.toString() : this.f.postalCode.value,
      town: this.f.isGeneva.value === 0 ? this.fullAddress.adresse.lieu : this.f.town.value,
      country,
      canton,
      chIdNumber,
      isSpecialCategorySite:  this.f.isSpecialCategorySite.value,
      status: this.f.status.value,
      codeSite: this.f.codeSite.value,
      codeNoga: this.f.codeNoga.value === '' ? null : this.f.codeNoga.value,
      organisation: this.f.organisation.value['@id']
    };

    if (this.data.site) {
      this.siteService.updateSite(this.data.site['@id'], site).subscribe(() => {
        this.dialogRef.close(true);
      });
    } else {
      this.siteService.createSite(site).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.streetChanges$) {
      this.streetChanges$.unsubscribe();
    }
    if (this.isGenevaChanges$) {
      this.isGenevaChanges$.unsubscribe();
    }
  }
}
