import { InjectionToken } from '@angular/core';

import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { appReducer } from './reducers/app.reducers';
import { AppState } from './states/app.state';

export const reducers = {
  app: appReducer
};


export function getReducers(): any { return reducers; }

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('[Application] Registered Reducers');

export const StoreModuleInit = {
  imports: [
    StoreModule.forRoot(REDUCER_TOKEN)
  ],
  providers: [{ provide: REDUCER_TOKEN, useFactory: getReducers }]
};
