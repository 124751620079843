<h1 mat-dialog-title>Modifier ces données en masse</h1>
<div *ngIf="isLoading" class="message">
  <h5>Application de vos modifications. Merci de ne pas fermer cette fenêtre.</h5>
  <div class="spinner"></div>
  <app-spinner *ngIf="showSpinner"></app-spinner>
</div>
<div *ngIf="!isLoading">
<h5>Êtes-vous sûr-e de vouloir appliquer les changements suivants sur les données filtrées ({{ data.wasteflowCount }} données) ? <br/>Cette action est irréversible.</h5>
<mat-dialog-content>
    <div class="col-12 filters">
      <mat-card>
        <mat-card-content>
          <p><strong>Liste des filtres:</strong></p>
          <div *ngIf="commonItemsLmod && commonItemsLmod.length > 0">
            <div><strong>Codes LMod</strong></div>
            <ul>
              <li *ngFor="let item of commonItemsLmod">
                {{ item.code }} {{ item.label }}
              </li>
            </ul>
          </div>
          <div *ngIf="commonItemsProvenance && commonItemsProvenance.length > 0">
            <div><strong>Origine</strong></div>
            <ul>
              <li *ngFor="let item of commonItemsProvenance">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div *ngIf="commonItemsDestination && commonItemsDestination.length > 0">
            <div><strong>Destination</strong></div>
            <ul>
              <li *ngFor="let item of commonItemsDestination">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div *ngIf="commonItemsStatus && commonItemsStatus.length > 0">
            <div><strong>Statut</strong></div>
            <ul>
              <li *ngFor="let item of commonItemsStatus">
                {{ item.label }}
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12">
      <mat-card>
        <mat-card-content>
          <p><strong>Modifications demandées:</strong></p>
          <ng-container *ngIf="keysToDisplay && keysToDisplay.length > 0">
            <div *ngFor="let customKey of keysToDisplay">
              <ng-container *ngIf="shouldDisplayKey(customKey)">
                <p><strong>{{ customKey.label }}:</strong> {{ processKey(customKey) }}</p>
              </ng-container>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button *ngIf="displayConfirmButton" (click)="submit()" class="btn" mat-button>Confirmer</button>
</mat-dialog-actions>
</div>
