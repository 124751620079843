<div class="registration">
  <app-half-page-logo></app-half-page-logo>
  <div class="registration__content">
    <h1>Veuillez choisir un nouveau mot de passe</h1>
    <form (ngSubmit)="submit()" [formGroup]="changePassword" autocomplete="current-password" class="registration__form">
      <mat-form-field *ngIf="!isPublic" appearance="outline">
        <mat-label>Ancien mot de passe</mat-label>
        <input
          [type]="showOldPassword ? 'text' : 'password'"
          formControlName="oldPassword"
          id="oldPassword"
          matInput
          placeholder="Mot de passe"
        />
        <mat-icon (click)="showOldPassword = !showOldPassword" class="material-icons-round" matSuffix>{{
          showOldPassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error *ngIf="submitted && f.oldPassword.errors && f.oldPassword.errors.required"
          >Ce champ est obligatoire.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nouveau mot de passe</mat-label>
        <input
          [type]="showNewPassword ? 'text' : 'password'"
          formControlName="newPassword"
          id="newPassword"
          matInput
          placeholder="Mot de passe"
        />
        <mat-icon (click)="showNewPassword = !showNewPassword" class="material-icons-round" matSuffix>{{
          showNewPassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error *ngIf="submitted && f.newPassword.errors">
          <ng-container *ngIf="f.newPassword.errors.pattern">
            Le mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 chiffre et un caractère spécial.
          </ng-container>
          <ng-container *ngIf="f.newPassword.errors.required">Ce champ est obligatoire.</ng-container>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Confirmez nouveau mot de passe</mat-label>
        <input
          [type]="showNewPasswordRepeated ? 'text' : 'password'"
          formControlName="newPasswordRepeated"
          id="newPasswordRepeated"
          matInput
          placeholder="Mot de passe"
        />
        <mat-icon (click)="showNewPasswordRepeated = !showNewPasswordRepeated" class="material-icons-round" matSuffix>{{
          showNewPasswordRepeated ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error *ngIf="submitted && f.newPasswordRepeated.errors">
          <ng-container *ngIf="f.newPasswordRepeated.errors.passwordNotMatch && !f.newPasswordRepeated.errors.required">
            Les deux mots de passes ne correspondent pas.
          </ng-container>
          <ng-container *ngIf="f.newPasswordRepeated.errors.required">Ce champ est obligatoire.</ng-container>
        </mat-error>
      </mat-form-field>
      <div *ngIf="error" class="text-orange text-sm mb-3">
        {{ error }}
        <a *ngIf="isInvalidLink" [routerLink]="'/forgotten-password'" class="pt-3 d-block">Demander un nouveau lien</a>
      </div>
      <button class="btn" type="submit">Changer mot de passe</button>
    </form>
    <app-registration-legend></app-registration-legend>
  </div>
</div>
