import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CodeType } from 'src/app/shared/model/code.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-code-type-list',
  templateUrl: './code-type-list.component.html',
  styleUrls: ['./code-type-list.component.scss']
})
export class CodeTypeListComponent implements OnInit {
  @ViewChild('link') link: ElementRef;
  downloadLink = null;

  data: CodeType[];
  displayedColumns: string[] = ['organisation', 'name', 'codeParent', 'commentaire', 'actions'];

  constructor(private codeService: CodeService) {}

  ngOnInit(): void {
    this.codeService.getCodeTypes().subscribe(codeTypes => {
      this.data = codeTypes;
    });
  }

  download(id: number) {
    this.codeService.getDownloadLink(id).subscribe(downloadLink => {
      this.downloadLink = `${environment.apiUrl.slice(0, -4)}/download?file=${downloadLink}`;
      setTimeout(() => {
        this.link.nativeElement.click();
      }, 0);
    });
  }
}
