import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code, Unit } from 'src/app/shared/model/code.model';
import { StatusInventory } from 'src/app/shared/model/import-event.model';
import { Organisation, OrganisationSimple, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UnitService } from 'src/app/shared/services/unit.service';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { isEmpty } from 'lodash';
import { WasteflowMassEditConfirmDialogComponent } from '../wasteflow-mass-edit-confirm-dialog/wasteflow-mass-edit-confirm-dialog.component';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-wasteflow-mass-edit-dialog',
  templateUrl: './wasteflow-mass-edit-dialog.component.html',
  styleUrls: ['./wasteflow-mass-edit-dialog.component.scss']
})
export class WasteflowMassEditDialogComponent implements OnInit {
  details: any;
  codeLmodIds: any[];
  codeLmodObject: any;
  private dialog$: Subscription;
  count: number = 0;
  displayConfirmButton: boolean = true;
  editStatut: Array<object>;
  editInclusion: Array<object>;
  userOrganisation: Organisation;
  wasteflowForm: FormGroup;
  sites: Site[];
  provenanceSites: Site[];
  filteredProvenanceSites: Observable<Site[]>;
  destinationSites: Site[];
  filteredDestinationSites: Observable<Site[]>;
  units: Unit[];
  filteredUnits: Unit[];
  lmodCodes: Code[] = [];
  filteredLmodCodes: Observable<Code[]>;
  oledCodes: Code[] = [];
  filteredOledCodes: Observable<Code[]>;
  treatments: Code[];
  filteredTreatments: Observable<Code[]>;

  provenanceCodes: Code[];
  filteredProvenanceCodes: Observable<Code[]>;
  validators: OrganisationSimple[] = [];
  filteredValidators: Observable<OrganisationSimple[]>;
  initialWrtInventoryValue: StatusInventory = StatusInventory.INVENTORY;

  private defaultStartDate = new Date('2021-01-01T22:00:00.000Z');
  private defaultEndDate = new Date('2021-12-31T22:00:00.000Z');

  constructor(
    public dialogRef: MatDialogRef<WasteflowMassEditDialogComponent>,
    public dialog: MatDialog,
    public wasteflowService: WasteflowService,
    private unitService: UnitService,
    private codeService: CodeService,
    private siteService: SiteService,
    private organisationService: OrganisationService,
    private dataSharingService: DataSharingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.count = data;
  }

  /**
   * Get form control
   */
  get f(): { [key: string]: AbstractControl } {
    return this.wasteflowForm.controls;
  }

  ngOnInit(): void {
    this.editStatut = [
      { label: 'À corriger', code: 'to_fix' },
      { label: 'À valider', code: 'to_validate' },
      { label: 'Validée', code: 'valid' },
      { label: 'Pré-clôturée', code: 'pre_closure' }
    ];
    this.editInclusion = [
      { label: "Donnée à inclure dans l'inventaire", code: 'integrated_to_inventory' },
      { label: 'Donnée complémentaire', code: 'complementary' }
    ];
    const today = new Date();
    this.defaultStartDate.setFullYear(today.getFullYear() - 1);
    this.defaultEndDate.setFullYear(today.getFullYear() - 1);
    this.userOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.wasteflowForm = new FormGroup({
      statut: new FormControl({ value: null, disabled: false }),
      inclusion: new FormControl({ value: null, disabled: false }),
      site: new FormControl({ value: null, disabled: false }),
      periodFrom: new FormControl({ value: null, disabled: false }),
      periodTo: new FormControl({ value: null, disabled: false }),
      provenanceCode: new FormControl({ value: null, disabled: false }),
      treatment: new FormControl({ value: null, disabled: false }),
      originQty: new FormControl({ value: null, disabled: false }, [Validators.min(0)]),
      originUnit: new FormControl({ value: null, disabled: false }),
      codeLmod: new FormControl({ value: null, disabled: false }),
      validator: new FormControl({ value: null, disabled: false },),
      inventory: new FormControl({ value: null, disabled: false }),
      comment: new FormControl({ value: null, disabled: false })
    });

    this.getSites(this.userOrganisation.type === OrganisationType.GESDEC ? null : this.userOrganisation.id);
    // COMMENTÉ AU CAS OU FAUT LE REMETTRE
    // if (this.userOrganisation.type === OrganisationType.GESDEC) {
    //   this.getSites(null, OrganisationType.SERVICEPROVIDER, 'public');
    // } else {
    //   this.getSites(this.userOrganisation.id, null, 'public');
    // }

    this.getProvenanceAndDestinationSites();
    this.getOriginUnits();
    this.getLmodCodes();
    this.getValidators();
    this.getTreatmentTypes();
    this.filterUnits();
    this.subscribeFormControls();
    this.dataSharingService.details$.subscribe(details => {
      this.details = details;
      this.codeLmodIds = this.details["hydra:member"].map(item => item.codeLmod.id);
      const lmodObject = {
        "filterCodeLmodIds": [],
        "filterProvenanceSiteId": 0,
        "filterDestinationSiteId": 0,
        "filterStatus": "",

        "filterFlowPeriodFrom": "",
        "filterFlowPeriodTo": "",
        "filterOrganisationId": this.userOrganisation.id
      };
      this.codeLmodObject = {
        ...lmodObject,  // Spread the existing properties
        "filterCodeLmodIds": [...lmodObject.filterCodeLmodIds, ...this.codeLmodIds]
      };
    });
  }

  submit(): void {
    const formData = this.wasteflowForm.value;
    this.wasteflowForm.markAsDirty();
    this.wasteflowForm.markAsTouched();
    this.wasteflowForm.markAllAsTouched();

    if (this.wasteflowForm.invalid) {
      return;
    }

    this.wasteflowService.postPreMassEditWasteflows().subscribe(
      (response) => {
        this.displayConfirmButton = true;
        const wasteflowCount = this.count;

        const dialogRef = this.dialog.open(WasteflowMassEditConfirmDialogComponent, {
          autoFocus: false,
          disableClose: true,
          panelClass: ['form-dialog'],
          data: { formData, wasteflowCount, dialogRef: this.dialogRef }
        });

      },
      (error) => {
        if (error.status === 400) {
          this.displayConfirmButton = false;
        } else {
          this.displayConfirmButton = false;
        }
      }
    );
  }

  /**
   * Reset filtered units if the lmod input is empty.
   */
  lmodInputChange(): void {
    if (isEmpty(this.f?.codeLmod?.value)) {
      this.filterUnits();
    }
  }

  /**
   * Filter units from specified lmod code.
   */
  filterUnits(): void {
    this.filteredUnits = this.unitService.filterUnits(this.f?.codeLmod?.value?.['@id'], this.units);
  }

  /**
   * Filter code values on type from specified codes.
   */
  private _validatorFilter(validators: OrganisationSimple[], value: any): OrganisationSimple[] {
    const filterValue = value?.name ? value.name.toLowerCase() : value?.toLowerCase();
    return validators?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /**
   * Add validators to period in case inventory and not yearly period
   */
  private verifyValidityOfPeriod(): void {
    const periodFrom = new Date(this.f.periodFrom.value);
    const periodTo = new Date(this.f.periodTo.value);

    // If inventory, check that dates correspond to a yearly period (01.01.20xx - 31.12.20xx)
    if (
      this.f.inventory.value !== StatusInventory.PRIVATE &&
      (periodFrom.getMonth() !== 0 ||
        periodFrom.getDate() !== 1 ||
        periodTo.getDate() !== 31 ||
        periodTo.getMonth() !== 11 ||
        periodTo.getFullYear() !== periodFrom.getFullYear())
    ) {
      this.f.periodFrom.setErrors({ incorrect: true });
      return;
    } else {
      setTimeout(() => {
        this.f.periodFrom.clearValidators();
        this.f.periodFrom.updateValueAndValidity();
      }, 0);
    }
  }

  /**
   * Get list of treatment types for Treatment type/Collecte field on wasteflow
   */
  private getTreatmentTypes(): void {
    this.codeService.getTreatmentTypes().subscribe(treatmentTypes => {
      treatmentTypes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.provenanceCodes = treatmentTypes;
      this.treatments = treatmentTypes;

      this.filteredProvenanceCodes = this.f.provenanceCode.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.provenanceCodes, val))
      );

      this.filteredTreatments = this.f.treatment.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.treatments, val))
      );
    });
  }

  /**
   * Get list of LMod codes for LMod field on wasteflow
   */
  private getLmodCodes(): void {
    this.codeService.getLmod().subscribe(lmodCodes => {
      lmodCodes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.lmodCodes = lmodCodes;

      this.filteredLmodCodes = this.f.codeLmod.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.lmodCodes, val))
      );

      if (this.units) {
        this.filterUnits();
      }
    });
  }

  /**
   * Get list of validators field on wasteflow
   */
  private getValidators(): void {
    let counter = 0;
    this.organisationService.getOrganisations(OrganisationType.GESDEC).subscribe(organisations => {
      counter++;
      this.validators.push(...organisations['hydra:member']);

      if (counter === 2) {
        this.setCurrentOrganisationAsDefaultValidator();
      }
    });

    this.organisationService.getOrganisations(OrganisationType.COMMUNE).subscribe(organisations => {
      counter++;
      this.validators.push(...organisations['hydra:member']);

      if (counter === 2) {
        this.setCurrentOrganisationAsDefaultValidator();
      }
    });
  }

  /**
   * Set current user organisation as data validator
   */
  private setCurrentOrganisationAsDefaultValidator(): void {
    if (this.validators.findIndex(v => v['@id'] === this.userOrganisation['@id']) === -1) {
      this.validators.push(this.userOrganisation);
    }

    this.filteredValidators = this.f.validator.valueChanges.pipe(
      startWith(''),
      map(val => this._validatorFilter(this.validators, val))
    );

  }

  private getSites(organisationId?: number, organisationType?: string, status?: string): void {
    this.siteService.getSites(organisationId, organisationType, status).subscribe(sites => {
      this.sites = sites;
    });
  }

  /**
   * Get provenance and destination sites for provenanceSite and destinationSite fields
   */
  private getProvenanceAndDestinationSites(): void {
    let customParams;
    if (this.userOrganisation.type === OrganisationType.GESDEC) {
      customParams = [{ name: 'siteStatus' }];
    } else {
      customParams = [{ name: 'siteStatusOrg', value: this.userOrganisation.id }];
    }
    this.siteService.getSites(null, null, null, customParams).subscribe(sites => {
      this.provenanceSites = sites;
      this.destinationSites = sites;

    });
  }

  /**
   * Get list of units for Quantity unit field on wasteflow
   */
  private getOriginUnits(): void {
    this.unitService.getUnits().subscribe(units => {
      this.units = units;
      this.filterUnits();
    });
  }

  /**
   * Subscribe the desired form controls.
   * @private
   */
  private subscribeFormControls(): void {
    this.f.periodTo.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.periodFrom.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.inventory.valueChanges.subscribe(() => {
      if (this.f.periodFrom.value && this.f.periodTo.value) {
        this.verifyValidityOfPeriod();
      }
    });

  }
}
