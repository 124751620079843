import { Authorization } from './authorization.model';
import { Code } from './code.model';
import { Organisation } from './organisation.model';
import { PreClosureProgressBar } from './wasteflow.model';

export interface SiteSimple {
  id: number;
  name: string;
}

export interface Site {
  id: number;
  name: string;
  country: string;
  canton: string;
  postalCode: string;
  idpadr: string;
  status: SiteStatus;
  street1: string;
  street2: string;
  town: string;
  codeSite: Code;
  organisation: Organisation;
  activeAuthorizations?: SiteAuthorization[];
  operatingAuthorizations: SiteAuthorization[];
  detailedOperatingAuthorizations?: Authorization[];
  authorizationMessage?: string;
  codeNoga: Code;
  autocompleteDisplayValue?: string;
  siteLabel?: string;
  chIdNumber?: string;
  isSpecialCategorySite?: boolean;
}

export interface SiteAuthorization {
  '@id': string;
  '@type': string;
  authorizationNumber: string;
  id: number;
  identificationNumber: string;
}

export interface SiteFlow {
  alert: boolean;
  percent_variation_1: number;
  percent_variation_2: number;
  site_id: number;
  site_name: string;
  progressBar: PreClosureProgressBar;
  wasteflow_count_destination: number;
  wasteflow_count_provenance: number;
  wasteflow_qty_destination: number;
  wasteflow_qty_provenance: number;
}

export enum SiteStatus {
  PUBLIC = 'public',
  PRIVATE = 'private',
  INVENTORY = 'inventory'
}

export class FusionResponse {
  error: Array<string>;
  infos: Array<string>;
  status: string;
}
