<div class="gdc-spinner-container">
  <div class="gdc-spinner">
    <div class="gdc-spinner-dot"></div>
    <div class="gdc-spinner-dot"></div>
    <div class="gdc-spinner-dot"></div>
    <div class="gdc-spinner-dot"></div>
    <div class="gdc-spinner-dot"></div>
    <div class="gdc-spinner-dot"></div>
  </div>
</div>
