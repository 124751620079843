import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/model/user.model';
import { ImpersonationService } from 'src/app/shared/services/impersonation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ConfirmImpersonationDialogComponent } from './confirm-impersonation-dialog/confirm-impersonation-dialog.component';

@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent implements OnInit, OnDestroy {
  public data: Array<User> = [];
  public displayedColumns: string[] = ['commune', 'prenom', 'nom', 'role', 'action'];
  private dialog$: Subscription;

  constructor(
    private impersonationService: ImpersonationService,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // Get list of users to impersonate
    this.impersonationService.getUsers().subscribe(data => {
      this.data = data;
      this.data.forEach(user => {
        if (user.userOrganisations[0] && user.userOrganisations[0].organisation) {
          user.commune = user.userOrganisations[0].organisation.name;
        } else {
          user.commune = undefined;
        }
      });

      // Sort by commune name by default
      this.sortData({ active: 'commune', direction: 'asc' });
    });
  }

  /**
   * Sort table data
   */
  public sortData(sort: Sort): void {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'commune':
          return this.compare(a.commune, b.commune, isAsc);
        case 'prenom':
          return this.compare(a.firstname, b.firstname, isAsc);
        case 'nom':
          return this.compare(a.lastname, b.lastname, isAsc);
        case 'role':
          return this.compare(a.userOrganisations[0].role, b.userOrganisations[0].role, isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Open dialog to confirm impersonation
   * @param user - User to impersonate to
   */
  public impersonate(user: User): void {
    const dialogRef = this.dialog.open(ConfirmImpersonationDialogComponent, {
      disableClose: true,
      data: {
        user,
        backToProfile: false
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldImpersonate => {
      if (shouldImpersonate) {
        // Impersonation replaces current user token with the impersonated one
        this.impersonationService.impersonate(user.id).subscribe(response => {
          this.replaceToken(response.token);
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Replace token in the local storage to fake being someone else
   * @param token Token to save in local storage
   */
  private replaceToken(token: string): void {
    if (!localStorage.getItem('originToken')) {
      localStorage.setItem('originToken', localStorage.getItem('token'));
    }

    localStorage.setItem('token', token);

    this.userService.getCurrent().subscribe(user => {
      if (!localStorage.getItem('originUser')) {
        localStorage.setItem('originUser', localStorage.getItem('user'));
      }

      if (!localStorage.getItem('originOrganisation')) {
        localStorage.setItem('originOrganisation', localStorage.getItem('organisation'));
      }

      localStorage.setItem('user', JSON.stringify(user));

      if (user.userOrganisations.length && user.userOrganisations[0].organisation) {
        localStorage.setItem('organisation', JSON.stringify(user.userOrganisations[0].organisation));
      }

      // Redirect user to dashboard
      window.location.href = `${window.location.protocol}//${window.location.host}/gestion/dashboard`;
    });
  }
}
