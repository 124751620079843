<div class="registration">
  <app-half-page-logo></app-half-page-logo>
  <div class="registration__content">
    <h1>Bienvenue</h1>
    <form (ngSubmit)="submit()" [formGroup]="loginForm" autocomplete="current-password" class="registration__form">
      <div *ngIf="isFromChangePassword" class="text-green text-sm mb-3">
        Votre mot de passe a été modifié avec succès. Merci de vous reconnecter.
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input formControlName="email" id="email" matInput placeholder="email@email.com" />
        <mat-error *ngIf="submitted && f.email.errors && f.email.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Mot de passe</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          id="password"
          matInput
          placeholder="Mot de passe"
        />
        <mat-icon (click)="showPassword = !showPassword" class="material-icons-round" matSuffix>{{
          showPassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error *ngIf="submitted && f.password.errors && f.password.errors.required">
          Ce champ est obligatoire.
        </mat-error>
      </mat-form-field>
      <div *ngIf="errorMessage" class="text-orange text-sm mb-3">{{ errorMessage }}</div>
      <button class="btn" type="submit">Se connecter</button>
      <a [routerLink]="'/forgotten-password'" class="pt-3">1ère connexion ou mot-de-passe oublié ?</a>
    </form>
    <app-registration-legend></app-registration-legend>
  </div>
</div>
