/**
 * The store which contains the state of the application.
 */
export interface AppState {
  selected_year: number;
}

/**
 * The initial state associated to the App state.
 */
export const initial: AppState = {
  selected_year: null
};
