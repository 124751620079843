<h1 mat-dialog-title>Rejeter la prestation</h1>
<mat-dialog-content>
  <div class="prestation-dialog__content">
    Faut-il bien rejeter cette prestation ?
    <span *ngIf="wasteflows && wasteflows.length"> Les données suivantes ont été soumises par ce prestataire:</span>
    <div *ngIf="wasteflows && wasteflows.length" class="mt-4">
      <mat-table [dataSource]="wasteflows">
        <ng-container matColumnDef="periode">
          <mat-header-cell *matHeaderCellDef>Période</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.flowPeriod }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="codeLmod">
          <mat-header-cell *matHeaderCellDef>Code LMod</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.codeLmod.code }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="libelleLmod">
          <mat-header-cell *matHeaderCellDef>Libellé LMod</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.codeLmod.label">
              {{ element.codeLmod.label }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="origine">
          <mat-header-cell *matHeaderCellDef>Origine</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.provenanceSite.name">
              {{ element.provenanceSite.name }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef>Destination</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.destinationSite.name">
              {{ element.destinationSite.name }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="traitement">
          <mat-header-cell *matHeaderCellDef>Traitement</mat-header-cell>
          <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
            <div matTooltipPosition="above" [matTooltip]="element.treatment.label">
              {{ element.treatment.label }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantite">
          <mat-header-cell *matHeaderCellDef>Quantité d'origine</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            {{ element.originQty }}
            {{ element.originUnit.name }}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button [mat-dialog-close]="true" class="btn" mat-button>Rejeter</button>
</mat-dialog-actions>
