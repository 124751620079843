import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appFormatIdGeInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormatInputIdGeDirective,
      multi: true
    }
  ]
})
export class FormatInputIdGeDirective implements ControlValueAccessor {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.formatValue(value);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    this.onChange(formattedValue);
  }

  private formatValue(value: string): string {
    return value.replace(/(\d{2})(\d{3})/, '$1-$2');
  }

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
