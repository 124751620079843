import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeclarationsStatus, DeclarationStatusElement } from 'src/app/shared/model/dashboard.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';

@Component({
  selector: 'app-declarations-status',
  templateUrl: './declarations-status.component.html',
  styleUrls: ['./declarations-status.component.scss']
})
export class DeclarationsStatusComponent implements OnInit, OnDestroy {
  public data: DeclarationsStatus;
  public dataSorted: Array<DeclarationStatusElement> = [];
  private organisation: Organisation;
  private organisationType: OrganisationType;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$)
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Get declarations status data
   */
  private getData(): void {
    this.dashboardService
      .getWasteflowStatus(this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id)
      .subscribe(data => {
        this.setData(data);
      });
  }

  /**
   * Set up the declarations status data
   * @param data - Data to set up
   */
  private setData(data: DeclarationsStatus): void {
    this.data = data;
    this.dataSorted = Object.values(data);
    this.sortData();
  }

  /**
   * Sort data by status
   * Order depends on organisation type
   */
  private sortData(): void {
    // Sorting for GESDEC or Commune
    let sortedBy = {
      new: 0,
      to_validate: 1,
      to_fix: 2,
      valid: 3
    };

    // Sorting for prestataire
    if (this.organisationType === OrganisationType.SERVICEPROVIDER) {
      sortedBy = {
        to_fix: 0,
        to_validate: 1,
        new: 2,
        valid: 3
      };
    }

    this.dataSorted = this.dataSorted.sort((a, b) => sortedBy[a.code] - sortedBy[b.code]);
  }
}
