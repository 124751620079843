import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code, CodeByCodeType } from 'src/app/shared/model/code.model';

@Component({
  selector: 'app-base-code-field',
  templateUrl: './base-code-field.component.html',
  styleUrls: ['./base-code-field.component.scss']
})
export class BaseCodeFieldComponent implements OnInit, OnDestroy {
  @Input() element: CodeByCodeType;
  @Output() updateField = new EventEmitter<Code>();
  @Input() options: Code[];

  filteredOptions: Observable<Code[]>;
  baseCodeControl = new FormControl();
  private baseCodeControl$: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.baseCodeControl.setValue(this.options.find(option => option.id === this.element.id_base_code));
    this.filteredOptions = this.baseCodeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.baseCodeControl$ = this.baseCodeControl.valueChanges.subscribe(value => {
      if (value && value.code) {
        this.updateField.emit(value);
      }
    });
  }

  /**
   * Display value of the field
   * @param value - Value of the field
   */
  public displayFn(value: Code): string {
    return value ? value.code : '';
  }

  /**
   * Filter autocomplete responses based on what the user has typed
   * @param value - Value typed
   */
  private _filter(value: any): Code[] {
    const filterValue = value && value.code ? value.code.toLowerCase() : value.toLowerCase();
    return this.options.filter(option =>
      `${option.code} ${option.label}`.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  ngOnDestroy(): void {
    this.baseCodeControl$.unsubscribe();
  }
}
