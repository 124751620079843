import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { Authorization, AuthorizationEdit } from '../model/authorization.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationsService {
  private dataSubject = new Subject<any>();
  constructor(private readonly http: HttpClient) {}

  data$ = this.dataSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  /**
   * Get list of all Authorizations, no pagination site[ASC]=marcherche || site=id du site
   */
  getAuthorisations(id?: any, authorisationsId?: any, searchTerm?: string, siteId?: any): Observable<HyDraMemberResponse<Authorization[]>> {
    let url = `/operating_authorizations?pagination=false`;

    if (id) {
      url += `&organisation=${id}`;
    }

    if (searchTerm) {
      url += `&simplesearch=${searchTerm}`;
    }

    if (siteId) {
      url += `&site=${siteId}`;
    }

    if (authorisationsId) {
      url += `&site=${authorisationsId}`;
    }

    return this.http.get<HyDraMemberResponse<Authorization[]>>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Update Authorization
   */
  public updateAuthorisations(id: number, values: AuthorizationEdit): Observable<{status: string}> {
    const requestBody = { ...values, id };
    return this.http.post<any>(`${environment.apiUrl}/post_operating_authorization`, requestBody, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      catchError(this.handleError)
    );
  }

   /**
   * Get list of Authorizations
   */
   public getAuthorisationsSearch(term?: string): Observable<HyDraMemberResponse<any[]>> {
    let url = `/operating_authorizations?pagination=false`;

    if (term) {
      url = `${url}&simplesearch=${term}`;
    }

    return this.http.get<HyDraMemberResponse<any[]>>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Post Authorization
   */
  public postAuthorisations(values: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/post_operating_authorization`, values, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error.error['hydra:description']);
    return throwError(error.error['hydra:description'] || "L'opération a échoué, aucune donnée n'a été modifiée.");
  }
}
