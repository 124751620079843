import { Pipe, PipeTransform } from '@angular/core';
import { StatusValidation } from '../model/import-event.model';
import { OrganisationType } from '../model/organisation.model';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {
  transform(status: StatusValidation, organisationType: OrganisationType, validator?: OrganisationType): string {
    switch (status) {
      case StatusValidation.NEW:
      case StatusValidation.TOVALIDATE:
        if (
          organisationType === OrganisationType.COMMUNE ||
          (organisationType === OrganisationType.GESDEC &&
            ((validator && validator === OrganisationType.GESDEC) || !validator))
        ) {
          return 'status--orange';
        } else if (
          organisationType === OrganisationType.SERVICEPROVIDER ||
          (organisationType === OrganisationType.GESDEC && validator && validator !== OrganisationType.GESDEC)
        ) {
          return 'status--red';
        }
        break;
      case StatusValidation.TOFIX:
        if (organisationType === OrganisationType.COMMUNE || organisationType === OrganisationType.GESDEC) {
          return 'status--red';
        } else if (organisationType === OrganisationType.SERVICEPROVIDER) {
          return 'status--orange';
        }
        break;
      case StatusValidation.VALID:
        if (organisationType === OrganisationType.GESDEC) {
          return 'status--orange';
        } else if (
          organisationType === OrganisationType.SERVICEPROVIDER ||
          organisationType === OrganisationType.COMMUNE
        ) {
          return 'status--green';
        }
        break;
      case StatusValidation.PRECLOSURE:
        return 'status--green';
    }
    // Return null to display gray color if there is no matches.
    return null;
  }
}
