import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {AtlasElement, PrestataireCanton, PrestataireCountry} from '../../model/organisation.model';
import { OrganisationService } from '../../services/organisation.service';
import {cantonsList, countriesList} from 'src/app/shared/data';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() addressForm: FormGroup;
  @Input() idpadr?: string;
  @Input() siteCodes?: any;
  @Input() required = true;
  @Output() fullAddressChange = new EventEmitter<AtlasElement>();
  public isSpecialCategorySiteValue = null;
  public isSearching = false;
  public options: Array<AtlasElement> = [];
  public countries: Array<PrestataireCountry> = countriesList;
  public cantons: Array<PrestataireCanton> = cantonsList;
  private fullAddress: AtlasElement;
  private isGenevaChange$: Subscription;
  private streetChange$: Subscription;
  private codeSiteChange$: Subscription;

  constructor(private organisationService: OrganisationService, public cd: ChangeDetectorRef) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.addressForm.controls;
  }

  ngOnInit(): void {
    const specialCategoryControl = this.addressForm.get('isSpecialCategorySite');
    if(specialCategoryControl) {
      if(this.addressForm.controls.isSpecialCategorySite.value === true) {
        this.isSpecialCategorySiteValue = true;
      }
    }
    if (this.addressForm.controls.isGeneva.value === 0 && this.idpadr) {
      this.isSearching = true;
      this.organisationService.getGenevaAddresses(this.addressForm.controls.street1.value, null).subscribe(
        addresses => {
          this.isSearching = false;
          this.fullAddress = addresses.find(address => address.idpadr.toString() === this.idpadr);
          this.fullAddressChange.emit(this.fullAddress);
        },
        () => {
          this.isSearching = false;
        }
      );
    }

    /*
    0:Adresse sur Genève (old true)
    1:Adresse en Suisse (old false)
    2:Adresse à l'étranger
     */
    const regionOption = this.addressForm.controls.isGeneva.value;
    this.checkRegion(regionOption);

    this.isGenevaChange$ = this.addressForm.get('isGeneva').valueChanges.subscribe(isGva => {
      this.checkRegion(isGva);
    });

    this.streetChange$ = this.addressForm.get('street1').valueChanges.subscribe(value => {
      if (this.f.isGeneva.value === 0 && value.length >= 3) {
        this.isSearching = true;
        this.organisationService.getGenevaAddresses(value, 5).subscribe(
          response => {
            this.isSearching = false;
            this.options = response;
          },
          () => {
            this.isSearching = false;
          }
        );

        if (this.options) {
          this.fullAddress = this.options.find(option => option.adresse.adresse === value);
          this.fullAddressChange.emit(this.fullAddress);
        }
      } else {
        // Remove all options if is not geneva
        this.options = null;
      }
    });

    const codeSiteControl = this.addressForm.get('codeSite');
    if(codeSiteControl) {
      this.codeSiteChange$ = codeSiteControl.valueChanges.subscribe(value => {
        if(value) {
          const obj = this.siteCodes.find(option => option['@id'] === value);
          this.isSpecialCategorySiteValue = obj ? obj.isSpecialCategorySite : false;
          if(!this.isSpecialCategorySiteValue) {
            this.addressForm.controls.chIdNumber.setValue("");
            this.isSpecialCategorySiteValue = false;
          } else {
            this.addressForm.controls.chIdNumber.value;
            this.isSpecialCategorySiteValue = true;
          }
        }
      });
    }
  }

  private checkRegion(regionCode: number) {
    if (regionCode === 0) {
      this.options = null;

      this.addressForm.controls.street2.disable();
      this.addressForm.controls.postalCode.disable();
      this.addressForm.controls.town.disable();
      this.addressForm.controls.country.disable();
      this.addressForm.controls.canton.disable();
      if (this.required) {
        if (this.addressForm.controls.chIdNumber) {
          this.addressForm.controls.chIdNumber.updateValueAndValidity();
        }
      }
    } else if (regionCode === 1) {
      this.fullAddress = null;
      this.fullAddressChange.emit(this.fullAddress);
      this.addressForm.controls.street2.enable();
      this.addressForm.controls.postalCode.enable();
      this.addressForm.controls.town.enable();
      this.addressForm.controls.canton.enable();
      if (this.addressForm.controls.chIdNumber) {
        this.addressForm.controls.chIdNumber.clearValidators();
        this.addressForm.controls.chIdNumber.updateValueAndValidity();
      }
      this.addressForm.controls.street2.updateValueAndValidity();
      this.addressForm.controls.postalCode.updateValueAndValidity();
      this.addressForm.controls.town.updateValueAndValidity();
      this.addressForm.controls.canton.updateValueAndValidity();
    } else if (regionCode === 2) {
      this.fullAddress = null;
      this.fullAddressChange.emit(this.fullAddress);
      this.addressForm.controls.street2.enable();
      this.addressForm.controls.postalCode.enable();
      this.addressForm.controls.town.enable();
      this.addressForm.controls.country.enable();
      this.addressForm.controls.canton.enable();
      this.addressForm.controls.street2.updateValueAndValidity();
      this.addressForm.controls.postalCode.updateValueAndValidity();
      this.addressForm.controls.town.updateValueAndValidity();
      this.addressForm.controls.country.updateValueAndValidity();
    }
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.streetChange$.unsubscribe();
    this.isGenevaChange$.unsubscribe();
    if(this.codeSiteChange$) {
      this.codeSiteChange$.unsubscribe();
    }
  }
}
