import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get back-end version
   */
  getVersion(): Observable<{back: string}> {
    return this.http.get<{back: string}>(`${environment.backendUrl}/version`);
  }

}
