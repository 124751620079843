import { Component, Input } from '@angular/core';
import { WasteflowHistoryData } from 'src/app/shared/model/wasteflow.model';

@Component({
  selector: 'app-history-line',
  templateUrl: './history-line.component.html',
  styleUrls: ['./history-line.component.scss']
})
export class HistoryLineComponent {
  @Input() value: WasteflowHistoryData;
  @Input() isOrigin = false;
  @Input() isCurrent = false;

  public statusLabel: string;
}
