import { AppState, initial } from '../states/app.state';
import { AppActions } from '../actions/app.actions';

export function appReducer(state = initial, action: AppActions.Actions): AppState {
  switch (action.type) {
    case AppActions.ActionTypes.SET_SELECTED_YEAR: {
      return {
        ...state,
        selected_year: action.payload.selected_year
      };
    }
    default : { return state; }
  }
}
