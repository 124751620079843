<h1>Gestion de mon organisation</h1>
<div *ngIf="organisationId" class="organisation">
  <div class="row">
    <div class="col-6 mb-4">
      <div class="organisation__section">
        <app-organisation-details></app-organisation-details>
      </div>
    </div>
    <div class="col-6 mb-4">
      <div class="organisation__section">
        <app-associated-datas></app-associated-datas>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="organisation__section">
        <app-users></app-users>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="organisation__section">
        <app-sites></app-sites>
      </div>
    </div>
  </div>
</div>
