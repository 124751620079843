import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIResponse, HyDraMemberResponse } from '../model/api.model';
import { ExportFile, Wasteflow, WasteflowHistory, WasteflowSorting, WasteFlowStatus } from '../model/wasteflow.model';
import { Code } from '../model/code.model';
import { Site } from '../model/site.model';
import { BaseService } from './base.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';

@Injectable({
  providedIn: 'root'
})
export class WasteflowService extends BaseService {
  filters: { [k: string]: any } = {};
  organisation: number;
  constructor(private readonly http: HttpClient, store$: Store<AppState>) {
    super(store$);
  }

  /**
   * Get wasteflow statuses
   */
  public getWasteflowStatus(): Observable<WasteFlowStatus[]> {
    return this.http
      .get<HyDraMemberResponse<WasteFlowStatus[]>>(`${environment.apiUrl}/waste_flow_statuses`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get waste flows
   */
  public getWasteflows(
    organisationId?: number,
    importEventId?: number,
    sort?: WasteflowSorting[],
    codeLmod?: number[],
    provenanceSite?: number,
    destinationSite?: number,
    status?: string,
    extra?: string,
    page = 1,
    wasteflowIds: string[] = []
  ): Observable<HyDraMemberResponse<Wasteflow[]>> {
    let url = `/waste_flows?page=${page + 1}${this.getYearPeriodParam(false)}`;
    let lmod = [];
    let provenance = [];
    let destination = [];
    let statut = [];

    if (sort && sort.length) {
      sort.forEach(s => {
        if (s.sort && s.dir) {
          if(s.sort === "importEventFrontIdOrder") {
            url += `&${s.sort}=${s.dir.toUpperCase()}`;
          } else {
            url += `&order[${s.sort}]=${s.dir.toUpperCase()}`;
          }
        }
      });
    }

    if (wasteflowIds) {
      if (typeof wasteflowIds === 'string') {
        url += `&id[]=${wasteflowIds}`;
      } else if (wasteflowIds.length > 0) {
        wasteflowIds.forEach(wid => {
          url += `&id[]=${wid}`;
        });
      }
    }

    if (extra) {
      url += `&${extra}`;
    }
    if (organisationId) {
      url += `&organisation=${organisationId}`;
    }
    if (importEventId) {
      url += `&importEvent=${importEventId}`;
    }
    if (codeLmod.length) {
      codeLmod.forEach(code => {
        url += `&codeLmod[]=${code}`;
        lmod.push(code);
      });
    }
    if (provenanceSite) {
      url += `&provenanceSite=${provenanceSite}`;
      provenance.push(provenanceSite)
    }
    if (destinationSite) {
      url += `&destinationSite=${destinationSite}`;
      destination.push(destinationSite)
    }
    if (status) {
      url += `&wasteFlowStatus.code=${status}`;
      statut.push(status)
    }

    this.filters.filterCodeLmodIds = lmod;
    this.filters.filterProvenanceSiteId = Number(provenance.join());
    this.filters.filterDestinationSiteId = Number(destination.join());
    this.filters.filterStatus = statut.join();
    this.organisation = organisationId;

    return this.http.get<HyDraMemberResponse<Wasteflow[]>>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get waste flow history
   */
  public getWasteflowHistory(wasteflowId: number): Observable<WasteflowHistory> {
    return this.http
      .get<HyDraMemberResponse<WasteflowHistory>>(
        `${environment.apiUrl}/wasteflowhistory/get_waste_flow_history?wasteFlow=${wasteflowId}`,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(map((data: any) => data.wasteFlowHistory));
  }

  /**
   * Get wasteflows filter
   */
  public getWasteflowsFilter(filter: string, organisation?: number, wasteflow?: number): Observable<Array<any>> {
    let url = `/filter/waste_flow/${filter}`;
    if (organisation) {
      url += `?organisation=${organisation}`;
    }
    if (wasteflow) {
      url += `${organisation ? `&` : `?`}importEvent=${wasteflow}`;
    }
    return this.http.get<Array<any>>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Update wasteflow
   */
  public updateWasteflow(
    wasteflowId: number,
    changes: {
      [k: string]: any;
    }
  ): Observable<Wasteflow> {
    return this.http.patch<any>(`${environment.apiUrl}/waste_flows/${wasteflowId}`, changes, {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    });
  }

  /**
   * Export wasteflows datas
   */
  public exportWasteflows(
    provenanceSite: number,
    destinationSite: number,
    codeLmod: number[],
    importEvent: number,
    selectedYear: number
  ): Observable<ExportFile> {
    const filters: { [k: string]: any } = {};

    if (provenanceSite) {
      filters.filterProvenanceSite = provenanceSite;
    }

    if (destinationSite) {
      filters.filterDestinationSite = destinationSite;
    }

    if (codeLmod) {
      filters.filterCodeLmod = codeLmod;
    }

    if (importEvent) {
      filters.filterImportEvent = importEvent;
    }

    if (selectedYear) {
      filters.filterYear = selectedYear;
    }

    return this.http.post<any>(`${environment.apiUrl}/post_waste_flow_export${this.getYearPeriodParam()}`, filters, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Post pre mass wasteflows edit
   */
  public postPreMassEditWasteflows(details?: any): Observable<any> {

    const filterLmodObject = {
      ...this.filters,  // Spread the existing properties
      "filterFlowPeriodFrom": this.getYearPeriodParamFrom(),
      "filterFlowPeriodTo": this.getYearPeriodParamTo(),
      "filterOrganisationId": this.organisation
    };

    return this.http.post<any>(`${environment.apiUrl}/post_waste_flow_pre_mass_edit`, filterLmodObject, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Post mass wasteflows edit
   */
  public postMassEditWasteflows(values: any): Observable<any> {

    const filterLmodObject = {
      ...values,  // Spread the existing properties
      "filterFlowPeriodFrom": this.getYearPeriodParamFrom(),
      "filterFlowPeriodTo": this.getYearPeriodParamTo(),
      "filterOrganisationId": this.organisation
    };

    return this.http.post<any>(`${environment.apiUrl}/post_waste_flow_mass_edit`, filterLmodObject, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error.error['hydra:description']);
    return throwError(error.error['hydra:description'] || "L'opération a échoué, aucune donnée n'a été modifiée.");
  }

  /**
   * Post waste flow ventilation
   * @param oldWasteflowId - Old waste flow ID
   * @param newWasteflows - New waste flows
   */
  public postWasteFlowDataBreakdown(oldWasteflowId: number, newWasteflows: any[]): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/post_waste_flow_data_breakdown`, { oldWasteflowId, newWasteflows })
      .pipe(
        tap((response: APIResponse) => response.success),
        mapTo(true),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  /**
   * Check if waste flow is a doublon
   * @param wasteflowId - wasteflow to check
   */
  public getWasteFlowIsDedoublonned(wasteflowId: number): Observable<boolean> {
    return this.http
      .get<any>(`${environment.apiUrl}/get_waste_flow_is_dedoublonned?id=${wasteflowId}`)
      .pipe(map(isDedoublonned => isDedoublonned.status));
  }

  /**
   * Compare to sort filters
   */
  public compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Material displayWith callback for entity autocomplete.
   */
  public displayNameFn(entity: any): string {
    return entity ? entity?.name : '';
  }

  /**
   * Material displayWith callback for entity autocomplete.
   */
  public displayLabelFn(entity: any, key: string): string {
    return entity ? entity?.label : '';
  }

  /**
   * Material displayWith callback for codes autocomplete.
   */
  public displayCodeFn(code: Code): string {
    return code ? code?.displayLabel : '';
  }

  /**
   * Filter code values on type from specified codes.
   * @param codes - Codes typed
   * @param value - Value typed
   */
  public _codeFilter(codes: Code[], value: any): Code[] {
    const filterValue = value?.code ? value.code.toLowerCase() : value?.toLowerCase();
    return codes?.filter(option => option.displayLabel.toLowerCase().includes(filterValue));
  }

  /**
   * Filter values on type from specified codes.
   * @param sites - Sites typed
   * @param value - Value typed
   * @param key - the key to filter in
   */
  public _entityFilter(elements: any[], value: any, key: string = 'name'): any[] {
    const filterValue = value?.[key] ? value?.[key].toLowerCase() : value?.toLowerCase();
    return elements?.filter(option => option?.[key].toLowerCase().includes(filterValue));
  }

  /**
   * Filter site values on type from specified codes.
   * @param sites - Sites typed
   * @param value - Value typed
   */
  public _siteFilter(sites: Site[], value: any): Site[] {
    const filterValue = value && value.name ? value.name.toLowerCase() : value?.toLowerCase();
    return sites?.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
