import { Pipe, PipeTransform } from '@angular/core';
import { SiteStatus } from '../model/site.model';

@Pipe({
  name: 'siteType'
})
export class SiteTypePipe implements PipeTransform {
  transform(status: SiteStatus): string {
    switch (status) {
      case SiteStatus.PUBLIC:
        return 'Public';
      case SiteStatus.PRIVATE:
        return 'Privé';
      case SiteStatus.INVENTORY:
        return 'Inventaire';
    }
  }
}
