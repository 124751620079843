import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { WasteFlowGeneric } from 'src/app/shared/model/dashboard.model';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-graph-waste-type-rate',
  templateUrl: './graph-waste-type-rate.component.html',
  styleUrls: ['./graph-waste-type-rate.component.scss']
})
export class GraphWasteTypeRateComponent implements OnInit, OnDestroy {

  public data: Array<any> = [];
  public view: any[] = [170, 170];
  public colorScheme = {
    domain: []
  };
  private organisation: Organisation;
  private selectedYear$: Subscription;

  constructor(private dashboardService: DashboardService, public decimalPipe: DecimalPipe, private readonly store$: Store<AppState>) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.selectedYear$ = this.store$.select(getSelectedYear$)
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Format value for tooltip
   * @param value - Value to format
   */
  public formatValue(value: number): string {
    return this.decimalPipe.transform(value, '', 'fr').replace(/\s/g, ' ');
  }

  /**
   * Get waste type rate data
   */
  private getData(): void {
    this.dashboardService
      .getWasteFlowGeneric(
        'GESDEC',
        this.organisation.type === OrganisationType.GESDEC ? null : this.organisation.id,
        false,
        true
      )
      .subscribe(data => {
        this.handleData(data);
      });
  }

  /**
   * Handle waste type rate data
   * @param data - Waste type rate data
   */
  private handleData(data: WasteFlowGeneric): void {
    //Reset Data;
    this.data = [];
    this.colorScheme.domain = [];
    if (data.data) {
      data.data.forEach(el => {
        this.data.push({
          name: el.aggregateLabel ?? '',
          value: el.value ?? '',
          percent: el.percent,
          color: el.aggregateColor
        });

        // Add colors to colorscheme of the graph
        this.colorScheme.domain.push(el.aggregateColor);
      });
    }
  }
}
