import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { AtlasElement, OrganisationFull, OrganisationSimple } from '../model/organisation.model';
import { FusionResponse } from '../model/site.model';
import { ExportFile } from '../model/wasteflow.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of prestataires
   */
  getPrestataires(organisationId: number): Observable<OrganisationSimple[]> {
    const params = 'pagination=false&groups[]=app.organisation.lite.get';
    return this.http
      .get<HyDraMemberResponse<OrganisationSimple[]>>(
        `${environment.apiUrl}/organisations${
          organisationId ? `?serviceprovider=${organisationId}&${params}` : `?${params}`
        }`,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Get list of all organisations, no pagination
   */
  getOrganisations(organisationType?: string): Observable<HyDraMemberResponse<OrganisationFull[]>> {
    let url = `/organisations?pagination=false`;

    if (organisationType) {
      url += `&type=${organisationType}`;
    }

    return this.http.get<HyDraMemberResponse<OrganisationFull[]>>(`${environment.apiUrl}${url}sites=Abb`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get list of organisations with pagination
   */
  getOrganisationsPaginated(page: number, term?: string): Observable<HyDraMemberResponse<OrganisationSimple[]>> {
    let url = `/organisations?order[name]=ASC&page=${page + 1}`;

    if (term) {
      url = `${url}&simplesearch=${term}`;
    }

    return this.http.get<HyDraMemberResponse<OrganisationSimple[]>>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get organisation details
   */
  getOrganisationDetails(organisationId: number): Observable<OrganisationFull> {
    return this.http.get<OrganisationFull>(`${environment.apiUrl}/organisations/${organisationId}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Download list of organisations
   */
  downloadOrganisations(): Observable<ExportFile> {
    return this.http.post<any>(`${environment.apiUrl}/post_organisation_export`, {});
  }

  /**
   * Fusion/Merge organisations
   */
  mergeOrganisations(sourceOrganisationId: number, destinationOrganisationId: number): Observable<FusionResponse> {
    return this.http.post<FusionResponse>(`${environment.apiUrl}/post_merge_organisation`, {
      sourceOrganisationId,
      destinationOrganisationId
    });
  }

  /**
   * Get list of geneva addresses
   */
  getGenevaAddresses(term: string, limit: number): Observable<Array<AtlasElement>> {
    return this.http.get<Array<AtlasElement>>(
      `https://atlas.eco21.ch/api/v2/adresses?term=${term}&includeGeometry=false&forceGeometryCacheUpdate=false&offset=0${
        limit ? 'limit=' + limit : ''
      }&groupByVoie=false`
    );
  }

  /**
   * Update organisation
   */
  updateOrganisation(organisationId: string, organisation): Observable<OrganisationFull> {
    return this.http.put<any>(`${environment.apiUrl}${organisationId.replace('/api', '')}`, organisation, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Create new organisation
   */
  createOrganisation(organisation): Observable<OrganisationFull> {
    return this.http.post<OrganisationFull>(`${environment.apiUrl}/organisations`, organisation, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Add prestataire
   */
  addPrestataire(prestataire: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/organisations`, prestataire, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
