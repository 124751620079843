<div class="impersonation">
  <div class="impersonation__top">
    <div>
      <h1>Liste des utilisateurs des communes</h1>
    </div>
  </div>
  <div *ngIf="data.length" class="content-tertiary">
    <mat-table
      (matSortChange)="sortData($event)"
      [dataSource]="data"
      class="impersonation__list-table impersonation__list-table--last"
      matSort
      matSortActive="commune"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="commune">
        <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="commune">Commune</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.commune ? element.commune : '-' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="prenom">
        <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="prenom">Prénom</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.firstname }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nom">
        <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="nom">Nom</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.lastname }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header="role">Rôle</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.userOrganisations">
            {{ element.userOrganisations[0].role | userType }}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button (click)="impersonate(element)" class="btn-link">Se connecter en tant que</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
