<h1 mat-dialog-title>
  {{ data.backToProfile ? 'Retour à mon profil' : 'Se connecter en tant que' }}
</h1>
<mat-dialog-content>
  <div *ngIf="!data.backToProfile" class="mb-4">
    Vous allez être connecté en tant que:<br />
    <div class="text-bold mt-1 text-primary">
      {{ data.user.commune ? data.user.commune + ' -' : '' }} {{ data.user.firstname }} {{ data.user.lastname }}.
    </div>
    <br /><br />
    Vous pourrez voir les mêmes informations que cet utilisateur et effectuer des actions à sa place.
  </div>
  <div *ngIf="data.backToProfile" class="mb-4">
    Vous allez maintenant vous déconnecter de ce profil et retourner sur le vôtre.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button [mat-dialog-close]="true" class="btn" mat-button>Valider</button>
</mat-dialog-actions>
