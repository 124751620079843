import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonationComponent } from './impersonation/impersonation.component';
import { SharedModule } from '../../shared/shared.module';
import { ConfirmImpersonationDialogComponent } from './impersonation/confirm-impersonation-dialog/confirm-impersonation-dialog.component';

@NgModule({
  declarations: [ImpersonationComponent, ConfirmImpersonationDialogComponent],
  imports: [CommonModule, SharedModule]
})
export class ImpersonationModule {}
