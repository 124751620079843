import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WasteflowsComponent } from './wasteflows.component';
import { WasteflowDialogComponent } from './wasteflow-dialog/wasteflow-dialog.component';
import { WasteflowMassEditDialogComponent } from './wasteflow-mass-edit-dialog/wasteflow-mass-edit-dialog.component';
import { WasteflowMassEditConfirmDialogComponent } from './wasteflow-mass-edit-confirm-dialog/wasteflow-mass-edit-confirm-dialog.component';
import { WasteflowVentilationDialogComponent } from './wasteflow-ventilation-dialog/wasteflow-ventilation-dialog.component';
import { PendingChangesGuard } from 'src/app/core/guards/component-can-deactivate.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { ExportWasteflowComponent } from './components/export-wasteflow/export-wasteflow.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { VentilationLmodFieldComponent } from './wasteflow-ventilation-dialog/ventilation-lmod-field/ventilation-lmod-field.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    WasteflowsComponent,
    WasteflowDialogComponent,
    WasteflowMassEditDialogComponent,
    WasteflowMassEditConfirmDialogComponent,
    WasteflowVentilationDialogComponent,
    ExportWasteflowComponent,
    VentilationLmodFieldComponent
  ],
  imports: [CommonModule, SharedModule, BrowserModule, AngularMultiSelectModule, MatCardModule],
  exports: [CommonModule, SharedModule, BrowserModule],
  providers: [PendingChangesGuard]
})
export class WasteflowsModule {}
