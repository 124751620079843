import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, Subscription, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code, Unit } from 'src/app/shared/model/code.model';
import {
  Organisation,
  OrganisationType
} from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { AuthorisationsService } from 'src/app/shared/services/authorisations.service';
import { CodeService } from 'src/app/shared/services/code.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UnitService } from 'src/app/shared/services/unit.service';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { isEmpty } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Authorization, AuthorizationEdit } from 'src/app/shared/model/authorization.model';

@Component({
  selector: 'app-authorisations-dialog',
  templateUrl: './authorisations-dialog.component.html',
  styleUrls: ['./authorisations-dialog.component.scss']
})
export class AuthorisationsDialogComponent implements OnInit, OnDestroy {
  private filteredLmodCodesSubject = new BehaviorSubject<any[]>([]);
  filteredLmodCodes$ = this.filteredLmodCodesSubject.asObservable();

  private filteredTreatmentsSubject = new BehaviorSubject<any[]>([]);
  filteredTreatments$ = this.filteredTreatmentsSubject.asObservable();

  private matchingAuth: any;
  public hasAuthorisations: boolean = false;
  public authorisationList: any;
  public submitted: boolean = false;
  private ngUnsubscribe = new Subject();
  public editStatut: Array<object>;
  public authorisationsForm: FormGroup;
  public site: any[] = [];
  public filteredSite$: Observable<Site[]>;
  public codeNogas: Code[] = [];
  public filteredCodeNogas: Observable<Code[]>;
  public currentOrganisation: Organisation;
  private isGenevaChanges$: Subscription;
  public operatingList: any[];
  lmodCodes: Code[] = [];
  filteredLmodCodes: Observable<Code[]>;
  units: Unit[];
  filteredUnits: Unit[];
  provenanceCodes: Code[];
  filteredProvenanceCodes: Observable<Code[]>;
  treatments: Code[];
  filteredTreatments: Observable<Code[]>;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {element: Authorization, authId?: number, type?: string},
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AuthorisationsDialogComponent>,
    private authorisationService: AuthorisationsService,
    private codeService: CodeService,
    private siteService: SiteService,
    public wasteflowService: WasteflowService,
    private unitService: UnitService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.currentOrganisation = JSON.parse(localStorage.getItem('organisation'));

    this.editStatut = [
      { label: 'Validée', code: 'validated' },
      { label: 'Rejetée', code: 'rejected' },
      { label: 'Nouvelle', code: 'draft' }
    ];

    // Create authorisation form
    this.authorisationsForm = this.fb.group({
      site: new FormControl({ value: null, disabled: false }, Validators.required),
      idGe: new FormControl({ value: '', disabled: false }, Validators.required),
      idCh: new FormControl({ value: '', disabled: false }),
      periodFrom: new FormControl({ value: null, disabled: false }, Validators.required),
      periodTo: new FormControl({ value: null, disabled: false }, Validators.required),
      statut: new FormControl({ value: null, disabled: false }, Validators.required),
      comment: new FormControl({ value: '', disabled: false }),
      items: this.fb.array([], [this.atLeastOneItemValidator()])
    });

    this.getTreatmentTypes();
    this.getLmodCodes();
    this.filterUnits();

    // Get all sites
    this.siteService.getSites().subscribe(siteList => {
      this.site = siteList;
      if (this.data) {
        const findSite = this.site.find(c => c['@id'] === this.data.element.site['@id'])
        this.f.site.setValue(this.site.find(c => c['@id'] === this.data.element.site['@id']));
        const authList = findSite.operatingAuthorizations;

        this.authorisationService.getAuthorisations(null, findSite.operatingAuthorizations).subscribe(response => {
          const datas = response['hydra:member'];
          const org = this.authorisationsForm.controls.site.value;
          const orgId = org['@id'];

          this.operatingList = datas
          .filter(item => item.site['@id'] === orgId)
          .map(item => {
            const idMatch = item['@id'].match(/\d+/);
            const authId = idMatch ? +idMatch[0] : null;
              return {
                  id: authId,
                  authorizationNumber: item.authorizationNumber,
                  identificationNumber: item.identificationNumber,
                  period: item.period
              };
          });
        });

        if (authList && authList.length > 1) {
          this.hasAuthorisations = true;
          const regex = /(\d+)$/;
          this.authorisationList = authList.map(url => {
            const match = url['@id'].match(regex);
            const number = match ? match[1] : null;
            return { code: url, number };
          });
        } else {
          this.hasAuthorisations = false;
        }
      }

      this.filteredSite$ = this.authorisationsForm.controls.site.valueChanges.pipe(
        startWith(''),
        map(value => {
          const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
          return this.site?.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
        })
      );
    })

    if (this.data) {
      const itemsArray = this.data.element.operatingAuthorizationCodes.map(item =>
        this.fb.group({
          codeLmod: item.codeLmod,
          codeTreatment: item.codeTreatment,
          volume: item.volume,
        })
      );

      const itemsFormArray = this.authorisationsForm.get('items') as FormArray;
      itemsFormArray.clear();
      itemsArray.forEach(itemGroup => itemsFormArray.push(itemGroup));

      if (this.data.type === 'renew') {
        const dateFrom = this.data.element.dateFrom;
        const dateTo = this.data.element.dateTo;
        const parsedDateTo = new Date(dateTo);
        const parsedDateFrom = new Date(dateFrom);
        const duration = parsedDateTo.getTime() - parsedDateFrom.getTime();
        const parsedNewDateFrom = new Date(parsedDateTo);
        const parsedNewDateFromLow = new Date(parsedDateTo);
        parsedNewDateFrom.setDate(parsedDateTo.getDate() + 1);
        const parsedNewDateTo = new Date(parsedNewDateFromLow.getTime() + duration);
        const newDateFrom = parsedNewDateFrom.toISOString();
        const newDateTo = parsedNewDateTo.toISOString();

        this.f.periodFrom.setValue(newDateFrom);
        this.f.periodTo.setValue(newDateTo);
      } else {
        this.f.periodFrom.setValue(this.data.element.dateFrom);
        this.f.periodTo.setValue(this.data.element.dateTo);
      }

      this.f.idGe.setValue(this.data.element.authorizationNumber);
      this.f.idCh.setValue(this.data.element.identificationNumber);
      this.f.statut.setValue(this.data.element.status);
      this.f.comment.setValue(this.data.element.comment);

      if (
        this.currentOrganisation.type !== OrganisationType.GESDEC
      ) {
        this.f.site.disable();
        this.f.idGe.disable();
        this.f.idCh.disable();
        this.f.periodFrom.disable();
        this.f.periodTo.disable();
        this.f.statut.disable();
        this.f.comment.disable();
        this.f.items.disable();
      }
    }
  }

  onOptionSelected(event: any): void {
    const authList = event.option.value.operatingAuthorizations;
    if (authList && authList.length > 0) {
      this.hasAuthorisations = true;
      const regex = /(\d+)$/;
      this.authorisationList = authList.map(url => {
        const match = url['@id'].match(regex);
        const number = match ? match[1] : null;
        return { code: url, number };
      });

      this.authorisationService.getAuthorisations(null, authList).subscribe(response => {
        const datas = response['hydra:member'];
        const org = this.authorisationsForm.controls.site.value;
        const orgId = org['@id'];
        
        const transformedData = datas
        .filter(item => item.site['@id'] === orgId)
        .map(item => {
          const idMatch = item['@id'].match(/\d+/);
          const authId = idMatch ? +idMatch[0] : null;
            return {
                id: authId,
                authorizationNumber: item.authorizationNumber,
                identificationNumber: item.identificationNumber,
                period: item.period
            };
        });
        this.operatingList = transformedData;
      });

    } else {
      this.hasAuthorisations = false;
    }
  }

  modifyAuthorisation(id: string):void {
    this.authorisationService.getAuthorisations().subscribe(item => {
      const data = item['hydra:member'];
      const matchId = Number(id);

      this.matchingAuth = data.find(item => {
        return item.id === matchId;
      });

      this.dialogRef.close();
      this.openAuthorisationDialog(this.matchingAuth, id, 'modify');

    });
  }

  renewAuthorisation(id: string):void {
    this.authorisationService.getAuthorisations().subscribe(item => {
      const data = item['hydra:member'];
      const matchId = Number(id);

      this.matchingAuth = data.find(item => {
        return item.id === matchId;
      });

      this.dialogRef.close();
      this.openAuthorisationDialog(this.matchingAuth, id, 'renew');

    });
  }

  openAuthorisationDialog(element, authId, type): void {
    const dialogRef = this.dialog.open(AuthorisationsDialogComponent, {
      disableClose: true,
      panelClass: ['form-dialog'],
      data: { element, authId, type },
      autoFocus: false
    });
  }

  get formControls() {
    return this.authorisationsForm.controls;
  }

  get itemsFormArray() {
    return this.formControls.items as FormArray;
  }

  atLeastOneItemValidator() {
    return (formArray: FormArray): { [key: string]: boolean } | null => {
      const atLeastOneValid = formArray.controls.some(control => control.valid);
      return atLeastOneValid ? null : { 'atLeastOneItemRequired': true };
    };
  }

  addItem() {
    const newItem = this.fb.group({
      codeLmod: new FormControl({ value: null, disabled: false }, Validators.required),
      codeTreatment: new FormControl({ value: null, disabled: false }, Validators.required),
      volume: new FormControl({ value: null, disabled: false }, Validators.required),
    });

    this.itemsFormArray.push(newItem);

    // Get lmod
    const codeLmodObservables = this.itemsFormArray.controls.map((item: FormGroup) => {
      const codeLmodControl = item.get('codeLmod');
      return codeLmodControl ? codeLmodControl.valueChanges.pipe(startWith('')) : null;
    });

    merge(...codeLmodObservables)
      .pipe(
        map(value => {
          return this.wasteflowService._codeFilter(this.lmodCodes, value);
        })
      )
      .subscribe(filteredCodes => {
        this.filteredLmodCodesSubject.next(filteredCodes);
      });

    // Get Treatments
    const treatmentsObservables = this.itemsFormArray.controls.map((item: FormGroup) => {
      const treatmentsControl = item.get('codeTreatment');
      return treatmentsControl ? treatmentsControl.valueChanges.pipe(startWith('')) : null;
    });

    merge(...treatmentsObservables)
      .pipe(
        map(value => {
          return this.wasteflowService._codeFilter(this.treatments, value);
        })
      )
      .subscribe(treatmentsCodes => {
        this.filteredTreatmentsSubject.next(treatmentsCodes);
      });
  }

  removeItem(index: number) {
    this.itemsFormArray.removeAt(index);
  }

  public displaySiteFn(value: Site): string {
    return value && value.name ? value.name : '';
  }

  private _filter(value: any): Code[] {
    const filterValue = value && value.label ? value.label : value;
    return this.codeNogas.filter(option => option.label.toLowerCase().includes(filterValue.toLowerCase()));
  }

  /**
   * Get form controls
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.authorisationsForm.controls;
  }

  /**
   * Get list of treatment types for Treatment type/Collecte field on wasteflow
   */
  private getTreatmentTypes(): void {
    this.codeService.getTreatmentTypes().subscribe(treatmentTypes => {
      treatmentTypes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.treatments = treatmentTypes;
      if (this.data) {
        const newData = this.data.element.operatingAuthorizationCodes;
        newData.forEach((data, index) => {
          if (index < this.itemsArray.length) {
            const formGroup = this.itemsArray.at(index) as FormGroup;
            formGroup.get('codeTreatment').patchValue(this.treatments.find(c => c['@id'] === data.codeTreatment.toString()));
          }
        });

        // Get Treatments
        const treatmentsObservables = this.itemsFormArray.controls.map((item: FormGroup) => {
          const treatmentsControl = item.get('codeTreatment');
          return treatmentsControl ? treatmentsControl.valueChanges.pipe(startWith('')) : null;
        });

        merge(...treatmentsObservables)
          .pipe(
            map(value => {
              return this.wasteflowService._codeFilter(this.treatments, value);
            })
          )
          .subscribe(treatmentsCodes => {
            this.filteredTreatmentsSubject.next(treatmentsCodes);
          });
      }
    });
  }

  get itemsArray() {
    return this.authorisationsForm.get('items') as FormArray;
  }

    /**
   * Get list of LMod codes for LMod field on wasteflow
   */
    private getLmodCodes(): void {
      this.codeService.getLmod().subscribe(lmodCodes => {
        lmodCodes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
        this.lmodCodes = lmodCodes;

        if (this.data) {
          const newData = this.data.element.operatingAuthorizationCodes;
          newData.forEach((data, index) => {
            if (index < this.itemsArray.length) {
              const formGroup = this.itemsArray.at(index) as FormGroup;
              formGroup.get('codeLmod').patchValue(this.lmodCodes.find(c => c['@id'] === data.codeLmod.toString()));
            }
          });

          // Get LmodCodes
          const codeLmodObservables = this.itemsFormArray.controls.map((item: FormGroup) => {
            const codeLmodControl = item.get('codeLmod');
            return codeLmodControl ? codeLmodControl.valueChanges.pipe(startWith('')) : null;
          });

          merge(...codeLmodObservables)
            .pipe(
              map(value => {
                return this.wasteflowService._codeFilter(this.lmodCodes, value);
              })
            )
            .subscribe(filteredCodes => {
              this.filteredLmodCodesSubject.next(filteredCodes);
            });
        }

        if (this.units) {
          this.filterUnits();
        }
      });
    }

  /**
   * Reset filtered units if the lmod input is empty.
   */
    lmodInputChange(): void {
      if (isEmpty(this.f?.codeLmod?.value)) {
        this.filterUnits();
      }
    }

  /**
   * Filter units from specified lmod code.
   */
  filterUnits(): void {
    this.filteredUnits = this.unitService.filterUnits(this.f?.codeLmod?.value?.['@id'], this.units);
  }

  /**
   * Submit prestataire form
   * Does not actually make a call to the backend
   */
  public submit(): void {
    this.submitted = true;
    this.authorisationsForm.markAsDirty();
    this.authorisationsForm.markAsTouched();
    this.authorisationsForm.markAllAsTouched();

    if (this.authorisationsForm.invalid) {
      return;
    }

    if (this.data) {
      const itemsArray = this.authorisationsForm.get('items').value.map((item: any) => ({
        codeLmod: item.codeLmod.id,
        codeTreatment: item.codeTreatment.id,
        volume: item.volume
      }));

      const operatingAuthorizationCodes = itemsArray.map(item => ({
        codeLmod: item.codeLmod,
        codeTreatment: item.codeTreatment,
        volume: item.volume
      }));

      // Hack to make it set it to the correct date
      const dateFrom = new Date(this.f.periodFrom.value);
      const dateTo = new Date(this.f.periodTo.value);
      dateFrom.setHours(dateFrom.getHours() + 2);
      dateTo.setHours(dateTo.getHours() + 2);

      const addAuth: AuthorizationEdit = {
        site: this.f.site.value.id,
        authorizationNumber: this.f.idGe.value,
        identificationNumber: this.f.idCh.value,
        dateFrom: dateFrom,
        dateTo: dateTo,
        status: this.f.statut.value,
        comment: this.f.comment.value,
        operatingAuthorizationCodes: operatingAuthorizationCodes
      };

      this.authorisationService.updateAuthorisations(this.data.authId, addAuth).subscribe(
        (response) => {
          this.authorisationService.sendData({ submitted: 'true' });
          this.dialogRef.close();
        },
        (error) => {
          const message = error;
          this.snackBar.open(message, 'close', {
            horizontalPosition: 'right',
            panelClass: ''
          });
        }
      );
    } else {
      const itemsArray = this.f.items.value.map(item => this.fb.group({
        codeLmod: item.codeLmod.id,
        codeTreatment: item.codeTreatment.id,
        volume: item.volume
      }));

      const operatingAuthorizationCodes = itemsArray.map((formGroup) => ({
        codeLmod: formGroup.get('codeLmod').value,
        codeTreatment: formGroup.get('codeTreatment').value,
        volume: formGroup.get('volume').value,
      }));

      const originDateTo = new Date(this.f.periodTo.value);
      originDateTo.setDate(originDateTo.getDate() + 1);
      const formattedDateTo = originDateTo.toISOString().replace('Z', '');

      const originDateFrom = new Date(this.f.periodFrom.value);
      originDateFrom.setDate(originDateFrom.getDate() + 1);
      const formattedDate = originDateFrom.toISOString().replace('Z', '');

      const addAuth: { [k: string]: any } = {
        site: this.f.site.value.id,
        authorizationNumber: this.f.idGe.value,
        identificationNumber: this.f.idCh.value,
        dateFrom: formattedDate,
        dateTo: formattedDateTo,
        status: this.f.statut.value,
        comment: this.f.comment.value,
        operatingAuthorizationCodes: operatingAuthorizationCodes
      };

      this.authorisationService.postAuthorisations(addAuth).subscribe(
        (response) => {
          this.authorisationService.sendData({ submitted: 'true' });
          this.dialogRef.close();
        },
        (error) => {
          const message = error;
          this.snackBar.open(message, 'close', {
            horizontalPosition: 'right',
            panelClass: ''
          });
        }
      );
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.isGenevaChanges$) {
      this.isGenevaChanges$.unsubscribe();
    }
  }
}
