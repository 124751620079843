import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { UserDialogComponent } from 'src/app/shared/components/user-dialog/user-dialog.component';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { User } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public users: Array<User>;
  public currentUser: User;
  public displayedColumns: string[] = ['role', 'prenom', 'nom', 'email', 'fixe', 'portable', 'actions'];
  public connectedOrganisation: Organisation;
  private organisationId: number;
  private dialog$: Subscription;
  private showDisabledUsers = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.connectedOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.showDisabledUsers =
      this.connectedOrganisation.type === OrganisationType.GESDEC ||
      this.connectedOrganisation.type === OrganisationType.ECO21;

    if (this.showDisabledUsers) {
      this.displayedColumns = ['role', 'prenom', 'nom', 'email', 'fixe', 'portable', 'active', 'actions'];
    }

    this.organisationId = parseInt(this.route.snapshot.params.id, 10);
    this.getUsers();
  }

  /**
   * Edit user
   * @param user User to edit
   */
  public editUser(user: User): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      disableClose: true,
      panelClass: ['form-dialog'],
      data: {
        user,
        organisationId: this.organisationId
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldReload => {
      if (shouldReload) {
        this.getUsers();
      }
    });
  }

  /**
   * Reinitialize user password
   * Only available for GESDEC user
   * @param user - User to reinitialize password for
   */
  public reinitPassword(user: User): void {
    let title = `Confirmez la réinitialisation du mot de passe de ${user.firstname} ${user.lastname}`;
    let text = `L'utilisateur recevra un email de réinitialisation de mot de passe à l'adresse suivante: ${user.email}.`;
    if (user.id === this.currentUser.id) {
      title = `Confirmez la réinitialisation de votre mot de passe`;
      text = `Cette action vous déconnectera de la plateforme. Vous recevrez un email de réinitialisation de mot de passe à l'adresse suivante: ${user.email}.`;
    }
    this.dialog$ = this.dialog
      .open(ConfirmDialogComponent, {
        panelClass: ['confirm-dialog'],
        data: {
          title,
          text
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.authenticationService.resetPassword(user.email).subscribe(() => {
            if (user.id === this.currentUser.id) {
              this.authenticationService.logout();
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }

  /**
   * Get list of users
   */
  private getUsers(): void {
    this.userService.getUsers(this.organisationId).subscribe(response => {
      this.users = response;
      if (!this.showDisabledUsers) {
        this.users = this.users.filter(users => users.enabled);
      }
    });
  }
}
