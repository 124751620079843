import { PreClosureProgressBar } from './wasteflow.model';

export interface InventoryPreviewResponse {
  data: Inventory[];
  nb_total_page: number;
}

export interface Inventory {
  agg_key: string;
  progress_bar: PreClosureProgressBar;
  provenance: string;
  provenance_commune_id: number;
  provenance_type: string;
  treatment: string;
  waste_type: string;
  wasteflows_data: WasteflowsData[];
  weight: number;
  weight_per_hab: number;
  weight_per_hab_brut: number;
  weight_per_hab_percent: number;
  weight_percent: number;
}

export interface InventoryFilter {
  label: string;
  value: string | number;
}

export interface InventoryFilters {
  filter: {
    provenance: InventoryFilter[];
    treatment: InventoryFilter[];
    waste_type: InventoryFilter[];
  };
}

export interface WasteflowsData {
  converted_qty: number;
  destination: string;
  id: number;
  lmod: string;
  origin: string;
  origin_qty: number;
  origin_qty_unit: string;
  reporter: string;
  reporter_id: number;
  status: string;
  treatment: string;
}

export enum InventoryClosureStateEnum {
  PRECHECK_LOADING,
  PRECHECK_SUCCESS,
  PRECHECK_FAILED,
  CLOSURE_LOADING,
  CLOSURE_SUCCESS,
  CLOSURE_FAILED
}

export interface ClosureResponse {
  errors: Array<string>;
  infos: Array<string>;
  status: string;
}

export interface InventoryExistsResponse {
  exist: boolean;
  data: string;
}
