<div class="form-dialog__title">
  <h1 *ngIf="data.site" mat-dialog-title>Edition d'un site</h1>
  <h1 *ngIf="!data.site" mat-dialog-title>Ajout d'un site</h1>
</div>
<mat-dialog-content>
  <div class="form-dialog__content">
    <form [formGroup]="siteForm" class="form-dialog__form">
      <mat-form-field appearance="outline" class="declaration-dialog__select" *ngIf="data.site && isGesdecUser">
        <mat-label>Organisation</mat-label>
        <input
          [matAutocomplete]="auto"
          aria-label="Organisations"
          formControlName="organisation"
          matInput
          type="text"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
          <mat-option *ngFor="let option of filteredOrganisations | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.organisation.errors && f.organisation.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__input">
        <mat-label>Nom</mat-label>
        <input formControlName="name" matInput type="text" />
        <mat-error *ngIf="f.name.errors && f.name.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Type de site</mat-label>
        <mat-select formControlName="codeSite">
          <mat-option *ngFor="let code of siteCodes" [value]="code['@id']"> {{ code.label }} </mat-option>
        </mat-select>
        <mat-error *ngIf="f.codeSite.errors && f.codeSite.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Code NOGA</mat-label>
        <mat-select formControlName="codeNoga">
          <mat-option *ngFor="let code of nogaCodes" [value]="code['@id']"> {{ code.label }} </mat-option>
        </mat-select>
        <mat-error *ngIf="f.codeNoga.errors && f.codeNoga.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>

      <app-address-form
        (fullAddressChange)="onFullAddressChange($event)"
        [addressForm]="siteForm"
        [idpadr]="data.site ? data.site.idpadr : null"
        [siteCodes]="siteCodes"
      ></app-address-form>
      <mat-form-field appearance="outline" class="form-dialog__select">
        <mat-label>Statut</mat-label>
        <mat-select formControlName="status">
          <mat-option value="public">Public</mat-option>
          <mat-option value="private">Privé</mat-option>
          <mat-option value="inventory">Inventaire</mat-option>
        </mat-select>
        <mat-error *ngIf="f.status.errors && f.status.errors.required">Ce champ est obligatoire.</mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Sauvegarder</button>
</mat-dialog-actions>
