import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Site } from 'src/app/shared/model/site.model';
import { SiteService } from 'src/app/shared/services/site.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SiteDialogComponent } from './site-dialog/site-dialog.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SiteFusionDialogComponent } from './site-fusion-dialog/site-fusion-dialog.component';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { AuthorisationsService } from 'src/app/shared/services/authorisations.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'siteLabel', 'street1', 'town', 'authorisation', 'public', 'actions'];

  dataSource: MatTableDataSource<Site>;
  organisation: Organisation;
  pageSize = 10;
  visiblePageSizeButtons = [];

  private organisationId: number;
  private allSites: Site[];
  private dialog$: Subscription;

  canMerge = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private siteService: SiteService, private authorisationsService: AuthorisationsService,) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.organisationId = parseInt(this.route.snapshot.params.id, 10);
    this.getSites(false);
  }

  /**
   * Get list of sites
   * @param isUpdate - Whether it's on update or first time called siteid
   */
  private getSites(isUpdate = true): void {
    this.siteService.getSites(this.organisationId).subscribe(response => {
      // If at least one site has an authorizations, get the authorizations of the organisation
      
      this.canMerge =
        response.length && (response[0].organisation?.type === OrganisationType.GESDEC || response.length > 1);
      this.allSites = response;

      response.forEach(site => {
        site.authorizationMessage = '-'
        if (site.codeSite) {
          site.siteLabel = site.codeSite.label;
        }
      })

      if (response.findIndex(site => site.operatingAuthorizations.length > 0) > -1) {
        this.authorisationsService.getAuthorisations(this.organisationId).subscribe(authorizationResponse => {
          const authorizationData = authorizationResponse['hydra:member'];
          const currentDate = new Date();

          response.forEach(site => {
            if (site.operatingAuthorizations.length) {
              // Get detailed operating authorizations
              site.detailedOperatingAuthorizations = site.operatingAuthorizations.map(auth => authorizationData.find(el => el['@id'] === auth['@id']))
              site.activeAuthorizations = site.detailedOperatingAuthorizations.filter(item => {
                const statusIsValidated = item.status === 'validated';
                const dateFrom = new Date(item.dateFrom);
                const dateTo = new Date(item.dateTo);
                const isDateWithinRange = currentDate >= dateFrom && currentDate <= dateTo;

                return statusIsValidated && isDateWithinRange;
              });

              if (site.activeAuthorizations.length) {
                site.authorizationMessage = null;
              } else {
                site.authorizationMessage = 'Non valide';
              }
            }
          })
        })
      }

      if (isUpdate) {
        this.dataSource.data = response;
      } else {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  /**
   * Search sites on input in search field
   */
  filterByName(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  /**
   * Whether the pagination should be shown or not
   */
  showPagination(): boolean {
    if (this.dataSource && this.dataSource.paginator) {
      return this.dataSource.paginator.length > this.dataSource.paginator.pageSize;
    } else {
      return true;
    }
  }

  /**
   * Get full street
   * @param element - Element to get the street of
   */
  getFullStreet(element: Site): string {
    return `${element.street1 ? element.street1 : ''} ${element.street2 ? element.street2 : ''}`;
  }

  /**
   * Get current page index
   */
  getCurrentIndex(): number {
    if (this.dataSource && this.dataSource.paginator) {
      return this.dataSource.paginator.pageIndex;
    }
  }

  getLastPage(): number {
    if (this.dataSource && this.dataSource.paginator) {
      return Math.ceil(this.dataSource.paginator.length / this.dataSource.paginator.pageSize) - 1;
    }
  }

  /**
   * Get all the pages button
   */
  getVisiblePageButtons(): Array<number> {
    if (this.dataSource && this.dataSource.paginator) {
      const firstPage = 0;
      const lastPage = this.getLastPage();
      const index = this.dataSource.paginator.pageIndex;

      this.visiblePageSizeButtons = [firstPage, lastPage];
      if (index !== firstPage && index !== lastPage) {
        this.visiblePageSizeButtons.push(index);
      }

      if (index + 1 > firstPage && index + 1 < lastPage) {
        this.visiblePageSizeButtons.push(index + 1);
      }

      if (index - 1 > firstPage && index - 1 < lastPage) {
        this.visiblePageSizeButtons.push(index - 1);
      }

      return this.visiblePageSizeButtons.sort((a, b) => a - b);
    } else {
      return [];
    }
  }

  /**
   * Whether a case with ... should be seen on pagination
   */
  shouldAddDots(el: number, i: number): boolean {
    return this.getVisiblePageButtons().length !== i + 1 && this.getVisiblePageButtons()[i + 1] !== el + 1;
  }

  /**
   * Go to a certain page
   * @param index - Index of the page to go to
   */
  private goToPage(index: number): void {
    this.dataSource.paginator.pageIndex = index;
    this.dataSource.paginator.page.emit({
      pageIndex: index,
      pageSize: this.dataSource.paginator.pageSize,
      length: this.dataSource.paginator.length
    });
  }

  /**
   * Go to page when clicked on a specific page number
   */
  goToPageManually(n: number): void {
    this.goToPage(n);
  }

  /**
   * Go to previous page
   */
  goToPreviousPage(): void {
    this.goToPage(this.dataSource.paginator.pageIndex - 1);
  }

  /**
   * Go to next page
   */
  goToNextPage(): void {
    this.goToPage(this.dataSource.paginator.pageIndex + 1);
  }

  fusion(site: Site): void {
    const dialogRef = this.dialog.open(SiteFusionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['fusion-dialog'],
      data: {
        site: site,
        organisationSites: site.organisation?.type === OrganisationType.GESDEC ? null : this.allSites
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getSites();
      }
    });
  }

  /**
   * Open site dialog
   * @param site - Site to edit, if any. Else, creation
   */
  openSiteDialog(site?: Site): void {
    const dialogRef = this.dialog.open(SiteDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data: {
        site: site ? site : null,
        organisationId: this.organisationId
      }
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((changes: boolean) => {
      if (changes) {
        this.getSites();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dialog$) {
      this.dialog$.unsubscribe();
    }
  }
}
