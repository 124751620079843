<div class="organisation-list">
  <div class="organisation-list__top">
    <div>
      <h1 class="mt-0">Liste des autorisations</h1>
      <div class="common-search">
        <h4>Recherche:</h4>
        <mat-form-field appearance="outline" class="mat-form-field-small">
          <input (input)="searchAuthorisation($event)" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="currentUserType === true" class="d-flex">
      <button (click)="addAuthorisation()" class="btn btn-small mr-2">Ajouter une autorisation</button>
    </div>
  </div>

  <div *ngIf="data" class="content-tertiary">
    <div class="decla-detail__success" *ngIf="showSuccessMessage">
      <mat-icon>done</mat-icon>
      <div>Vos modifications ont été enregistrées avec succès.</div>
    </div>
    <div class="table-container">
      <mat-table [dataSource]="data" class="organisation-list__table">
        <ng-container matColumnDef="site">
          <mat-header-cell *matHeaderCellDef>Site</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.organisationName}} - {{ element.siteName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="period">
          <mat-header-cell *matHeaderCellDef>Période</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.period }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef>Numéro GE</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.authorizationNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="numberch">
          <mat-header-cell *matHeaderCellDef>Numéro CH</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.identificationNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="statut">
          <mat-header-cell *matHeaderCellDef> Statut</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.statusLabel }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="codes">
          <mat-header-cell *matHeaderCellDef>Nombre de codes</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.operatingAuthorizationCount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="volumes">
          <mat-header-cell *matHeaderCellDef>Volume total</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.volumeSum }}t
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <!--<a [routerLink]="['/gestion/organisation/' + element.id]" class="btn-link mr-2">Voir les détails</a>-->
            <button
            (click)="openAuthorisationDialog(element, element.id)"
              *ngIf="currentOrganisation.type !== 'gesdec'"
              class="btn-link"
            >
            Voir le détail
            </button>
            <button
              (click)="openAuthorisationDialog(element, element.id)"
              *ngIf="currentOrganisation.type === 'gesdec'"
              class="btn-link"
            >
              Éditer
            </button>
            <button
              *ngIf="currentOrganisation.type === 'gesdec'"
              (click)="openAuthorisationDialog(element, element.id, 'renew')"
              class="btn-link"
            >
              Renouveler
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
