import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-performance-rate-element',
  templateUrl: './performance-rate-element.component.html',
  styleUrls: ['./performance-rate-element.component.scss']
})
export class PerformanceRateElementComponent {
  @Input() value: number;
  @Input() height: string;
  @Input() label: string | number;
  @Input() color: string;

  constructor(public decimalPipe: DecimalPipe) {}

  public formatValue(value: number): string {
    return this.decimalPipe.transform(value, '', 'fr').replace(/\s/g, ' ');
  }
}
