<div class="prestation-dialog__title">
  <div>
    <button (click)="backToPrestation()" class="btn-secondary">
      <mat-icon>arrow_back</mat-icon>
      Retour
    </button>
  </div>
  <h1 mat-dialog-title>Nouveau prestataire</h1>
</div>
<mat-dialog-content>
  <div class="prestation-dialog__content">
    <div class="prestation-dialog__content-prestataire">
      <form [formGroup]="prestataireForm" class="prestation-dialog__form">
        <mat-form-field appearance="outline" class="prestation-dialog__input">
          <mat-label>Nom du prestataire</mat-label>
          <input formControlName="name" matInput type="text" />
          <mat-error *ngIf="f.name.errors && f.name.errors.required">Ce champ est obligatoire.</mat-error>
        </mat-form-field>
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" class="prestation-dialog__input">
              <mat-label>Prénom du contact</mat-label>
              <input formControlName="contactFirstName" matInput type="text" />
              <mat-error *ngIf="f.contactFirstName.errors && f.contactFirstName.errors.required"
              >Ce champ est obligatoire.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" class="prestation-dialog__input">
              <mat-label>Nom du contact</mat-label>
              <input formControlName="contactLastName" matInput type="text" />
              <mat-error *ngIf="f.contactLastName.errors && f.contactLastName.errors.required"
              >Ce champ est obligatoire.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="outline" class="prestation-dialog__input">
          <mat-label>Email</mat-label>
          <input formControlName="contactEmail" matInput type="email" />
          <mat-error *ngIf="f.contactEmail.errors && f.contactEmail.errors.required"
          >Ce champ est obligatoire.
          </mat-error>
          <mat-error *ngIf="f.contactEmail.errors && f.contactEmail.errors.pattern"
          >Le format ne correspond pas à un email.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="prestation-dialog__input">
          <mat-label>Téléphone</mat-label>
          <input formControlName="contactPhone" matInput type="text" />
          <mat-error *ngIf="f.contactPhone.errors && f.contactPhone.errors.required"
          >Ce champ est obligatoire.
          </mat-error>
        </mat-form-field>
        <app-address-form
          (fullAddressChange)="onFullAddressChange($event)"
          [addressForm]="prestataireForm"
          [required]="false"
        ></app-address-form>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-link" mat-button mat-dialog-close>Annuler</button>
  <button (click)="submit()" class="btn" mat-button>Ajouter</button>
</mat-dialog-actions>
