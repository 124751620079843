import { Pipe, PipeTransform } from '@angular/core';
import { StatusInventory } from '../../model/import-event.model';
import { WasteflowViews } from '../../model/wasteflow.model';

@Pipe({
  name: 'wasteflowToggleState'
})
export class WasteflowToggleStatePipe implements PipeTransform {
  transform(
    status: StatusInventory,
    newStatus: StatusInventory,
    currentView: WasteflowViews,
    isComplementary?: boolean
  ): string {
    switch (currentView) {
      case WasteflowViews.ENTERPRISE:
        if (
          (status === 'integrated_to_inventory' && !newStatus) ||
          newStatus === 'integrated_to_inventory' ||
          (!isComplementary && status === 'inventory' && !newStatus)
        ) {
          return 'decla-detail__slide-toggle--active';
        } else if (
          (status === 'complementary' && !newStatus) ||
          newStatus === 'complementary' ||
          (isComplementary && status === 'inventory' && !newStatus)
        ) {
          return 'decla-detail__slide-toggle--inactive';
        } else if (status === 'inventory' && !newStatus) {
          return 'decla-detail__slide-toggle--light';
        }
        break;
      case WasteflowViews.DOUBLON:
        if ((!newStatus && status === 'integrated_to_inventory') || newStatus === 'integrated_to_inventory') {
          return 'decla-detail__slide-toggle--active';
        } else if ((!newStatus && status === 'complementary') || newStatus === 'complementary') {
          return 'decla-detail__slide-toggle--inactive';
        }
    }
    // Return null to display default toggle
    return null;
  }
}
