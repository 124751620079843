import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PendingChangesGuard } from './core/guards/component-can-deactivate.guard';
import { LoginGuard } from './core/guards/login.guard';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { WasteflowsComponent } from './modules/wasteflows/wasteflows.component';
import { ImportEventsComponent } from './modules/import-events/import-events.component';
import { SitesFlowComponent } from './modules/sites-flow/sites-flow.component';
import { ImpersonationComponent } from './modules/impersonation/impersonation/impersonation.component';
import { MainComponent } from './main/main/main.component';
import { OrganisationComponent } from './modules/organisation/organisation.component';
import { OrganisationsListComponent } from './modules/organisation/organisations-list/organisations-list.component';
import { PrestationsComponent } from './modules/prestations/prestations/prestations.component';
import { ChangePasswordComponent } from './modules/registration/change-password/change-password.component';
import { ForgottenPasswordComponent } from './modules/registration/forgotten-password/forgotten-password.component';
import { LoginComponent } from './modules/registration/login/login.component';
import { SitesFlowDetailComponent } from './modules/sites-flow/sites-flow-detail/sites-flow-detail.component';
import { InventoryPreviewComponent } from './modules/inventory-preview/inventory-preview.component';
import { CodeTypeListComponent } from './modules/code/code-type-list/code-type-list.component';
import { CodeTypeDetailComponent } from './modules/code/code-type-detail/code-type-detail.component';
import { AuthorisationsListComponent } from './modules/organisation/authorisations-list/authorisations-list.component';
import { VersionComponent } from './main/version/version.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/gestion/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'gestion',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/gestion/declarations', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'declarations',
        component: ImportEventsComponent
      },
      { path: 'sites-flow', component: SitesFlowComponent },
      { path: 'sites-flow/:id', component: SitesFlowDetailComponent },
      { path: 'inventory-preview', component: InventoryPreviewComponent },
      { path: 'declarations-detail', component: WasteflowsComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'prestations', component: PrestationsComponent },
      { path: 'impersonation', component: ImpersonationComponent },
      { path: 'code-type', component: CodeTypeListComponent },
      { path: 'code-type/:id', component: CodeTypeDetailComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'autorisations', component: AuthorisationsListComponent },
      { path: 'autorisations/:id', component: AuthorisationsListComponent },
      { path: 'organisations', component: OrganisationsListComponent },
      { path: 'organisation/:id', component: OrganisationComponent }
    ]
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'version',
    component: VersionComponent
  },
  {
    path: 'public-change-password',
    component: ChangePasswordComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
