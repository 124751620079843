import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Code, Unit } from 'src/app/shared/model/code.model';
import { StatusInventory } from 'src/app/shared/model/import-event.model';
import { Wasteflow } from 'src/app/shared/model/wasteflow.model';
import { Organisation, OrganisationSimple, OrganisationType } from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { CodeService } from 'src/app/shared/services/code.service';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UnitService } from 'src/app/shared/services/unit.service';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { HistoryDialogComponent } from '../history-dialog/history-dialog.component';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-wasteflow-dialog',
  templateUrl: './wasteflow-dialog.component.html',
  styleUrls: ['./wasteflow-dialog.component.scss']
})
export class WasteflowDialogComponent implements OnInit, OnDestroy {
  userOrganisation: Organisation;
  wasteflowForm: FormGroup;
  sites: Site[];
  provenanceSites: Site[];
  filteredProvenanceSites: Observable<Site[]>;
  destinationSites: Site[];
  filteredDestinationSites: Observable<Site[]>;
  units: Unit[];
  filteredUnits: Unit[];
  lmodCodes: Code[] = [];
  filteredLmodCodes: Observable<Code[]>;
  oledCodes: Code[] = [];
  filteredOledCodes: Observable<Code[]>;
  treatments: Code[];
  filteredTreatments: Observable<Code[]>;

  flowTypes: Code[];
  provenanceCodes: Code[];
  filteredProvenanceCodes: Observable<Code[]>;
  validators: OrganisationSimple[] = [];
  filteredValidators: Observable<OrganisationSimple[]>;
  initialWrtInventoryValue: StatusInventory = StatusInventory.INVENTORY;

  private flowType$: Subscription;
  private defaultStartDate = new Date('2021-01-01T22:00:00.000Z');
  private defaultEndDate = new Date('2021-12-31T22:00:00.000Z');

  constructor(
    public dialogRef: MatDialogRef<WasteflowDialogComponent>,
    public dialog: MatDialog,
    public wasteflowService: WasteflowService,
    private unitService: UnitService,
    private datePipe: DatePipe,
    private codeService: CodeService,
    private siteService: SiteService,
    private importEventService: ImportEventService,
    private organisationService: OrganisationService,
    @Inject(MAT_DIALOG_DATA) public data: Wasteflow
  ) {}

  /**
   * Get form control
   */
  get f(): { [key: string]: AbstractControl } {
    return this.wasteflowForm.controls;
  }

  ngOnInit(): void {
    const today = new Date();
    this.defaultStartDate.setFullYear(today.getFullYear() - 1);
    this.defaultEndDate.setFullYear(today.getFullYear() - 1);
    this.userOrganisation = JSON.parse(localStorage.getItem('organisation'));
    this.wasteflowForm = new FormGroup({
      site: new FormControl({ value: null, disabled: false }, Validators.required),
      flowType: new FormControl({ value: null, disabled: false }, Validators.required),
      periodFrom: new FormControl({ value: this.defaultStartDate, disabled: false }, Validators.required),
      periodTo: new FormControl({ value: this.defaultEndDate, disabled: false }, Validators.required),
      provenanceSite: new FormControl({ value: null, disabled: false }),
      destinationSite: new FormControl({ value: null, disabled: false }),
      provenanceCode: new FormControl({ value: null, disabled: false }, Validators.required),
      treatment: new FormControl({ value: null, disabled: false }, Validators.required),
      originQty: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0)]),
      originUnit: new FormControl({ value: null, disabled: false }, Validators.required),
      codeLmod: new FormControl({ value: null, disabled: false }, Validators.required),
      validator: new FormControl({ value: null, disabled: false }, Validators.required),
      inventory: new FormControl({ value: 'inventory', disabled: false }, Validators.required),
      comment: new FormControl({ value: null, disabled: false })
    });

    this.getSites(this.userOrganisation.type === OrganisationType.GESDEC ? null : this.userOrganisation.id);
    // COMMENTÉ AU CAS OU FAUT LE REMETTRE
    // if (this.userOrganisation.type === OrganisationType.GESDEC) {
    //   this.getSites(null, OrganisationType.SERVICEPROVIDER, 'public');
    // } else {
    //   this.getSites(this.userOrganisation.id, null, 'public');
    // }

    this.getProvenanceAndDestinationSites();
    this.getOriginUnits();
    this.getLmodCodes();
    this.getFlowTypes();
    this.getValidators();
    this.getTreatmentTypes();
    this.filterUnits();
    this.subscribeFormControls();

    if (this.data) {
      if (this.data.statusWrtInventory !== StatusInventory.PRIVATE) {
        this.initialWrtInventoryValue = this.data.statusWrtInventory;
      }

      this.f.site.clearValidators();
      this.f.site.updateValueAndValidity();
      this.f.periodFrom.setValue(this.data.flowPeriodFrom);
      this.f.periodTo.setValue(this.data.flowPeriodTo);
      this.f.originQty.setValue(this.data.originQty);
      this.f.validator.setValue(this.data.validator);
      this.f.inventory.setValue(this.data.statusWrtInventory);
    }
  }

  submit(): void {
    this.wasteflowForm.markAsDirty();
    this.wasteflowForm.markAsTouched();
    this.wasteflowForm.markAllAsTouched();
    this.verifyNotIdenticalSites();

    if (this.wasteflowForm.invalid) {
      return;
    }

    if (this.data) {
      const detail: { [k: string]: any } = {
        statusWrtInventory: this.f.inventory.value,
        originQty: this.f.originQty.value,
        originUnit: this.f.originUnit.value['@id'],
        flowPeriodFrom: this.datePipe.transform(this.f.periodFrom.value, 'yyyy-MM-dd'),
        flowPeriodTo: this.datePipe.transform(this.f.periodTo.value, 'yyyy-MM-dd'),
        validator: this.f.validator.value['@id'],
        codeLmod: this.f.codeLmod.value['@id'],
        treatment: this.f.treatment.value['@id'],
        provenanceCode: this.f.provenanceCode.value['@id'],
        internalCode: '',
        flowType: this.f.flowType.value['@id']
      };

      if (this.f.flowType.value.code === 'in' && this.f.provenanceSite.value) {
        detail.provenanceSite = this.f.provenanceSite.value['@id'];
      }

      if (this.f.flowType.value.code === 'out' && this.f.destinationSite.value) {
        detail.destinationSite = this.f.destinationSite.value['@id'];
      }

      if (this.f.flowType.value.code === 'transport' && this.f.destinationSite.value && this.f.provenanceSite.value) {
        detail.provenanceSite = this.f.provenanceSite.value['@id'];
        detail.destinationSite = this.f.destinationSite.value['@id'];
      }

      if (this.f.comment.value) {
        detail.comment = this.f.comment.value;
      }

      this.wasteflowService.updateWasteflow(this.data.id, detail).subscribe(() => {
        this.dialogRef.close(true);
      });
    } else {
      const detail: { [k: string]: any } = {
        site: this.f.site.value.id,
        statusWrtInventory: this.f.inventory.value,
        originQty: this.f.originQty.value,
        originUnit: this.f.originUnit.value.id,
        flowPeriodFrom: this.datePipe.transform(this.f.periodFrom.value, 'yyyy-MM-dd'),
        flowPeriodTo: this.datePipe.transform(this.f.periodTo.value, 'yyyy-MM-dd'),
        validator: this.f.validator.value.id,
        codeLmod: this.f.codeLmod.value.id,
        treatment: this.f.treatment.value.id,
        provenanceCode: this.f.provenanceCode.value.id,
        internalCode: '',
        flowType: this.f.flowType.value.id
      };

      if (this.f.flowType.value.code === 'in' && this.f.provenanceSite.value) {
        detail.provenanceSite = this.f.provenanceSite.value.id;
      }

      if (this.f.flowType.value.code === 'out' && this.f.destinationSite.value) {
        detail.destinationSite = this.f.destinationSite.value.id;
      }

      if (this.f.flowType.value.code === 'transport' && this.f.destinationSite.value && this.f.provenanceSite.value) {
        detail.destinationSite = this.f.destinationSite.value.id;
        detail.provenanceSite = this.f.provenanceSite.value.id;
      }

      if (this.f.comment.value) {
        detail.comment = this.f.comment.value;
      }

      this.importEventService.addImportEvent(detail).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  /**
   * Open history & comments dialog of the wasteflow
   */
  openHistoryDialog(): void {
    this.dialog.open(HistoryDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['history-dialog'],
      data: this.data.id
    });
  }

  ngOnDestroy(): void {
    if (this.flowType$) {
      this.flowType$.unsubscribe();
    }
  }

  /**
   * Reset filtered units if the lmod input is empty.
   */
  lmodInputChange(): void {
    if (isEmpty(this.f?.codeLmod?.value)) {
      this.filterUnits();
    }
  }

  /**
   * Filter units from specified lmod code.
   */
  filterUnits(): void {
    this.filteredUnits = this.unitService.filterUnits(this.f?.codeLmod?.value?.['@id'], this.units);
  }

  /**
   * Filter code values on type from specified codes.
   */
  private _validatorFilter(validators: OrganisationSimple[], value: any): OrganisationSimple[] {
    const filterValue = value?.name ? value.name.toLowerCase() : value?.toLowerCase();
    return validators?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /**
   * Add validators to provenance and destination sites to make sure they're not the same
   */
  private verifyNotIdenticalSites(): void {
    if (this.f.flowType.value) {
      if (this.f.flowType.value.code === 'in') {
        if (
          this.f.provenanceSite.value &&
          ((this.f.site.value && this.f.provenanceSite.value.id === this.f.site.value.id) ||
            (this.f.destinationSite.value && this.f.provenanceSite.value.id === this.f.destinationSite.value.id))
        ) {
          this.f.provenanceSite.setErrors({ incorrect: true });
          if (!this.data) {
            this.f.site.setErrors({ incorrect: true });
          }
        } else {
          this.clearSitesValidators();
        }
      } else if (this.f.flowType.value.code === 'out') {
        if (
          this.f.destinationSite.value &&
          ((this.f.site.value && this.f.destinationSite.value.id === this.f.site.value.id) ||
            (this.f.provenanceSite.value && this.f.destinationSite.value.id === this.f.provenanceSite.value.id))
        ) {
          this.f.destinationSite.setErrors({ incorrect: true });
          if (!this.data) {
            this.f.site.setErrors({ incorrect: true });
          }
        } else {
          this.clearSitesValidators();
        }
      } else {
        if (
          this.f.provenanceSite.value &&
          this.f.destinationSite.value &&
          this.f.provenanceSite.value.id === this.f.destinationSite.value.id
        ) {
          this.f.provenanceSite.setErrors({ incorrect: true });
          this.f.destinationSite.setErrors({ incorrect: true });
        } else {
          this.clearSitesValidators();
        }
      }
    }
  }

  private clearSitesValidators(): void {
    this.f.site.setErrors({ incorrect: false });
    this.f.site.updateValueAndValidity({ emitEvent: false });
    this.f.provenanceSite.setErrors({ incorrect: false });
    this.f.provenanceSite.updateValueAndValidity({ emitEvent: false });
    this.f.destinationSite.setErrors({ incorrect: false });
    this.f.destinationSite.updateValueAndValidity({ emitEvent: false });
  }

  /**
   * Add validators to period in case inventory and not yearly period
   */
  private verifyValidityOfPeriod(): void {
    const periodFrom = new Date(this.f.periodFrom.value);
    const periodTo = new Date(this.f.periodTo.value);

    // If inventory, check that dates correspond to a yearly period (01.01.20xx - 31.12.20xx)
    if (
      this.f.inventory.value !== StatusInventory.PRIVATE &&
      (periodFrom.getMonth() !== 0 ||
        periodFrom.getDate() !== 1 ||
        periodTo.getDate() !== 31 ||
        periodTo.getMonth() !== 11 ||
        periodTo.getFullYear() !== periodFrom.getFullYear())
    ) {
      this.f.periodFrom.setErrors({ incorrect: true });
      return;
    } else {
      setTimeout(() => {
        this.f.periodFrom.clearValidators();
        this.f.periodFrom.updateValueAndValidity();
      }, 0);
    }
  }

  /**
   * Get list of treatment types for Treatment type/Collecte field on wasteflow
   */
  private getTreatmentTypes(): void {
    this.codeService.getTreatmentTypes().subscribe(treatmentTypes => {
      treatmentTypes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.provenanceCodes = treatmentTypes;
      this.treatments = treatmentTypes;

      this.filteredProvenanceCodes = this.f.provenanceCode.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.provenanceCodes, val))
      );

      this.filteredTreatments = this.f.treatment.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.treatments, val))
      );

      if (this.data) {
        this.f.treatment.setValue(this.treatments.find(t => t['@id'] === this.data.treatment['@id']));
      }

      if (this.data && this.data.provenanceCode) {
        this.f.provenanceCode.setValue(this.provenanceCodes.find(c => c['@id'] === this.data.provenanceCode['@id']));
      }
    });
  }

  /**
   * Get list of LMod codes for LMod field on wasteflow
   */
  private getLmodCodes(): void {
    this.codeService.getLmod().subscribe(lmodCodes => {
      lmodCodes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.lmodCodes = lmodCodes;

      this.filteredLmodCodes = this.f.codeLmod.valueChanges.pipe(
        startWith(''),
        map(val => this.wasteflowService._codeFilter(this.lmodCodes, val))
      );

      if (this.data) {
        this.f.codeLmod.setValue(this.lmodCodes.find(c => c['@id'] === this.data.codeLmod['@id']));
      }
      if (this.units) {
        this.filterUnits();
      }
    });
  }

  /**
   * Get list of validators field on wasteflow
   */
  private getValidators(): void {
    let counter = 0;
    this.organisationService.getOrganisations(OrganisationType.GESDEC).subscribe(organisations => {
      counter++;
      this.validators.push(...organisations['hydra:member']);

      if (counter === 2) {
        this.setCurrentOrganisationAsDefaultValidator();
      }
    });

    this.organisationService.getOrganisations(OrganisationType.COMMUNE).subscribe(organisations => {
      counter++;
      this.validators.push(...organisations['hydra:member']);

      if (counter === 2) {
        this.setCurrentOrganisationAsDefaultValidator();
      }
    });
  }

  /**
   * Set current user organisation as data validator
   */
  private setCurrentOrganisationAsDefaultValidator(): void {
    if (this.validators.findIndex(v => v['@id'] === this.userOrganisation['@id']) === -1) {
      this.validators.push(this.userOrganisation);
    }

    this.filteredValidators = this.f.validator.valueChanges.pipe(
      startWith(''),
      map(val => this._validatorFilter(this.validators, val))
    );

    if (!this.data) {
      this.f.validator.setValue(this.userOrganisation['@id']);
    }
  }

  /**
   * Get list of Flow types for Flow field on wasteflow
   */
  private getFlowTypes(): void {
    this.codeService.getFlowTypes().subscribe(flowTypes => {
      this.flowTypes = flowTypes;
      if (this.data) {
        this.f.flowType.setValue(this.flowTypes.find(f => f['@id'] === this.data.flowType['@id']));
      }
    });
  }

  private getSites(organisationId?: number, organisationType?: string, status?: string): void {
    this.siteService.getSites(organisationId, organisationType, status).subscribe(sites => {
      this.sites = sites;
    });
  }

  /**
   * Get provenance and destination sites for provenanceSite and destinationSite fields
   */
  private getProvenanceAndDestinationSites(): void {
    let customParams;
    if (this.userOrganisation.type === OrganisationType.GESDEC) {
      customParams = [{ name: 'siteStatus' }];
    } else {
      customParams = [{ name: 'siteStatusOrg', value: this.userOrganisation.id }];
    }
    this.siteService.getSites(null, null, null, customParams).subscribe(sites => {
      if (this.data) {
        sites = this.addSiteIfNotInList(sites, this.data.importEvent.site);
        sites = this.addSiteIfNotInList(sites, this.data.provenanceSite);
        sites = this.addSiteIfNotInList(sites, this.data.destinationSite);
      }

      this.provenanceSites = sites;
      this.destinationSites = sites;

      this.subscribeProvenanceAndDestinationChange();

      if (this.data) {
        this.f.destinationSite.setValue(this.destinationSites.find(s => s.id === this.data.destinationSite.id));
        this.f.provenanceSite.setValue(this.provenanceSites.find(s => s.id === this.data.provenanceSite.id));
      }
    });
  }

  private addSiteIfNotInList(sitesList: Site[], site: Site): Site[] {
    if (site && sitesList.findIndex(s => s.id === site.id) === -1) {
      sitesList.push(site);
    }

    return sitesList;
  }

  private subscribeProvenanceAndDestinationChange(): void {
    this.filteredProvenanceSites = this.f.provenanceSite.valueChanges.pipe(
      startWith(''),
      map(val => this.wasteflowService._siteFilter(this.provenanceSites, val))
    );
    this.filteredDestinationSites = this.f.destinationSite.valueChanges.pipe(
      startWith(''),
      map(val => this.wasteflowService._siteFilter(this.destinationSites, val))
    );
  }

  /**
   * Get list of units for Quantity unit field on wasteflow
   */
  private getOriginUnits(): void {
    this.unitService.getUnits().subscribe(units => {
      this.units = units;
      if (this.data) {
        this.f.originUnit.setValue(this.units.find(u => u['@id'] === this.data.originUnit['@id']));
      }
      this.filterUnits();
    });
  }

  /**
   * Subscribe the desired form controls.
   * @private
   */
  private subscribeFormControls(): void {
    this.f.periodTo.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.periodFrom.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.inventory.valueChanges.subscribe(() => {
      if (this.f.periodFrom.value && this.f.periodTo.value) {
        this.verifyValidityOfPeriod();
      }
    });

    this.f.provenanceSite.valueChanges.subscribe(() => {
      this.verifyNotIdenticalSites();
    });

    this.f.destinationSite.valueChanges.subscribe(() => {
      this.verifyNotIdenticalSites();
    });

    this.f.site.valueChanges.subscribe(() => {
      this.verifyNotIdenticalSites();

      if (!this.data && this.f.flowType.value) {
        if (this.f.flowType.value.code === 'in') {
          this.f.destinationSite.setValue(this.f.site.value);
        } else if (this.f.flowType.value.code === 'out') {
          this.f.provenanceSite.setValue(this.f.site.value);
        }
      }
    });

    this.flowType$ = this.f.flowType.valueChanges.subscribe(value => {
      this.verifyNotIdenticalSites();

      if (value.code === 'in') {
        this.f.destinationSite.clearValidators();
        this.f.destinationSite.disable();
        if (this.data) {
          this.f.destinationSite.setValue(this.data.destinationSite);
        } else {
          this.f.destinationSite.setValue(this.f.site.value);
        }
        this.f.provenanceSite.enable();
        this.f.provenanceSite.setValidators(Validators.required);
      } else if (value.code === 'out') {
        this.f.provenanceSite.clearValidators();
        this.f.provenanceSite.disable();
        if (this.data) {
          this.f.provenanceSite.setValue(this.data.provenanceSite);
        } else {
          this.f.provenanceSite.setValue(this.f.site.value);
        }
        this.f.destinationSite.enable();
        this.f.destinationSite.setValidators(Validators.required);
      } else {
        this.f.destinationSite.enable();
        this.f.provenanceSite.enable();
        this.f.destinationSite.setValidators(Validators.required);
        this.f.provenanceSite.setValidators(Validators.required);
      }
      this.f.provenanceSite.updateValueAndValidity({ emitEvent: false });
      this.f.destinationSite.updateValueAndValidity({ emitEvent: false });
      this.subscribeProvenanceAndDestinationChange();
    });
  }
}
