import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Code } from 'src/app/shared/model/code.model';
import { ImportEvent, StatusValidation } from 'src/app/shared/model/import-event.model';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { SiteSimple } from 'src/app/shared/model/site.model';
import { WasteFlowStatus } from 'src/app/shared/model/wasteflow.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { WasteflowMassEditDialogComponent } from '../wasteflow-mass-edit-dialog/wasteflow-mass-edit-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WasteflowsComponent } from '../wasteflows.component';
import { WasteflowsTableComponent } from 'src/app/shared/components/wasteflows-table/wasteflows-table.component';
import { MasseModificationService } from 'src/app/shared/services/mass-modification.service';

@Component({
  selector: 'app-wasteflow-mass-edit-confirm-dialog',
  templateUrl: './wasteflow-mass-edit-confirm-dialog.component.html',
  styleUrls: ['./wasteflow-mass-edit-confirm-dialog.component.scss'],
})
export class WasteflowMassEditConfirmDialogComponent implements OnInit {
  @ViewChild(WasteflowsComponent) wasteflow: WasteflowsComponent;
  @ViewChild(WasteflowsTableComponent) wasteflowTable: WasteflowsTableComponent;
  private dialog$: Subscription;
  showSpinner = false;
  isLoading = false;
  valuesToUpdate: { [k: string]: any } = {};
  formDataEdit: any;
  commonItemsLmod: any;
  commonItemsProvenance: any;
  commonItemsDestination: any;
  commonItemsStatus: any;
  filters: any;
  displayConfirmButton: boolean = true;
  wasteflowForm: FormGroup;
  public organisation: Organisation;
  public selectedImportEventId: number;
  public previousSelectedImportEventId: number;
  public importEvents: ImportEvent[] = [];
  public filteredImportEvents: Observable<ImportEvent[]>;
  public importEventsControl = new FormControl('');
  public provenanceSiteList: SiteSimple[] = [];
  public wasteflowStatus: WasteFlowStatus[] = [];
  public filteredProvenanceSiteList: Observable<SiteSimple[]>;
  public destinationSiteList: SiteSimple[] = [];
  public filteredDestinationSiteList: Observable<SiteSimple[]>;
  public codeLmodList: Code[] = [];
  public wasteflowFilters: FormGroup = new FormGroup({
    codeLmod: new FormControl(),
    provenanceSite: new FormControl(),
    destinationSite: new FormControl(),
    status: new FormControl()
  });

  keysToDisplay = [
    { key: 'statut', label: 'Statut', customKey: 'customStatus' },
    { key: 'inclusion', label: 'Inclusion' },
    { key: 'site.name', label: "Site de l'organisation" },
    { key: 'inventory', label: "Donnée d'inventaire" },
    { key: 'periodFrom', label: 'Période de la déclaration' },
    { key: 'periodTo', label: 'Période de la déclaration' },
    { key: 'provenanceCode.displayLabel', label: "Collecte / Traitement à l'origine" },
    { key: 'treatment.displayLabel', label: 'Traitement à la destination' },
    { key: 'codeLmod.displayLabel', label: 'Code LMod' },
    { key: 'originQty', label: 'Valeur de la donnée' },
    { key: 'originUnit.name', label: 'Unité' },
    { key: 'validator.name', label: 'Organisation validatrice' },
    { key: 'comment', label: 'Commentaire sur cette modification' }
  ];

  constructor(
    public dialogRef: MatDialogRef<WasteflowMassEditConfirmDialogComponent>,
    public dialogPreMassRef: MatDialogRef<WasteflowMassEditDialogComponent>,
    public dialog: MatDialog,
    public wasteflowService: WasteflowService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private masseModificationService: MasseModificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formDataEdit = data.formData;
  }

  ngOnInit(): void {
    this.spinnerService.showSpinner$.subscribe(showSpinner => (this.showSpinner = showSpinner));
    this.filters = this.wasteflowService.filters;
    const filterCodeLmodIds = this.wasteflowService.filters.filterCodeLmodIds;
    const filterDestinationSiteId = this.wasteflowService.filters.filterDestinationSiteId;
    const filterProvenanceSiteId = this.wasteflowService.filters.filterProvenanceSiteId;
    const filterStatus = this.wasteflowService.filters.filterStatus;

    // Get Lmod
    this.wasteflowService
      .getWasteflowsFilter(
        'list_code_lmod'
      )
      .subscribe(list => {
        this.codeLmodList = list;
        this.commonItemsLmod = this.codeLmodList.filter(item => filterCodeLmodIds.includes(item.id));
      });

    // Get Provenance
    this.wasteflowService
      .getWasteflowsFilter(
        'list_site_provenance',
      )
      .subscribe(list => {
        this.provenanceSiteList = list;
        this.commonItemsProvenance = this.provenanceSiteList.filter(item => item.id === filterProvenanceSiteId);
      });

    // Get Destination
    this.wasteflowService
      .getWasteflowsFilter(
        'list_site_destination',
      )
      .subscribe(list => {
        this.destinationSiteList = list;
        this.commonItemsDestination = this.destinationSiteList.filter(item => item.id === filterDestinationSiteId);
      });

    // Get Status
    const statusOrder = [
      StatusValidation.NEW,
      StatusValidation.TOVALIDATE,
      StatusValidation.TOFIX,
      StatusValidation.VALID,
      StatusValidation.VIEW,
      StatusValidation.PREARCHIVE,
      StatusValidation.PRECLOSURE,
      StatusValidation.ARCHIVE,
      StatusValidation.CLOSURE,
      StatusValidation.OUTSERVICE
    ];

    this.wasteflowService.getWasteflowStatus().subscribe(status => {
      status.sort((a, b) => {
        return statusOrder.indexOf(a.code) - statusOrder.indexOf(b.code);
      });
      this.wasteflowStatus = status;
      this.commonItemsStatus = this.wasteflowStatus.filter(item => item.code === filterStatus);
    });

  }

  processKey(keyItem: any): string {
    const key = keyItem.key;
    const customKey = keyItem.customKey;

    const keyParts = typeof key === 'string' ? key.split('.') : [];
    const value = keyParts.reduce((o, i) => (o && o[i] !== undefined ? o[i] : null), this.formDataEdit);

    if (value !== null && value !== undefined) {
      switch (key) {
        case 'statut':
          const statutValue = value;
          if (statutValue === 'to_validate') {
            return 'À valider';
          }
          if (statutValue === 'valid') {
            return 'Validée';
          }
          if (statutValue === 'to_fix') {
            return 'À corriger';
          }
          if (statutValue === 'pre_closure') {
            return 'Pré-clôturée';
          }
          return statutValue !== null && statutValue !== undefined ? statutValue.toString() : '';
        case 'inclusion' :
          const inclusionValue = value;
          if (inclusionValue === 'complementary') {
            return 'Donnée complémentaire';
          }
          if (inclusionValue === 'integrated_to_inventory') {
            return "Donnée à inclure dans l'inventaire";
          }
          return inclusionValue !== null && inclusionValue !== undefined ? inclusionValue.toString() : '';
        case 'inventory' :
          const inventoryValue = value;
          if (inventoryValue === 'inventory') {
            return "Donnée d'inventaire";
          }
          if (inventoryValue === 'private') {
            return "Donnée privée";
          }
          return inventoryValue !== null && inventoryValue !== undefined ? inventoryValue.toString() : '';
        case 'periodTo':
        case 'periodFrom':
          const periodFrom = this.formDataEdit['periodFrom'] || '';
          const periodTo = this.formDataEdit['periodTo'] || '';
          const formattedPeriodFrom = periodFrom ? new Date(periodFrom).toLocaleDateString() : '';
          const formattedPeriodTo = periodTo ? new Date(periodTo).toLocaleDateString() : '';
          return `${formattedPeriodFrom} au ${formattedPeriodTo}`;
        case 'originQty':
          const unit = this.formDataEdit['originUnit']?.['name'] || '';
          return `${value} ${unit}`;
        default:
          return value.toString();
      }
    } else {
      return '';
    }
  }

  shouldDisplayKey(keyItem: any): boolean {
    // Add keys you want to exclude from display
    const excludedKeys = ['periodTo'];

    return !excludedKeys.includes(keyItem.key) && this.processKey(keyItem) !== '';
  }

  submit(): void {
    if (this.formDataEdit.periodFrom !== null) {
      this.valuesToUpdate.flowPeriodFrom = this.formDataEdit.periodFrom;
    }
    if (this.formDataEdit.periodTo !== null) {
      this.valuesToUpdate.flowPeriodTo = this.formDataEdit.periodTo;
    }
    if (this.formDataEdit.provenanceCode?.id !== null) {
      this.valuesToUpdate.provenanceCode = this.formDataEdit.provenanceCode?.id;
    }
    if (this.formDataEdit.treatment?.id !== null) {
      this.valuesToUpdate.treatment = this.formDataEdit.treatment?.id;
    }
    if (this.formDataEdit.codeLmod?.id !== null) {
      this.valuesToUpdate.codeLmod = this.formDataEdit.codeLmod?.id;
    }
    if (this.formDataEdit.originQty !== null) {
      this.valuesToUpdate.originQty = this.formDataEdit.originQty;
    }
    if (this.formDataEdit.originUnit?.id !== null) {
      this.valuesToUpdate.unit = this.formDataEdit.originUnit?.id;
    }
    if (this.formDataEdit.site?.organisation?.id !== null) {
      this.valuesToUpdate.organisation = this.formDataEdit.site?.organisation?.id;
    }
    if (this.formDataEdit.comment !== null) {
      this.valuesToUpdate.comment = this.formDataEdit.comment;
    }
    if (this.formDataEdit.statut !== null) {
      this.valuesToUpdate.status = this.formDataEdit.statut;
    }
    if (this.formDataEdit.inclusion !== null) {
      this.valuesToUpdate.statusWrtInventory = this.formDataEdit.inclusion;
    }

    const massEditDatas = {
      ...this.filters, valuesToUpdate: {
        ...this.valuesToUpdate
      }
    }

    this.isLoading = true;
    this.showSpinner = true;

    this.wasteflowService.postMassEditWasteflows(massEditDatas).subscribe(
      (response) => {
        const message = 'Vos modifications ont été sauvegardées avec succès.';
        this.isLoading = false;
        this.showSpinner = false;
        this.dialogRef.close();
        const secondDialogRef: MatDialogRef<WasteflowMassEditDialogComponent> = this.data.dialogRef;
        secondDialogRef.close();
        this.dialog$ = this.data.dialogRef.afterClosed().subscribe(() => {
          this.masseModificationService.sendData({ submitted: 'true' });
        });
      },
      (error) => {
        const message = error;
        this.isLoading = false;
        this.showSpinner = false;
        this.dialogRef.close();
        const secondDialogRef: MatDialogRef<WasteflowMassEditDialogComponent> = this.data.dialogRef;
        secondDialogRef.close();
        this.snackBar.open(message, 'close', {
          horizontalPosition: 'right',
          panelClass: ''
        });
      }
    );
  }
}
