import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public changePassword: FormGroup;
  public submitted = false;
  public showOldPassword = false;
  public showNewPassword = false;
  public showNewPasswordRepeated = false;
  public isInvalidLink = false;
  public isPublic = false;
  public error = null;
  public token: null;
  private route$: Subscription;

  constructor(
    private readonly formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Get form controls
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.changePassword.controls;
  }

  ngOnInit(): void {
    this.route$ = this.route.queryParams.subscribe((params: { [k: string]: any }) => {
      this.token = params.token;
    });

    this.isPublic = this.router.url !== '/change-password';
    this.changePassword = this.formBuilder.group({
      oldPassword: ['', this.isPublic ? '' : Validators.required],
      newPassword: [
        '',
        [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#.,?;\$%\^&\*])(?=.{8,})/)]
      ],
      newPasswordRepeated: ['', [Validators.required, this.passwordMatcher.bind(this)]]
    });
  }

  /**
   * Submit change password
   */
  public submit(): void {
    this.submitted = true;
    if (this.changePassword.invalid) {
      return;
    } else {
      if (this.isPublic) {
        // Change password with token (from email link)
        this.authenticationService
          .publicChangePassword(this.token, this.f.newPassword.value, this.f.newPasswordRepeated.value)
          .subscribe(
            success => {
              if (success) {
                this.error = undefined;
                this.isInvalidLink = false;
                this.authenticationService.logout();
                this.router.navigate(['login'], {
                  queryParams: { isFromChangePassword: true }
                });
              } else {
                this.isInvalidLink = true;
                this.error = 'Ce lien de réinitialisation de mot-de-passe n\'est plus valide. Vous pouvez en générer un nouveau via le lien ci-dessous.';
              }
            },
            error => {
              return error;
            }
          );
      } else {
        // Change password with old and new password
        this.authenticationService
          .changePassword(this.f.oldPassword.value, this.f.newPassword.value, this.f.newPasswordRepeated.value)
          .subscribe(
            success => {
              if (success) {
                this.router.navigate(['gestion/dashboard']);
              }
            },
            error => {
              this.error = error.error.detail;
              return error;
            }
          );
      }
    }
  }

  ngOnDestroy(): void {
    this.route$.unsubscribe();
  }

  /**
   * Check to see if passwords match
   */
  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (this.changePassword && control.value !== this.changePassword.controls.newPassword.value) {
      return { passwordNotMatch: true };
    }
    return null;
  }
}
