import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WasteflowsData } from '../../../shared/model/inventory.model';
import { Organisation } from '../../../shared/model/organisation.model';
import { map } from 'lodash';
import { Wasteflow } from 'src/app/shared/model/wasteflow.model';

@Component({
  selector: 'app-inventory-details-row',
  templateUrl: './inventory-details-row.component.html',
  styleUrls: ['./inventory-details-row.component.scss']
})
export class InventoryDetailsRowComponent {
  @Input() wasteflows: WasteflowsData[];
  @Input() organisation: Organisation;
  @Output() preclotureInventory = new EventEmitter<WasteflowsData[]>();
  isClosured: boolean = false;

  displayedColumns: string[] = [
    'reporter',
    'lmod',
    'origin',
    'destination',
    'treatment',
    'origin_qty',
    'converted_qty',
    'status'
  ];

  labels: string[] = [
    'Rapporteur',
    'LMod',
    'Origine',
    'Destination',
    'Traitement',
    "Quantité d'origine",
    'Poids (t)',
    'Statut'
  ];

  getWasteflowIds(): string[] {
    return map(this.wasteflows, 'id');
  }

  disableWasteflowClosure(): boolean {
    this.wasteflows.map((e: WasteflowsData) => {
      if(e.status === 'Cloturée') {
        this.isClosured = true;
      }
    })
    return this.isClosured;
  }

  preclotureWasteflows(): void {
    this.preclotureInventory.emit(this.wasteflows);
  }
}
