import { Component, OnDestroy, OnInit } from '@angular/core';
import { InventoryService } from '../../shared/services/inventory.service';
import { PreClosureProgressBar, WasteflowSorting, WasteFlowStatus } from '../../shared/model/wasteflow.model';
import { Sort } from '@angular/material/sort';
import {
  Inventory,
  InventoryClosureStateEnum,
  InventoryFilter,
  WasteflowsData
} from '../../shared/model/inventory.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Organisation, OrganisationType } from '../../shared/model/organisation.model';
import { WasteflowService } from '../../shared/services/wasteflow.service';
import { getSelectedYear$ } from '../../core/store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { Observable, Subscription } from 'rxjs';
import { StatusValidation } from '../../shared/model/import-event.model';
import { FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InventoryClosureDialogComponent } from './inventory-closure-dialog/inventory-closure-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inventory-preview',
  templateUrl: './inventory-preview.component.html',
  styleUrls: ['./inventory-preview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class InventoryPreviewComponent implements OnInit, OnDestroy {
  inventories: Inventory[];
  inventoriesProgress: PreClosureProgressBar;
  pageLength = 1;
  currentPage = 1;
  sorting: WasteflowSorting = {
    dir: 'asc',
    sort: 'provenance',
    displaySort: 'provenance'
  };
  displayedColumns: string[] = [
    'provenance',
    'waste_type',
    'treatment',
    'weight',
    'weight_per_hab',
    'progress_bar',
    'actions'
  ];
  expandedElement: Inventory | null;
  organisation: Organisation;
  provenanceSiteList: InventoryFilter[] = [];
  filteredProvenanceSiteList$: Observable<InventoryFilter[]>;
  wasteTypeList: InventoryFilter[] = [];
  filteredWasteTypeSiteList$: Observable<InventoryFilter[]>;
  treatmentList: InventoryFilter[] = [];
  filteredTreatmentSiteList$: Observable<InventoryFilter[]>;
  organisationType = OrganisationType;
  firstInitGlobalProgressBar = true;
  firstInitProgressBar = true;
  inventoryFilters: FormGroup = new FormGroup({
    provenanceSite: new FormControl(),
    wasteType: new FormControl(),
    treatment: new FormControl()
  });
  private preclotureStatus: WasteFlowStatus;
  private selectedYear$: Subscription;
  private selectedYear: number;

  communeClosureExists = false;
  entrepriseClosureExists = false;
  communeClosureBtnLabel = `Clôturer l'inventaire des communes`;
  entrepriseClosureBtnLabel = `Clôturer l'inventaire des entreprises`;

  messagePostClosure: string;

  constructor(
    private inventoryService: InventoryService,
    public wasteflowService: WasteflowService,
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.getPreclotureStatus();
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(year => {
      this.selectedYear = year;
      this.resetFilters();
      this.getFilters();
      this.checkIfClosureExists();
    });
  }

  downloadInventory(isCommune: boolean): void {
    this.inventoryService.generateRapport(this.selectedYear, isCommune).subscribe(data => {
      location.href = `${environment.apiUrl.split('/api')[0]}/download?file=${data.file_name}`;
    });
  }

  openClosureDialog(isCommune: boolean): void {
    this.dialog
      .open(InventoryClosureDialogComponent, {
        data: {
          year: this.selectedYear,
          isCommune
        }
      })
      .afterClosed()
      .subscribe(state => {
        if (state === InventoryClosureStateEnum.PRECHECK_FAILED) {
          this.sortData({
            active: 'progress_bar',
            direction: 'asc'
          });
        }

        if (state === InventoryClosureStateEnum.CLOSURE_SUCCESS) {
          this.getData();
          this.checkIfClosureExists();
          this.messagePostClosure = `Les données ont été clôturées, vous pouvez à présent télécharger le rapport d'inventaire.`;
        }
      });
  }

  getData(): void {
    this.getInventoryPreview();
    this.getInventoryPreviewProgress();
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  /**
   * Reinitialize filters to "all" and filter wasteflows again
   */
  resetFilters(): void {
    this.inventoryFilters.controls.provenanceSite.setValue('');
    this.inventoryFilters.controls.wasteType.setValue('');
    this.inventoryFilters.controls.treatment.setValue('');
    this.getData();
  }

  /**
   * Sort table
   * @param sort - Column to sort it to and direction
   */
  sortData(sort: Sort): void {
    this.sorting.dir = sort.direction;
    this.sorting.displaySort = this.sorting.sort = sort.active;
    this.getInventoryPreview();
  }

  updatePage(page: number): void {
    this.currentPage = page + 1;
    this.firstInitProgressBar = true;
    this.getInventoryPreview();
  }

  /**
   * Precloture the inventory data.
   */
  preclotureInventory(wasteflows: WasteflowsData[]): void {
    let counter = 0;
    wasteflows.forEach(wasteflow => {
      // Check if there are any changes on the inventory status
      if (!['closure', 'archive'].includes(wasteflow.status) && this.preclotureStatus) {
        this.wasteflowService
          .updateWasteflow(wasteflow.id, {
            wasteFlowStatus: this.preclotureStatus['@id']
          })
          .subscribe(() => {
            counter++;
            if (counter === wasteflows.length) {
              this.getData();
            }
          });
      } else {
        counter++;

        // If we've been through all data, reload all content to be updated
        if (counter === wasteflows.length) {
          this.getData();
        }
      }
    });
  }

  /**
   * Check if the filter input is empty and reload filters.
   */
  filterInputChange(val: string | undefined): void {
    if (val !== undefined && val === '') {
      this.getData();
    }
  }

  private checkIfClosureExists(): void {
    this.inventoryService.getExistsClosure(this.selectedYear, true).subscribe(response => {
      this.communeClosureExists = response.exist;
      if (response.data) {
        this.communeClosureBtnLabel = `Clôturé le ${response.data}`;
      }
    });
    this.inventoryService.getExistsClosure(this.selectedYear, false).subscribe(response => {
      this.entrepriseClosureExists = response.exist;
      if (response.data) {
        this.entrepriseClosureBtnLabel = `Clôturé le ${response.data}`;
      }
    });
  }

  /**
   * Get lists to fill in the 3 filters for the page on main view
   */
  private getFilters(): void {
    this.inventoryService.getInventoryPreviewFilters(this.selectedYear).subscribe(filters => {
      this.provenanceSiteList = filters?.filter?.provenance;
      this.filteredProvenanceSiteList$ = this.inventoryFilters.controls.provenanceSite.valueChanges.pipe(
        startWith(''),
        map(val => {
          return this.wasteflowService._entityFilter(this.provenanceSiteList, val, 'label');
        })
      );

      this.wasteTypeList = filters?.filter?.waste_type;
      if (this.wasteTypeList) {
        this.wasteTypeList.sort((a, b) => {
          return this.wasteflowService.compare(a.label, b.label, true);
        });
      }
      this.filteredWasteTypeSiteList$ = this.inventoryFilters.controls.wasteType.valueChanges.pipe(
        startWith(''),
        map(val => {
          return this.wasteflowService._entityFilter(this.wasteTypeList, val, 'label');
        })
      );

      this.treatmentList = filters?.filter?.treatment;
      if (this.treatmentList) {
        this.treatmentList.sort((a, b) => {
          return this.wasteflowService.compare(a.label, b.label, true);
        });
      }
      this.filteredTreatmentSiteList$ = this.inventoryFilters.controls.treatment.valueChanges.pipe(
        startWith(''),
        map(val => {
          return this.wasteflowService._entityFilter(this.treatmentList, val, 'label');
        })
      );
    });
  }

  /**
   * Get all the inventory data with filters and associated page.
   */
  private getInventoryPreview(): void {
    this.inventoryService
      .getInventoryPreview(
        this.sorting,
        this.inventoryFilters.controls.provenanceSite.value?.value,
        this.inventoryFilters.controls.wasteType.value?.value,
        this.inventoryFilters.controls.treatment.value?.value,
        this.currentPage
      )
      .subscribe(inventoryResponse => {
        setTimeout(() => (this.firstInitProgressBar = false), 0);
        this.inventories = inventoryResponse.data;
        this.pageLength = inventoryResponse.nb_total_page;
        if (this.pageLength !== 0 && this.currentPage > this.pageLength) {
          this.currentPage = 1;
          this.getInventoryPreview();
        }
      });
  }

  /**
   * Get the global inventory progress bar.
   */
  private getInventoryPreviewProgress(): void {
    this.inventoryService
      .getInventoryPreviewProgress(
        this.inventoryFilters.controls.provenanceSite.value?.value,
        this.inventoryFilters.controls.wasteType.value?.value,
        this.inventoryFilters.controls.treatment.value?.value
      )
      .subscribe(inventoriesProgress => {
        setTimeout(() => (this.firstInitGlobalProgressBar = false), 0);
        this.inventoriesProgress = inventoriesProgress.data;
      });
  }

  /**
   * Get the preloture status.
   * @private
   */
  private getPreclotureStatus(): void {
    this.wasteflowService.getWasteflowStatus().subscribe(statuses => {
      this.preclotureStatus = statuses.find(stat => stat.code === StatusValidation.PRECLOSURE);
    });
  }
}
