import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { PrivacyDialogComponent, RegistrationLegendComponent } from './registration-legend/registration-legend.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [RegistrationLegendComponent, PrivacyDialogComponent],
  exports: [RegistrationLegendComponent],
  imports: [CommonModule, SharedModule, MatButtonModule]
})
export class RegistrationModule {}
