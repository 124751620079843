import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private detailsSource = new BehaviorSubject<any>(null);
  details$ = this.detailsSource.asObservable();

  setDetails(details: any) {
    this.detailsSource.next(details);
  }
}
