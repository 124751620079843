import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImportEvent } from '../model/import-event.model';
import { BaseService } from './base.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';
import { ExportFile } from '../model/wasteflow.model';

@Injectable({
  providedIn: 'root'
})
export class ImportEventService extends BaseService {
  constructor(private readonly http: HttpClient, store$: Store<AppState>) {
    super(store$);
  }

  /**
   * Get list of import events
   */
  public getImportEventsByOrganisation(organisationId?: number): Observable<ImportEvent[]> {
    let url = `/front/import_event${this.getYearParam()}`;
    if (organisationId) {
      url += `&id=${organisationId}`;
    }
    return this.http.get<ImportEvent[]>(`${environment.apiUrl}${url}`);
  }

  /**
   * Generate import events xlsx template with specific site id
   */
  public generateTemplateBySite(siteId?: number): Observable<ExportFile> {
    return this.http.post<any>(`${environment.apiUrl}/generate_template_by_site`, { id: siteId });
  }

  /**
   * Delete import event
   * @param importEventId - Import event to delete
   */
  public deleteImportEvent(importEventId: number): Observable<any> {
    return this.http.get<ImportEvent[]>(`${environment.apiUrl}/front/remove_import_event?id=${importEventId}`);
  }

  /**
   * Add Import event manually
   */
  public addImportEvent(importEvent: { [k: string]: any }): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/importevent/add_import_event`, importEvent, {
      headers: { Accept: 'application/json' }
    });
  }

  /**
   * Check the import event file.
   */
  public checkImportEventFile(filename: FormData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/pre_import_check`, filename, {
      headers: { Accept: 'application/ld+json' }
    });
  }

  /**
   * Import/Upload import event file
   */
  public uploadImportEventFile(filename: FormData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/import`, filename, {
      headers: { Accept: 'application/ld+json' }
    });
  }
}
