import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/core/store/states/app.state';
import { Code } from 'src/app/shared/model/code.model';
import { WasteflowService } from 'src/app/shared/services/wasteflow.service';
import { environment } from 'src/environments/environment';
import { getSelectedYear$ } from '../../../../core/store/selectors/app.selectors';

@Component({
  selector: 'app-export-wasteflow',
  templateUrl: './export-wasteflow.component.html',
  styleUrls: ['./export-wasteflow.component.scss']
})
export class ExportWasteflowComponent implements OnInit, OnDestroy {
  @Input() selectedProvenanceSite: number;
  @Input() selectedDestinationSite: number;
  @Input() selectedCodeLmod: Code[];
  @Input() selectedImportEventId: number;

  private selectedYear$: Subscription;
  private selectedYear: number;

  constructor(private wasteflowService: WasteflowService, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.selectedYear$ = this.store$.select(getSelectedYear$).subscribe(year => {
      this.selectedYear = year;
    });
  }

  ngOnDestroy(): void {
    this.selectedYear$.unsubscribe();
  }

  public exportDatas(): void {
    this.wasteflowService
      .exportWasteflows(
        this.selectedProvenanceSite,
        this.selectedDestinationSite,
        this.selectedCodeLmod && this.selectedCodeLmod.length ? this.selectedCodeLmod.map(code => code.id) : [],
        this.selectedImportEventId,
        this.selectedYear
      )
      .subscribe(data => {
        location.href = `${environment.apiUrl.split('/api')[0]}/download?file=${data.file_name}`;
      });
  }
}
