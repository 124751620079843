import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { Property } from '../model/property.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of properties
   */
  public getPropertiesByOrganisation(organisationId?: number): Observable<Property[]> {
    let url = '/properties';
    if (organisationId) {
      url += `?organisation=${organisationId}`;
    }
    return this.http
      .get<HyDraMemberResponse<Property[]>>(`${environment.apiUrl}${url}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }

  /**
   * Update property
   */
  public updateProperty(url: string, property: any): Observable<Property> {
    return this.http.put<Property>(`${environment.apiUrl}${url.replace('/api', '')}`, property, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Create property
   */
  public createProperty(property: any): Observable<Property> {
    return this.http.post<Property>(`${environment.apiUrl}/properties`, property, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
