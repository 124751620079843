import {PrestataireCanton, PrestataireCountry} from './model/organisation.model';

export const multiselectConfiguration = {
  text: 'Codes LMod',
  selectAllText: 'Tout sélectionner',
  unSelectAllText: 'Tout désélectionner',
  searchPlaceholderText: 'Rechercher',
  noDataLabel: 'Aucune donnée disponible',
  enableSearchFilter: true,
  filterSelectAllText: 'Sélectionner tous les résultats filtrés',
  filterUnSelectAllText: 'Désélectionner tous les résultats filtrés',
  labelKey: 'label',
  searchAutofocus: true,
  escapeToClose: true,
  tagToBody: true
};

export const cantonsList: Array<PrestataireCanton> = [
  { code: 'AR', name: 'Appenzell Rh.-Ext.' },
  { code: 'AI', name: 'Appenzell Rh.-Int.' },
  { code: 'AG', name: 'Argovie' },
  { code: 'BL', name: 'Bâle-Campagne' },
  { code: 'BS', name: 'Bâle-Ville' },
  { code: 'BE', name: 'Berne' },
  { code: 'FR', name: 'Fribourg' },
  { code: 'GE', name: 'Genève' },
  { code: 'GL', name: 'Glaris' },
  { code: 'GR', name: 'Grisons' },
  { code: 'JU', name: 'Jura' },
  { code: 'LU', name: 'Lucerne' },
  { code: 'NE', name: 'Neuchâtel' },
  { code: 'NW', name: 'Nidwald' },
  { code: 'OW', name: 'Obwald' },
  { code: 'SG', name: 'Saint-Gall' },
  { code: 'SH', name: 'Schaffhouse' },
  { code: 'SZ', name: 'Schwyz' },
  { code: 'SO', name: 'Soleure' },
  { code: 'TI', name: 'Tessin' },
  { code: 'TG', name: 'Thurgovie' },
  { code: 'UR', name: 'Uri' },
  { code: 'VS', name: 'Valais' },
  { code: 'VD', name: 'Vaud' },
  { code: 'ZG', name: 'Zoug' },
  { code: 'ZH', name: 'Zurich' }
];

export const countriesList: Array<PrestataireCountry> = [
  { code: 'Suisse', name: 'Suisse' },
  { code: 'France', name: 'France' },
  { code: 'ITA', name: 'Italie' },
  { code: 'Allemagne', name: 'Allemagne' },
  { code: 'AFG', name: 'Afghanistan' },
  { code: 'ALA', name: 'Îles Aland' },
  { code: 'ALB', name: 'Albanie' },
  { code: 'DZA', name: 'Algérie' },
  { code: 'ASM', name: 'Samoa américaines' },
  { code: 'AND', name: 'Andorre' },
  { code: 'AGO', name: 'Angola' },
  { code: 'AIA', name: 'Anguilla' },
  { code: 'ATA', name: 'Antarctique' },
  { code: 'ATG', name: 'Antigua-et-Barbuda' },
  { code: 'ARG', name: 'Argentine' },
  { code: 'ARM', name: 'Arménie' },
  { code: 'ABW', name: 'Aruba' },
  { code: 'AUS', name: 'Australie' },
  { code: 'AUT', name: 'Autriche' },
  { code: 'AZE', name: 'Azerbaïdjan' },
  { code: 'BHS', name: 'Bahamas' },
  { code: 'BHR', name: 'Bahreïn' },
  { code: 'BGD', name: 'Bangladesh' },
  { code: 'BRB', name: 'Barbades' },
  { code: 'BLR', name: 'Biélorussie' },
  { code: 'BEL', name: 'Belgique' },
  { code: 'BLZ', name: 'Belize' },
  { code: 'BEN', name: 'Bénin' },
  { code: 'BMU', name: 'Bermudes' },
  { code: 'BTN', name: 'Bhoutan' },
  { code: 'BOL', name: 'Bolivie' },
  { code: 'BES', name: 'Saba, Saint-Eustache et Bonaire' },
  { code: 'BIH', name: 'Bosnie-Herzégovine' },
  { code: 'BWA', name: 'Botswana' },
  { code: 'BVT', name: 'Île Bouvet' },
  { code: 'BRA', name: 'Brésil' },
  { code: 'IOT', name: `Territoire Britannique de l'Océan Indien` },
  { code: 'VGB', name: 'Îles Vierges britanniques' },
  { code: 'BRN', name: 'Brunei' },
  { code: 'BGR', name: 'Bulgarie' },
  { code: 'BFA', name: 'Burkina Faso' },
  { code: 'BDI', name: 'Burundi' },
  { code: 'KHM', name: 'Cambodge' },
  { code: 'CMR', name: 'Cameroun' },
  { code: 'CAN', name: 'Canada' },
  { code: 'CPV', name: 'Cap vert' },
  { code: 'CYM', name: 'Îles Caïmans' },
  { code: 'CAF', name: 'République centrafricaine' },
  { code: 'TCD', name: 'Tchad' },
  { code: 'CHL', name: 'Chili' },
  { code: 'CHN', name: 'Chine' },
  { code: 'CXR', name: 'Îles Christmas' },
  { code: 'CCK', name: 'Îles Cocos' },
  { code: 'COL', name: 'Colombie' },
  { code: 'COM', name: 'Comores' },
  { code: 'COK', name: 'Îles Cook' },
  { code: 'CRI', name: 'Costa Rica' },
  { code: 'HRV', name: 'Croatie' },
  { code: 'CUB', name: 'Cuba' },
  { code: 'CUW', name: 'Curaçao' },
  { code: 'CYP', name: 'Chypre' },
  { code: 'CZE', name: 'République Tchèque' },
  { code: 'COD', name: 'République démocratique du Congo' },
  { code: 'DNK', name: 'Danemark' },
  { code: 'DJI', name: 'Djibouti' },
  { code: 'DMA', name: 'Dominique' },
  { code: 'DOM', name: 'République Dominicaine' },
  { code: 'TLS', name: 'Timor oriental' },
  { code: 'ECU', name: 'Équateur' },
  { code: 'EGY', name: 'Égypte' },
  { code: 'SLV', name: 'Le Salvador' },
  { code: 'GNQ', name: 'Guinée équatoriale' },
  { code: 'ERI', name: 'Érythrée' },
  { code: 'EST', name: 'Estonie' },
  { code: 'ETH', name: 'Éthiopie' },
  { code: 'FLK', name: 'Îles Falkland' },
  { code: 'FRO', name: 'Îles Féroé' },
  { code: 'FJI', name: 'Fiji' },
  { code: 'FIN', name: 'Finlande' },
  { code: 'GUF', name: 'Guyane française' },
  { code: 'PYF', name: 'Polynésie française' },
  { code: 'ATF', name: 'Terres australes et antarctiques françaises' },
  { code: 'GAB', name: 'Gabon' },
  { code: 'GMB', name: 'Gambie' },
  { code: 'GEO', name: 'État de Géorgie' },
  { code: 'GHA', name: 'Ghana' },
  { code: 'GIB', name: 'Gribraltar' },
  { code: 'GRC', name: 'Grèce' },
  { code: 'GRL', name: 'Groenland' },
  { code: 'GRD', name: 'Grenade' },
  { code: 'GLP', name: 'Guadeloupe' },
  { code: 'GUM', name: 'Guam' },
  { code: 'GTM', name: 'Guatémala' },
  { code: 'GGY', name: 'Guernesey' },
  { code: 'GIN', name: 'Guinée' },
  { code: 'GNB', name: 'Guinée-Bissau' },
  { code: 'GUY', name: 'Guyane' },
  { code: 'HTI', name: 'Haïti' },
  { code: 'HMD', name: 'Îles Heard et îles MacDonald' },
  { code: 'HND', name: 'Honduras' },
  { code: 'HKG', name: 'Hong Kong' },
  { code: 'HUN', name: 'Hongrie' },
  { code: 'ISL', name: 'Islande' },
  { code: 'IND', name: 'Inde' },
  { code: 'IDN', name: 'Indonésie' },
  { code: 'IRN', name: 'Iran' },
  { code: 'IRQ', name: 'Irak' },
  { code: 'IRL', name: 'Irlande' },
  { code: 'IMN', name: 'Île de Man' },
  { code: 'ISR', name: 'Israël' },
  { code: 'CIV', name: `Côte-d'Ivoire` },
  { code: 'JAM', name: 'Jamaïque' },
  { code: 'JPN', name: 'Japon' },
  { code: 'JEY', name: 'Jersey' },
  { code: 'JOR', name: 'Jordanie' },
  { code: 'KAZ', name: 'Kazakhstan' },
  { code: 'KEN', name: 'Kenya' },
  { code: 'KIR', name: 'Kiribati' },
  { code: 'XXK', name: 'Kosovo' },
  { code: 'KWT', name: 'Koweït' },
  { code: 'KGZ', name: 'Kirghizistan' },
  { code: 'LAO', name: 'Laos' },
  { code: 'LVA', name: 'Lettonie' },
  { code: 'LBN', name: 'Liban' },
  { code: 'LSO', name: 'Lesotho' },
  { code: 'LBR', name: 'Libéria' },
  { code: 'LBY', name: 'Libye' },
  { code: 'LIE', name: 'Liechtenstein' },
  { code: 'LTU', name: 'Lituanie' },
  { code: 'LUX', name: 'Luxembourg' },
  { code: 'MAC', name: 'Macao' },
  { code: 'MKD', name: 'Macédoine' },
  { code: 'MDG', name: 'Madagascar' },
  { code: 'MWI', name: 'Malawi' },
  { code: 'MYS', name: 'Malaysie' },
  { code: 'MDV', name: 'Maldives' },
  { code: 'MLI', name: 'Mali' },
  { code: 'MLT', name: 'Malte' },
  { code: 'MHL', name: 'Îles Marshall' },
  { code: 'MTQ', name: 'Martinique' },
  { code: 'MRT', name: 'Mauritanie' },
  { code: 'MUS', name: 'Île Maurice' },
  { code: 'MYT', name: 'Mayotte' },
  { code: 'MEX', name: 'Mexique' },
  { code: 'FSM', name: 'Micronésie' },
  { code: 'MDA', name: 'Moldavie' },
  { code: 'MCO', name: 'Monaco' },
  { code: 'MNG', name: 'Mongolie' },
  { code: 'MNE', name: 'Monténégro' },
  { code: 'MSR', name: 'Montserrat' },
  { code: 'MAR', name: 'Maroc' },
  { code: 'MOZ', name: 'Mozambique' },
  { code: 'MMR', name: 'Myanmar' },
  { code: 'NAM', name: 'Namibie' },
  { code: 'NRU', name: 'Nauru' },
  { code: 'NPL', name: 'Népal' },
  { code: 'NLD', name: 'Pays-Bas' },
  { code: 'ANT', name: 'Antilles néerlandaises' },
  { code: 'NCL', name: 'Nouvelle-Calédonie' },
  { code: 'NZL', name: 'Nouvelle Zélande' },
  { code: 'NIC', name: 'Nicaragua' },
  { code: 'NER', name: 'Niger' },
  { code: 'NGA', name: 'Nigéria' },
  { code: 'NIU', name: 'Niue' },
  { code: 'NFK', name: 'Île Norfolk' },
  { code: 'PRK', name: 'Corée du Nord' },
  { code: 'MNP', name: 'Îles Mariannes du Nord' },
  { code: 'NOR', name: 'Norvège' },
  { code: 'OMN', name: 'Oman' },
  { code: 'PAK', name: 'Pakistan' },
  { code: 'PLW', name: 'Palaos' },
  { code: 'PSE', name: 'Territoire Palestinien' },
  { code: 'PAN', name: 'Panama' },
  { code: 'PNG', name: 'Papouasie-Nouvelle-Guinée' },
  { code: 'PRY', name: 'Paraguay' },
  { code: 'PER', name: 'Pérou' },
  { code: 'PHL', name: 'Philippines' },
  { code: 'PCN', name: 'Îles Pitcairn' },
  { code: 'POL', name: 'Pologne' },
  { code: 'PRT', name: 'Portugal' },
  { code: 'PRI', name: 'Puerto Rico' },
  { code: 'QAT', name: 'Qatar' },
  { code: 'COG', name: 'République du Congo' },
  { code: 'REU', name: 'La Réunion' },
  { code: 'ROU', name: 'Roumanie' },
  { code: 'RUS', name: 'Russie' },
  { code: 'RWA', name: 'Rwanda' },
  { code: 'BLM', name: 'Saint Barthélemy' },
  { code: 'SHN', name: 'Sainte-Hélène' },
  { code: 'KNA', name: 'Saint-Kitts-et-Nevis' },
  { code: 'LCA', name: 'Sainte-Lucie' },
  { code: 'MAF', name: 'Saint-Martin' },
  { code: 'SPM', name: 'Saint Pierre et Miquelon' },
  { code: 'VCT', name: 'Saint-Vincent-et-les-Grenadines' },
  { code: 'WSM', name: 'Samoa' },
  { code: 'SMR', name: 'San Marin' },
  { code: 'STP', name: 'Sao Tomé-et-Principe' },
  { code: 'SAU', name: 'Arabie Saoudite' },
  { code: 'SEN', name: 'Sénégal' },
  { code: 'SRB', name: 'Serbie' },
  { code: 'SCG', name: 'Serbie et Montenegro' },
  { code: 'SYC', name: 'Seychelles' },
  { code: 'SLE', name: 'Sierra Leone' },
  { code: 'SGP', name: 'Singapour' },
  { code: 'SXM', name: 'Saint-Martin' },
  { code: 'SVK', name: 'Slovaquie' },
  { code: 'SVN', name: 'Slovénie' },
  { code: 'SLB', name: 'Îles Salomon' },
  { code: 'SOM', name: 'Somalie' },
  { code: 'ZAF', name: 'Afrique du Sud' },
  { code: 'SGS', name: 'Géorgie du Sud et les îles Sandwich' },
  { code: 'KOR', name: 'Corée du Sud' },
  { code: 'SSD', name: 'Soudan du Sud' },
  { code: 'ESP', name: 'Espagne' },
  { code: 'LKA', name: 'Sri Lanka' },
  { code: 'SDN', name: 'Soudan' },
  { code: 'SUR', name: 'Surinam' },
  { code: 'SJM', name: 'Svalbard et Jan Mayen' },
  { code: 'SWZ', name: 'Swaziland' },
  { code: 'SWE', name: 'Suède' },
  { code: 'SYR', name: 'Syrie' },
  { code: 'TWN', name: 'Taiwan' },
  { code: 'TJK', name: 'Tajikistan' },
  { code: 'TZA', name: 'Tanzanie' },
  { code: 'THA', name: 'Thaïlande' },
  { code: 'TGO', name: 'Togo' },
  { code: 'TKL', name: 'Tokelau' },
  { code: 'TON', name: 'Tonga' },
  { code: 'TTO', name: 'Trinité et Tobago' },
  { code: 'TUN', name: 'Tunisie' },
  { code: 'TUR', name: 'Turquie' },
  { code: 'TKM', name: 'Turkménistan' },
  { code: 'TCA', name: 'Îles Turques et Caïques' },
  { code: 'TUV', name: 'Tuvalu' },
  { code: 'VIR', name: 'Îles Vierges des États-Unis' },
  { code: 'UGA', name: 'Ouganda' },
  { code: 'UKR', name: 'Ukraine' },
  { code: 'ARE', name: 'Émirats Arabes Unis' },
  { code: 'GBR', name: 'Royaume-Uni' },
  { code: 'USA', name: 'États-Unis (USA)' },
  { code: 'UMI', name: 'Îles Mineures éloignées des États-Unis' },
  { code: 'URY', name: 'Uruguay' },
  { code: 'UZB', name: 'Ouzbékistan' },
  { code: 'VUT', name: 'Vanuatu' },
  { code: 'VAT', name: 'Vatican' },
  { code: 'VEN', name: 'Vénézuela' },
  { code: 'VNM', name: 'Vietnam' },
  { code: 'WLF', name: 'Wallis et Futuna' },
  { code: 'ESH', name: 'Sahara occidental' },
  { code: 'YEM', name: 'Yemen' },
  { code: 'ZMB', name: 'Zambie' },
  { code: 'ZWE', name: 'Zimbabwe' }
];
