import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { LoginToken } from '../../shared/model/login.model';
import { APIResponse } from 'src/app/shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authHeaders = { headers: { 'Content-Type': 'application/json' } };
  public loggedInObservable = new BehaviorSubject<boolean>(this.isLoggedIn());

  constructor(public router: Router, private http: HttpClient) {}

  /**
   * Get token from local storage
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Whether or not user is logged in/there is a stored token
   */
  public isLoggedIn(): boolean {
    return !!this.getToken();
  }

  /**
   * Log in user
   * @param email - Email to log in
   * @param password - Password to log in
   */
  public login(email: string, password: string): Observable<boolean> {
    return this.http
      .post<any>(`${environment.apiUrl}/authentication_token`, { email, password }, this.authHeaders)
      .pipe(
        tap((token: LoginToken) => {
          localStorage.setItem('token', token.token);
          this.loggedInObservable.next(true);
        }),
        mapTo(true),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  /**
   * Log out and remove all saved elements in local storage
   */
  public logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('organisation');
    localStorage.removeItem('originToken');
    localStorage.removeItem('originUser');
    localStorage.removeItem('originOrganisation');
    this.router.navigate(['/login']);
    this.loggedInObservable.next(false);
  }

  /**
   * Reset user password, this will send a reset link to the user
   * @param email - Email of the user to reset password for
   */
  public resetPassword(email: string): Observable<boolean> {
    return this.http.get<any>(`${environment.apiUrl}/user/reset_password?email=${email}`).pipe(
      tap(response => {
        return response.success;
      }),
      mapTo(true),
      catchError((error: HttpErrorResponse) => {
        return of(false);
      })
    );
  }

  /**
   * Change user password
   * @param oldPassword - Old password of the user
   * @param newPassword - New password
   * @param newPasswordRepeated - Confirm new password
   */
  public changePassword(oldPassword: string, newPassword: string, newPasswordRepeated: string): Observable<boolean> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/user/change_password`,
        { oldPassword, newPassword, newPasswordRepeated },
        this.authHeaders
      )
      .pipe(
        tap((response: APIResponse) => {
          return response.success;
        }),
        mapTo(true),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  /**
   * Change user password with a token
   * @param token - Token, will be sent by email to the user
   * @param newPassword - New password
   * @param newPasswordRepeated - Confirm new password
   */
  public publicChangePassword(token: string, newPassword: string, newPasswordRepeated: string): Observable<boolean> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/user/public_change_password`,
        { token, newPassword, newPasswordRepeated },
        this.authHeaders
      )
      .pipe(
        tap((response: APIResponse) => {
          return response.success;
        }),
        mapTo(true),
        catchError((error: HttpErrorResponse) => {
          return of(false);
        })
      );
  }
}
