import { Action } from '@ngrx/store';

// tslint:disable-next-line:no-namespace
export namespace AppActions {

  export enum ActionTypes {
    SET_SELECTED_YEAR = '[APP] Set selected year',
  }

  export class SetSelectedYear implements Action {
    readonly type = ActionTypes.SET_SELECTED_YEAR;

    constructor(public payload: { selected_year: number }) {}
  }

  export type Actions = SetSelectedYear;
}
