import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HyDraMemberResponse } from '../model/api.model';
import { Unit } from '../model/code.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Filter units to avoid mixing global units with lmod related ones.
   */
  public filterUnits(selectedCodeLmod: string | undefined, units: Unit[]): Unit[] {
    return units?.filter(unit => {
      return (
        (!selectedCodeLmod && !unit.codeLmod) ||
        selectedCodeLmod === unit.codeLmod ||
        (selectedCodeLmod &&
          !unit.codeLmod &&
          !units.find(element => {
            return element.codeLmod === selectedCodeLmod && element.name === unit.name;
          }))
      );
    });
  }

  /**
   * Get list of units
   */
  public getUnits(): Observable<Unit[]> {
    return this.http
      .get<HyDraMemberResponse<Unit[]>>(`${environment.apiUrl}/units`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((data: any) => data['hydra:member']));
  }
}
