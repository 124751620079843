import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AtlasElement } from 'src/app/shared/model/organisation.model';

@Component({
  selector: 'app-prestataire-dialog',
  templateUrl: './prestataire-dialog.component.html',
  styleUrls: ['./prestataire-dialog.component.scss']
})
export class PrestataireDialogComponent implements OnInit {
  @Output() closePrestataire = new EventEmitter();
  public prestataireForm: FormGroup;
  private fullAddress: AtlasElement;

  constructor(private formBuilder: FormBuilder) {}

  /**
   * Get form controls
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.prestataireForm.controls;
  }

  ngOnInit(): void {
    // Create prestataire form
    this.prestataireForm = this.formBuilder.group({
      name: new FormControl({ value: null, disabled: false }, Validators.required),
      contactFirstName: new FormControl({ value: null, disabled: false }),
      contactLastName: new FormControl({ value: null, disabled: false }),
      contactEmail: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
        )
      ]),
      contactPhone: new FormControl({ value: null, disabled: false }),
      isGeneva: new FormControl({ value: true, disabled: false }),
      street1: new FormControl({ value: null, disabled: false }),
      street2: new FormControl({ value: null, disabled: false }),
      postalCode: new FormControl({ value: null, disabled: false }),
      town: new FormControl({ value: null, disabled: false }),
      country: new FormControl({ value: null, disabled: false })
    });
  }

  /**
   * Update full address when it changes on child component
   * @param address - New address
   */
  public onFullAddressChange(address: AtlasElement): void {
    this.fullAddress = address;
  }

  /**
   * Back to prestation dialog
   */
  public backToPrestation(): void {
    this.closePrestataire.emit(null);
  }

  /**
   * Submit prestataire form
   * Does not actually make a call to the backend
   */
  public submit(): void {
    this.prestataireForm.markAsDirty();
    this.prestataireForm.markAsTouched();
    this.prestataireForm.markAllAsTouched();

    if (this.prestataireForm.invalid) {
      return;
    }

    if (this.f.isGeneva.value === true) {
      if (
        (!this.fullAddress && this.f.street1.value !== null && this.f.street1.value !== '') ||
        (this.fullAddress &&
          this.f.street1.value !== this.fullAddress.adresse.adresse &&
          this.f.street1.value !== null &&
          this.f.street1.value !== '')
      ) {
        // If value has been filled in but no full address has been selected
        // Or full address has been selected but street1 value has been changed afterwards
        this.f.street1.setErrors({ incorrect: true });
        return;
      }

      if (this.fullAddress && (this.f.street1.value === null || this.f.street1.value === '')) {
        // If full address has been selected but street 1 value has been cleared, remove full address
        this.fullAddress = null;
      }
    }

    const prestataire: { [k: string]: any } = {
      name: this.f.name.value,
      contactFirstname: this.f.contactFirstName.value,
      contactLastname: this.f.contactLastName.value,
      contactEmail: this.f.contactEmail.value,
      type: 'service_provider',
      active: true,
      status: 'to_confirm'
    };

    if (this.f.contactPhone.value) {
      prestataire.contactPhone = this.f.contactPhone.value;
    }

    // If is geneva and field is filled in
    if (this.f.isGeneva.value && this.fullAddress && this.fullAddress.adresse.adresse === this.f.street1.value) {
      prestataire.street1 = this.fullAddress.adresse.adresse;
      prestataire.idpadr = this.fullAddress.idpadr.toString();
      prestataire.postalCode = this.fullAddress.adresse.npa.toString();
      prestataire.town = this.fullAddress.adresse.lieu;
      prestataire.country = 'CHE';
    }

    // If is not geneva, take all filled in fields
    if (this.f.isGeneva.value === false) {
      if (this.f.street1.value) {
        prestataire.street1 = this.f.street1.value;
      }

      if (this.f.street2.value) {
        prestataire.street2 = this.f.street2.value;
      }

      if (this.f.postalCode.value) {
        prestataire.postalCode = this.f.postalCode.value;
      }

      if (this.f.town.value) {
        prestataire.town = this.f.town.value;
      }

      if (this.f.country.value) {
        prestataire.country = this.f.country.value;
      }
    }

    this.closePrestataire.emit(prestataire);
  }
}
