import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeclarationsStatus, GenericData, WasteFlowGeneric, WastePerResident } from '../model/dashboard.model';
import { BaseService } from './base.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/states/app.state';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  constructor(private readonly http: HttpClient, store$: Store<AppState>) {
    super(store$);
  }

  /**
   * Get data for dashboard graph "Répartition des déchets"
   */
  public getWasteFlowGeneric(
    type: string,
    organisationId?: number,
    isRecyclable?: boolean,
    isDistribution?: boolean
  ): Observable<WasteFlowGeneric> {
    const body: { [k: string]: any } = {
      aggregationCodeTypeLabel: type,
      filtersCustom: [{ filter: 'date', value: `${this.year + 1}-01-01` }]
    };

    if (organisationId) {
      body.filtersCustom.push({ filter: 'organisationId', value: organisationId });
    }

    if (type === 'GESDEC') {
      if (isRecyclable) {
        body.filtersField = [{ filter: 'treatment_parent.code', value: 'R' }];
      } else {
        if (!isDistribution && organisationId) {
          body.filtersField = [{ filter: 'destinationSite_organisation.id', value: organisationId }];
        }
      }
    }

    return this.http.post<any>(`${environment.apiUrl}/dashboard/waste_flow_generic`, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get declaration status
   */
  public getWasteflowStatus(organisationId?: number): Observable<DeclarationsStatus> {
    let url = `/dashboard/declaration_status?year=${this.year}`;
    if (organisationId) {
      url += `&id=${organisationId}`;
    }

    return this.http.get<DeclarationsStatus>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get waste rate by resident
   */
  public getWasteRatePerResident(organisationId?: number): Observable<WastePerResident> {
    let url = `/dashboard/municipality/waste_per_resident?date=${this.year}`;
    if (organisationId) {
      url += `&id=${organisationId}`;
    }
    return this.http.get<WastePerResident>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  /**
   * Get waste evolution
   */
  public getWasteEvolution(organisationId?: number): Observable<GenericData> {
    let url = `/dashboard/municipality/evolution?date=${this.year}`;
    if (organisationId) {
      url += `&id=${organisationId}`;
    }
    return this.http.get<GenericData>(`${environment.apiUrl}${url}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
