<div class="decla-detail__success" *ngIf="showSuccessMessage">
  <mat-icon>done</mat-icon>
  <div>Vos modifications ont été enregistrées avec succès.</div>
</div>

<div class="table-container decla-detail__table-container">
  <mat-table
    (matSortChange)="sortData($event)"
    [class.decla-detail__table--prestataire]="organisation && organisation.type === 'service_provider'"
    [dataSource]="data"
    [matSortActive]="'sourceRef'"
    [matSortDirection]="'asc'"
    class="decla-detail__table"
    matSort
    matSortDisableClear
    multiTemplateDataRows
  >
    <ng-container matColumnDef="source">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="source"
      >
        Source
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          *ngIf="element.error || element.aberrantJsonInfoFront"
          matTooltipPosition="above"
          [matTooltip]="element.error ? element.error : element.aberrantJsonInfoFront.join('\n')"
          class="material-icons decla-detail__error-icon"
        >
          warning_amber
        </span>
        <div
          class="ellipsis"
          matTooltipPosition="above"
          matTooltip="{{ element.importEvent.reporter?.name }}-{{ element.flowPeriod }}-{{ element.importEvent.id }}"
        >
          {{ element.importEvent.reporter?.name }}-{{ element.flowPeriod }}-{{ element.importEvent.id }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="codeLmod">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="codeLmod"
      >
        Code LMod
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <app-lmod-field
          (updateField)="onUpdateLmodField($event, element)"
          *ngIf="lmodCodes"
          [currentView]="currentView"
          [element]="element"
          [lmodCodes]="lmodCodes"
        ></app-lmod-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="origine">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="origine"
      >
        Origine
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div [matTooltip]="element.provenanceSite.name" matTooltipPosition="above">
          {{ element.provenanceSite.name }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="collecte">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="collecte"
      >
        Collecte
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <ng-container *ngIf="element.wasteFlowStatus.code === 'to_fix' && currentView !== V.DOUBLON">
          <mat-form-field class="mat-form-field-small mat-form-field-small--large w-full">
            <input
              (keyup)="provenanceCodeInputChange(element, $event.target?.value)"
              [matAutocomplete]="provenanceCode"
              [value]="element.newProvenanceCode.code + ' ' + element.newProvenanceCode.label"
              matInput
              type="text"
            />
            <mat-autocomplete
              #provenanceCode="matAutocomplete"
              (optionSelected)="selectProvenanceCode(element, $event)"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let provenanceCode of element.filteredProvenanceCodes | async" [value]="provenanceCode">
                {{ provenanceCode.code }} {{ provenanceCode.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="element.wasteFlowStatus.code !== 'to_fix' || currentView === V.DOUBLON">
          <div [matTooltip]="element.provenanceCode?.code + ' ' + element.provenanceCode?.label" matTooltipPosition="above">
            {{ element.provenanceCode?.code }} {{ element.provenanceCode?.label }}
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="destination">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="destination"
      >
        Destination
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <div [matTooltip]="element.destinationSite.name" matTooltipPosition="above">
          {{ element.destinationSite.name }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="traitement">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="traitement"
      >
        Traitement
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-ellipsis">
        <ng-container *ngIf="element.wasteFlowStatus.code === 'to_fix' && currentView !== V.DOUBLON">
          <mat-form-field class="mat-form-field-small mat-form-field-small--large w-full">
            <input
              (keyup)="treatmentInputChange(element, $event.target?.value)"
              [matAutocomplete]="treatment"
              [value]="element.newTreatment.code + ' ' + element.newTreatment.label"
              matInput
              type="text"
            />
            <mat-autocomplete
              #treatment="matAutocomplete"
              (optionSelected)="selectTreatment(element, $event)"
              [displayWith]="wasteflowService.displayCodeFn"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let treatment of element.filteredTreatments | async" [value]="treatment">
                {{ treatment.code }} {{ treatment.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="element.wasteFlowStatus.code !== 'to_fix' || currentView === V.DOUBLON">
          <div [matTooltip]="element.treatment.code + ' ' + element.treatment.label" matTooltipPosition="above">
            {{ element.treatment.code }} {{ element.treatment.label }}
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantite">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="quantite"
      >
        Quantité d'origine
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.wasteFlowStatus.code === 'to_fix' && currentView !== V.DOUBLON">
          <mat-form-field
            [class.mat-form-field-invalid]="isInvalid(element.newOriginQty)"
            class="mat-form-field-small mr-3"
          >
            <input
              (ngModelChange)="setToToValidateStatus(element)"
              [(ngModel)]="element.newOriginQty"
              matInput
              tabindex="2"
              type="number"
            />
          </mat-form-field>
          <mat-form-field class="mat-form-field-small origin-symbol">
            <mat-select
              (selectionChange)="setToToValidateStatus(element)"
              [(value)]="element.newOriginUnitId"
              tabindex="-1"
            >
              <mat-option *ngFor="let unit of element.filteredUnits" [value]="unit['@id']">
                {{ unit.symbol }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="element.wasteFlowStatus.code !== 'to_fix' || currentView === V.DOUBLON">
          <div
            matTooltip="{{ element.newOriginQty | number : '' : 'fr' }} {{
              getOriginParam(element.newOriginUnitId, 'name')
            }}"
            matTooltipPosition="above"
          >
            {{ element.newOriginQty | numberValue | number : '' : 'fr' }}
            <span class="origin-symbol">{{ getOriginParam(element.newOriginUnitId, 'symbol') }}</span>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="convertedQty">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="convertedQty"
      >
        Poids (t)
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [matTooltip]="element.convertedQty | number : '' : 'fr'" matTooltipPosition="above">
          {{ element.convertedQty | numberValue | number : '' : 'fr' }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statusValidation">
      <mat-header-cell
        *matHeaderCellDef
        [disabled]="submitting || !disableEditFields || currentView !== V.MAIN"
        arrowPosition="before"
        mat-sort-header="statusValidation"
      >
        Statut
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          [matTooltip]="element.wasteFlowStatus.code | statusLabel"
          matTooltipPosition="above"
          [ngClass]="element.wasteFlowStatus.code | statusColor : organisation.type : element.validator?.type"
          class="status pl-1"
        ></span>
        <span
          *ngIf="element.lastWasteFlowHistory"
          class="text-primary pl-2"
          matTooltipPosition="above"
          [matTooltip]="element.lastWasteFlowHistory"
        >
          <mat-icon>chat_bubble_outline</mat-icon>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="decla-detail__actions d-flex justify-content-between">
          <div *ngIf="currentView === V.MAIN" class="d-flex">
            <button
              (click)="toggleStatus(element, statusValidation.TOFIX)"
              *ngIf="shouldShowAskForCorrection(element)"
              [class.btn-icon--pushed]="element.newStatus && element.newStatus.code === 'to_fix'"
              [matTooltip]="getCorrectionTooltip(element)"
              matTooltipPosition="above"
              class="btn-icon mr-2"
              tabindex="2"
            >
              <img alt="" src="../../../assets/img/correction.svg" />
            </button>
            <button
              (click)="toggleStatus(element, statusValidation.PRECLOSURE)"
              *ngIf="shouldShowPreclosure(element)"
              [class.btn-icon--pushed]="element.newStatus && element.newStatus.code === 'pre_closure'"
              matTooltip="Pré-clôturer"
              matTooltipPosition="above"
              class="btn-icon mr-2"
              tabindex="2"
            >
              <img alt="" class="decla-detail__btn-icon-large" src="../../../assets/img/check-double.svg" />
            </button>
            <button
              (click)="toggleStatus(element, statusValidation.VALID)"
              *ngIf="showValidate(element)"
              [class.btn-icon--pushed]="element.newStatus && element.newStatus.code === 'valid'"
              matTooltip="Valider"
              matTooltipPosition="above"
              class="btn-icon mr-2"
              tabindex="2"
            >
              <img alt="" src="../../../assets/img/check.svg" />
            </button>
            <button
              (click)="toggleStatus(element, statusValidation.TOVALIDATE)"
              *ngIf="element.wasteFlowStatus.code === 'to_fix'"
              [class.btn-icon--pushed]="element.newStatus && element.newStatus.code === 'to_validate'"
              [matTooltip]="
                element.newStatus && element.newStatus.code === 'to_validate'
                  ? 'Annuler les corrections'
                  : toToValidateTooltip
              "
              matTooltipPosition="above"
              class="btn-icon mr-2"
              tabindex="2"
            >
              <img alt="" class="decla-detail__reverse-icon" src="../../../assets/img/correction.svg" />
            </button>
          </div>
          <div *ngIf="currentView !== V.MAIN">
            <button
              *ngIf="
                !(
                  organisation.type !== organisationType.GESDEC &&
                  element.wasteFlowStatus.code === statusValidation.PRECLOSURE
                )
              "
              (click)="toggleDoublonElement(element)"
              [ngClass]="
                element.statusWrtInventory
                  | wasteflowToggleState : element.newStatusWrtInventory : currentView : element.isComplementary
              "
              [matTooltip]="
                currentView === V.DOUBLON
                  ? (element.statusWrtInventory | wasteflowToggleLabel : element.newStatusWrtInventory)
                  : null
              "
              [disabled]="element.wasteFlowStatus.code === 'closure'"
              matTooltipPosition="above"
              class="decla-detail__slide-toggle"
            ></button>
          </div>

          <!-- Classic view -> Expand to see details and secondary actions -->
          <button
            *ngIf="(currentView === V.MAIN || currentView === V.DOUBLON) && !selectedSiteId"
            (click)="element.expanded = !element.expanded"
            class="btn-link px-0"
          >
            <mat-icon [class.expanded]="element.expanded">expand_more</mat-icon>
          </button>

          <!-- Other views (flow, doublons) -> Secondary actions on button -->
          <div *ngIf="(currentView !== V.MAIN && currentView !== V.DOUBLON) || selectedSiteId">
            <button
              [matMenuTriggerFor]="menu"
              aria-label="Voir actions supplémentaires"
              class="btn-link px-0"
              mat-icon-button
              tabindex="2"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                (click)="openWasteflowDialog(element)"
                [disabled]="
                  submitting || !disableEditFields || showEdit(element) || element.wasteFlowStatus.code === 'closure'
                "
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                <span>Editer</span>
              </button>
              <button (click)="openHistoryDialog(element.id)" mat-menu-item>
                <mat-icon>chat_bubble_outline</mat-icon>
                <span>Voir modifications et commentaires</span>
              </button>
              <button
                (click)="openVentilationDialog(element)"
                *ngIf="element.wasteFlowStatus.code === 'to_fix'"
                mat-menu-item
              >
                <img alt="" src="../../../assets/img/ventilation.svg" />
                <span>Ventiler ce flux</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="secondary">
      <mat-cell *matCellDef="let element">
        <mat-form-field class="mat-form-field-small" floatLabel="never">
          <input
            [(ngModel)]="element.comment"
            matInput
            placeholder="Veuillez saisir un commentaire"
            tabindex="2"
            type="text"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expanded">
      <mat-cell *matCellDef="let element">
        <div class="w-full">
          <div class="decla-detail__expanded-line__table mt-4">
            <div class="row small-table-header pr-0">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">Période</div>
                  <div class="col-5">Fourni par</div>
                  <div class="col-4">Fourni pour</div>
                </div>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-4">Code interne de collecte</div>
                  <div class="col-4">Code interne de traitement</div>
                  <div class="col-2">Code interne</div>
                  <div class="col-2">OLED</div>
                </div>
              </div>
            </div>
            <div class="row small-table-content">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">{{ element.flowPeriod }}</div>
                  <div
                    class="col-5 ellipsis"
                    matTooltipPosition="above"
                    [matTooltip]="element.importEvent?.reporter?.name"
                  >
                    {{ element.importEvent?.reporter?.name }}
                  </div>
                  <div class="col-4 ellipsis" matTooltipPosition="above" [matTooltip]="element.validator?.name">
                    {{ element.validator?.name }}
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="row">
                  <div
                    class="col-4 ellipsis"
                    matTooltipPosition="above"
                    matTooltip="{{ element.provenanceCodeMyCode?.code }} {{ element.provenanceCodeMyCode?.label }}"
                  >
                    {{ element.provenanceCodeMyCode?.code }} {{ element.provenanceCodeMyCode?.label }}
                  </div>
                  <div
                    class="col-4 ellipsis"
                    matTooltipPosition="above"
                    matTooltip="{{ element.internalMyCode?.code }} {{ element.internalMyCode?.label }}"
                  >
                    {{ element.internalMyCode?.code }} {{ element.internalMyCode?.label }}
                  </div>
                  <div
                    class="col-2 ellipsis"
                    matTooltipPosition="above"
                    matTooltip="{{ element.codeLmodMyCode?.code }} {{ element.codeLmodMyCode?.label }}"
                  >
                  {{ element.codeLmodMyCode?.code }} {{ element.codeLmodMyCode?.label }}
                  </div>
                  <div
                    class="col-2 ellipsis"
                    matTooltipPosition="above"
                    matTooltip="{{ element.codeOled?.code }} {{ element.codeOled?.label }}"
                  >
                    {{ element.codeOled?.code }} {{ element.codeOled?.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="decla-detail__expanded-line__actions my-4">
            <button
              class="btn-link"
              (click)="openWasteflowDialog(element)"
              [disabled]="
                submitting || !disableEditFields || showEdit(element) || element.wasteFlowStatus.code === 'closure'
              "
            >
              <mat-icon>edit</mat-icon>
              <span>Editer</span>
            </button>
            <button
              class="btn-link"
              (click)="openHistoryDialog(element.id)"
              [disabled]="submitting || !disableEditFields"
            >
              <mat-icon>chat_bubble_outline</mat-icon>
              <span>Voir modifications et commentaires</span>
            </button>
            <button
              class="btn-link"
              (click)="openVentilationDialog(element)"
              *ngIf="element.wasteFlowStatus.code === 'to_fix'"
            >
              <img alt="" src="../../../assets/img/ventilation-primary.svg" />
              <span>Ventiler ce flux</span>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row id="wasteflowTableHeader" *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.decla-detail__row--error]="row.error || row.aberrantJsonInfoFront"
      [class.decla-detail__row--is-last]="row.isLastOfGroup && !row.expanded"
      [class.decla-detail__row--light]="
        (row.statusWrtInventory === 'complementary' &&
          (!row.newStatusWrtInventory || row.newStatusWrtInventory === 'complementary')) ||
        row.newStatusWrtInventory === 'complementary' ||
        (currentView === V.ENTERPRISE &&
          row.isComplementary &&
          row.statusWrtInventory === 'inventory' &&
          !row.newStatusWrtInventory)
      "
      [class.decla-detail__row--success]="
        (row.isFirstOfGroup &&
          (row.statusWrtInventory !== 'complementary' ||
            (row.statusWrtInventory === 'complementary' &&
              row.newStatusWrtInventory &&
              row.newStatusWrtInventory !== 'complementary')) &&
          row.newStatusWrtInventory !== 'complementary') ||
        (currentView === V.ENTERPRISE &&
          ((row.statusWrtInventory === 'integrated_to_inventory' &&
            (!row.newStatusWrtInventory || row.newStatusWrtInventory === 'integrated_to_inventory')) ||
            row.newStatusWrtInventory === 'integrated_to_inventory' ||
            (!row.isComplementary && row.statusWrtInventory === 'inventory' && !row.newStatusWrtInventory)))
      "
    ></mat-row>
    <mat-row
      *matRowDef="let row; columns: ['expanded']"
      [class.decla-detail__row--is-last]="row.isLastOfGroup && row.expanded"
      [class.d-none]="!row.expanded"
      [class.decla-detail__row--light]="
        (row.statusWrtInventory === 'complementary' &&
          (!row.newStatusWrtInventory || row.newStatusWrtInventory === 'complementary')) ||
        row.newStatusWrtInventory === 'complementary' ||
        (currentView === V.ENTERPRISE &&
          row.isComplementary &&
          row.statusWrtInventory === 'inventory' &&
          !row.newStatusWrtInventory)
      "
      [class.decla-detail__row--success]="
        (row.isFirstOfGroup &&
          (row.statusWrtInventory !== 'complementary' ||
            (row.statusWrtInventory === 'complementary' &&
              row.newStatusWrtInventory &&
              row.newStatusWrtInventory !== 'complementary')) &&
          row.newStatusWrtInventory !== 'complementary') ||
        (currentView === V.ENTERPRISE &&
          ((row.statusWrtInventory === 'integrated_to_inventory' &&
            (!row.newStatusWrtInventory || row.newStatusWrtInventory === 'integrated_to_inventory')) ||
            row.newStatusWrtInventory === 'integrated_to_inventory' ||
            (!row.isComplementary && row.statusWrtInventory === 'inventory' && !row.newStatusWrtInventory)))
      "
      class="decla-detail__expanded-line"
    ></mat-row>
    <mat-row
      *matRowDef="let row; columns: ['secondary']"
      [class.d-none]="!row.showCommentLine"
      class="decla-detail__comment-line"
    ></mat-row>
  </mat-table>
</div>
<app-table-pagination
  (updatePage)="updatePage($event)"
  [currentPage]="currentPage"
  [itemsPerPage]="itemsPerPage"
  [pageLength]="pageLength"
  [shouldHide]="currentView === V.DOUBLON"
></app-table-pagination>
