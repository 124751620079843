import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Observable, of, Subscription } from 'rxjs';
import { HistoryDialogComponent } from 'src/app/modules/wasteflows/history-dialog/history-dialog.component';
import { WasteflowDialogComponent } from 'src/app/modules/wasteflows/wasteflow-dialog/wasteflow-dialog.component';
import { WasteflowMassEditDialogComponent } from 'src/app/modules/wasteflows/wasteflow-mass-edit-dialog/wasteflow-mass-edit-dialog.component';
import { WasteflowVentilationDialogComponent } from 'src/app/modules/wasteflows/wasteflow-ventilation-dialog/wasteflow-ventilation-dialog.component';
import { Code, Unit } from '../../model/code.model';
import { StatusInventory, StatusValidation } from '../../model/import-event.model';
import { Organisation, OrganisationType } from '../../model/organisation.model';
import { Wasteflow, WasteflowSorting, WasteFlowStatus, WasteflowViews } from '../../model/wasteflow.model';
import { CodeService } from '../../services/code.service';
import { UnitService } from '../../services/unit.service';
import { WasteflowService } from '../../services/wasteflow.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { map } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/states/app.state';
import { getSelectedYear$ } from '../../../core/store/selectors/app.selectors';
import { MasseModificationService } from '../../services/mass-modification.service';
import { DataSharingService } from '../../services/data-sharing.service';

@Component({
  selector: 'app-wasteflows-table',
  templateUrl: './wasteflows-table.component.html',
  styleUrls: ['./wasteflows-table.component.scss']
})
export class WasteflowsTableComponent implements OnInit, OnDestroy {
  @Input() disableEditFields: boolean;
  @Input() submitting: boolean;
  @Output() updateDisableEditFields = new EventEmitter<boolean>();
  @Input() selectedCodeLmod?: Code[] | string[];

  // For wasteflow standard view
  @Input() currentView: WasteflowViews;
  @Input() selectedImportEventId: number;
  @Input() selectedProvenanceSite?: number;
  @Input() selectedDestinationSite?: number;
  @Input() selectedWasteflowStatus?: string;
  @Input() wasteflowIds?: string[];
  @Output() changeView = new EventEmitter<WasteflowViews>();

  // For site flow view
  @Input() selectedSiteId?: number;

  public count: number = 0;
  public statuses: WasteFlowStatus[] = [];
  public units: Unit[] = [];
  public organisation: Organisation;
  public data: Wasteflow[];
  public lmodCodes: Code[] = [];
  public treatments: Code[] = [];
  public provenanceCodes: Code[] = [];
  public pageLength = 1;
  public itemsPerPage = 100;
  public currentPage = 0;
  public canStillSaveOnEnterprise = false;
  public V = WasteflowViews;
  public statusValidation = StatusValidation;
  public organisationType = OrganisationType;
  public sortedBy = null;
  public toToValidateTooltip = 'Passer le statut à "À valider"';
  public displayedColumns: string[] = [
    'source',
    'codeLmod',
    'origine',
    'collecte',
    'destination',
    'traitement',
    'quantite',
    'convertedQty',
    'statusValidation',
    'actions'
  ];
  public sorting: WasteflowSorting[] = [
    {
      dir: 'asc',
      sort: 'sourceRef',
      displaySort: 'sourceRef'
    }
  ];
  private dialog$: Subscription;
  private subscriptions$: Subscription[] = [];
  public showSuccessMessage = false;
  private massSubscription: Subscription;

  constructor(
    private wasteflowService: WasteflowService,
    private codeService: CodeService,
    private dialog: MatDialog,
    private unitService: UnitService,
    private readonly store$: Store<AppState>,
    private masseModificationService: MasseModificationService,
    private dataSharingService: DataSharingService
  ) {
    this.massSubscription = this.masseModificationService.data$.subscribe(data => {
      this.showSuccessMessage = true;
    });
  }

  /**
   * Whether the form can be saved
   * Only way to invalidate a saving is if a value is negative
   */
  public get canSave(): boolean {
    return this.data && this.data.findIndex(data => data.newOriginQty < 0) === -1;
  }

  ngOnInit(): void {
    this.organisation = JSON.parse(localStorage.getItem('organisation'));
    this.getWasteflowStatuses();
    this.getUnits();
    this.getLmodCodes();
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  /**
   * Subscribe the global selected year changes.
   */
  public subscribeSelectedYear(): void {
    this.subscriptions$.push(this.store$.select(getSelectedYear$).subscribe(() => this.getWasteflows()));
  }

  /**
   * Open wasteflows dialog to add or to edit data
   * @param data - Data to edit. If there's no data, it will open the creation modal
   */
  public openWasteflowDialog(data?: Wasteflow): void {
    const dialogRef = this.dialog.open(WasteflowDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldReload => {
      if (shouldReload) {
        this.getWasteflows();
      }
    });
  }

  /**
   * Open wasteflows mass edit dialog to edit data
   * @param data - Data to edit. If there's no data, it will open the creation modal
   */
  public openWasteflowMassEditDialog(data?: Wasteflow): void {
    const dialogRef = this.dialog.open(WasteflowMassEditDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: ['form-dialog'],
      data: this.count
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldReload => {
      if (shouldReload) {
        this.getWasteflows();
      }
    });
  }

  /**
   * Sort table
   * @param sort - Column to sort it to and direction
   */
  public sortData(sort: Sort): void {
    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        if (!sort.active || sort.direction === '') {
          this.sorting = [
            {
              dir: null,
              sort: null,
              displaySort: null
            }
          ];
          return;
        }

        this.sorting[0].dir = sort.direction;

        switch (sort.active) {
          case 'source':
            this.sorting[0].sort = 'importEventFrontIdOrder';
            this.sorting[0].displaySort = 'importEventFrontIdOrder';
            break;
          case 'codeLmod':
            this.sorting[0].sort = 'codeLmod.code';
            this.sorting[0].displaySort = 'codeLmod.code';
            break;
          case 'origine':
            this.sorting[0].sort = 'provenanceSite.name';
            this.sorting[0].displaySort = 'provenanceSite.name';
            break;
          case 'destination':
            this.sorting[0].sort = 'destinationSite.name';
            this.sorting[0].displaySort = 'destinationSite.name';
            break;
          case 'traitement':
            this.sorting[0].sort = 'treatment.label';
            this.sorting[0].displaySort = 'treatment.label';
            break;
          case 'quantite':
            this.sorting[0].sort = 'originQty';
            this.sorting[0].displaySort = 'originQty';
            break;
          case 'convertedQty':
            this.sorting[0].sort = 'convertedQty';
            this.sorting[0].displaySort = 'convertedQty';
            break;
          case 'statusValidation':
            this.sorting[0].sort = this.getCurrentStatusSort();
            this.sorting[0].displaySort = 'statusValidation';
            break;
          default:
            this.sorting[0].sort = this.getCurrentStatusSort();
            this.sorting[0].displaySort = 'statusValidation';
            break;
        }
        this.getWasteflows();
      }
    });
    this.subscriptions$.push(this.dialog$);
  }

  /**
   * Update current sorting
   * @param sorting - New sorting
   */
  public updateSorting(sorting: WasteflowSorting[]): void {
    this.sorting = sorting;
  }

  /**
   * Open waste flow ventilation dialog of a wasteflow
   * @param wasteflow - wasteflow
   */
  public openVentilationDialog(wasteflow: Wasteflow): void {
    this.wasteflowService.getWasteFlowIsDedoublonned(wasteflow.id).subscribe(isDedoublonned => {
      if (isDedoublonned) {
        this.dialog
          .open(WasteflowVentilationDialogComponent, {
            disableClose: true,
            width: '80vw',
            panelClass: ['wasteflow-ventilation-dialog'],
            data: {
              wasteflow,
              units: this.units,
              lmodCodes: this.lmodCodes,
              treatments: this.treatments
            }
          })
          .afterClosed()
          .subscribe(shouldReload => {
            if (shouldReload) {
              this.showSuccessMessage = true;
              this.getWasteflows();
              setTimeout(() => {
                this.showSuccessMessage = false;
              }, 10000);
            }
          });
      } else {
        this.dialog
          .open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
              title: 'Doublons détectés',
              text: 'Cette donnée a des doublons. Merci de la traiter sur la page dédiée.',
              customBtn: 'Passer en mode dédoublonnement'
            }
          })
          .afterClosed()
          .subscribe(shouldRedirect => {
            if (shouldRedirect) {
              this.changeView.emit(WasteflowViews.DOUBLON);
            }
          });
      }
    });
  }

  /**
   * Save changes on wasteflows
   */
  public saveChanges(): void {
    this.submitting = true;
    let counter = 0;

    if (this.currentView === this.V.MAIN) {
      let errorsCounter = 0;

      // Submit all data
      this.data.forEach((data, index) => {
        // Check if there are any changes in the data or status
        if (this.hasChangedData(data) || data.newStatus) {
          if (this.hasChangedData(data)) {
            // If a data has been changed, change status to "TOVALIDATE"
            data.newStatus = this.statuses.find(stat => stat.code === StatusValidation.TOVALIDATE);
          }

          const detail: { [k: string]: any } = {
            wasteFlowStatus: data.newStatus['@id'],
            originQty: data.newOriginQty,
            originUnit: data.newOriginUnitId,
            treatment: data.newTreatment['@id'],
            provenanceCode: data.newProvenanceCode['@id'],
            codeLmod: data.newCodeLmod['@id']
          };

          if (data.comment) {
            detail.comment = data.comment;
          }

          // Update wasteflow element
          this.wasteflowService.updateWasteflow(data.id, detail).subscribe(
            response => {
              this.data[index] = response;
              this.handleWasteflowElement(this.data[index]);

              counter++;
              // If we've been through all data, reload all content to be updated
              if (counter === this.data.length && errorsCounter === 0) {
                this.showSuccessMessage = true;
                this.getWasteflows();
                setTimeout(() => {
                  this.showSuccessMessage = false;
                }, 10000);
              }

              if (counter === this.data.length && errorsCounter > 0) {
                this.handlePostUpdate();
              }
            },
            error => {
              this.submitting = false;
              errorsCounter++;
              counter++;
              if (error.error && error.error.errorMessage) {
                this.data[index].error = error.error.errorMessage;
              }

              if (counter === this.data.length) {
                this.handlePostUpdate();
              }
            }
          );
        } else {
          counter++;

          // If we've been through all data, reload all content to be updated
          if (counter === this.data.length) {
            this.getWasteflows();
          }
        }
      });
    } else {
      // Submit all data
      this.data.forEach(data => {
        // Check if there are any changes on the inventory status
        if (
          data.newStatusWrtInventory ||
          (this.canStillSaveOnEnterprise && data.statusWrtInventory === StatusInventory.INVENTORY)
        ) {
          // Update wasteflow element
          let newStatus: string;
          if (data.newStatusWrtInventory) {
            newStatus = data.newStatusWrtInventory;
          } else if (this.canStillSaveOnEnterprise) {
            if (data.isComplementary) {
              newStatus = StatusInventory.COMPLEMENTARY;
            } else {
              newStatus = StatusInventory.INTEGRATED;
            }
          }
          this.wasteflowService
            .updateWasteflow(data.id, {
              statusWrtInventory: newStatus
            })
            .subscribe(
              () => {
                counter++;
                if (counter === this.data.length) {
                  this.getWasteflows();
                }
              },
              () => {
                this.submitting = false;
              }
            );
        } else {
          counter++;

          // If we've been through all data, reload all content to be updated
          if (counter === this.data.length) {
            this.getWasteflows();
          }
        }
      });
    }
  }

  /**
   * Get list of wasteflows
   */
  public getWasteflows(): void {
    if (!this.selectedSiteId || (this.selectedSiteId && this.selectedCodeLmod?.length)) {
      let extra: string;
      if (this.currentView === this.V.DOUBLON) {
        extra = 'notStatusWrtInventory=private';
      } else if (this.currentView === this.V.ENTERPRISE) {
        extra = 'importEvent.reporter.type=enterprise&notStatusWrtInventory=private';
      } else if (this.selectedSiteId) {
        extra = `sitePd[]=${this.selectedSiteId}&statusWrtInventory[]=integrated_to_inventory&statusWrtInventory[]=inventory&notWasteFlowStatus=9,10`;
      }
      this.wasteflowService
        .getWasteflows(
          this.organisation.id,
          this.selectedImportEventId ? this.selectedImportEventId : null,
          this.sorting,
          // Todo: remove this quick type search when implementing multiselect in wasteflow.
          this.selectedCodeLmod?.length && typeof this.selectedCodeLmod[0] === 'number'
            ? this.selectedCodeLmod
            : map(this.selectedCodeLmod, 'id'),
          this.selectedProvenanceSite,
          this.selectedDestinationSite,
          this.selectedWasteflowStatus,
          extra,
          this.currentPage,
          this.wasteflowIds
        )
        .subscribe(details => {
          this.dataSharingService.setDetails(details);
          this.submitting = false;
          this.disableEditFields = true;
          this.updateDisableEditFields.emit(this.disableEditFields);
          this.pageLength = Math.ceil(details['hydra:totalItems'] / this.itemsPerPage);
          this.count = details['hydra:totalItems'];
          if (this.pageLength !== 0 && this.currentPage >= this.pageLength) {
            this.currentPage = 0;
            this.getWasteflows();
          } else {
            this.handleWasteflows(details['hydra:member']);
          }
        });
    } else {
      this.data = [];
    }
  }

  /**
   * Toggle wasteflow element to change its inventory status
   * @param wasteflow - Wasteflow doublon element to toggle status for
   */
  public toggleDoublonElement(wasteflow: Wasteflow): void {
    this.disableEditFields = false;
    this.updateDisableEditFields.emit(this.disableEditFields);

    if (wasteflow.newStatusWrtInventory) {
      // If element has already been modified once, switch between complementary and integrated
      wasteflow.newStatusWrtInventory =
        wasteflow.newStatusWrtInventory === StatusInventory.INTEGRATED
          ? StatusInventory.COMPLEMENTARY
          : StatusInventory.INTEGRATED;
    } else {
      if (wasteflow.statusWrtInventory !== StatusInventory.INVENTORY) {
        // If element has not been touched yet but the status was already changed earlier, switch between complementary and integrated
        wasteflow.newStatusWrtInventory =
          wasteflow.statusWrtInventory === StatusInventory.INTEGRATED
            ? StatusInventory.COMPLEMENTARY
            : StatusInventory.INTEGRATED;
      } else {
        // If element is "neutral" (= default, inventory), switch it to integrated and swith the others of the same group to complementary
        wasteflow.newStatusWrtInventory = StatusInventory.INTEGRATED;
        const elementsOfTheSameGroup = this.data.filter(
          d => d.duplicateGroupId === wasteflow.duplicateGroupId && d.id !== wasteflow.id
        );
        elementsOfTheSameGroup.forEach(el => (el.newStatusWrtInventory = StatusInventory.COMPLEMENTARY));
      }
    }
  }

  public updatePage(page: number): void {
    this.dialog$ = this.askForConfirmation().subscribe(result => {
      if (result) {
        this.currentPage = page;
        this.getWasteflows();
      }
    });
    this.subscriptions$.push(this.dialog$);
  }

  /**
   * Toggle status
   * @param element - Element to toggle status for
   * @param status - Status to toggle
   */
  public toggleStatus(element: Wasteflow, status: StatusValidation): void {
    this.disableEditFields = false;
    this.updateDisableEditFields.emit(this.disableEditFields);

    if (status === StatusValidation.TOFIX) {
      if (element.newStatus && element.newStatus.code === StatusValidation.TOFIX) {
        element.showCommentLine = false;
        element.comment = null;
      } else {
        element.showCommentLine = true;
      }
    }

    // If a change has occured on one of the field, the "Set to to validate" button is set to active.
    // If user clicks on it, we should put back the original value for the fields
    if (
      element.newStatus &&
      element.newStatus.code === StatusValidation.TOVALIDATE &&
      element.newStatus.code !== element.wasteFlowStatus.code
    ) {
      element.newOriginUnitId = element.originUnit['@id'];
      element.newCodeLmod = element.codeLmod;
      element.newTreatment = element.treatment;
      element.newProvenanceCode = element.provenanceCode;
      element.newOriginQty = element.originQty;
      element.newStatus = element.wasteFlowStatus;
      element.showCommentLine = false;
      element.comment = null;
      return;
    }

    // If clicks on active button = set old status
    if (element.newStatus && element.newStatus.code === status) {
      element.newStatus = element.wasteFlowStatus;
    } else {
      // If clicks on unactive button = set new status
      element.newStatus = this.statuses.find(stat => stat.code === status);
    }
  }

  /**
   * Whether the "Préclôturer" button should be visible on the line
   * @param wasteflow - Wasteflow
   */
  public shouldShowPreclosure(wasteflow: Wasteflow): boolean {
    if (this.organisation.type === OrganisationType.GESDEC) {
      return (
        wasteflow.wasteFlowStatus.code === StatusValidation.VALID ||
        wasteflow.wasteFlowStatus.code === StatusValidation.NEW ||
        wasteflow.wasteFlowStatus.code === StatusValidation.TOVALIDATE
      );
    } else {
      return false;
    }
  }

  /**
   * Open history & comments dialog of a wasteflow
   * @param data - ID of the wasteflow
   */
  public openHistoryDialog(data: number): void {
    const dialogRef = this.dialog.open(HistoryDialogComponent, {
      disableClose: true,
      panelClass: ['history-dialog'],
      data
    });

    this.dialog$ = dialogRef.afterClosed().subscribe(shouldReload => {
      if (shouldReload) {
        this.getWasteflows();
      }
    });
  }

  /**
   * Check if a line can be validated = Can pass status to "VALID"
   * @param element - Element we want to validate
   */
  public showValidate(element: Wasteflow): boolean {
    return (
      this.organisation &&
      this.organisation.type !== OrganisationType.GESDEC &&
      this.organisation.type !== OrganisationType.SERVICEPROVIDER &&
      (element.wasteFlowStatus.code === StatusValidation.NEW ||
        element.wasteFlowStatus.code === StatusValidation.TOVALIDATE)
    );
  }

  /**
   * Check if a line can be edited.
   * @param element - Element we want to edit
   */
  public showEdit(element: Wasteflow): boolean {
    return (
      element.wasteFlowStatus.code !== StatusValidation.TOFIX &&
      this.organisation &&
      this.organisation.type !== OrganisationType.GESDEC &&
      this.organisation.type !== OrganisationType.ECO21
    );
  }

  /**
   * Handle wasteflows data
   * @param wasteflows - wasteflows
   */
  public handleWasteflows(wasteflows: Wasteflow[]): void {
    // Set up data
    this.data = wasteflows;
    this.data.forEach(data => {
      this.handleWasteflowElement(data);
    });

    if (this.currentView === this.V.ENTERPRISE) {
      this.canStillSaveOnEnterprise = this.data.findIndex(d => d.statusWrtInventory === StatusInventory.INVENTORY) > -1;
    } else {
      this.canStillSaveOnEnterprise = false;
    }
    this.getTreatmentTypes().then(treatments => {
      this.data.forEach((data, index) => {
        data.filteredTreatments = treatments;
        data.filteredProvenanceCodes = treatments;
      });
    });
    if (this.currentView === this.V.DOUBLON) {
      this.data.forEach((data, index) => {
        if (this.data[index - 1] && this.data[index - 1].duplicateGroupId === data.duplicateGroupId) {
          data.isFirstOfGroup = false;
        } else {
          data.isFirstOfGroup = true;
        }

        if (
          !this.data[index + 1] ||
          (this.data[index + 1] && this.data[index + 1].duplicateGroupId === data.duplicateGroupId)
        ) {
          data.isLastOfGroup = false;
        } else {
          data.isLastOfGroup = true;
        }
      });

      // If there is more content than received, remove the last group of the page as it might not be complete
      if (this.pageLength > 1) {
        const last = this.data[this.data.length - 1].duplicateGroupId;
        this.data = this.data.filter(d => d.duplicateGroupId !== last);
      }
    }
  }

  /**
   * Set the default wasteflow new values
   * @param wasteflow - Wasteflow
   */
  public handleWasteflowElement(wasteflow: Wasteflow): void {
    wasteflow.newOriginUnitId = wasteflow.originUnit['@id'];
    wasteflow.newOriginQty = wasteflow.originQty;
    wasteflow.newTreatment = wasteflow.treatment;
    wasteflow.newProvenanceCode = wasteflow.provenanceCode;
    wasteflow.newCodeLmod = wasteflow.codeLmod;
    this.filterUnits(wasteflow);
  }

  /**
   * Whether the "Activer la/Demande de correction" button should be visible on the line
   * @param wasteflow - Wasteflow
   */
  public shouldShowAskForCorrection(wasteflow: Wasteflow): boolean {
    return (
      wasteflow.wasteFlowStatus.code === StatusValidation.VALID ||
      wasteflow.wasteFlowStatus.code === StatusValidation.NEW ||
      wasteflow.wasteFlowStatus.code === StatusValidation.TOVALIDATE ||
      (this.organisation.type === OrganisationType.GESDEC &&
        wasteflow.wasteFlowStatus.code === StatusValidation.PRECLOSURE)
    );
  }

  /**
   * Get origin name based on its ID
   * @param id - ID to get the name of
   */
  public getOriginParam(unitId: string, param: string): string {
    if (this.units.find(unit => unit['@id'] === unitId)) {
      return this.units.find(unit => unit['@id'] === unitId)?.[param];
    } else {
      return '';
    }
  }

  /**
   * If element is negative, then it's invalid
   * @param el - Value for origin quantity
   */
  public isInvalid(el: number): boolean {
    return !el || el < 0;
  }

  /**
   * Called when lmod field is updated
   * @param value - New value for Lmod code
   * @param wasteflow - Wasteflow element to update
   */
  public onUpdateLmodField(value: Code, wasteflow: Wasteflow): void {
    wasteflow.newCodeLmod = value;
    this.filterUnits(wasteflow);
    this.setToToValidateStatus(wasteflow);
  }

  /**
   * Set wasteflow element to "To validate"
   * @param element - Element to set to "To validate"
   */
  public setToToValidateStatus(element: Wasteflow): void {
    this.disableEditFields = false;
    this.updateDisableEditFields.emit(this.disableEditFields);

    element.newStatus = this.statuses.find(stat => stat.code === StatusValidation.TOVALIDATE);
    element.showCommentLine = true;
  }

  /**
   * Filter units from specified lmod code.
   */
  public filterUnits(wasteflow: Wasteflow): void {
    wasteflow.filteredUnits = this.unitService.filterUnits(wasteflow.newCodeLmod?.['@id'], this.units);
  }

  public treatmentInputChange(element: Wasteflow, val: string): void {
    element.filteredTreatments = of(this.wasteflowService._codeFilter(this.treatments, val));
  }

  public provenanceCodeInputChange(element: Wasteflow, val: string): void {
    element.filteredProvenanceCodes = of(this.wasteflowService._codeFilter(this.provenanceCodes, val));
  }

  public selectTreatment(element: Wasteflow, val): void {
    element.newTreatment = val.option.value;
    this.setToToValidateStatus(element);
  }

  public selectProvenanceCode(element: Wasteflow, val): void {
    element.newProvenanceCode = val.option.value;
    this.setToToValidateStatus(element);
  }

  /**
   * Get the correction tooltip label.
   */
  public getCorrectionTooltip(element: Wasteflow): string {
    if (element.newStatus && element.newStatus.code === 'to_fix') {
      return 'Annuler la demande de correction';
    }
    return this.organisation && this.organisation.type === 'service_provider'
      ? 'Activer la correction'
      : 'Demande de correction';
  }

  /**
   * If user has unsaved changes, ask to confirm the action. Otherwise changes won't be saved
   */
  private askForConfirmation(): Observable<boolean> {
    if (!this.submitting && this.disableEditFields) {
      return of(true);
    } else {
      return this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Voulez-vous vraiment quitter la page?',
            text: 'Vous avez des modifications non sauvegardées sur cette page.'
          }
        })
        .afterClosed();
    }
  }

  /**
   * If sort by status, define by which order to request the back depending on current role
   * @returns - Sort order to send to the BE
   */
  private getCurrentStatusSort(): string {
    switch (this.organisation.type) {
      case OrganisationType.COMMUNE:
        return 'wasteFlowStatus.orderCommune';
      case OrganisationType.SERVICEPROVIDER:
        return 'wasteFlowStatus.orderServiceProvider';
      case OrganisationType.ECO21:
        return 'wasteFlowStatus.orderEco21';
      case OrganisationType.ENTERPRISE:
        return 'wasteFlowStatus.orderEnterprise';
      case OrganisationType.GESDEC:
        return 'wasteFlowStatus.orderGesdec';
      default:
        return 'wasteFlowStatus.orderCommune';
    }
  }

  /**
   * Return if data in one of the editable field has changed
   * @param detail - wasteflow line
   */
  private hasChangedData(detail: Wasteflow): boolean {
    return (
      detail.originQty !== detail.newOriginQty ||
      detail.treatment['@id'] !== detail.newTreatment?.['@id'] ||
      detail.provenanceCode['@id'] !== detail.newProvenanceCode?.['@id'] ||
      detail.originUnit['@id'] !== detail.newOriginUnitId ||
      detail.codeLmod['@id'] !== detail.newCodeLmod['@id']
    );
  }

  /**
   * Handle data post update, in case one of them had an error
   */
  private handlePostUpdate(): void {
    this.disableEditFields = false;
    this.updateDisableEditFields.emit(this.disableEditFields);
    const temporaryData = this.data;
    this.data = [];

    setTimeout(() => {
      this.data = temporaryData;
    }, 0);
  }

  /**
   * Get list of treatments for Treatment field on wasteflows
   */
  private getTreatmentTypes(): Promise<Observable<Code[]>> {
    return new Promise((resolve, reject) => {
      this.codeService.getTreatmentTypes().subscribe(
        treatments => {
          treatments.forEach(code => (code.displayLabel = `${code.code} - ${code.label}`));
          this.treatments = treatments;
          resolve(of(treatments));
        },
        error => reject(error)
      );
    });
  }

  /**
   * Get list of LMod codes for LMod field on wasteflows
   */
  private getLmodCodes(): void {
    this.codeService.getLmod().subscribe(lmodCodes => {
      lmodCodes.forEach(code => (code.displayLabel = `${code.code} ${code.label}`));
      this.lmodCodes = lmodCodes;
    });
  }

  /**
   * Get declaration status options
   */
  private getWasteflowStatuses(): void {
    this.wasteflowService.getWasteflowStatus().subscribe(statuses => {
      this.statuses = statuses;
    });
  }

  /**
   * Get list of units for Quantity unit field on wasteflows
   */
  private getUnits(): void {
    this.unitService.getUnits().subscribe(units => {
      this.units = units;
    });
  }
}
