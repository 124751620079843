import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Organisation, OrganisationType } from 'src/app/shared/model/organisation.model';
import { environment } from 'src/environments/environment';
import { ImportEventService } from 'src/app/shared/services/import-event.service';
import { OrganisationSimple } from 'src/app/shared/model/organisation.model';
import { Site } from 'src/app/shared/model/site.model';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-download-event-dialog',
  templateUrl: './download-event-dialog.component.html',
  styleUrls: ['./download-event-dialog.component.scss']
})
export class DownloadEventDialogComponent implements OnInit {
  public submitted = false;
  public showErrors = false;
  public showSuccess = false;
  public showWaiting = false;
  public fileData: FormData;
  public downloadForm: FormGroup;
  public organisation: Organisation;
  public organisations: OrganisationSimple[];
  public sites: Site[];
  public filteredSites: Observable<Site[]>;

  private defaultStartDate = new Date('2021-01-01T22:00:00.000Z');
  private defaultEndDate = new Date('2021-12-31T22:00:00.000Z');

  constructor(
    private importEventService: ImportEventService,
    public dialogRef: MatDialogRef<DownloadEventDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: Organisation,
    private formBuilder: FormBuilder,
    private siteService: SiteService
  ) {}

  /**
   * Get form control
   */
  public get f(): { [key: string]: AbstractControl } {
    return this.downloadForm.controls;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const today = new Date();
    this.defaultStartDate.setFullYear(today.getFullYear() - 1);
    this.defaultEndDate.setFullYear(today.getFullYear() - 1);
    this.downloadForm = this.formBuilder.group({
      // Site is only mandatory for service provider
      site: ['', this.data.type === 'service_provider' ? Validators.required : null],
      privacy: ['inventory', Validators.required],
      periodFrom: [this.defaultStartDate, Validators.required],
      periodTo: [this.defaultEndDate, Validators.required]
    });

    this.showWaiting = false;

    this.siteService.getSites(this.data.type === OrganisationType.GESDEC ? null : this.data.id).subscribe(value => {
      this.sites = value;
      this.sites.forEach(site => {
        if (site.organisation) {
          site.autocompleteDisplayValue = `${site.organisation.name} - ${site.name}`;
        } else {
          site.autocompleteDisplayValue = site.name;
        }
      });

      this.filteredSites = this.f.site.valueChanges.pipe(
        startWith(''),
        map(val => this._filter(val))
      );

      if (this.data.type !== OrganisationType.GESDEC && this.sites && this.sites.length) {
        this.f.site.setValue(this.sites[0].id);
      }
    });

    this.f.periodTo.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.periodFrom.valueChanges.subscribe(() => {
      this.verifyValidityOfPeriod();
    });

    this.f.privacy.valueChanges.subscribe(() => {
      if (this.f.periodFrom.value && this.f.periodTo.value) {
        this.verifyValidityOfPeriod();
      }
    });

  }

  public displayFn(value: Site): string {
    return value ? value.autocompleteDisplayValue : '';
  }

  private downloadImportEventTemplate(siteId?: number): void {
    this.importEventService
      .generateTemplateBySite(siteId)
      .subscribe(
        template => (location.href = `${environment.apiUrl.split('/api')[0]}/download?file=${template.file_name}`)
      );
  }

  /**
   * Submit form
   */
  public submit(): void {
    this.downloadForm.markAsDirty();
    this.downloadForm.markAsTouched();
    this.downloadForm.markAllAsTouched();
    this.submitted = true;
    // If service provider, the form site value is just the ID, otherwise it's a full object
    const siteId = this.f.site.value;

    if (this.downloadForm.invalid) {
      return;
    }

    if (this.data.type === 'service_provider') {
        this.downloadImportEventTemplate(this.f.site.value);
    } else {
      if (siteId) {
        this.fileData = new FormData();
        this.fileData.append('siteId', siteId.id);
        // Create the file with id...
        this.downloadImportEventTemplate(siteId.id);
      } else {
        // Create the file as no organisation
        this.downloadImportEventTemplate();
      }
    }
  }

  public resetForm(): void {
    this.showErrors = false;
  }

  /**
   * Add validators to period in case inventory and not yearly period
   */
  private verifyValidityOfPeriod(): void {
    const periodFrom = new Date(this.f.periodFrom.value);
    const periodTo = new Date(this.f.periodTo.value);

    // If inventory, check that dates correspond to a yearly period (01.01.20xx - 31.12.20xx)
    if (
      this.f.privacy.value !== 'private' &&
      (periodFrom.getMonth() !== 0 ||
        periodFrom.getDate() !== 1 ||
        periodTo.getDate() !== 31 ||
        periodTo.getMonth() !== 11 ||
        periodTo.getFullYear() !== periodFrom.getFullYear())
    ) {
      this.f.periodFrom.setErrors({ incorrect: true });
      return;
    } else {
      setTimeout(() => {
        this.f.periodFrom.clearValidators();
        this.f.periodFrom.updateValueAndValidity();
      }, 0);
    }
  }

  /**
   * Filter site field
   * @param value - Value typed in
   */
  private _filter(value: any): Site[] {
    const filterValue = value && value.name ? value.name : value;
    return this.sites.filter(option =>
      option.autocompleteDisplayValue.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
}
