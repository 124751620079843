import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent {
  @Input() pageLength: number;
  @Input() autoUpdatePagination = true;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  @Input() shouldHide = false;
  @Output() updatePage = new EventEmitter<number>();

  public goToPreviousPage(): void {
    if (this.autoUpdatePagination) {
      this.currentPage -= 1;
      this.updatePage.emit(this.currentPage);
    } else {
      this.updatePage.emit(this.currentPage - 1);
    }
  }

  /**
   * Whether a box with ... should be seen on pagination
   */
  public shouldAddDots(el: number, i: number): boolean {
    return this.getVisiblePageButtons().length !== i + 1 && this.getVisiblePageButtons()[i + 1] !== el + 1;
  }

  public goToPageManually(page: number): void {
    if (this.autoUpdatePagination) {
      this.currentPage = page;
      this.updatePage.emit(this.currentPage);
    } else {
      this.updatePage.emit(page);
    }
  }

  public goToNextPage(): void {
    if (this.autoUpdatePagination) {
      this.currentPage += 1;
      this.updatePage.emit(this.currentPage);
    } else {
      this.updatePage.emit(this.currentPage + 1);
    }
  }

  /**
   * Get all the pages button
   */
  public getVisiblePageButtons(): Array<number> {
    if (this.pageLength > 1) {
      const firstPage = 0;
      const lastPage = this.pageLength - 1;
      const visiblePageSizeButtons = [0, lastPage];

      if (this.currentPage !== firstPage && this.currentPage !== lastPage) {
        visiblePageSizeButtons.push(this.currentPage);
      }

      if (this.currentPage + 1 > firstPage && this.currentPage + 1 < lastPage) {
        visiblePageSizeButtons.push(this.currentPage + 1);
      }

      if (this.currentPage - 1 > firstPage && this.currentPage - 1 < lastPage) {
        visiblePageSizeButtons.push(this.currentPage - 1);
      }

      return visiblePageSizeButtons.sort((a, b) => a - b);
    } else {
      return [];
    }
  }
}
